import React from 'react'
import { useDispatch } from 'react-redux'

import FormModal from './FormModal'
import { Form, Input } from '../controls'

import actions from '../../actions'
import { useModalData } from '../../redux/hooks/appHooks'

function EntityAttachmentEditModal() {
  const {
    entity,
    url,
  } = useModalData(EntityAttachmentEditModal.id)

  const dispatch = useDispatch()
  const onOk = async ({ title }) => {
    const entityId = entity.id
    const payload = {
      attachments: entity.attachments.map(attachment => attachment.url === url
        ? { ...attachment, title }
        : attachment
      ),
    }
    const meta = {
      entityId,
      entityType: entity.entityType,
      ancestry: entity.ancestry,
    }
    dispatch(actions.riskEntity.update(payload, meta))
  }

  const attachment = entity.attachments.find(attachment => attachment.url === url)

  return (
    <FormModal
      initialValues={{ title: attachment.title }}
      modalId={EntityAttachmentEditModal.id}
      onProcess={onOk}
    >
      {initialFocus => (
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: 'Title is required.' }]}
        >
          <Input ref={initialFocus} />
        </Form.Item>
      )}
    </FormModal>
  )
}
EntityAttachmentEditModal.id = 'EntityAttachmentEditModal'

export default EntityAttachmentEditModal
