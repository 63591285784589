import React from 'react'
/** @jsxImportSource @emotion/react */

export default function Label({ text }) {
  return (
    <label
      css={{
        pointerEvents: 'none',
        transitionProperty: 'transform',
        transitionDuration: '0.2s',
        transitionTimingFunction: 'ease-out',
        transform: 'translateY(-10px) scaleY(0.9)',
        opacity: '0',
        position: 'absolute',
        top: '-17px',
        right: '-2px',
        height: '25px',
        padding: '0 5px',
        backgroundColor: 'var(--fg-color)',
        color: 'white',
        fontSize: '16px',
        fontWeight: '700',
      }}
    >
      {text}
    </label>
  )
}
