function setObject(key, o) {
  window.localStorage.setItem(key, JSON.stringify(o))
}

function getObject(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

export {
  setObject,
  getObject,
}
