import _keyBy from 'lodash/keyBy'
import _groupBy from 'lodash/groupBy'
import { ValidRating } from '../types'

import { ParticipationSession } from './participationSession'
import { Criterion, CriterionData, CriterionPriority, RatingScaleConfig } from './Criterion'
import { prioritizeContext, RatingToPrioritizationAlgorithm } from './prioritization/quickPrioritization'
import { treeToString } from '../utils/str'

export const defaultCriteriaPrioritizationScaleConfig: RatingScaleConfig = {
  maxRatingLabel: 'More Important',
  minRatingLabel: 'Less Important',
  abstainLabel: 'No Opinion',
  maxRating: 10,
  minRating: 0,
  ratingScale: [],
}

export const defaultOptionRatingScaleConfig: RatingScaleConfig = {
  maxRatingLabel: 'Higher Perf.',
  minRatingLabel: 'Lower Perf.',
  abstainLabel: 'No Opinion',
  maxRating: 10,
  minRating: 0,
  ratingScale: [
    { label: 'High Performance', maxValue: 10 },
    { label: 'Med. Performance', maxValue: 20 / 3 },
    { label: 'Low Performance', maxValue: 10 / 3 },
  ],
}

/**
 * Implementation of Criterion.  Note that Criterion instances are intended to be constructed
 * by the Criteria class, so this implementation is not exported, but it accessable (as
 * interface Criterion) through the Criteria properties.
 */
class CriterionInternal implements Criterion {
  _data: CriterionData

  get id() { return this._data.id }
  get name() { return this._data.name }
  get abbrev() { return this._data.abbrev }
  get description() { return this._data.description || { $type: 'html', value: '' } }
  get type() { return this._data.type }
  get color() { return this._data.color }
  get optionRatingScaleConfig() { return this._data.optionRatingScaleConfig }

  _localPri: number | null = null
  _localPriByParticipantId: Record<string, number> = {}
  _ratings: ValidRating[] = []
  /**
   * This should only be called from Criteria instance.
   */
  setParticipationData(
    localPri: number | null,
    participantPriorities: Record<string, number>,
    ratings: ValidRating[],
  ) {
    this._localPri = localPri
    this._localPriByParticipantId = participantPriorities
    this._ratings = ratings
  }

  get pri(): Readonly<CriterionPriority> {
    // if it doesn't have a parent, it's a root, with an implicit global prioirty of 1
    const parentGlobal = this.parent ? this.parent.pri.global : 1
    const local = this._localPri
    const global = parentGlobal !== null && local !== null ? parentGlobal * local : null
    return { local, global }
  }
  get localPriByParticipantId(): Readonly<Record<string, number>> {
    return this._localPriByParticipantId
  }

  get ratings() {
    return this._ratings
  }

  /**
   * The label for this criterion, which includeds its ancestry (akin to a crumb trail).
   */
  label(options: { abbrev?: boolean, skipAncestors?: number, sep?: string } = {}): string {
    const {
      abbrev = false,
      sep = ' > '
    } = options
    let { skipAncestors = 0 } = options
    const criteria = [...this.ancestors, this]
    while(skipAncestors-- && criteria.length > 1) criteria.shift()
    return criteria.map(c => abbrev ? c.abbrev : c.name).join(sep)
  }

  parent: Criterion | null = null
  children: Criterion[] = []

  get ancestors(): Criterion[] {
    return this.parent ? [...this.parent.ancestors, this.parent] : []
  }
  get descendants(): Criterion[] {
    return [
      ...this.children,
      ...this.children.map(c => c.descendants).flat(),
    ]
  }
  get isRoot() { return !this.parent }
  get isLeaf() { return this.children.length === 0 }
  get isInternal() { return !this.isRoot && !this.isLeaf }

  get data() { return this._data }

  constructor(data: CriterionData) {
    this._data = data
  }

}

/**
 * Represents a coherent collection (or graph) of criteria (Criteiron instances).
 * This class is primarily for constructing Criterion instances, and providing access
 * methods (indexing by ID, identifying root and performance roots, in particular).
 * You can also attach a participation session, which enables the Criterion to have
 * prioritization data.
 */
export class Criteria {

  private _all: CriterionInternal[]
  private _byId: Record<string, CriterionInternal>
  private _root: CriterionInternal
  private _perfRoot: CriterionInternal

  private _participationSession?: ParticipationSession

  get byId(): Record<string, Criterion> { return this._byId }
  get all(): Criterion[] { return this._all }

  get contextCriteria(): Criterion[] {
    return this._all.filter(c => c.type === 'Performance' || c.type === 'Rated' && !c.isLeaf)
  }

  /**
   * The root criterion.
   */
  get root(): Criterion { return this._root }

  /**
   * The root performance criterion.
   */
  get perfRoot(): Criterion { return this._perfRoot }

  constructor(criteriaData: CriterionData[]) {
    this._all = criteriaData.map(cd => new CriterionInternal(cd))
    this._byId = _keyBy(this._all, 'id')
    let root: Criterion | undefined = undefined
    let perfRoot: Criterion | undefined = undefined
    this._all.forEach(c => {
      if(c._data.parentId) {
        const parent = this._byId[c._data.parentId]
        if(!parent) throw new Error(`parent ID refers to missing criterion: ${c._data.parentId}`)
        c.parent = parent
      } else {
        c.parent = null
        if(root) throw new Error(`multiple roots found`)
        root = c
      }
      if(c.type === 'Performance') {
        if(perfRoot) throw new Error(`multiple performance roots found`)
        perfRoot = c
      }
      c.children = this._all.filter(c2 => c2._data.parentId === c.id)
    })
    if(!root) throw new Error(`no root criterion found`)
    if(!perfRoot) throw new Error(`no root performance criteiron found`)
    this._root = root
    this._perfRoot = perfRoot
  }

  useParticipationSession(
    ps?: ParticipationSession,
    ratingToPrioritizationAlgorithm: RatingToPrioritizationAlgorithm = 'RecenterAndNormalize'
  ) {
    this._participationSession = ps
    // when a participation context is set, we can calculate priorities
    if(ps) {
      const byContext = _groupBy(ps.validRatings, 'contextId')
      this._root.setParticipationData(1, {}, [])
      this._perfRoot.setParticipationData(1, {}, [])
      this.contextCriteria.forEach(ctx => {
        const priByCri = prioritizeContext(ctx.children, byContext[ctx.id], ratingToPrioritizationAlgorithm)
        Object.entries(priByCri).forEach(([criterionId, { aggregate, byParticipantId }]) => {
          this._byId[criterionId].setParticipationData(
            aggregate,
            byParticipantId,
            (byContext[ctx.id] || []).filter(r => r.subjectId === criterionId),
          )
        })
      })
    } else {
      this._all.forEach(c => c.setParticipationData(null, {}, []))
    }
  }

  log() {
    console.group()
    console.log(
      treeToString(
        this.root,
        c => c.children,
        c => `${c.id} ${c.name} ${c.pri.local === null ? '-' : c.pri.local.toFixed(3)}`,
      )
    )
    console.groupEnd()
  }

}
