import { useState, useMemo, ReactElement } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { Criterion } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { Option } from '@vms/vmspro3-core/dist/nextgen/Option'

import { CycleSelect } from '../../CycleSelect'

const rechartsDarkFill = '#666'
const perfLabel = 'Total Perf.'

interface PerformanceChartProps {
  options: Option[]
  criteria: Criterion[],
}
export function PerformanceChart({
  options,
  criteria,
}: PerformanceChartProps): ReactElement {
  const data = useMemo(
    () => options.map(option => {
      if(!option) return

      const datum: Record<string, string | number | null> = {
        name: option.abbrev,
      }

      if(option.performanceGraph) {
        datum[perfLabel] = option.performanceGraph.value

        option.performanceGraph.descendants.forEach(perfNode => {
          if(perfNode.value !== null && perfNode.criterion.pri.global !== null) {
            const criterionLabel = perfNode.criterion.label({ skipAncestors: 2 })
            datum[criterionLabel] = perfNode.value * perfNode.criterion.pri.global
          }
        })
      }

      return datum
    }),
    [options]
  )

  const leafCriteriaSelectOptions = useMemo(
    () => criteria
      .filter(criterion => criterion.isLeaf)
      .map(criterion => ({
        value: criterion.id,
        label: criterion.label({ skipAncestors: 2 }),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    [criteria]
  )

  const [selectedCriterionId, setSelectedCriterionId] = useState(() => leafCriteriaSelectOptions[0]?.value)
  const selectedCriterion = criteria.find(criterion => criterion.id === selectedCriterionId)

  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} barCategoryGap="20%" barGap="-40">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            domain={[0, 10]}
            label={{
              angle: -90,
              position: 'insideLeft',
              style: { textAnchor: 'middle' },
              value: 'Perf Score',
            }}
            yAxisId="score"
          />
          <Tooltip formatter={(value: number) => value.toFixed(1)} />
          <Legend formatter={value => <span style={{ color: rechartsDarkFill }}>{value}</span>} />
          <Bar
            barSize={40}
            dataKey="Total Perf."
            fill="#aaa"
            yAxisId="score"
          />
          {selectedCriterion &&
            <Bar
              barSize={40}
              dataKey={selectedCriterion.label({ skipAncestors: 2 })}
              fill={selectedCriterion.color}
              yAxisId="score"
            />
          }
        </BarChart>
      </ResponsiveContainer>
      <CycleSelect
        onChange={setSelectedCriterionId}
        selectOptions={leafCriteriaSelectOptions}
        value={selectedCriterionId}
      />
    </>
  )
}
