/* @jsxImportSource @emotion/react */
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserAddOutlined from '@ant-design/icons/UserAddOutlined'
import { Color } from '@vms/vmspro3-core/dist/systemConsts'

import InviteUserModal from '../modals/InviteUserModal'
import PageHeader from './common/PageHeader'
import UsersTable from '../tables/UsersTable'
import { Spin, Table, Button } from '../controls'

import actions from '../../actions'
import useAuthz from '../../hooks/useAuthz'
import useEnforceUsersQuota from '../../hooks/useEnforceUsersQuota'
import { contentMargin } from '../styles/style-consts'
import { formatTimestamp } from '../../utils/formatUtils'
import { showModal } from '../../redux/actions'
import { selectDateFormat } from '../../redux/user/selectors'
import { useFetchUsers } from '../../redux/users/hooks'
import {
  usePendingInvitationsForAccount,
  useFetchPendingInvitationsForAccount,
} from '../../redux/account/hooks'
import { testId } from '../../test-automation'

function PendingInvitations() {
  const [pendingInvitations, loading, authorized] = usePendingInvitationsForAccount()
  const dateFormat = useSelector(selectDateFormat)
  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'fullName',
      },
      {
        title: 'Invited By',
        dataIndex: 'invitedByName',
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'Date Invited',
        dataIndex: 'created',
        render: ts => formatTimestamp(ts, dateFormat),
      },
    ],
    [dateFormat]
  )

  if(authorized && loading) return <Spin />
  if(!authorized || !pendingInvitations?.length) return null

  return (
    <>
      <div css={style.tableHeadingBar}>
        <h2>Pending Invitations</h2>
      </div>
      <div css={style.table}>
        <Table
          {...testId('pending-invitations')}
          columns={columns}
          dataSource={pendingInvitations}
        />
      </div>
    </>
  )
}

const Users = () => {
  useFetchUsers()
  useFetchPendingInvitationsForAccount()

  const authz = useAuthz()
  const dispatch = useDispatch()

  const [enforceUsersQuota, loadingEnforceUsersQuota] = useEnforceUsersQuota()
  const showInviteUserModal = useMemo(
    () => enforceUsersQuota(
      () => dispatch(showModal(InviteUserModal.id))
    ),
    [dispatch, enforceUsersQuota]
  )

  return (
    <>
      <PageHeader title='User Management' />
      <div css={style.container}>
        <div css={style.tableHeadingBar}>
          <h2>Users</h2>
          {authz({ module: 'System', type: actions.account.inviteUser.toString() }) &&
            <Button
              type="link"
              disabled={loadingEnforceUsersQuota}
              onClick={showInviteUserModal}
              icon={<UserAddOutlined />}
            >
              Invite User
            </Button>
          }
        </div>
        <div css={style.table}>
          <UsersTable />
        </div>
        <PendingInvitations />
      </div>
    </>
  )
}

const style = {
  container: {
    padding: '30px',
  },
  table: {
    backgroundColor: Color.WHITE,
    padding: contentMargin,
    '&:not(:last-of-type)': {
      marginBottom: '24px',
    },
  },
  tableHeadingBar: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 12px 4px',
  },
}

export default Users
