import React from 'react'
import moment from 'moment'
import qs from 'querystringify'

import AntDatePicker from 'antd/lib/date-picker'
import 'antd/lib/date-picker/style/css'

const DatePicker = React.forwardRef(({
  dateFormat,
  onChange,
  placeholder,
  readOnly,
  value,
  ...props
}, ref) => {
  if(readOnly) {
    return (
      <div ref={ref} style={readOnlyStyle}>
        {typeof value === 'undefined' || value === null
          ? placeholder ? <i style={{ color: '#aaa' }}>{placeholder}</i> : null
          : moment(value).format(dateFormat)
        }
      </div>
    )
  }

  // provide querystring-based override of date picker to ease test burden
  // with automatic test tools (such as Cypress)
  const { datePicker } = qs.parse(window.location.search)
  if(datePicker === 'browser') {
    /**
     * Converts string of type YYYY-MM-DD to Unix timestamp,
     * assuming date is in local time.
     *
     * @param {string} v - date value in YYYY-MM-DD format (local time assumed)
     * @returns {number|null} - timestamp or null if could not parse string
     */
    const toTimestamp = v => {
      if(typeof v !== 'string') return null
      const parts = v.split(/-/g)
      if(parts.length !== 3) return null
      const [y, m, d] = parts.map(s => Number(s))
      const ts = new Date(y, m - 1, d).valueOf()
      return Number.isNaN(ts) ? null : ts
    }
    return (
      <input
        type="date"
        ref={ref}
        onChange={({ target: { value } }) => onChange(toTimestamp(value))}
      />
    )
  }

  return (
    <AntDatePicker
      ref={ref}
      onChange={v => onChange(v && v.valueOf())}
      value={value && moment(value)}
      format={dateFormat}
      {...props}
    />
  )

})
DatePicker.RangePicker = AntDatePicker.RangePicker

const readOnlyStyle = {
  minHeight: '32px',
  paddingLeft: '12px',
}

export default DatePicker
