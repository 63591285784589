/**
 * Given a set of filter function definitions, createProjectFilterFunctions returns an array of filter
 *    functions for users.
 * @param {Array.<{ field: string, value: * }>} filters - array containing filter function definitions
 * @returns {Array.<(fields: ?Object) => boolean>} - array containing filter functions
 */
function createUserFilterFunctions(filters) {
  if(!filters.length) return [() => true]
  return filters.map(({ field, value }) => {
    switch(field) {
      case 'keyword':
        return ({ fullName, email }) => {
          const matchKeyword = str => str && str.toLowerCase().includes(value.toLowerCase())
          return matchKeyword(fullName) || matchKeyword(email)
        }
      default: throw new Error('unrecognized filter field: ' + field)
    }
  })
}

export {
  createUserFilterFunctions,
}
