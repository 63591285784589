/* eslint-disable @typescript-eslint/no-var-requires, no-undef */
const { EntityType } = require('../systemConsts')
const { createId } = require('../idUtils')
const { splitAncestry } = require('../utils/ancestry')

const actions: Record<string, any> = {} // eslint-disable-line @typescript-eslint/no-explicit-any

const UpdateProgramActionType = 'Update Program'
/**
 * Updates a risk program.
 */
actions.update = function updateProgram(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'Risk',
    type: UpdateProgramActionType,
    payload,
    meta,
  }
}
actions.update.toString = () => UpdateProgramActionType

const CreateProgramActionType = 'Create Program'
/**
 * create a risk program
 *
 * @params [data.id] - If omitted, a UUID will be generated.  Providing this can be useful for testing.
 * @params data.name - Program name.
 * @params data.description - Program description.
 * @params data.riskContext - Risk context for this program.
 * @params data.created - If omitted, defaults to now.  Providing this can be useful for testing.
 * @params data.ancestry - The ancestry of this program.
 */
actions.create = function createRiskProgramActionCreator(data: Record<string, unknown>) {
  const {
    id = createId(),
    name,
    description,
    riskContext,
    created = Date.now(),
    ancestry,
  } = data

  const type = CreateProgramActionType

  if(!name) throw new Error(type + ': data.name is required')
  if(!ancestry) throw new Error(type + ': data.ancestry is required')
  const payload = {
    id,
    entityType: EntityType.PROGRAM,
    name,
    description,
    riskContext,
    created,
  }

  const meta = {
    authz: {
      resources: splitAncestry(ancestry),
    },
    ancestry,
  }

  return { module: 'Risk', type, payload, meta }
}
actions.create.toString = () => CreateProgramActionType

export default {
  create: actions.create,
  update: actions.update
}
