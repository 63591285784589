const COLOR = {
  GREY_1LR: '#f9fafd',
  GREY_1L: '#f5f6f9',
  GREY_1: '#f1f2f5',
  GREY_1D: '#e5e6e9',
  GREY_1DR: '#dbdcdf',
  GREY_2L: '#ced0d2',
  GREY_2: '#babbbe',
  GREY_2D: '#9a9c9f',
  GREY_3: '#616365',
  BLUE_C: '#00bbfd',
  BLUE_B: '#1188cc',
  BLUE_A: '#556688',
  GREEN: '#50a028',
  YELLOW: '#d8a200',
  RED: '#e63250',
}

const colorScale = [
  {
    rule: sev => sev >= -1 && sev < -0.38,
    magnitude: [-1, -0.38],
    color: COLOR.BLUE_C,
  },
  {
    rule: sev => sev >= -0.38 && sev < -0.18,
    magnitude: [-0.38, -0.18],
    color: COLOR.BLUE_B,
  },
  {
    rule: sev => sev >= -0.18 && sev < 0,
    magnitude: [-0.18, 0],
    color: COLOR.BLUE_A,
  },
  {
    rule: sev => !sev,
    magnitude: 0,
    color: '#888888',
  },
  {
    rule: sev => sev <= 0.18 && sev > 0,
    magnitude: [0, 0.18],
    color: COLOR.GREEN,
  },
  {
    rule: sev => sev <= 0.38 && sev > 0.18,
    magnitude: [0.18, 0.38],
    color: COLOR.YELLOW,
  },
  {
    rule: sev => sev <= 1 && sev > 0.38,
    magnitude: [0.38, 1],
    color: COLOR.RED,
  },
]

export default function getSeverityColor(sev) {
  const severityRange = colorScale.find(severityRange => severityRange.rule(sev))
  return severityRange.color
}

export { COLOR, colorScale }
