import React from 'react'

import { Popover, Tag } from '../controls'
import { naturalAlphanumericSort } from '../../utils/sort-utils'

const TableCellMultiple = ({
  color,
  items,
  noItems,
  tagText,
  title,
}) => {
  switch(items.length) {
    case 0:
      return noItems
    case 1:
      return items[0]
    default: {
      const sortedItems = items
        .sort(naturalAlphanumericSort)
        .map(label => <div key={label}>{label}</div>)
      return (
        <Popover title={title} content={sortedItems}>
          <Tag color={color}>{tagText}</Tag>
        </Popover>
      )
    }
  }
}
TableCellMultiple.defaultProps = {
  color: 'blue',
  noItems: null,
  tagText: 'Multiple...',
}

export default TableCellMultiple
