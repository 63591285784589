import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import { RatingVector, Ratable } from '@vms/vmspro3-core/dist/types'
import {
  useDndMonitor,
  useDraggable,
  DragMoveEvent,
  DragEndEvent,
} from '@dnd-kit/core'

import { useRatableContext } from './RatingProvider'

import style from './RatableItem.module.css'

type RatableItemProps<T> = {
  item: T,
}
export const RatableItem = <T extends Ratable>({ item }: RatableItemProps<T>): ReactElement => {
  const { minRating, maxRating, onRateItem, renderItem, zIndexOrder } = useRatableContext<T>()

  const itemId = item.id
  const { setNodeRef, attributes, listeners, transform, isDragging } = useDraggable({
    id: itemId,
    data: item,
  })

  const [rect, setRect] = useState<DOMRect | null>(null)
  const setRef = useCallback(
    (elem: HTMLDivElement) => {
      setNodeRef(elem)
      setRect(elem?.getBoundingClientRect() ?? null)
    },
    [setNodeRef]
  )

  const [ratingVector, setRatingVector] = useState<RatingVector>(item.ratingVector)
  useEffect(
    () => {
      setRatingVector(item.ratingVector)
    },
    [item.ratingVector]
  )
  const [abstain, setAbstain] = useState<boolean>(item.abstain ?? false)
  useEffect(
    () => {
      setAbstain(item.abstain ?? false)
    },
    [item.abstain]
  )

  const ratingSpan = maxRating - minRating
  const percentToRating = useCallback(
    (percent: number) => (percent * ratingSpan) + minRating,
    [minRating, ratingSpan]
  )

  useDndMonitor({
    onDragMove: useCallback(
      (event: DragMoveEvent) => {
        if(event.active.id === itemId) {
          if(event.over?.id && event.active.rect.current.translated) {
            const draggableRect = event.active.rect.current.translated
            const droppableRect = event.over.rect

            const draggablePosition = {
              left: draggableRect.offsetLeft - droppableRect.offsetLeft,
              top: draggableRect.offsetTop - droppableRect.offsetTop,
            }

            const span = {
              x: droppableRect.width - draggableRect.width,
              y: droppableRect.height - draggableRect.height,
            }

            const percent = {
              x: Math.min(1, Math.max(0, draggablePosition.left / span.x)),
              y: Math.min(1, Math.max(0, 1 - draggablePosition.top / span.y)),
            }

            setRatingVector([
              percentToRating(percent.y),
              percentToRating(percent.x),
            ])
            setAbstain(event.over?.id === 'abstainCanvas')
          } else {
            setRatingVector(null)
            setAbstain(false)
          }
        }
      },
      [itemId, percentToRating]
    ),
    onDragEnd: useCallback(
      (event: DragEndEvent) => {
        if(event.active.id === itemId) {
          onRateItem(itemId, ratingVector, event.over?.id === 'abstainCanvas')
        }
      },
      [itemId, onRateItem, ratingVector]
    ),
  })

  const ratingToPercent = useCallback(
    (rating: number) => (rating - minRating) / (ratingSpan),
    [minRating, ratingSpan]
  )

  const ratingDecimal = useMemo(
    () => {
      if(!item.ratingVector) return null
      const [primary, secondary] = item.ratingVector.map(ratingToPercent)
      return { x: secondary, y: primary }
    },
    [item, ratingToPercent]
  )

  const ratableZIndex = zIndexOrder.indexOf(item.id) + 1
  const draggableStyle = useMemo(
    () => {
      const style = {
        '--translate-x': `${transform?.x ?? 0}px`,
        '--translate-y': `${transform?.y ?? 0}px`,
        '--position-left': undefined as string | undefined,
        '--position-top': undefined as string | undefined,
        '--ratable-z-index': ratableZIndex,
      }

      if(ratingDecimal && rect) {
        style['--position-left'] = `calc(${ratingDecimal.x * 100 + '%'} - ${ratingDecimal.x * rect.width}px)`
        style['--position-top'] =
          `calc(${(1 - ratingDecimal.y) * 100 + '%'} - ${(1 - ratingDecimal.y) * rect.height}px)`
      }

      return style as React.CSSProperties
    },
    [rect, transform, ratingDecimal, ratableZIndex]
  )

  return (
    <div
      className={classnames(style.ratableItem, {
        [style.hasRating]: !!ratingDecimal,
        [style.active]: isDragging,
      })}
      ref={setRef}
      style={draggableStyle}
      {...attributes}
      {...listeners}
    >
      {renderItem(item, ratingVector, abstain)}
    </div>
  )
}
