import React from 'react'
import { Select } from 'antd'
import { gql, useQuery } from '@apollo/client'

import { Form, Button, Space, Spin } from '../../client/controls'
import style from './AdHocParticipantPickerAndForm.module.css'

const GET_DECISION_PARTICIPANTS = gql`
  query GetDecisionParticipants(
    $accountId: ID!,
    $decisionId: ID!,
    $participationSessionId: ID!
  ) {
    decisionParticipants(
      accountId: $accountId,
      decisionId: $decisionId,
      participationSessionId: $participationSessionId
    ) {
      id
      fullName
    }
  }
`
type DecisionParticipantsData = {
  decisionParticipants: { id: string, fullName: string }[],
}
type DecisionParticipantsVariables = {
  accountId: string,
  decisionId: string,
  participationSessionId: string,
}

type AdhocParticipantPickerProps = {
  accountId: string,
  decisionId: string,
  participationSessionId: string,
  setParticipantId: (participantId?: string) => void,
  onShowCreateParticipant: () => void,
}
export function AdHocParticipantPicker({
  accountId,
  decisionId,
  participationSessionId,
  setParticipantId,
  onShowCreateParticipant,
}: AdhocParticipantPickerProps): JSX.Element {
  const { data, loading, error } = useQuery<DecisionParticipantsData, DecisionParticipantsVariables>(
    GET_DECISION_PARTICIPANTS,
    {
      variables: {
        accountId,
        decisionId,
        participationSessionId,
      },
    }
  )

  if(loading) return <Spin />
  if(error) return <p>ERROR: {error.message}</p>
  if(!data) return <p>Not Found...</p>

  return (
    <div className={style.formContainer}>
      <h3>Welcome to VMSPro® Decision Participation</h3>
      <Form
        layout="vertical"
        style={{ maxWidth: '300px' }}
        onFinish={({ participantId }) => setParticipantId(participantId)}
      >
        <Form.Item name="participantId" label="Select your name">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              // ant design specifies option.label as a react element, typescript
              // needs to confirm type is string before using string methods
              typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
            }
            options={data.decisionParticipants.map(p => ({ label: p.fullName, value: p.id }))}
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          {form => (
            <Space size="large">
              <Button
                disabled={!form.getFieldValue('participantId')}
                htmlType="submit"
                type="primary"
              >
                Select Participant
              </Button>
              <Button onClick={onShowCreateParticipant}>New Participant</Button>
            </Space>
          )}
        </Form.Item>
      </Form>
    </div>
  )
}
