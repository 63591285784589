import { produce } from 'immer'
import { DecisionFolder } from '@vms/vmspro3-core/dist/types'
import { splitAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import asSet from 'arraysetjs'
import {
  CreateDecisionFolderAction,
  UpdateDecisionFolderAction,
  DeleteDecisionFolderAction,
  CreateDecisionAction,
  DeleteDecisionAction,
} from '@vms/vmspro3-core/dist/actions/decision'

import { LoadableDecisionFolder } from '../../types'
import {
  FetchDecisionFolderChildrenSuccessAction,
  FetchDecisionEntityRequestAction,
  FetchDecisionEntitySuccessAction,
  ResetAccountStateAction,
} from '../actionTypes'

export type DecisionFoldersState = Record<DecisionFolder["id"], LoadableDecisionFolder>
export const initialState: DecisionFoldersState = {}

type Actions =
  | ResetAccountStateAction
  | FetchDecisionEntityRequestAction
  | FetchDecisionEntitySuccessAction
  | FetchDecisionFolderChildrenSuccessAction
  | CreateDecisionFolderAction
  | UpdateDecisionFolderAction
  | DeleteDecisionFolderAction
  | CreateDecisionAction
  | DeleteDecisionAction

function getLoadedDecisionFolderByAncestry(state: DecisionFoldersState, ancestry: string) {
  const decisionFolderId = splitAncestry(ancestry).pop()
  const decisionFolder = decisionFolderId ? state[decisionFolderId] : undefined
  if(decisionFolder?.status === 'Success') {
    return decisionFolder
  }
}
function getLoadedDecisionFolderById(state: DecisionFoldersState, decisionFolderId: string) {
  const decisionFolder = state[decisionFolderId]
  if(decisionFolder?.status === 'Success') {
    return decisionFolder
  }
}

export const decisionFoldersReducer = produce<DecisionFoldersState, [Actions?]>((state, action) => {
  if(!action?.type) return

  switch(action.type) {
    case 'ResetAccountState': {
      return initialState
    }

    case 'FetchDecisionEntityRequest': {
      if(action.meta.entityType === 'DecisionFolder') {
        state[action.meta.decisionEntityId] = { status: 'Loading' }
      }
      break
    }
    case 'FetchDecisionEntitySuccess': {
      if(action.payload.decisionFolders) {
        Object.assign(state, action.payload.decisionFolders)
      }
      break
    }

    case 'FetchDecisionFolderChildrenSuccess': {
      if(action.payload.decisionFolders) {
        Object.assign(state, action.payload.decisionFolders)
      }
      const parentDecisionFolder = getLoadedDecisionFolderByAncestry(state, action.meta.ancestry)
      if(parentDecisionFolder) {
        parentDecisionFolder.children = {
          decisionFolders: action.payload.decisionFolders ? Object.keys(action.payload.decisionFolders) : [],
          decisions: action.payload.decisions ? Object.keys(action.payload.decisions) : [],
        }
      }
      break
    }

    // decision folder
    case 'Create Folder': {
      state[action.payload.id] = {
        status: 'Success',
        data: {
          id: action.payload.id,
          ancestry: action.meta.ancestry,
          entityType: 'DecisionFolder',
          name: action.payload.name,
          owner: {
            // TODO: need to sort out types for augmented actions
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            userId: action.meta.authUserId,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            userName: action.meta.authUserName,
          },
        },
      }

      const parentDecisionFolder = getLoadedDecisionFolderByAncestry(state, action.meta.ancestry)
      if(parentDecisionFolder?.children) {
        if(!parentDecisionFolder.children.decisionFolders) parentDecisionFolder.children.decisionFolders = []
        parentDecisionFolder.children.decisionFolders.push(action.payload.id)
      }

      break
    }
    case 'Update Folder': {
      const decisionFolder = getLoadedDecisionFolderById(state, action.meta.decisionFolderId)
      if(decisionFolder) {
        Object.assign(decisionFolder.data, action.payload)
      }
      break
    }
    case 'Delete Folder': {
      const decisionFolder = state[action.meta.decisionFolderId]

      if(decisionFolder.status === 'Success') {
        const parentDecisionFolder = getLoadedDecisionFolderByAncestry(state, decisionFolder.data.ancestry)
        if(parentDecisionFolder?.children?.decisionFolders) {
          asSet.mutable(parentDecisionFolder.children.decisionFolders).remove(action.meta.decisionFolderId)
        }
      }

      delete state[action.meta.decisionFolderId]

      break
    }

    // decision
    case 'Create Decision': {
      const parentDecisionFolder = getLoadedDecisionFolderByAncestry(state, action.meta.ancestry)
      if(parentDecisionFolder?.children) {
        if(!parentDecisionFolder.children.decisions) parentDecisionFolder.children.decisions = []
        parentDecisionFolder.children.decisions.push(action.payload.id)
      }
      break
    }
    case 'Delete Decision': {
      const parentDecisionFolder = getLoadedDecisionFolderById(state, action.meta.parentDecisionFolderId)
      if(parentDecisionFolder?.children?.decisions) {
        asSet.mutable(parentDecisionFolder.children.decisions).remove(action.meta.decisionId)
      }
      break
    }

    default: {
      return state
    }
  }
}, initialState)
