import { ReactElement, useCallback, useEffect, useRef } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { ValueNode } from '@vms/vmspro3-core/dist/nextgen/valueGraph/valueNode'
import cytoscape from 'cytoscape'
import dagre from 'cytoscape-dagre'

import { Button } from '../../client/controls'

cytoscape.use(dagre as unknown as cytoscape.Ext)  // dagre typing could use some work

const dagreLayout = {
  name: 'dagre',
  rankDir: 'RL',
  spacingFactor: 1.5,
  roots: ['perf'],
  padding: 30,
} as cytoscape.BaseLayoutOptions

const nodeTextStyleBase = {
  dominantBaseline: 'middle',
  fontFamily: 'sans-serif',
  fill: 'black',
}
const nodeHeaderStyle = {
  ...nodeTextStyleBase,
  fontSize: '12px',
  fontWeight: 'bold',
}
const nodeValueStyle = {
  ...nodeTextStyleBase,
  fontSize: '24px',
}
const nodeBreakdownStyle = {
  ...nodeTextStyleBase,
  textAnchor: 'middle',
  fontSize: '12px',
}

function ValueGraphNodeSvg({ node, width }: { node: ValueNode, width: number }) {
  const label = node.symbol || node.name || '?'

  return (
    // note that xmlns is necessary for cytoscape
    <svg width={width} height="50" viewBox={`0 0 ${width} 50`} version="1.1" xmlns="http://www.w3.org/2000/svg">
      <rect x={0} y={0} width={width} height={50} stroke="black" fill="none" />
      <g>
        <line x1={0} y1={16} x2={width} y2={16} stroke="black" />
        <text x={5} y={9} {...nodeHeaderStyle}>{label}</text>
        {node.weight === undefined
          ? ( // number is centered for nodes w/out coefficient
            <text x={width / 2} y={35} textAnchor="middle" {...nodeValueStyle}>
              {node.weightedValue?.toFixed(2)}
            </text>
          )
          : ( // all other nodes are left-aligned to make room for breakdown
            <g>
              <text x={5} y={35} {...nodeValueStyle}>
                {node.value?.toFixed(2)}
              </text>
              <text x={95} y={38} {...nodeBreakdownStyle}>
                {node.weight !== null && node.weight !== 1 &&
                  'w = ' + (node.weight * 100).toFixed(1) + '%'
                }
              </text>
            </g>
          )
        }
      </g>
    </svg>
  )
}

export function ValueGraph({ rootValueNode }: { rootValueNode: ValueNode }): ReactElement {
  const cyRef = useRef<cytoscape.Core>()
  const graphContainerRef = useRef<HTMLDivElement>(null)

  useEffect(
    () => {
      const valueNodes = [rootValueNode, ...rootValueNode.descendants]

      const cy = cytoscape({
        container: graphContainerRef.current,
        layout: dagreLayout,
        elements: valueNodes.map(valueNode => {
          const width = valueNode.weight === undefined ? 90 : 150
          const svg = '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg>' +
            renderToStaticMarkup(<ValueGraphNodeSvg width={width} node={valueNode} />)

          return [
            {
              data: {
                id: valueNode.id,
                width,
                bgImage: encodeURI(`data:image/svg+xml;utf-8,${svg}`),
              },
            },
            // edges
            ...valueNode.children.map((childNode, idx) => ({
              data: {
                id: `${childNode.id}-${valueNode.id}`,
                source: childNode.id,
                target: valueNode.id,
              },
              classes: valueNode.operation === 'Divide' && idx > 0 ? 'Divide' : '',
            })),
          ]
        }).flat(),
        style: [
          {
            selector: 'node',
            style: {
              'background-color': '#eee',
              'background-image': 'data(bgImage)',
              shape: 'rectangle',
              width: 'data(width)',
              height: '50px',
            },
          },
          {
            selector: 'edge',
            style: {
              width: 1,
              'font-size': 36,
              'line-color': '#000',
              'target-arrow-color': '#000',
              'target-arrow-shape': 'vee',
              'target-arrow-fill': 'filled',
              'text-background-color': '#fff',
              'text-background-opacity': 1,
              'curve-style': 'bezier',
            },
          },
          {
            selector: '.Divide',
            style: {
              label: ' ÷ ', // unicode U+00D7
            },
          },
          // TODO: not currently in use
          // {
          //   selector: '.Multiply',
          //   style: {
          //     label: ' × ', // unicode U+00D7
          //   },
          // },
        ],
      })

      cy.on('resize', () => cy.fit(undefined, 30))
      cyRef.current = cy

      return () => {
        cy.removeListener('resize')
      }
    },
    [rootValueNode]
  )

  useEffect(() => () => cyRef.current?.destroy(), [])

  const resetLayout = useCallback(() => { cyRef.current?.layout(dagreLayout).run() }, [])

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <div>
        <Button onClick={resetLayout}>Reset Layout</Button>
      </div>
      <div
        ref={graphContainerRef}
        style={{
          flex: '1 1 0',
          background: '#fff',
          border: '1px solid #000000',
          overflow: 'hidden',
        }}
      />
    </div>
  )
}
