import React from 'react'
import { css } from 'glamor'
import _get from 'lodash/get'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'
import { Duration, DurationUnitMetadata } from '@vms/vmspro3-core/dist/utils/qty'

import RiskScale from './RiskScale'
import { Col, Row } from '../../controls'

const { RiskColor } = systemConsts

const getImpactTables = (includePerformance, effectiveRiskContext, isPercentage = true) => {

  const { defaultCostUnit, defaultDurationUnit } = effectiveRiskContext
  const costLabel = defaultCostUnit // just using the value for now
  const durationLabel = DurationUnitMetadata[defaultDurationUnit].label

  const impactTables = [
    {
      key: 'cost',
      title: 'Cost Impact',
      titleBgColor: RiskColor.COST_IMPACT,
      subtitle: isPercentage ? '% Range' : `Cost Range (${costLabel})`,
      value: isPercentage ? null : _get(effectiveRiskContext, 'attributes.cost.value.value'),
      format: isPercentage ? 'percentage' : 'currency',
    },
    {
      key: 'time',
      title: 'Schedule Impact',
      titleBgColor: RiskColor.TIME_IMPACT,
      subtitle: isPercentage ? '% Range' : `Schedule Range (${durationLabel})`,
      value: isPercentage
        ? null
        : Duration.convert(effectiveRiskContext.attributes.time.value, defaultDurationUnit),
      format: isPercentage ? 'percentage' : 'duration',
    },
  ]
  if(includePerformance) {
    impactTables.push({
      key: 'perf',
      title: 'Performance Impact',
      titleBgColor: RiskColor.PERF_IMPACT,
      subtitle: isPercentage ? '% Range' : `Performance Range`,
      value: isPercentage ? null : 1,
      format: isPercentage ? 'percentage' : 'number',
    })
  }
  return impactTables
}

const RiskContextScaleReference = ({
  effectiveRiskContext,
  includePerformance,
  vertical,
  headerHeight,
}) => {
  const span = vertical ? 24 : includePerformance ? 6 : 8
  return (
    <div style={style.container}>
      <Row gutter={24}>
        <Col span={span}>
          <RiskScale
            title="Probability"
            subtitle="% Range"
            readOnly
            headerHeight={headerHeight}
            riskScale={effectiveRiskContext.riskScales.prob}
          />
        </Col>
        {getImpactTables(includePerformance, effectiveRiskContext, false).map(({ key, ...riskScaleProps }) => (
          <Col span={span} key={key}>
            <RiskScale
              {...riskScaleProps}
              readOnly
              showHeaderCol={false}
              headerHeight={headerHeight}
              riskScale={effectiveRiskContext.riskScales[key]}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}
RiskContextScaleReference.defaultProps = {
  includePerformance: true,
  vertical: false,
}

const style = {
  controls: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '12px',
    width: '100%',
    '& > *': {
      marginRight: '16px',
      '&:not(:first-child)': {
        marginLeft: '16px',
      },
    },
  }),
  container: {
    backgroundColor: 'white',
    margin: '0 30px',
    padding: '0 15px',
  },
  tableTitle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: '700',
  },
}

export default RiskContextScaleReference
