import clientId from '../../utils/clientId'

/**
 * Sends interesting action data (navigation & non-ephemeral actions) to the
 * analytics engine (AWS Pinpoint, currently) to help understand usage patterns.
 */
const analyticsMiddleware = () => next => action => {
  const { meta } = action
  if(meta && !meta.ephemeral && meta.clientId === clientId) {
    // TODO: and here's where we'll record non-ephemeral action types
  }
  return next(action)
}

export default analyticsMiddleware
