import React from 'react'
import { Link } from 'react-router-dom'
import { css } from 'glamor'
import _get from 'lodash/get'
import numeral from 'numeral'
import SearchOutlined from '@ant-design/icons/SearchOutlined'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'
import { BaseQuantity, CostUnit, DurationUnit, DurationUnitMetadata } from '@vms/vmspro3-core/dist/utils/qty'
import { getRiskCategoryLabelFactory } from '@vms/vmspro3-core/dist/utils/risk'

import { formatCost, formatDuration } from '../../utils/formatUtils'
import { getEnumKeyComparer } from '../../utils/sort-utils'
import { Button, Input } from '../controls'
import SeverityDisplay from '../components/Risk/SeverityDisplay'
import EditableTableCell from './EditableTableCell'
import RiskCategoryLabel from '../components/Risk/RiskCategoryLabel'

const { inputTypes } = EditableTableCell

// String constants
const COST = 'COST'
const TIME = 'TIME'
const PERF = 'PERF'
const MIN = 'MINIMUM'
const MAX = 'MAXIMUM'
const ML = 'ML'
const EV = 'EV'
const MANAGED = 'MANAGED'
const UNMANAGED = 'UNMANAGED'

const riskMetrics = {
  COST: { key: 'cost', title: { short: 'Cost', long: 'Cost' } },
  TIME: { key: 'time', title: { short: 'Sched', long: 'Schedule' } },
  PERF: { key: 'perf', title: { short: 'Perf', long: 'Performance' } },
  SEVERITY: { key: 'severity', title: { short: 'Sev', long: 'Severity' } },
}

const riskMeasures = {
  MINIMUM: { key: 'min', title: { short: 'Min', long: 'Minimum' } },
  MAXIMUM: { key: 'max', title: { short: 'Max', long: 'Maximum' } },
  ML: { key: 'ml', title: { short: 'ML', long: 'Most Likely' } },
  EV: { key: 'ev', title: { short: 'EV', long: 'Expected Value' } },
}

const { riskImpactTypesByKey } = systemConsts

// TODO: grab the ranges from the config
const severityFilters = [
  { text: 'Threat - High', value: [0.4, 1.1] },
  { text: 'Threat - Medium', value: [0.2, 0.4] },
  { text: 'Threat - Low', value: [0, 0.2] },
  { text: 'Opportunity - High', value: [-0.4, -1.1] },
  { text: 'Opportunity - Medium', value: [-0.2, -0.4] },
  { text: 'Opportunity - Low', value: [-0, -0.2] },
]

/**
 * A helper function to filter the severity against the severityFilters object (above)
 *
 * @param {Array} range
 *    @param {Number} range.low - low end of the comparison. Checked as an inclusive value.
 *    @param {Number} range.high - high end of the comparison.
 * @param {Number} sev
 *
 * @returns {Boolean} Boolean to denote the sev falls within the given range.
 */
const severityFilterFunction = ([low, high], sev) => {
  if(!sev) return false
  if(low < 0 || high < 0) return (sev <= low && sev > high)
  return (sev >= low && sev < high)
}

const propPrefix = resp => resp === MANAGED ? 'managed.' : ''

/**
 * Get a filter object based on the provided configuration object
 *
 * @param {Object} configObj - the configuration value object to be mapped
 * @param {String} idx - the label index to use ('short' or 'long'). Defaults to long.
 */
const getColumnFilter = (configObj, idx = 'long') => {
  const filters = Object.values(configObj).map(({ label }) => ({ text: label[idx], value: label[idx] }))
  // remove duplicate entries
  return filters.filter(({ text }, index) => filters.findIndex(f => f.text === text) === index)
}

const getImpactClassName = overlay => `risk-impact-measure-${overlay.toLowerCase()}`

/**
 * Case insensitive search to check if a substring exists in a given string.
 *
 * @param {String} origin - original string
 * @param {String} comparand - comparison string
 *
 * @return {Boolean}
 */
const getCaseInsensitiveInclusion = (origin, comparand) => origin.toLowerCase().includes(comparand.toLowerCase())

/* eslint-disable class-methods-use-this */
/**
 * Risk Inventory Columns Class is designed to take in the risk configuration and set the necessary
 * filters and columns for the riskInvetoryTable.
 */
class RiskInventoryColumns {

  /**
   * Constructor to instantiate the columns and filters
   *
   * @param {Object} props
   *    @param {Boolean} [props.editable=false] - if true use editable table cells
   *    @param {String} [props.editingKey] - specify the active editing row key (id)
   *    @param {Object} props.effectiveRiskContext
   *    @param {Object} props.filters - column filter settings
   *    @param {Boolean} props.formFieldsTouched - check antd form for isFieldsTouched()
   *    @param {Boolean} [props.includePerformcance=false]
   *    @param {Object} props.label - label index to grab from config mappings ('short' or 'long')
   *    @param {Function} [props.onBeginEdit] - callback used when user clicks to 'Edit' a row
   *    @param {Function} [props.onCancelEdit] - callback used when user clicks to 'Discard' a row's changes
   *    @param {Function} [props.onFieldChange] - callback used when user updates a field
   *    @param {Function} [props.onSave] - callback used when user clicks to 'Save' a row
   *    @param {Object} props.pathname - url path - used to append to name column
   */
  constructor({
    editable = false,
    editingKey,
    effectiveRiskContext,
    filters,
    formFieldsTouched,
    includePerformance = true,
    label = 'short',
    onBeginEdit,
    onCancelEdit,
    onFieldChange,
    onSave,
    pathname,
  }) {
    // Map provided properties
    this.editable = editable
    this.editingKey = editingKey
    this.effectiveRiskContext = effectiveRiskContext
    this.filters = filters || {}
    this.formFieldsTouched = formFieldsTouched
    this.includePerformance = includePerformance
    this.label = label
    this.onBeginEdit = onBeginEdit
    this.onCancelEdit = onCancelEdit
    this.onFieldChange = onFieldChange
    this.onSave = onSave
    this.pathname = pathname
    this.getRiskCategoryLabel = getRiskCategoryLabelFactory(effectiveRiskContext.types.category.values)

    // Map additional properties
    this.defaultCostUnit = effectiveRiskContext.defaultCostUnit || CostUnit.USD
    this.defaultTimeUnit = effectiveRiskContext.defaultDurationUnit || DurationUnit.MONTHS
    this.searchInput = {}
    this.setFilters()
    this.setRiskColumns()
    this.setCustomSortFn()
    this.setEditableColumnConsts()
  }

  /**
   * Checks if the selected record is in editing mode
   *
   * @param {object} record - the row record to compare
   */
  isEditing = record => record.id === this.editingKey

  /**
   * Get the properties necessary for a custom search dropdown for a specified column in the table
   *
   * @param {String} dataIndex - the column dataIndex to filter on
   *
   * @returns {Object} the properties for the custom search dropdown for the column
   */
  getColumnSearchProps(dataIndex) {
    return ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => this.searchInput = node}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined alt="Search" style={{ color: filtered ? '#1890ff' : undefined }} />,
      filteredValue: this.filters.name || null,
      onFilter: (value, record) => getCaseInsensitiveInclusion(_get(record, dataIndex, ''), value),
      onFilterDropdownVisibleChange: visible => {
        if(visible) {
          setTimeout(() => this.searchInput.select())
        }
      },
      render: (text, { id }) => <Link to={`${this.pathname}/risk/${id}`}>{text}</Link>,
    })
  }

  /**
   * Grab the configuration types from the risk context that align with the provided configuration key
   *
   * @param {String} configKey - the identifier for the type in the effective risk configuration
   */
  getConfigDefintionValues(configKey) {
    return this.effectiveRiskContext?.types?.[configKey]?.values || []
  }

  /**
   * Construct the column filters from the risk configuration object
   */
  setFilters() {
    this.categoryValues = this.getConfigDefintionValues('category')
    this.phaseValues = this.getConfigDefintionValues('phase').filter(v => !v.isDeleted)
    this.statusValues = this.getConfigDefintionValues('status')
    this.riskRespFocusValues = this.getConfigDefintionValues('riskRespFocus')
    this.riskRespStratValues = this.getConfigDefintionValues('riskRespStrat')
    this.riskTypeValues = this.getConfigDefintionValues('riskType')
    this.riskScaleValues = {
      cost: this.effectiveRiskContext?.riskScales?.cost || [],
      time: this.effectiveRiskContext?.riskScales?.time || [],
      perf: this.effectiveRiskContext?.riskScales?.perf || [],
      prob: this.effectiveRiskContext?.riskScales?.prob || [],
    }
    this.categoryFilters = this.effectiveRiskContext.types.category.values
      .filter(({ isDeleted }) => !isDeleted)
      .map(({ value }) => ({
        text: this.getRiskCategoryLabel(value),
        value,
      }))
    this.phaseFilters = this.effectiveRiskContext.types.phase.values
      .filter(({ isDeleted }) => !isDeleted)
      .map(({ value, label }) => ({
        text: label.long,
        value,
      }))
    this.statusFilters = getColumnFilter(this.statusValues, this.label)
    this.riskRespStratFilters = getColumnFilter(this.riskRespStratValues, this.label)
    this.riskRespFocusFilters = getColumnFilter(this.riskRespFocusValues, this.label)
    this.riskTypeFilters = getColumnFilter(this.riskTypeValues, this.label)
    this.impactLevelFilters = {}
    Object.entries(this.riskScaleValues).map(([k, v]) => this.impactLevelFilters[k] = getColumnFilter(v, 'short'))
  }

  setEditableColumnConsts() {
    this.costInputProps = {
      allowNull: false,
      unit: this.defaultCostUnit,
      align: "right",
      decimalPlaces: 2,
    }
    this.timeInputProps = {
      allowNull: false,
      align: "right",
      decimalPlaces: 2,
      unit: this.defaultTimeUnit,
    }
    this.perfInputProps = {
      allowNull: false,
      trimDecimals: true,
      align: "right",
      isPercentage: true,
      decimalPlaces: 2,
    }
    this.numberInputProps = {
      allowNull: false,
      trimDecimals: true,
      align: "right",
      isPercentage: false,
      decimalPlaces: 2,
    }
  }

  /**
   * Construct an object placeholder for the basic risk columns and append their respective
   * filters (where applicable)
   */
  setRiskColumns() {
    this.riskColumns = {
      category: {
        dataIndex: 'category',
        key: 'category',
        sortType: 'string',
        title: 'Category',
        filters: this.categoryFilters,
        filteredValue: this.filters.category || null,
        onFilter: (value, record) => getCaseInsensitiveInclusion(_get(record, 'category', ''), value),
        editable: this.editable,
        inputType: inputTypes.RISK_CATEGORY_SELECT,
        inputProps: {
          categoryValues: this.categoryValues,
        },
        render: category =>
          <RiskCategoryLabel
            category={category}
            categoryValues={this.effectiveRiskContext.types.category.values}
          />,
      },
      name: {
        dataIndex: 'name',
        disabled: true, // disabled is used in the column configuration model to prevent unchecking the column
        fixed: 'left',
        key: 'name',
        sortType: 'string',
        title: 'Name',
        width: 250,
        editable: this.editable,
        inputType: inputTypes.TEXT,
        ...this.getColumnSearchProps('name'),
      },
      num: {
        align: 'right',
        dataIndex: 'num',
        defaultSortOrder: 'ascend',
        disabled: true, // disabled is used in the column configuration model to prevent unchecking the column
        fixed: 'left',
        key: 'num',
        sortType: 'number',
        title: 'Number',
      },
      phase: {
        dataIndex: 'phase',
        filters: this.phaseFilters,
        key: 'phase',
        sortType: 'string',
        title: 'Phase',
        filteredValue: this.filters.phase || null,
        onFilter: (value, record) => getCaseInsensitiveInclusion(_get(record, 'phase', ''), value),
        editable: this.editable,
        inputType: inputTypes.RISK_ENUM_SELECT,
        inputProps: {
          selectOptions: this.phaseValues,
          allowClear: true,
        },
        render: phase => this.phaseValues?.find(c => c.value === phase)?.label?.[this.label],
      },
      responseFocus: {
        dataIndex: 'riskRespFocus',
        key: 'riskRespFocus',
        sortType: 'string',
        title: 'Focus',
        filters: this.riskRespFocusFilters,
        filteredValue: this.filters.riskRespFocus || null,
        onFilter: (value, record) => getCaseInsensitiveInclusion(_get(record, 'riskRespFocus', ''), value),
        editable: this.editable,
        inputType: inputTypes.SELECT,
        inputProps: {
          selectOptions: this.riskRespFocusValues,
        },
        render: riskRespFocus =>
          this.riskRespFocusValues?.find(c => c.value === riskRespFocus)?.label?.[this.label],
      },
      responseStrategy: {
        dataIndex: 'riskRespStrat',
        key: 'riskRespStrat',
        sortType: 'string',
        title: 'Strategy',
        filters: this.riskRespStratFilters,
        filteredValue: this.filters.riskRespStrat || null,
        onFilter: (value, record) => getCaseInsensitiveInclusion(_get(record, 'riskRespStrat', ''), value),
        editable: this.editable,
        inputType: inputTypes.SELECT,
        inputProps: {
          selectOptions: this.riskRespStratValues,
        },
        render: riskRespStrat =>
          this.riskRespStratValues?.find(c => c.value === riskRespStrat)?.label?.[this.label],
      },
      riskType: {
        className: 'risk-impact-type',
        dataIndex: 'type',
        key: 'type',
        sortType: 'string',
        title: `Type`,
        filters: this.riskTypeFilters,
        filteredValue: this.filters.type || null,
        onFilter: (value, record) => getCaseInsensitiveInclusion(_get(record, 'type', ''), value),
        editable: this.editable,
        inputType: inputTypes.SELECT,
        inputProps: {
          selectOptions: this.riskTypeValues,
          allowClear: false,
        },
        render: type => this.riskTypeValues?.find(c => c.value === type)?.label?.[this.label],
      },
      status: {
        dataIndex: 'status',
        key: 'status',
        sortType: 'string',
        title: 'Status',
        filters: this.statusFilters,
        filteredValue: this.filters.status || null,
        onFilter: (value, record) => getCaseInsensitiveInclusion(_get(record, 'status', ''), value),
        editable: this.editable,
        inputType: inputTypes.SELECT,
        inputProps: {
          selectOptions: this.statusValues,
          allowClear: false,
        },
        render: status => this.statusValues?.find(c => c.value === status)?.label?.[this.label],
      },
    }
  }

  /**
   * Construct the custom filters needed for the columns. These are passed to the Table.js
   * wrapper and used to sort the columns
   */
  setCustomSortFn() {
    this.customSortFns = {
      riskScales_prob: getEnumKeyComparer(this.riskScaleValues.prob),
      riskScales_cost: getEnumKeyComparer(this.riskScaleValues.cost),
      riskScales_time: getEnumKeyComparer(this.riskScaleValues.time),
      riskScales_perf: getEnumKeyComparer(this.riskScaleValues.perf),
    }
  }

  /**
   * return the custom sort functions for the colums
   */
  getCustomSortFns() {
    return this.customSortFns
  }

  /**
   * Get the qualitative and quantitative risk probabilities
   *
   * @param {String} overlay - the dataIndex overlay ('UNMANAGED' or 'MANAGED')
   *
   * @returns {Object} array containing columns for qualitative and quantitative risk probabilities
   */
  getRiskProbabilityCols(overlay) {
    const key = propPrefix(overlay) + 'prob'
    const qualDataIndex = [...key.split('.'), 'qual']
    const quantDataIndex = [...key.split('.'), 'quant']
    const className = getImpactClassName(overlay)
    return [
      {
        // Qualitative Probability
        align: 'left',
        className,
        dataIndex: qualDataIndex,
        key: key + '.qual',
        sortType: 'riskScales_prob',
        title: 'Qual',
        filters: this.impactLevelFilters.prob,
        filteredValue: this.filters[key + '.qual'] || null,
        onFilter: (value, record) =>
          _get(record, qualDataIndex, '').toLowerCase().indexOf(value.toLowerCase()) === 0,
        editable: this.editable,
        inputType: inputTypes.SELECT,
        inputProps: {
          selectOptions: this.riskScaleValues.prob,
          allowClear: false,
          label: 'short',
        },
      },
      {
        // Quantitative Probability
        align: 'right',
        className,
        dataIndex: quantDataIndex,
        key: key + '.quant',
        sortType: 'number',
        title: 'Quant',
        filteredValue: this.filters[key + '.quant'] || null,
        onFilter: (value, record) =>
          _get(record, quantDataIndex, '').toLowerCase().indexOf(value.toLowerCase()) === 0,
        editable: this.editable,
        inputType: inputTypes.NUMBER,
        inputProps: {
          ...this.numberInputProps,
          step: 0.1,
        },
        render: prob => numeral(prob).format('0,0.0%'),
      },
    ]
  }

  /**
   * Get the risk Impact Type relative to the metric
   *
   * @param {String} overlay - UNMANAGED or MANAGED
   * @param {String} metric - COST, TIME, or PERF
   *
   * @returns {Object} the risk impact column
   */
  getImpactTypeCol(overlay, metric) {
    const key = `${propPrefix(overlay)}impact.${riskMetrics[metric].key}.type`
    const dataIndex = key.split('.')
    const className = getImpactClassName(metric)
    return ({
      className,
      dataIndex,
      key,
      sortType: 'string',
      title: `Impact Type`,
      filters: this.riskTypeFilters,
      filteredValue: this.filters[key] || null,
      onFilter: (value, record) => {
        const impactType = riskImpactTypesByKey[_get(record, dataIndex, '')]?.label ?? ''
        return impactType.toLowerCase().indexOf(value.toLowerCase()) === 0
      },
      editable: this.editable,
      inputType: inputTypes.SELECT,
      inputProps: {
        selectOptions: this.riskTypeValues,
        allowClear: false,
      },
      render: impactType => riskImpactTypesByKey[impactType]?.label || impactType,
    })
  }

  /**
   * Get the Qualitative Impact relative to the metric
   *
   * @param {String} overlay - UNMANAGED or MANAGED
   * @param {String} metric - COST, TIME, or PERF
   *
   * @returns {Object} the Qualitative Impact column
   */
  getQualImpact(overlay, metric) {
    const key = `${propPrefix(overlay)}impact.${riskMetrics[metric].key}.qual`
    const dataIndex = key.split('.')
    const className = getImpactClassName(metric)
    return ({
      className,
      dataIndex,
      key,
      sortType: 'riskScales_' + riskMetrics[metric].key,
      title: `Qual Impact`,
      filters: this.impactLevelFilters[riskMetrics[metric].key],
      filteredValue: this.filters[key] || null,
      onFilter: (value, record) => _get(record, dataIndex, '').indexOf(value) === 0,
      editable: this.editable,
      inputType: inputTypes.SELECT,
      inputProps: {
        selectOptions: this.riskScaleValues[metric.toLowerCase()],
        allowClear: false,
        label: 'short',
      },
      disableOnNull: true,
    })
  }

  /**
   * return inventory properties for the editable quantitative impacts
   *
   * @param {strign} metric - the quantitative attribute type
   * @returns {object} inputProperties - inputType and coordinated inputProps
   */
  getInputProps(metric) {
    if(metric === COST) {
      return {
        inputType: inputTypes.CURRENCY,
        inputProps: this.costInputProps,
      }
    } else if(metric === TIME) {
      return {
        inputType: inputTypes.DURATION,
        inputProps: this.timeInputProps,
      }
    }
    return {
      inputType: inputTypes.DIMENSIONLESS,
      inputProps: this.perfInputProps,
    }
  }

  /**
   * Get the Quantitative Impact relative to the metric
   *
   * @param {String} overlay - UNMANAGED or MANAGED
   * @param {String} metric - COST, TIME, or PERF
   *
   * @returns {Object} the Quantitative Impact column
   */
  getQuantImpact(overlay, metric, measure) {
    const key = `${propPrefix(overlay)}impact.${riskMetrics[metric].key}.quant.`
      + `${riskMeasures[measure].key}`
    const dataIndex = key.split('.')
    const className = getImpactClassName(metric)

    return ({
      align: 'right',
      className,
      dataIndex,
      key,
      sortType: metric === COST ? 'cost' : metric === TIME ? 'time' : 'number',
      title: riskMeasures[measure].title.short,
      editable: this.editable && measure === 'ML', // only Most Likely value is editable
      ...this.getInputProps(metric),
      disableOnNull: true,
      render: (impact, record) => {
        // If impact type is not set we shold not display any quantitative values
        if(!impact || _get(record, `impact.${riskMetrics[metric].key}.type`, 'NONE') === 'NONE') return ''
        switch(impact.base) {
          case BaseQuantity.Cost: {
            if(impact.unit !== this.defaultCostUnit) {
              throw new Error(`invalid currency object found for a risk in the project: ${impact.unit}`)
            }
            return formatCost(impact, false, 0)
          }
          case BaseQuantity.Duration: {
            return formatDuration(impact, this.defaultTimeUnit, '', 1, false)
          }
          case BaseQuantity.Dimensionless: {
            return numeral(impact.value).format('0,0.0%')
          }
          default: throw new Error(`invalid impact base quantity: ${impact.base}`)
        }
      },
    })
  }

  /**
   * Get the Total Severity column
   *
   * @param {String} overlay - UNMANAGED or MANAGED
   *
   * @returns {Object} the Total Severity column
   */
  getTotalSeverityCol(overlay) {
    const key = `${propPrefix(overlay)}severity.total`
    const dataIndex = key.split('.')
    const className = getImpactClassName(overlay)
    return ({
      align: 'right',
      className,
      dataIndex,
      key,
      sortType: 'number',
      title: 'Total Severity',
      render: severity => (<SeverityDisplay severity={severity} />),
    })
  }

  /**
   * Get the Severity Column relative to the metric
   *
   * @param {String} overlay - UNMANAGED or MANAGED
   * @param {String} metric - COST, TIME, or PERF
   *
   * @returns {Object} the Severity column
   */
  getSeverityCol(overlay, metric) {
    const key = `${propPrefix(overlay)}severity.${riskMetrics[metric].key}`
    const dataIndex = key.split('.')
    const className = getImpactClassName(metric)
    return ({
      align: 'right',
      className,
      dataIndex,
      key,
      sortType: 'number',
      title: 'Severity',
      filters: severityFilters,
      filteredValue: this.filters[key] || null,
      onFilter: (value, record) => severityFilterFunction(value, _get(record, key)),
      render: severity => (<SeverityDisplay severity={severity} />),
    })
  }

  /**
   * Get the nested columns for a given overlay. Overlay in this context is a snapshot of the
   * 'Managed' or 'Unmanaged' data for the risks.
   *
   * Example:
   *  -----------------------------------------------------------------------------------------
   * |                          Unmanaged                                                      |
   * |     Prob     |                       Cost                      | Schedule | Performance |
   * | Qual | Quant | Imp Type | Tot Sev | Qual |         Quant       | ...      | ...         |
   * |      |       |          |         |      | Min | Ml | Max | EV | ...      | ...         |
   *  -----------------------------------------------------------------------------------------
   *
   * @param {string} overlay - the overlay of the columns. 'UNMANAGED' of 'MANAGED'
   *
   * @returns {Object} the riskImpact columns relative to the overlay
   */
  getRiskImpactColumns(overlay = UNMANAGED) {
    const classVar = overlay.toLowerCase()
    const prefix = propPrefix(overlay)
    return ({
      title: overlay === MANAGED ? 'Managed' : 'Unmanaged',
      key: `${prefix}response`,
      className: `risk-impact-response-${classVar}`,
      children: [
        // Probability Columns (Qualitative and Quantitative)
        {
          className: getImpactClassName(overlay),
          key: `${prefix}prob`,
          title: 'Probability',
          children: [
            ...this.getRiskProbabilityCols(overlay),
          ],
        },
        // Cost Columns
        {
          className: `risk-impact-measure-cost`,
          key: `${prefix}${riskMetrics[COST].key}.impact.measure`,
          title: riskMetrics[COST].title.short,
          children: [
            this.getImpactTypeCol(overlay, COST),
            this.getSeverityCol(overlay, COST),
            this.getQualImpact(overlay, COST),
            {
              title: `Quant Impact (${this.defaultCostUnit})`,
              key: `${prefix}cost.quant.impact`,
              className: `risk-impact-measure-cost`,
              children: [
                this.getQuantImpact(overlay, COST, MIN),
                this.getQuantImpact(overlay, COST, ML),
                this.getQuantImpact(overlay, COST, MAX),
                this.getQuantImpact(overlay, COST, EV),
              ],
            },
          ],
        },
        // Schedule Columns
        {
          className: `risk-impact-measure-time`,
          key: `${prefix}${riskMetrics[TIME].key}.impact.measure`,
          title: riskMetrics[TIME].title.short,
          children: [
            this.getImpactTypeCol(overlay, TIME),
            this.getSeverityCol(overlay, TIME),
            this.getQualImpact(overlay, TIME),
            {
              title: `Quant Impact (${DurationUnitMetadata[this.defaultTimeUnit].label})`,
              key: `${prefix}time.quant.impact`,
              className: `risk-impact-measure-time`,
              children: [
                this.getQuantImpact(overlay, TIME, MIN),
                this.getQuantImpact(overlay, TIME, ML),
                this.getQuantImpact(overlay, TIME, MAX),
                this.getQuantImpact(overlay, TIME, EV),
              ],
            },
          ],
        },
        // Performance Columns
        this.includePerformance && {
          className: `risk-impact-measure-perf`,
          key: `${prefix}${riskMetrics[PERF].key}.impact.measure`,
          title: riskMetrics[PERF].title.short,
          children: [
            this.getImpactTypeCol(overlay, PERF),
            this.getSeverityCol(overlay, PERF),
            this.getQualImpact(overlay, PERF),
            {
              title: 'Quant Impact',
              key: `${prefix}perf.quant.impact`,
              className: `risk-impact-measure-perf`,
              children: [
                this.getQuantImpact(overlay, PERF, MIN),
                this.getQuantImpact(overlay, PERF, ML),
                this.getQuantImpact(overlay, PERF, MAX),
                this.getQuantImpact(overlay, PERF, EV),
              ],
            },
          ],
        },
        // Total Severity
        this.getTotalSeverityCol(overlay),
      ],
    })
  }

  /**
   * Return an 'Operation' or 'Action' column, housing the options to 'Edit' then 'Save' or 'Discard'
   * changes to a row.
   */
  getOperationsColumn() {
    return {
      title: 'Action',
      dataIndex: 'operation',
      disabled: true, // disabled is used in the column configuration model to prevent unchecking the column
      fixed: 'right', // Fix the column to the right
      align: 'center',
      render: (text, record) => {
        const editable = this.isEditing(record)
        return editable
          ? this.formFieldsTouched
            ? (
              <div {...style.controls}>
                <Button type="link" onClick={() => this.onCancelEdit()}>Discard</Button>
                <Button type="primary" onClick={() => this.onSave(record.id)}>Save</Button>
              </div>
            ) : (
              <Button type="link" onClick={() => this.onCancelEdit(record)}>Cancel</Button>
            )
          : (
            <Button type="link" onClick={() => this.onBeginEdit(record)} disabled={this.editingKey}>Edit</Button>
          )
      },
    }
  }

  /**
   * Return the components used by Ant Design for custom editable rows and columns.
   *
   * See AntD documenatation for more information:
   * https://ant.design/components/table/#components-table-demo-edit-row
   */
  getComponents() {
    return {
      body: {
        cell: EditableTableCell,
      },
    }
  }

  /**
   * Map each column (and its children!) to attach its editable properties, if the column is editable
   *
   * @param {object} col - the column to attach editable properties to
   * @returns {object} col - with added properties (if applicable)
   */
  getEditableCellProps(col) {
    const { children, dataIndex, disableOnNull = false, editable, inputProps, inputRules, inputType, title } = col
    if(children) col.children = children.map(_col => this.getEditableCellProps(_col))
    if(!editable) return col
    return {
      ...col,
      onCell: record => ({
        record,
        onFieldChange: this.onFieldChange,
        dataIndex,
        disableOnNull,
        title,
        editing: this.isEditing(record),
        inputType,
        inputProps,
        inputRules,
        defaultTimeUnit: this.defaultTimeUnit,
      }),
    }
  }

  /**
   * Get the superset of columns
   *
   * @returns {Object} the Risk Inventory columns
   */
  getColumns() {
    const columns = [
      this.riskColumns.num,
      this.riskColumns.name,
      this.riskColumns.status,
      this.riskColumns.category,
      this.riskColumns.phase,
      this.riskColumns.riskType,
      {
        title: 'Response',
        key: `riskResp`,
        children: [
          this.riskColumns.responseStrategy,
          this.riskColumns.responseFocus,
        ],
      },
      this.getRiskImpactColumns(UNMANAGED),
      this.getRiskImpactColumns(MANAGED),
    ].map(col => this.getEditableCellProps(col))

    // If the table is editable, attach the operations column
    if(this.editable) columns.push(this.getOperationsColumn())
    return columns
  }
}

const style = {
  controls: css({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '& > * &:not(:first-child)': {
      marginLeft: '16px',
    },
  }),
}

export default RiskInventoryColumns
