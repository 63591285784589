import { createSelector } from 'reselect'

import { selectPoliciesById } from '../policies/selectors'
import { LoadingStatus } from '../../utils/appConsts'

export const selectAuthState = state => state.auth

export const selectAuthUserId = createSelector(
  [selectAuthState],
  state => state.userId
)
export const selectAuthUserEmail = createSelector(
  [selectAuthState],
  state => state.email
)
export const selectAuthLoadingStatus = createSelector(
  [selectAuthState],
  state => state.loadingStatus
)

export const selectAssumedAuthz = createSelector(
  [selectAuthState],
  state => state.assumedAuthz
)
export const selectAssumedRolePolicyIds = createSelector(
  [selectAssumedAuthz],
  assumedAuthz => assumedAuthz?.assumedRoleIds
)
export const selectAssumedRolePolicies = createSelector(
  [selectPoliciesById, selectAssumedRolePolicyIds],
  (policiesById, assumedRolePolicyIds) => assumedRolePolicyIds?.map(id => policiesById[id])
)
export const selectAssumedUserId = createSelector(
  [selectAssumedAuthz],
  assumedAuthz => assumedAuthz?.assumedUserId
)

export const selectIsAuthenticated = state => Boolean(state.auth.userId)
export const selectAuthLoading = state => state.auth.loadingStatus !== LoadingStatus.Loaded
