export const DurationUnits = <const>[
  'Seconds',
  'Minutes',
  'Hours',
  'Days',
  'Weeks',
  'Months',
  'Quarters',
  'Years',
]

export type DurationUnit = typeof DurationUnits[number]

export type Duration = {
  base: 'Duration'
  unit: DurationUnit,
  value: number
}

export const CostUnits = <const>[
  'AUD',
  'BRL',
  'BTC',
  'CAD',
  'CHF',
  'CNY',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'ILS',
  'JPY',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'PHP',
  'PLN',
  'SGD',
  'SEK',
  'TWD',
  'THB',
  'TRY',
  'USD',
]

export type CostUnit = typeof CostUnits[number]

export type Cost = {
  base: 'Cost'
  unit: CostUnit,
  value: number
  updated?: number  // TODO: this should really be required, but I just now noticed this wasn't
                    // there, and I'm afraid there will be conseqeunces to making this required;
                    // see https://vms.atlassian.net/browse/VP3-2467
}

export type Dimensionless = {
  base: 'Dimensionless'
  unit: 'Dimensionless'
  value: number
}

export type Quantity = Duration | Cost | Dimensionless
