import { ReactElement } from 'react'

import { CreateAuthUserParticipant } from './CreateAuthUserParticipant'

import { useAuthUserParticipant } from '../../redux/hooks'
import { createTypedContext } from '../../utils/createTypedContext'

const [
  useAuthUserParticipantContext,
  ContextProvider,
] = createTypedContext<string>()
export { useAuthUserParticipantContext }

type ParticipantContextProviderProps = {
  decisionId: string,
  children: ReactElement,
}
export function AuthUserParticipantContextProvider({
  decisionId,
  children,
}: ParticipantContextProviderProps): ReactElement {
  const participant = useAuthUserParticipant(decisionId)

  if(!participant) {
    return <CreateAuthUserParticipant decisionId={decisionId} />
  }

  return (
    <ContextProvider value={participant.id}>
      {children}
    </ContextProvider>
  )
}
