import _filter from 'lodash/filter'
import _sum from 'lodash/sum'

import { ValidRating } from "../../types"
import { vectorMean } from '../../valuemetrics/util'
import { Criterion } from "../Criterion"
import { ParticipationSession } from "../participationSession"
import { ValueNode } from "./valueNode"

/**
 * Details about missing ratings during criteria prioritization.
 */
export interface MissingPerformanceRatingInfo {
  criterion: Criterion
  ancestorSubstitution?: Criterion
  message: string
}

/**
 * A performance value node represents, for a given option, the contribution to value
 * of a criterion (aka performance attribute).  Note that performance nodes are subject
 * to direct rating by participants (unlike intrinsic nodes).  Hence a performance node
 * has zero or more ratings, and a (possibly null) value (score).
 */
export class PerformanceValueNode extends ValueNode {
  symbol?: string
  name: string
  /**
   * The (unweighted) direct value of the node.  The direct value is the average value of participant
   * ratings for this node; if this is an internal node, this value may be "masked" by the sum of it's
   * children values.  This can happen when options are rated on some criterion, and then that criterion
   * is given children, and those children are rated.
   */
  directValue: number | null
  value: number | null
  weight: number | null
  children: PerformanceValueNode[]
  /**
   * Descendants of this node (as flat array).
   * This is the same implementation as in the superclass, but typed more specifically; all
   * descendents of a performance node are also performance nodes.
   */
   get descendants(): PerformanceValueNode[] {
    return [...this.children, ...this.children.map(c => c.descendants).flat()]
  }
  parent?: ValueNode // needs to be settable to connect value graphs
  ratings: ValidRating[]
  constructor(
    readonly criterion: Criterion,
    readonly optionId: string,
    readonly participationSession: ParticipationSession,
    parent?: ValueNode,
  ) {
    super()
    this.symbol = criterion.abbrev
    this.name = criterion.name
    this.operation = 'Add'
    this.ratings = _filter(this.participationSession.validRatings, {
      contextId: criterion.id,
      subjectType: 'Option',
      subjectId: optionId,
    })
    this.directValue = this.ratings.length === 0
      ? null
      : vectorMean(this.ratings.map(r => r.ratingVector))[0]
    this.weight = criterion.pri.local
    // this will recurse all the way down to descendants
    this.children = criterion.children.map(child =>
      new PerformanceValueNode(child, optionId, participationSession, this)
    )
    // TODO: this is where we need to worry about how to handle incomplete ratings in children nodes;
    // i.e., how to decide whether to use the direct rating or the child ratings?
    this.value = criterion.isLeaf || this.children.some(c => c.weightedValue === null)
      ? this.directValue
      : _sum(this.children.map(c => c.weightedValue))
    this.parent = parent
  }
  private _missingRatings(missingRatings: MissingPerformanceRatingInfo[]) {
    if(this.criterion.isLeaf && this.ratings.length === 0) {
      let ancestorNode = this.parent
      while(ancestorNode) {
        if(ancestorNode.value !== null) break
        ancestorNode = ancestorNode.parent
      }
      if(!(ancestorNode instanceof PerformanceValueNode)) ancestorNode = undefined
      missingRatings.push({
        criterion: this.criterion,
        ancestorSubstitution: ancestorNode?.criterion,
        message: `"${this.criterion.name}" doesn't have any ratings.`,
      })
    }
    this.children.forEach(c => c._missingRatings(missingRatings))
    return missingRatings
  }
  /**
   * Returns any missing ratings for this node, or any of its descendants.
   */
  get missingRatings() {
    return this._missingRatings([])
  }
}
