import { useCallback, useMemo } from 'react'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { Expression as ValueFunctionExpression } from '@vms/vmspro3-core/dist/valuemetrics/valueFormula'
import { Option } from '@vms/vmspro3-core/dist/nextgen/Option'
import { extractCoefficients } from '@vms/vmspro3-core/dist/valuemetrics/valuemetrics'

const costLabel = 'Cost Score'
const timeLabel = 'Time Score'
const perfLabel = 'Perf. Score'
const valueLabel = 'Value'

const coefficientFormatter = Intl.NumberFormat(undefined, {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format

function formatTooltipScore(score: number, coefficient?: number) {
  return coefficient
    ? (score / coefficient).toFixed(1) + ' × ' + coefficientFormatter(coefficient)
    : '(unused)'
}

interface ValuemetricsChartProps {
  options: Option[],
  valueFunctionExpr: ValueFunctionExpression,
}
export function ValuemetricsChart({
  options,
  valueFunctionExpr,
}: ValuemetricsChartProps) {
  // the two most common value formulas are P/(C+T) and P+C+T.  in the case
  // of the former (which we can identify by a "Divide" operator in position 0),
  // we want performance to be in a different bar stack than cost and time (since
  // you can't meaningfully compare parameters in the numerator and denominator).
  // unfortunately, this will not correctly handle "exotic" value formulas like (P + C)/T,
  // etc.  a better solution would be to group the parameters determining whether
  // they're found in the numerator or denominator.
  const perfStackId = Array.isArray(valueFunctionExpr) && valueFunctionExpr[0] === 'Divide' ? 'b' : 'a'

  const coefficients = useMemo(() => extractCoefficients(valueFunctionExpr), [valueFunctionExpr])

  const tooltipFormatter = useCallback((value: number, name: string) => {
    switch(name) {
      case costLabel: return formatTooltipScore(value, coefficients.cost)
      case timeLabel: return formatTooltipScore(value, coefficients.time)
      case perfLabel: return formatTooltipScore(value, coefficients.perf)
      case valueLabel: return value.toFixed(1)
      default: return value
    }
  }, [coefficients])

  const data = useMemo(
    () => options.map(option => {
      const datum: Record<string, string | number | null> = {
        name: option.abbrev,
        [costLabel]: (option.quantScores.C ?? 0) * (coefficients.cost ?? 0),
        [timeLabel]: (option.quantScores.T ?? 0) * (coefficients.time ?? 0),
        [perfLabel]: option.performanceGraph?.value
          ? option.performanceGraph.value * (coefficients.perf ?? 0)
          : null,
        [valueLabel]: option.valueGraph?.weightedValue ?? null,
      }
      return datum
    }),
    [options, coefficients]
  )

  return (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart data={data} barCategoryGap="20%">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          label={{
            angle: -90,
            position: 'insideLeft',
            style: { textAnchor: 'middle' },
            value: 'Weighted Cost/Time/Perf Score',
          }}
          yAxisId="score"
        />
        <YAxis
          label={{
            angle: 90,
            position: 'insideRight',
            style: { textAnchor: 'middle' },
            value: 'Value Index',
          }}
          orientation="right"
          yAxisId="value"
        />
        <Tooltip formatter={tooltipFormatter} />
        <Legend />
        <Bar dataKey={timeLabel} stackId="a" yAxisId="score" fill="#247ba0" />
        <Bar dataKey={costLabel} stackId="a" yAxisId="score" fill="#4daa57" />
        <Bar dataKey={perfLabel} stackId={perfStackId} yAxisId="score" fill="#f5b800" />
        <Line
          dataKey={valueLabel}
          dot={{ stroke: '#f50041', fill: '#f50041' }}
          stroke="#f50041"
          type="linear"
          yAxisId="value"
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
