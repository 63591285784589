import { AWSIoTProvider } from '@aws-amplify/pubsub'
import _throttle from 'lodash/throttle'

import config from '../config.json'
import { Auth, API, PubSub } from '../server/AmplifyProxy'
import { API_NAME } from '../utils/appConsts'

let errorHandler = undefined
const accountSubscriptions = {}
const systemSubscriptions = {}

// configure IoT provider pluggable in PubSub class
PubSub.addPluggable(new AWSIoTProvider(config.pubsub))

/**
 * Posts a request to the API to attach the required IoT PubSub IAM
 * policy to the Cognito identity.
 */
async function configure() {
  const { identityId } = await Auth.currentUserCredentials()
  await API.post(API_NAME, `/api/register-pubsub`, { body: { identityId } })
}

/**
 * Adds handler for caught subscription errors.
 *
 * @param {Function} handler - (error: Error, message: string) => void
 */
function registerErrorHandler(handler) {
  errorHandler = handler
}

const handleError = _throttle((error, message) => {
  if(typeof errorHandler !== 'function') {
    console.error('error: pubsub error handler not configured properly')
    console.error(error)
  } else {
    errorHandler(error, message)
  }
}, 1000)

function unsubscribe(topic, subscriptions) {
  if(!subscriptions[topic]) return
  subscriptions[topic].unsubscribe()
  delete subscriptions[topic]
}

function getOnSubscriptionError({ topic, reconnect }) {
  return error => {
    error.topic = topic
    console.log('pub/sub subscription error:', error)
    // Most error types here come back as an error object, but the undefined socket error
    // comes back as a nested error object - so we call error.error
    if(error.error && error.error.errorMessage === 'AMQJS0007E Socket error:undefined.') {
      // TODO: there is a breaking change in amplify since 1.1.40 that appears to have eliminated a way
      // to reconnect after the Mqtt socket is destroyed.
      // There are open tickets for this, with little to no response from AWS development team.
      // https://github.com/aws-amplify/amplify-js/issues/3039 (opened AUG 2019, active JAN 2020)
      // https://github.com/aws-amplify/amplify-js/issues/4459 (opened NOV 2019, no response from AWS dev)
      // Trigger our server disconnect here.
      handleError(error.error, {
        header: 'Session Expired',
        body: 'Your session has expired or you have lost your network connection.',
        details: 'Please verify your device is connected to the internet then reload the page to continue.',
      })
    } else {
      reconnect()
    }
  }
}

function subscribe({ topic, onNextData, subscriptions, reconnect }) {
  if(typeof topic !== 'string') {
    throw new Error('only single topic subscriptions are supported at this time')
  }

  subscriptions[topic] = PubSub.subscribe(topic).subscribe({
    next: onNextData,
    error: getOnSubscriptionError({ topic, reconnect }),
    close: () => console.log(`pub/sub: AWSAmplify subscription closed for topic: `, topic),
  })
}

function subscribeToAccountTopic(topic, onNextData) {
  subscribe({
    topic,
    onNextData,
    subscriptions: accountSubscriptions,
    reconnect: () => {
      unsubscribe(topic, accountSubscriptions)

      // the following is from @jakefeldmann and likely needs investigation. The first
      // point should be a non-issue as unsubscribing is a synchronous process.

      // use a timesout here for a couple reasons:
      //    1) We want to make sure the unsubscribe has had plenty of time to do its thing
      //    2) Without a timeout, if there is no network connection, this may be triggered many times per second
      //       and can crash the app.
      //    3) We do not want to throttle the calls, because we may be re-subscribing to many different connections
      //       and we do not want any of these swallowed.
      setTimeout(() => subscribeToAccountTopic(topic, onNextData), 15000)
    },
  })
}

function subscribeToSystemTopic(topic, onNextData) {
  subscribe({
    topic,
    onNextData,
    subscriptions: systemSubscriptions,
    reconnect: () => {
      unsubscribe(topic, systemSubscriptions)

      // the following is from @jakefeldmann and likely needs investigation. The first
      // point should be a non-issue as unsubscribing is a synchronous process.

      // use a timesout here for a couple reasons:
      //    1) We want to make sure the unsubscribe has had plenty of time to do its thing
      //    2) Without a timeout, if there is no network connection, this may be triggered many times per second
      //       and can crash the app.
      //    3) We do not want to throttle the calls, because we may be re-subscribing to many different connections
      //       and we do not want any of these swallowed.
      setTimeout(() => subscribeToSystemTopic(topic, onNextData), 15000)
    },
  })
}

function unsubscribeFromAccountTopics() {
  Object.keys(accountSubscriptions)
    .forEach(topic => {
      accountSubscriptions[topic].unsubscribe()
      delete accountSubscriptions[topic]
    })
}

function unsubscribeFromAccountTopic(topic) {
  unsubscribe(topic, accountSubscriptions)
}

export default {
  configure,
  registerErrorHandler,
  subscribeToAccountTopic,
  subscribeToSystemTopic,
  unsubscribeFromAccountTopics,
  unsubscribeFromAccountTopic,
}
