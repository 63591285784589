/**
* Gets sort function that will sort an array of objects by a
* specified property.  Objects where the specified property
* is null or undefined will be sorted last regardless of the
* direction of the sort.
*
* @example
* const input = [{ x: 3 }, { x: 1 }, {}, { x: 2 }]
* const output = input.sort(getNumberSort('x'))
* // output: [{ x: 1 }, { x: 2 }, { x: 3 }, {}]
*
* @param {string} prop - property key to sort on
* @param {boolean} descending - true to sort in descending order (3, 2, 1),
*   false (default) to sort in ascending order (1, 2, 3)
* @returns {Function} - sort function that can be used with Array#sort
*/
export const getNumberSort = (prop, descending = false) => (a, b) => {
  if(Number.isFinite(a[prop]) && Number.isFinite(b[prop])) return (b[prop] - a[prop]) * (descending ? 1 : -1)
  return Number.isFinite(a[prop]) ? -1 : 1
}
