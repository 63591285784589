import React from 'react'
/** @jsxImportSource @emotion/react */

import { COLOR } from '../../_riskConfig/color'

export default function Close({ pinTooltip }) {
  return (
    <div
      css={{
        gridArea: 'close',
        width: '100%',
        height: '45px',
        fontSize: '30px',
        fontWeight: '300',
        textAlign: 'center',
        lineHeight: '1.3',
        color: COLOR.GREY_2,
        cursor: 'pointer',
        transformOrigin: 'center',
        transition: 'background-color color transform',
        transitionDuration: '.1s',
        transitionTimingFunction: 'ease-out',
        '&:hover,&:focus': {
          color: COLOR.GREY_3,
          background: COLOR.GREY_1D,
        },
        '&:active': {
          background: COLOR.GREY_2,
          transform: 'scale(0.98)',
          color: 'black',
        },
      }}
      onClick={() => pinTooltip(undefined)}
    >
     ×
  </div>
  )
}
