import _keyBy from 'lodash/keyBy'
import { EntityType, ROOT_ACCOUNT_ID } from '@vms/vmspro3-core/dist/systemConsts'

import Server from '../server/VMSProServerAdapter'
import actions from '../actions'

// TODO: GQL query
export async function getAccount(accountId, userId) {
  await Server.auth.configureAPIHeaders({ accountId, userId })
  const { account } = await Server.fetchInitialState('account', userId)
  return account
}

export async function getUserAccounts(userId) {
  await Server.auth.configureAPIHeaders({ accountId: ROOT_ACCOUNT_ID, userId })
  const { userAccounts, userAccountRecords } = await Server.fetchInitialState('userAccounts', userId)
  const userAccountRecordsByAccountId = _keyBy(userAccountRecords, 'accountId')
  // TODO
  userAccounts.forEach(account => {
    account.userAccountRecord = userAccountRecordsByAccountId[account.id]
  })
  return userAccounts.filter(a => a.status === 'Active')
}

export async function getPendingInvitationsForUser(email) {
  return Server.getItemsByAncestryBeginsWith(email, EntityType.USER_INVITATION)
    .then(({ entities }) => entities.filter(inv => !inv.accepted))
}

export async function getPendingInvitationsForAccount(accountId) {
  const query = `{
    invitationsForAccount(accountId: "${accountId}") {
      fullName
      id
      email
      accepted
      created
      inviteToAccountId
      invitedByName
    }
  }`
  return Server.graphql({ query })
    // TODO: figure out how to filter a gql query
    .then(response => response.data.invitationsForAccount.filter(inv => !inv.accepted))
}

export async function acceptAccountInvitation(userId, email, accountId, augmentAction) {
  await Server.auth.configureAPIHeaders({ accountId: ROOT_ACCOUNT_ID, userId })

  return Server.tryAction(
    augmentAction(actions.account.acceptInvitation({ userId, email, accountId }))
  )
}

export async function cancelAccountSubscription(accountId, reason, augmentAction) {
  return Server.tryAction(
    augmentAction(actions.account.cancelSubscription(accountId, reason))
  )
}
