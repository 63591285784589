import { useCallback, useMemo, ReactElement } from 'react'
import _groupBy from 'lodash/groupBy'

import { Criterion, RatingVector } from '@vms/vmspro3-core/dist/types'
import { updateRating, updateRatingNotes } from '@vms/vmspro3-core/dist/actions/decision'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'
import { identifyRatingNotes } from '@vms/vmspro3-core/dist/utils/ratingNotes'
import { defaultCriteriaPrioritizationScaleConfig } from '@vms/vmspro3-core/dist/nextgen/criteria'

import { RatingInterface, RatingInterfaceProps } from '../RatingInterface/RatingInterface'

import { useAuthUserParticipantContext } from './authUserParticipantContext'
import { useAppDispatch, useAppSelector } from '../../redux'
import { useQuerystringValue } from '../../hooks/useQuerystringValue'
import { useCycleSelectOptions } from '../../hooks/useCycleSelectOptions'
import { getSelectRatings } from '../../redux/selectors'
import {
  useChildCriteria,
  useContextCriteria,
  useContextCriteriaSelectOptions,
  useCriterion,
  useDecision,
  useDecisionChildAncestry,
  useDescendantCriteria,
  useLeafCriterionLabel,
  useRatableChildCriteria,
  useRootPerfCriterionId,
} from '../../redux/hooks/decisionHooks'

type CriteriaRatingInterfaceProps = RatingInterfaceProps<Criterion>

function useRatingContextProgressProps(
  decisionId: string,
): CriteriaRatingInterfaceProps['ratingContextProgressProps'] {
  const participantId = useAuthUserParticipantContext()

  const rootPerfCriterionId = useRootPerfCriterionId(decisionId)
  const subjectCriteria = useDescendantCriteria(decisionId, rootPerfCriterionId)
  const contextCriteria = useContextCriteria(decisionId, rootPerfCriterionId)
  const ratings = useAppSelector(
    useMemo(
      () => getSelectRatings<'participantId'>(decisionId, 'CriteriaPrioritization', { participantId }),
      [decisionId, participantId]
    )
  )

  return useMemo(
    () => {
      const ratingsByContextId = _groupBy(ratings, 'contextId')
      const subjectCriteriaByParentId = _groupBy(subjectCriteria, 'parentId')

      const totalCount = contextCriteria.length
      let inProgressCount = 0
      let completedCount = 0

      // it is possible that a criterion may have been used as a rating context
      // before its children were deleted, leaving stray ratings that should
      // not contribute to rating progress metrics. only use current context
      // criteria ratings.
      contextCriteria.forEach(({ id: contextCriterionId }) => {
        const contextRatings = ratingsByContextId[contextCriterionId]
        const contextChildCriteria = subjectCriteriaByParentId[contextCriterionId]
        const subjectHasRating = (criterion: Criterion) => contextRatings?.some(rating =>
          rating.subjectId === criterion.id &&
          rating.ratingVector !== null
        )

        if(contextChildCriteria.every(subjectHasRating)) {
          completedCount++
        } else if(contextChildCriteria.some(subjectHasRating)) {
          inProgressCount++
        }
      })

      return {
        totalCount,
        inProgressCount,
        completedCount,
      }
    },
    [ratings, subjectCriteria, contextCriteria]
  )
}

type RatingContextDetailsType = CriteriaRatingInterfaceProps['ratingContextDetailsProps']['detailsType']
function useRatingDetailDrawerProps(
  decisionId: string,
  contextCriterionId: string,
): CriteriaRatingInterfaceProps['ratingContextDetailsProps'] {
  const decision = useDecision(decisionId)
  const contextCriterion = useCriterion(contextCriterionId)
  const rootPerfCriterionId = useRootPerfCriterionId(decisionId)
  const contextLeafCriterionLabel = useLeafCriterionLabel(decisionId, contextCriterionId, rootPerfCriterionId)

  const childCriteria = useChildCriteria(decisionId, contextCriterionId)

  const [detailsType, setDetailsType] = useQuerystringValue<RatingContextDetailsType>('detail-type', 'context')

  return {
    decisionObjective: decision.objective,
    ratingContextDescription: contextCriterion?.description,
    ratingContextLabel: contextLeafCriterionLabel || '',
    subjects: childCriteria,
    subjectTypeLabel: 'Child Criteria',
    detailsType,
    setDetailsType,
  }
}

function useRatingNotesProps(
  decisionId: string,
  participationSessionId: string,
  contextCriterionId: string,
): CriteriaRatingInterfaceProps['ratingNotesProps'] {
  const participantId = useAuthUserParticipantContext()

  const contextCriterion = useCriterion(contextCriterionId)

  const ancestry = useDecisionChildAncestry(decisionId)
  const dispatch = useAppDispatch()
  const onChangeRatingNotesValue = useCallback(
    (notes: string) => dispatch(updateRatingNotes(ancestry, {
      participationSessionId,
      participantId,
      contextType: 'Criterion',
      contextId: contextCriterionId,
      subjectType: 'Criterion',
      notes: createHtmlObject(notes || null),
    })),
    [dispatch, ancestry, participationSessionId, participantId, contextCriterionId]
  )
  const ratingNotesId = useMemo(
    () => identifyRatingNotes({
      participationSessionId,
      participantId,
      contextId: contextCriterionId,
      subjectType: 'Criterion',
    }),
    [participationSessionId, participantId, contextCriterionId]
  )

  const ratingNotesValue = useAppSelector(
    state => ratingNotesId && state.ratingNotes.byId[ratingNotesId]?.notes?.value
  )

  return {
    label: `Notes for "${contextCriterion?.name}"`,
    value: ratingNotesValue,
    onChange: onChangeRatingNotesValue,
    key: ratingNotesId,
  }
}

function useRatingProps(
  decisionId: string,
  participationSessionId: string,
  contextCriterionId: string,
): CriteriaRatingInterfaceProps['ratingProps'] {
  const participantId = useAuthUserParticipantContext()

  const ratableChildCriteria = useRatableChildCriteria(
    decisionId,
    participantId,
    contextCriterionId,
  )

  const dispatch = useAppDispatch()
  const ancestry = useDecisionChildAncestry(decisionId)
  const onRateCriterion = useCallback(
    (criterionId: string, ratingVector: RatingVector, abstain?: boolean) => {
      if(ancestry && participantId && participationSessionId) {
        dispatch(updateRating(ancestry, {
          participationSessionId,
          participantId,
          contextType: 'Criterion',
          contextId: contextCriterionId,
          subjectType: 'Criterion',
          subjectId: criterionId,
          ratingVector,
          abstain,
        }))
      }
    },
    [dispatch, ancestry, participationSessionId, participantId, contextCriterionId]
  )

  return {
    items: ratableChildCriteria,
    onRateItem: onRateCriterion,
    ratingScaleConfig: defaultCriteriaPrioritizationScaleConfig,
  }
}

function useRatingContextControls(decisionId: string) {
  const rootPerfCriterionId = useRootPerfCriterionId(decisionId)
  const contextCriteriaSelectOptions = useContextCriteriaSelectOptions(decisionId, rootPerfCriterionId)
  if(!contextCriteriaSelectOptions.length) {
    throw new Error('participation requires decision to have root performance criterion')
  }

  const [ratingContextId, setRatingContextId] = useQuerystringValue(
    'contextCriterionId',
    contextCriteriaSelectOptions[0].value
  )

  const { prevOption, nextOption } = useCycleSelectOptions({
    selectOptions: contextCriteriaSelectOptions,
    value: ratingContextId,
    onChange: setRatingContextId,
  })

  return {
    prevRatingContext: prevOption,
    nextRatingContext: nextOption,
    ratingContextId,
  }
}

type CriteriaPrioritizationProps = {
  decisionId: string,
  participationSessionId: string,
}
export const CriteriaPrioritization = ({
  decisionId,
  participationSessionId,
}: CriteriaPrioritizationProps): ReactElement => {
  const {
    prevRatingContext,
    nextRatingContext,
    ratingContextId: contextCriterionId,
  } = useRatingContextControls(decisionId)

  const ratingContextProgressProps = useRatingContextProgressProps(decisionId)
  const ratingContextDetailsProps = useRatingDetailDrawerProps(decisionId, contextCriterionId)
  const ratingNotesProps = useRatingNotesProps(decisionId, participationSessionId, contextCriterionId)
  const ratingProps = useRatingProps(decisionId, participationSessionId, contextCriterionId)

  const rootPerfCriterionId = useRootPerfCriterionId(decisionId)
  const contextLeafCriterionLabel = useLeafCriterionLabel(decisionId, contextCriterionId, rootPerfCriterionId)
  const decision = useDecision(decisionId)

  if(!contextLeafCriterionLabel) return <h2>Context criterion not found</h2>

  return (
    <RatingInterface<Criterion>
      hideRating
      ratingContextProgressProps={ratingContextProgressProps}
      ratingContextDetailsProps={ratingContextDetailsProps}
      ratingNotesProps={ratingNotesProps}
      ratingProps={ratingProps}
      prevRatingContext={prevRatingContext}
      nextRatingContext={nextRatingContext}
      ratingContextLabel={contextLeafCriterionLabel}
      decisionName={decision.name}
      participationActivityLabel="Prioritization"
    />
  )
}
