import { createStore, combineReducers, applyMiddleware, AnyAction } from 'redux'
import { TypedUseSelectorHook, useSelector, useDispatch, Provider } from 'react-redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk'

import account from './account/reducer'
import auth from './auth/reducer'
import policies from './policies/reducer'
import riskEntities from './riskEntities/reducer'
import user from './user/reducer'
import users from './users/reducer'
import {
  appReducer,
  criteriaReducer,
  decisionFoldersReducer,
  decisionsReducer,
  optionsReducer,
  participantsReducer,
  ratingNotesReducer,
  ratingsReducer,
} from './reducers'

import {
  augmentationMiddleware,
  isomorphicReduxMiddleware,
  pubSubMiddleware,
  analyticsMiddleware,
} from './middleware'

import { stage } from '../utils/env'

const reducer = combineReducers({
  account,
  app: appReducer,
  auth,
  criteria: criteriaReducer,
  decisions: decisionsReducer,
  decisionFolders: decisionFoldersReducer,
  options: optionsReducer,
  participants: participantsReducer,
  policies,
  ratings: ratingsReducer,
  ratingNotes: ratingNotesReducer,
  riskEntities,
  user,
  users,
})

const enabledMiddleware = [
  thunkMiddleware,
  augmentationMiddleware,
  isomorphicReduxMiddleware,
  pubSubMiddleware,
]
// only apply analytics middleware in production instances
if(stage?.startsWith('prod') || stage === 'risk') {
  enabledMiddleware.push(analyticsMiddleware)
}

export const store = createStore(
  reducer,
  undefined,
  composeWithDevTools(
    applyMiddleware(...enabledMiddleware)
  )
)

export type Store = typeof store
export type RootState = ReturnType<Store['getState']>
export type AppDispatch = ThunkDispatch<RootState, undefined, AnyAction>

// TODO: i think this rule is important most of the time, however is unnecessary in this case
// where the return type is known
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export { Provider, createStore, reducer }
