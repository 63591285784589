import { ROOT_ACCOUNT_ID } from '@vms/vmspro3-core/dist/systemConsts'
import { createId } from '@vms/vmspro3-core/dist/idUtils'
import clientId from '../../utils/clientId'

/**
 * Augments Redux standard action (RSA) with "boilerplate" common to all VMSPro actions
 *
 *  - Account ID (VMSPro account being affected by this action)
 *  - Actual user ID (the ID of the original user login, before any impersonation)
 *  - Assumed role IDs (any roles an authorized user is temporarily assuming)
 *  - Authorizing user ID (the effective ID of the user; either the original user or the user being impersonated)
 *  - Client ID (unique random ID for each client/browser)
 *  - Timestamp (timestamp of when the action was dispatched)
 *  - Sequence (timestamp with random UUID appended for disambiguation)
 */
const augmentAction = (action, { accountId, actualUserId, assumedRoleIds, authUserId, authUserName }) => {
  const timestamp = Date.now()
  // "seq" is to disambiguate when multiple clients produce a colliding timestamp
  const seq = `${timestamp}:${createId()}`

  action.meta = Object.assign({
    accountId: accountId ?? ROOT_ACCOUNT_ID,
    actualUserId,
    assumedRoleIds,
    authUserId,
    authUserName,
    clientId,
    timestamp,
    seq,
  }, action.meta)

  return action
}

export default augmentAction
