import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _isEmpty from 'lodash/isEmpty'
import FrownOutlined from '@ant-design/icons/FrownOutlined'

import FormModal from './FormModal'
import { Button } from '../controls'

class ErrorModal extends React.Component {
  static propsTypes = {
    /** By default, this dialog shows a generic error message.  If you
      * wish to explicitly use your own copy, set this to true, and
      * set error (which should be an Error instance).
      */
    showCustomMessage: PropTypes.bool,
    /** Custom error message to display.  Note that this will be ignored
      * unless you set showCustomMessage to true.
      */
    error: PropTypes.instanceOf(Error),
  }

  static get id() { return 'ErrorModal' }

  onReload = () => {
    window.location.reload()
  }

  goHome = () => {
    window.location = '/'
  }

  render() {
    const { sentryId, customMessage = {} } = this.props
    const showCustomMessage = !_isEmpty(customMessage)
    const {
      body = 'Please contact support for more information.',
      details = 'An unknown error occurred.',
      header = 'Unknown Error',
    } = customMessage

    return (
      <FormModal
        modalId={ErrorModal.id}
        onConfirm={this.onReload}
        onCancel={this.onReload}
        footer={[
          <Button key="reload" onClick={this.onReload} type="primary">Reload This Page</Button>,
          <Button key="goHome" onClick={this.goHome}>Go Home</Button>,
        ]}
      >
        {showCustomMessage ?
          (
            <>
              <h3 style={style.header}>{header}</h3>
              <p style={style.body}>{body}</p>
              <p style={style.body}>{details}</p>
            </>
          ) : (
            <>
              <h3><FrownOutlined /> System Error</h3>
              <p>We&apos;re sorry, we&apos;ve encountered an error.
                The development team has been notified.</p>
            </>
          )
        }
        {sentryId &&
          <p style={{ fontSize: 'smaller' }}>
            Error receipt ID: <span style={{ fontFamily: 'monospace' }}>{sentryId}</span>
          </p>
        }
      </FormModal>
    )
  }
}

const style = {
  header: {
    textAlign: 'center',
  },
  body: {

  },
}

const state2props = ({ app }) => ({ ...app.modals.byId[ErrorModal.id] })

export default connect(state2props)(ErrorModal)
