const _sumBy = require('lodash/sumBy')  // eslint-disable-line @typescript-eslint/no-var-requires, no-undef

const BaseQuantity = {
  Duration: 'Duration',
  Cost: 'Cost',
  Dimensionless: 'Dimensionless',
}

const DurationUnit = {
  Years: 'Years',
  Quarters: 'Quarters',
  Months: 'Months',
  Weeks: 'Weeks',
  Days: 'Days',
  Hours: 'Hours',
  Minutes: 'Minutes',
  Seconds: 'Seconds',
}

const DurationUnitMetadata = {
  [DurationUnit.Years]: { key: DurationUnit.Years, label: 'Years', abbrev: 'yr' },
  [DurationUnit.Quarters]: { key: DurationUnit.Quarters, label: 'Quarters', abbrev: 'q' },
  [DurationUnit.Months]: { key: DurationUnit.Months, label: 'Months', abbrev: 'mo' },
  [DurationUnit.Weeks]: { key: DurationUnit.Weeks, label: 'Weeks', abbrev: 'wk' },
  [DurationUnit.Days]: { key: DurationUnit.Days, label: 'Days', abbrev: 'days' },
  [DurationUnit.Hours]: { key: DurationUnit.Hours, label: 'Hours', abbrev: 'hr' },
  [DurationUnit.Minutes]: { key: DurationUnit.Minutes, label: 'Minutes', abbrev: 'min' },
  [DurationUnit.Seconds]: { key: DurationUnit.Seconds, label: 'Seconds', abbrev: 'sec' },
}

const CostUnit = {
  AUD: 'AUD',
  BRL: 'BRL',
  CAD: 'CAD',
  CHF: 'CHF',
  CNY: 'CNY',
  CZK: 'CZK',
  DKK: 'DKK',
  EUR: 'EUR',
  GBP: 'GBP',
  HKD: 'HKD',
  HUF: 'HUF',
  ILS: 'ILS',
  JPY: 'JPY',
  MXN: 'MXN',
  MYR: 'MYR',
  NOK: 'NOK',
  NZD: 'NZD',
  PHP: 'PHP',
  PLN: 'PLN',
  SGD: 'SGD',
  SEK: 'SEK',
  TWD: 'TWD',
  THB: 'THB',
  TRY: 'TRY',
  USD: 'USD',
  BTC: 'BTC',
}

const CostUnitMetadata = {
  [CostUnit.AUD]: { key: 'AUD', label: 'Australian Dollar' },
  [CostUnit.BRL]: { key: 'BRL', label: 'Brazilian Real' },
  [CostUnit.CAD]: { key: 'CAD', label: 'Canadian Dollar', quickSelectIndex: 1 },
  [CostUnit.CHF]: { key: 'CHF', label: 'Swiss Franc' },
  [CostUnit.CNY]: { key: 'CNY', label: 'Chinese Yuan Renminbi', symbol: '¥' },
  [CostUnit.CZK]: { key: 'CZK', label: 'Czech Koruna' },
  [CostUnit.DKK]: { key: 'DKK', label: 'Danish Krone' },
  [CostUnit.EUR]: { key: 'EUR', label: 'Euro', symbol: '€' },
  [CostUnit.GBP]: { key: 'GBP', label: 'Pound Sterling', symbol: '£' },
  [CostUnit.HKD]: { key: 'HKD', label: 'Hong Kong Dollar' },
  [CostUnit.HUF]: { key: 'HUF', label: 'Hungarian Forint' },
  [CostUnit.ILS]: { key: 'ILS', label: 'Israeli New Sheqel' },
  [CostUnit.JPY]: { key: 'JPY', label: 'Japanese Yen', symbol: '¥' },
  [CostUnit.MXN]: { key: 'MXN', label: 'Mexican Peso', symbol: '$' },
  [CostUnit.MYR]: { key: 'MYR', label: 'Malaysian Ringgit' },
  [CostUnit.NOK]: { key: 'NOK', label: 'Norwegian Krone' },
  [CostUnit.NZD]: { key: 'NZD', label: 'New Zealand Dollar' },
  [CostUnit.PHP]: { key: 'PHP', label: 'Philippine Peso', symbol: '₱' },
  [CostUnit.PLN]: { key: 'PLN', label: 'Polish Zloty' },
  [CostUnit.SGD]: { key: 'SGD', label: 'Singapore Dollar' },
  [CostUnit.SEK]: { key: 'SEK', label: 'Swedish Krona' },
  [CostUnit.TWD]: { key: 'TWD', label: 'Taiwan New Dollar' },
  [CostUnit.THB]: { key: 'THB', label: 'Thai Baht', symbol: '฿' },
  [CostUnit.TRY]: { key: 'TRY', label: 'Turkish Lira' },
  [CostUnit.USD]: { key: 'USD', label: 'U.S. Dollar', symbol: '$', quickSelectIndex: 0 },
}

class Quantity {
  constructor(base, unit, value) {
    this.base = base
    this.unit = unit
    this.value = value
  }
}
Quantity.isQuantity = obj => {
  if(!obj || typeof obj !== 'object') return false
  if(!Object.values(BaseQuantity).includes(obj.base)) return false
  if(typeof obj.unit !== 'string') return false
  if('value' in obj && obj.value !== undefined && typeof obj.value !== 'number') return false
  return true
}

class Cost extends Quantity {
  constructor(unit, value, updated = Date.now()) {
    super(BaseQuantity.Cost, unit, value)
    this.updated = updated
  }
}
// adding as class properties until there's better support for static fields
Cost.isCost = obj => Quantity.isQuantity(obj) && obj.base === BaseQuantity.Cost
Cost.USD = (value, updated = Date.now()) => new Cost(CostUnit.USD, value, updated)
Cost.sum = (costs, unit) => {
  if(!costs.length) return new Cost(unit, 0)
  if(costs.some(c => c.unit !== unit)) throw new Error('cannot sum costs with nonhomogenous units')
  return new Cost(unit, _sumBy(costs, 'value'))
}

class Duration extends Quantity {
  constructor(unit, value) {
    super(BaseQuantity.Duration, unit, value)
  }
}
Duration.isDuration = obj => Quantity.isQuantity(obj) && obj.base === BaseQuantity.Duration
Duration.convert = (duration, unit) => {
  const chain = [
    { unit: DurationUnit.Years, down: 4 },
    { unit: DurationUnit.Quarters, down: 3 },
    { unit: DurationUnit.Months, down: 52 / 12 },
    { unit: DurationUnit.Weeks, down: 7 },
    { unit: DurationUnit.Days, down: 24 },
    { unit: DurationUnit.Hours, down: 60 },
    { unit: DurationUnit.Minutes, down: 60 },
    { unit: DurationUnit.Seconds },
  ]
  const fromIdx = chain.findIndex(c => c.unit === duration.unit)
  const toIdx = chain.findIndex(c => c.unit === unit)
  let { value } = duration
  if(fromIdx < toIdx) {
    for(let i = fromIdx; i < toIdx; i++) value *= chain[i].down
  } else {
    for(let i = fromIdx; i > toIdx; i--) value /= chain[i - 1].down
  }
  return new Duration(unit, value)
}

/**
 * Compares two durations a and b:
 *
 *   returns negative number if b > a
 *   returns positive number if a < b
 *   returns  0 if a === b
 *
 * This function is suitable for use with Array#sort.
 *
 * You may also specify an optional unit as the basis for comparison; in theory,
 * it doesn't make a difference, but there could be precision issues if you are
 * comparing durations in large units by converting to small units or vice versa.
 */
Duration.compare = (a, b, unit = DurationUnit.Months) =>
  Duration.convert(a, unit).value - Duration.convert(b, unit).value

// adding as class properties until there's better support for static fields
Duration.Years = v => new Duration(DurationUnit.Years, v)
Duration.Quarters = v => new Duration(DurationUnit.Quarters, v)
Duration.Months = v => new Duration(DurationUnit.Months, v)
Duration.Weeks = v => new Duration(DurationUnit.Weeks, v)
Duration.Days = v => new Duration(DurationUnit.Days, v)
Duration.Hours = v => new Duration(DurationUnit.Hours, v)
Duration.Minutes = v => new Duration(DurationUnit.Minutes, v)
Duration.Seconds = v => new Duration(DurationUnit.Seconds, v)

class Dimensionless extends Quantity {
  constructor(value) {
    super(BaseQuantity.Dimensionless, BaseQuantity.Dimensionless, value)
  }
}
Dimensionless.isDimensionless = obj => Quantity.isQuantity(obj) && obj.base === BaseQuantity.Dimensionless

// eslint-disable-next-line no-undef
module.exports = {
  BaseQuantity,
  CostUnit,
  CostUnitMetadata,
  Quantity,
  Cost,
  Duration,
  DurationUnit,
  DurationUnitMetadata,
  Dimensionless,
}
