import { Criterion } from '../types/criterion'
import { Rating } from '../types/rating'

import _sumBy from 'lodash/sumBy'
import _minBy from 'lodash/minBy'
import _maxBy from 'lodash/maxBy'
import _keyBy from 'lodash/keyBy'

// for the purposes of quick prioritization, we don't actually need too much, so we
// narrow down these types; note that quick prioritization assumes participant ratings
// have already been aggregated
type _Criterion = Pick<Criterion, 'id'>
type _Rating = Pick<Rating, 'subjectId' | 'ratingVector' | 'abstain'>

/**
 * @deprecated see core/src/nextgen/prioritization/quickPrioritization
 */
export type RatingsToPrioritizationFunction =
  (criteria: _Criterion[], ratings: _Rating[]) => Record<string, number>

/**
 * @deprecated see core/src/nextgen/prioritization/quickPrioritization
 */
export type PriorityRatingsAggregationFunction =
  (criteria: _Criterion[], ratings: _Rating[]) => Record<string, number>

/**
 * @deprecated see core/src/nextgen/prioritization/quickPrioritization
 */
export type RatingToPrioritizationAlgorithm = 'Normalize' | 'RecenterAndNormalize'

/**
 * @deprecated see core/src/nextgen/prioritization/quickPrioritization
 */
export const RatingsToPrioritizationAlgorithms:
  Record<RatingToPrioritizationAlgorithm, PriorityRatingsAggregationFunction> = {

  /**
   * @deprecated see core/src/nextgen/prioritization/quickPrioritization
   */
  Normalize: (criteria, ratings) => {
    const rs = ratings
      .filter((r): r is _Rating & { ratingVector: number[] } => !r.abstain && r.ratingVector !== null)
    const sum = _sumBy(rs, 'ratingVector.0') ?? 0
    if(sum === 0) return Object.fromEntries(criteria.map(c => [c.id, 0])) // edge case...everything rated 0
    const ratingsByCri = _keyBy(rs, 'subjectId')
    return Object.fromEntries(criteria.map(c => {
      // here we assume that no ratings equate a priority of 0
      const r = ratingsByCri[c.id]?.ratingVector?.[0] ?? 0
      return [c.id, r / sum]
    }))
  },

  /**
   * @deprecated see core/src/nextgen/prioritization/quickPrioritization
   */
  RecenterAndNormalize: (criteria, ratings) => {
    // all we really need are the first component of the rating vector (assuming 0 if not present) and
    // the criteiron ID
    const rs = ratings
      .filter((r): r is _Rating & { ratingVector: number[] } => !r.abstain && r.ratingVector !== null)
      .map(({ subjectId, ratingVector }) => ({ subjectId, r: ratingVector[0] ?? 0  }))
    const minR = _minBy(rs, 'r')?.r ?? 0
    const maxR = _maxBy(rs, 'r')?.r ?? 0
    const d = maxR - minR  // distance between min and max rating
    const shift =  -(minR + d / 2) + 5
    const recentered = rs.map(r => ({ ...r, r: r.r + shift }))
    const recenteredByCri = _keyBy(recentered, 'subjectId')
    const sum = _sumBy(recentered, 'r') // possibly undefined if no ratings
    return Object.fromEntries(criteria.map(c => [c.id, sum ? (recenteredByCri[c.id]?.r ?? 0) / sum : 0]))
  },

}
