/* eslint-disable @typescript-eslint/no-var-requires, no-undef */
const { EntityType } = require('../systemConsts')
const { createId } = require('../idUtils')
const { splitAncestry } = require('../utils/ancestry')

const actions: Record<string, any> = {} // eslint-disable-line @typescript-eslint/no-explicit-any

const UpdatePortfolioActionType = 'Update Portfolio'
actions.update = function updateRiskPortfolioActionCreator(
  payload: Record<string, unknown>,
  meta: Record<string, unknown>
) {
  return {
    module: 'Risk',
    type: UpdatePortfolioActionType,
    payload,
    meta,
  }
}
actions.update.toString = () => UpdatePortfolioActionType

const CreatePortfolioActionType = 'Create Portfolio'
/**
 * create a risk portfolio
 *
 * @params [data.id] - If omitted, a UUID will be generated.  Providing this can be useful for testing.
 * @params data.name - Project name.
 * @params data.description - Portfolio description.
 * @params data.riskContext - Risk context for this portfolio.
 * @params data.created - If omitted, defaults to now.  Providing this can be useful for testing.
 * @params data.ancestry - The ancestry of this (sub)portfolio.
 */
actions.create = function createRiskPortfolioActionCreator(data: Record<string, unknown>) {
  const {
    id = createId(),
    name,
    description,
    riskContext,
    created = Date.now(),
    ancestry,
  } = data

  const type = CreatePortfolioActionType

  if(!name) throw new Error(type + ': data.name is required')
  if(!ancestry) throw new Error(type + ': data.ancestry is required')
  const payload = {
    id,
    entityType: EntityType.PORTFOLIO,
    name,
    description,
    riskContext,
    created,
  }

  const meta = {
    authz: {
      resources: splitAncestry(ancestry),
    },
    ancestry,
  }

  return { module: 'Risk', type, payload, meta }
}
actions.create.toString = () => CreatePortfolioActionType

export default {
  create: actions.create,
  update: actions.update,
}
