import { ReactElement, useMemo } from 'react'
import _groupBy from 'lodash/groupBy'
import _keyBy from 'lodash/keyBy'
import _matches from 'lodash/matches'

import { Html, Option, Ratable, Rating, RatingNotes } from '@vms/vmspro3-core/dist/types'
import { CriterionData } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { identifyRatingNotes } from '@vms/vmspro3-core/dist/utils/ratingNotes'
import {
  createTree,
  getLeafNodeData,
  getNodeLabels,
  TreeNode,
} from '@vms/vmspro3-core/dist/utils/createTree'

import { RatingInterface, RatingInterfaceProps } from '../RatingInterface/RatingInterface'

import { useAdHocParticipationContext } from './adHocParticipationContext'
import { useQuerystringValue } from '../../hooks/useQuerystringValue'
import { useCycleSelectOptions } from '../../hooks/useCycleSelectOptions'
import { useUpdateRating, useUpdateRatingNotes } from '../../services/adHocDecisionService'
import { getLeafNodeSelectOptions } from '../../utils/treeSelectOptions'

type OptionRatingInterfaceProps = RatingInterfaceProps<Option>

type useRatingContextProgressPropsData = {
  participationSessionId: string,
  participantId: string,
  options: Option[],
  ratings: Rating[],
  rootPerformanceCriteriaTreeNode: TreeNode<CriterionData>
}
function useRatingContextProgressProps({
  participationSessionId,
  participantId,
  options,
  ratings,
  rootPerformanceCriteriaTreeNode,
}: useRatingContextProgressPropsData): OptionRatingInterfaceProps['ratingContextProgressProps'] {
  const subjectRatingsByContextId = useMemo(
    () => _groupBy(
      ratings.filter(_matches<
        Pick<Rating, 'participationSessionId' | 'participantId' | 'contextType' | 'subjectType'>
      >({
        participationSessionId,
        participantId,
        contextType: 'Criterion',
        subjectType: 'Option',
      })),
      'contextId'
    ),
    [ratings, participationSessionId, participantId]
  )

  const leafCriteria = useMemo(
    () => getLeafNodeData(rootPerformanceCriteriaTreeNode),
    [rootPerformanceCriteriaTreeNode]
  )

  return useMemo(
    () => {
      const totalCount = leafCriteria.length
      let inProgressCount = 0
      let completedCount = 0

      // it is possible that a criterion may have been used as a rating context
      // before child criteria were added, leaving stray ratings that should
      // not contribute to rating progress metrics. only use current leaf
      // criteria ratings.
      leafCriteria.forEach(leafCriterion => {
        const contextRatings = subjectRatingsByContextId[leafCriterion.id]
        const subjectHasRating = (option: Option) => contextRatings?.some(rating =>
          rating.subjectId === option.id &&
          rating.ratingVector !== null
        )

        if(options.every(subjectHasRating)) {
          completedCount++
        } else if(options.some(subjectHasRating)) {
          inProgressCount++
        }
      })

      return {
        totalCount,
        inProgressCount,
        completedCount,
      }
    },
    [subjectRatingsByContextId, options, leafCriteria]
  )
}

type RatingContextDetailsType = OptionRatingInterfaceProps['ratingContextDetailsProps']['detailsType']
type useRatingContextDetailsPropsData = {
  decisionObjective: Html,
  contextCriterion: CriterionData,
  options: Option[],
  ratingContextLabel: string,
}
function useRatingContextDetailsProps({
  decisionObjective,
  contextCriterion,
  options,
  ratingContextLabel,
}: useRatingContextDetailsPropsData): OptionRatingInterfaceProps['ratingContextDetailsProps'] {
  const [detailsType, setDetailsType] = useQuerystringValue<RatingContextDetailsType>('detail-type', 'context')

  return {
    decisionObjective,
    ratingContextDescription: contextCriterion.description,
    ratingContextLabel,
    subjects: options,
    subjectTypeLabel: 'Options',
    detailsType,
    setDetailsType,
  }
}

type useRatingNotesPropsData = {
  accountId: string,
  decisionId: string,
  participationSessionId: string,
  participantId: string,
  ratingNotes: RatingNotes[],
  ratingContextLabel: string,
  contextId: string,
}
function useRatingNotesProps({
  accountId,
  decisionId,
  participationSessionId,
  participantId,
  ratingNotes,
  ratingContextLabel,
  contextId,
}: useRatingNotesPropsData): OptionRatingInterfaceProps['ratingNotesProps'] {
  const [onChangeRatingNotesValue] = useUpdateRatingNotes(accountId, decisionId, {
    participationSessionId,
    participantId,
    contextType: 'Criterion',
    contextId,
    subjectType: 'Option',
  })

  const ratingNotesId = useMemo(
    () => identifyRatingNotes({
      participationSessionId,
      participantId,
      contextId,
      subjectType: 'Option',
    }),
    [participationSessionId, participantId, contextId]
  )

  const selectedRatingNotes = ratingNotes.find(_matches<
    Pick<RatingNotes, 'participationSessionId' | 'participantId' | 'contextId' | 'subjectType'>
  >({
    participationSessionId,
    participantId,
    contextId,
    subjectType: 'Option',
  }))

  return {
    label: `Notes for "${ratingContextLabel}"`,
    value: selectedRatingNotes?.notes.value ?? '',
    onChange: onChangeRatingNotesValue,
    key: ratingNotesId,
  }
}

type useRatingPropsData = {
  accountId: string,
  decisionId: string,
  contextId: string,
  participantId: string,
  participationSessionId: string,
  options: Option[],
  ratings: Rating[],
  contextCriterion: CriterionData,
}
function useRatingProps({
  accountId,
  decisionId,
  participantId,
  participationSessionId,
  options,
  ratings,
  contextId,
  contextCriterion,
}: useRatingPropsData): OptionRatingInterfaceProps['ratingProps'] {
  const ratableOptions = useMemo<Ratable<Option>[]>(
    () => {
      if(!options || !ratings || !contextId) {
        return []
      }

      const ratingsBySubjectId = _keyBy(
        ratings.filter(_matches<
          Pick<Rating, 'participationSessionId' | 'participantId' | 'contextType' | 'contextId' | 'subjectType'>
        >({
          participationSessionId,
          participantId,
          contextType: 'Criterion',
          contextId,
          subjectType: 'Option',
        })),
        'subjectId'
      )

      return options.map(option => {
        const rating = ratingsBySubjectId[option.id]

        return {
          ...option,
          ratingVector: rating?.ratingVector ?? null,
          abstain: rating?.abstain,
        }
      })
    },
    [ratings, options, participantId, participationSessionId, contextId]
  )

  const [onRateOption] = useUpdateRating(accountId, decisionId, {
    participationSessionId,
    participantId,
    contextType: 'Criterion',
    contextId,
    subjectType: 'Option',
  })

  return {
    ratingScaleConfig: contextCriterion.optionRatingScaleConfig,
    items: ratableOptions,
    onRateItem: onRateOption,
  }
}

function useRatingContextControls(rootPerformanceCriterionTreeNode: TreeNode<CriterionData>) {
  const criteriaSelectOptions = useMemo(
    () => getLeafNodeSelectOptions(rootPerformanceCriterionTreeNode),
    [rootPerformanceCriterionTreeNode]
  )

  const [ratingContextId, setRatingContextId] = useQuerystringValue('criterionId', criteriaSelectOptions[0].value)

  const { prevOption, nextOption } = useCycleSelectOptions({
    selectOptions: criteriaSelectOptions,
    value: ratingContextId,
    onChange: setRatingContextId,
  })

  return {
    prevRatingContext: prevOption,
    nextRatingContext: nextOption,
    ratingContextId,
  }
}

export function AdHocOptionRating(): ReactElement {
  const {
    accountId,
    decisionId,
    decisionName,
    decisionObjective,
    participationSessionId,
    participantId,
    options,
    criteria,
    ratings,
    ratingNotes,
  } = useAdHocParticipationContext()

  const criteriaTree = useMemo(() => createTree(criteria ?? []), [criteria])
  const rootPerformanceCriteriaTreeNode = useMemo(
    () => {
      const rootPerformanceCriteriaTreeNode = criteriaTree.all.find(node => node.data.type === 'Performance')
      if(!rootPerformanceCriteriaTreeNode) {
        throw new Error('Root performance criterion not found in decision')
      }
      return rootPerformanceCriteriaTreeNode
    },
    [criteriaTree]
  )

  const {
    prevRatingContext,
    nextRatingContext,
    ratingContextId,
  } = useRatingContextControls(rootPerformanceCriteriaTreeNode)

  const ratingContextLabel = useMemo(
    () => {
      const treeNodeLabels = getNodeLabels(rootPerformanceCriteriaTreeNode)
      return treeNodeLabels?.[ratingContextId] ?? ''
    },
    [rootPerformanceCriteriaTreeNode, ratingContextId]
  )
  const contextCriterion = criteriaTree.byId[ratingContextId]?.data

  const ratingContextProgressProps = useRatingContextProgressProps({
    options,
    participantId,
    participationSessionId,
    ratings,
    rootPerformanceCriteriaTreeNode,
  })
  const ratingContextDetailsProps = useRatingContextDetailsProps({
    contextCriterion,
    decisionObjective,
    options,
    ratingContextLabel,
  })
  const ratingNotesProps = useRatingNotesProps({
    accountId,
    decisionId,
    participationSessionId,
    participantId,
    ratingNotes,
    ratingContextLabel,
    contextId: ratingContextId,
  })
  const ratingProps = useRatingProps({
    accountId,
    decisionId,
    participantId,
    participationSessionId,
    options,
    ratings,
    contextId: ratingContextId,
    contextCriterion,
  })

  return (
    <RatingInterface<Option>
      ratingContextProgressProps={ratingContextProgressProps}
      ratingContextDetailsProps={ratingContextDetailsProps}
      ratingNotesProps={ratingNotesProps}
      ratingProps={ratingProps}
      prevRatingContext={prevRatingContext}
      nextRatingContext={nextRatingContext}
      ratingContextLabel={ratingContextLabel}
      decisionName={decisionName}
      participationActivityLabel="Rating"
    />
  )
}
