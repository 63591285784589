/* @jsxImportSource @emotion/react */
import { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { createAccountPreflight } from '@vms/vmspro3-core/dist/actions/account/createAccount'

import AccountCreateModal from '../../client/modals/AccountCreateModal'
import { Row, Col, Card, List, Spin, Button } from '../../client/controls'

import useAuthz from '../../hooks/useAuthz'
import { showModal } from '../../redux/actions'
import { useUserAccounts } from '../../redux/account/hooks'
import { setAccountId } from '../../redux/account/actions'
import { acceptInvitation } from '../../redux/user/actions'
import {
  useCurrentUser,
  useUserInvitations,
  useFetchUserInvitations,
} from '../../redux/user/hooks'

function AccountSelectionWelcome({ invitations, accounts }) {
  return (
    <>
      <h2>Welcome to VMSPro&reg;!</h2>
      <p>From here, you have access to any VMSPro accounts you have access to, or accept
        invitations from other users.</p>
      {!invitations.length && !accounts.length &&
        <p>It looks like you're a new user, so you'll want to get started by creating
          a new VMSPro account.</p>
      }
    </>
  )
}

function AccountSelection() {
  useFetchUserInvitations()

  const authz = useAuthz()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [currentUser, currentUserLoading] = useCurrentUser()
  const [accounts, accountsLoading] = useUserAccounts()

  const showAccountCreateModal = () => dispatch(showModal(AccountCreateModal.id))

  const [invitations, invitationsLoading] = useUserInvitations()
  const [loadingInvitationAccountId, setLoadingInvitationAccountId] = useState(null)

  const onAcceptInvitation = async accountId => {
    if(currentUserLoading || loadingInvitationAccountId) return // one at a time...

    setLoadingInvitationAccountId(accountId)
    await dispatch(acceptInvitation(currentUser.id, currentUser.email, accountId, navigate))
    setLoadingInvitationAccountId(null)
  }

  const canCreateAccount = useMemo(() => {
    const preflightAction = createAccountPreflight()
    return authz(preflightAction)
  }, [authz])

  if(currentUserLoading || accountsLoading || invitationsLoading) return <Spin />

  return (
    <div css={style.content}>
      <Row align="center">
        <Col span={12}>
          <Card css={style.card}>
            <AccountSelectionWelcome invitations={invitations} accounts={accounts} />
            {(invitations.length > 0 || accounts.length > 0) &&
              <>
                <h3>Your accounts:</h3>
                <List loading={!invitations} bordered>
                  {invitations && invitations.map(invitation => (
                    <List.Item
                      key={invitation.id}
                      css={style.invitationListItem}
                    >
                      {invitation.accountName}
                      <Button
                        css={style.invitationButton}
                        type="primary"
                        loading={loadingInvitationAccountId === invitation.inviteToAccountId}
                        onClick={() => onAcceptInvitation(invitation.inviteToAccountId)}
                      >
                        Accept Invitation
                      </Button>
                    </List.Item>
                  ))}
                  {accounts.map(account => {
                    const { disabled } = account.userAccountRecord
                    return (
                      <List.Item
                        key={account.id}
                        onClick={!disabled && (() => dispatch(setAccountId(account.id, navigate)))}
                        css={style.accountListItem}
                      >
                        {disabled && <CloseCircleOutlined style={{ marginRight: '8px' }} />}
                        {account.name}
                        {disabled && (
                          <span style={{ marginLeft: '8px' }}>
                            <i>Your access to this account has been disabled by an administrator.</i>
                          </span>
                        )}
                      </List.Item>
                    )
                  })}
                </List>
              </>
            }
            {canCreateAccount &&
              <Button
                style={{ marginTop: '36px' }}
                type={invitations.length || accounts.length ? 'default' : 'primary'}
                onClick={showAccountCreateModal}
              >
                <PlusOutlined /> Create a New Account
              </Button>
            }
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const style = {
  content: {
    paddingTop: '30px',
  },
  card: { minHeight: '500px' },
  invitationListItem: {
    padding: '16px 12px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  invitationButton: {
    transition: 'all .2s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.07)',
      boxShadow: '0 0 15px rgba(0,0,0,0.4)',
    },
  },
  accountListItem: {
    padding: '16px 12px',
    transition: 'all .2s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.03)',
      boxShadow: '0 0 15px rgba(0,0,0,0.2)',
    },
  },
}

export default AccountSelection
