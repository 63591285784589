import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

import { Tabs, Spin } from '../../client/controls'
import { Page } from '../../components'
import { Participants } from '../../components/Participants'
import { DecisionDownloadButton } from '../../components/Decision/DecisionDownloadButton'
import {
  Criteria,
  Options,
  OptionRatings,
  CriteriaRatings,
  ParticipationTab,
  Valuemetrics,
} from '../../components/Decision'

import { useQuerystringValue } from '../../hooks/useQuerystringValue'
import { useAccountId } from '../../redux/account/hooks'
import {
  useLoadDecision,
  useDecision,
  useDecisionBreadcrumbRoutes,
} from '../../redux/hooks/decisionHooks'
import { Overview } from '../../components/Decision/Overview'

function renderTabContents(tabKey: string, accountId: string, decisionId: string) {
  switch(tabKey) {
    case 'overview': {
      return <Overview decisionId={decisionId} />
    }
    case 'criteria': {
      return <Criteria decisionId={decisionId} />
    }
    case 'criteria-prioritization': {
      return <ParticipationTab
        decisionId={decisionId}
        participationSessionType="CriteriaPrioritization"
        joinSessionLinkLabel="Prioritize Criteria"
      />
    }
    case 'criteria-prioritization-results': {
      return <CriteriaRatings decisionId={decisionId} />
    }
    case 'options': {
      return <Options decisionId={decisionId} />
    }
    case 'option-rating': {
      return <ParticipationTab
        decisionId={decisionId}
        participationSessionType="OptionRating"
        joinSessionLinkLabel="Rate Options"
      />
    }
    case 'ratings': {
      return <OptionRatings decisionId={decisionId} />
    }
    case 'participants': {
      return <Participants accountId={accountId} decisionId={decisionId} />
    }
    case 'option-valuemetrics': {
      return <Valuemetrics decisionId={decisionId} />
    }
    default: {
      return null
    }
  }
}

export function DecisionPageContent({ decisionId }: { decisionId: string }): ReactElement {
  const decision = useDecision(decisionId)
  const breadcrumbRoutes = useDecisionBreadcrumbRoutes(decisionId)

  const [tab, setTab] = useQuerystringValue('tab', 'overview', true)
  const accountId = useAccountId()

  return (
    <Page
      title={decision.name}
      breadcrumbRoutes={breadcrumbRoutes}
      extra={<DecisionDownloadButton decisionId={decisionId} />}
    >
      <Tabs activeKey={tab} onChange={setTab}>
        <Tabs.TabPane key="overview" tab="Overview" />
        <Tabs.TabPane key="criteria" tab="Criteria" />
        <Tabs.TabPane key="criteria-prioritization" tab="Criteria Prioritization" />
        <Tabs.TabPane key="criteria-prioritization-results" tab="Criteria Prioritization Results" />
        <Tabs.TabPane key="options" tab="Options" />
        <Tabs.TabPane key="option-rating" tab="Option Rating" />
        <Tabs.TabPane key="ratings" tab="Option Rating Results" />
        <Tabs.TabPane key="participants" tab="Participants" />
        <Tabs.TabPane key="option-valuemetrics" tab="Valuemetrics" />
      </Tabs>
      {renderTabContents(tab, accountId, decisionId)}
    </Page>
  )
}

export function DecisionPage(): ReactElement {
  const { decisionId } = useParams<'decisionId'>()
  invariant(decisionId, 'decisionId required in URL parameters')

  const loading = useLoadDecision(decisionId)

  if(loading) return <Spin />

  return <DecisionPageContent decisionId={decisionId} />
}
