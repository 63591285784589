import { useCallback } from 'react'
import { joinAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { createParticipant } from '@vms/vmspro3-core/dist/actions/decision'

import { useAppDispatch } from '../../redux/store'
import { useDecision } from '../../redux/hooks/decisionHooks'
import { useCurrentUser } from '../../redux/user/hooks'
import { Button } from '../../client/controls'

type CreateAuthUserParticipantProps = {
  decisionId: string,
}
export const CreateAuthUserParticipant: React.VFC<CreateAuthUserParticipantProps> = ({ decisionId }) => {
  const decision = useDecision(decisionId)
  const participantAncestry = decision && joinAncestry(decision.ancestry, decisionId)

  const [{ fullName, id: userId }] = useCurrentUser()

  const dispatch = useAppDispatch()
  const onCreateParticipant = useCallback(
    () => {
      dispatch(createParticipant(participantAncestry, {
        userId,
        fullName,
        // TODO: trackingId is required by the Participant TS type declaration
        trackingId: null,
      }))
    },
    [dispatch, participantAncestry, userId, fullName]
  )

  return (
    <div>
      <h1>You aren't a participant in this decision. Would you like to become one?</h1>
      <Button type="primary" onClick={onCreateParticipant}>Yes</Button>
    </div>
  )
}
