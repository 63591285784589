import React, { useMemo, useCallback } from 'react'
import { createStructuredSelector } from 'reselect'
import { useSelector, useDispatch } from 'react-redux'

import FormModal from './FormModal'
import EmailField from '../../components/EmailField'
import { Form, Input, Select } from '../controls'

import actions from '../../actions'
import useAuthz from '../../hooks/useAuthz'
import { getRolePolicies } from '../../selectors'
import { selectAccountId, selectAccountName } from '../../redux/account/selectors'
import { selectAuthUserId } from '../../redux/auth/selectors'
import { selectCurrentUserName } from '../../redux/user/selectors'
import { selectUsers } from '../../redux/users/selectors'

const selectInvitationData = createStructuredSelector({
  accountId: selectAccountId,
  accountName: selectAccountName,
  invitedById: selectAuthUserId,
  invitedByName: selectCurrentUserName,
})

function InviteUserModal() {
  const dispatch = useDispatch()
  const invitationData = useSelector(selectInvitationData)
  const allUsers = useSelector(selectUsers)

  const onOk = useCallback(fieldValues => {
    const { email, fullName, policyIds } = fieldValues

    dispatch(actions.account.inviteUser({
      ...invitationData,
      email,
      fullName,
      policyIds,
    }))
  }, [dispatch, invitationData])

  const rolePolicies = useSelector(getRolePolicies)
  const authz = useAuthz()
  const policyOptions = useMemo(
    () => rolePolicies
      .filter(({ id: policyId }) => authz(actions.user.addPolicy(null, {
        userId: 'ANY',
        authUserId: invitationData.invitedById,
        policyId,
      })))
      .map(({ id, name }) => ({ value: id, label: name })),
    [invitationData, rolePolicies, authz]
  )

  const existingUser = () => ({
    validator(_, value) {
      return allUsers.some(u => u.email === value)
        // eslint-disable-next-line prefer-promise-reject-errors
        ? Promise.reject('A user with that email already has access to this account')
        : Promise.resolve()
    },
  })

  return (
    <FormModal
      layout="vertical"
      onOk={onOk}
      modalId={InviteUserModal.id}
    >
      <h2>Invite a user to join {invitationData.accountName}.</h2>
      <EmailField additionalRules={[existingUser]} />
      <Form.Item name="fullName" label="Full Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Global roles"
        name="policyIds"
        rules={[{ required: true }]}
      >
        <Select mode="multiple" options={policyOptions} />
      </Form.Item>
    </FormModal>
  )
}
InviteUserModal.id = 'InviteUserModal'

export default InviteUserModal
