import { countBy, minBy, sample } from 'lodash'

export function getRandomColorFromLeastFrequent<T extends { color: string }>(
  colors: string[],
  inUse: T[]
) {
  const colorsInUse = countBy(inUse, 'color')
  const colorCounts = colors.map(color => [color, colorsInUse[color] ?? 0])
  const lowestCount = minBy(colorCounts, '1')?.[1]
  const leastUsedColors = colorCounts.filter(cc => cc[1] === lowestCount).map(cc => cc[0])
  return sample(leastUsedColors) as string
}
