import { produce } from 'immer'
import { Option } from '@vms/vmspro3-core/dist/types'
import {
  CreateOptionAction,
  UpdateOptionAction,
  DeleteOptionAction,
} from '@vms/vmspro3-core/dist/actions/decision'

import { FetchDecisionEntitySuccessAction, FetchDecisionFolderChildrenSuccessAction } from '../actionTypes'

export type OptionsState = {
  byId: Record<string, Option>
}
export const initialState: OptionsState = {
  byId: {},
}

type Actions =
  | FetchDecisionFolderChildrenSuccessAction
  | FetchDecisionEntitySuccessAction
  | CreateOptionAction
  | UpdateOptionAction
  | DeleteOptionAction

export const optionsReducer = produce<OptionsState, [Actions?]>((state, action) => {
  if(!action?.type) return

  switch(action.type) {
    case 'FetchDecisionFolderChildrenSuccess': // fall through to FetchDecisionEntitySuccess
    case 'FetchDecisionEntitySuccess': {
      if(action.payload.options) {
        Object.assign(state.byId, action.payload.options)
      }
      break
    }

    case 'Create Option': {
      state.byId[action.payload.id] = action.payload
      break
    }

    case 'Update Option': {
      Object.assign(state.byId[action.meta.optionId], action.payload)
      break
    }

    case 'Delete Option': {
      delete state.byId[action.meta.optionId]
      break
    }

    default: {
      break
    }
  }
}, initialState)
