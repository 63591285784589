import { useMemo } from 'react'
import { updateAccountSubscription } from '@vms/vmspro3-core/dist/actions/account/updateSubscription'

import useAuthz from './useAuthz'
import { useAccount } from '../redux/account/hooks'
import config from '../config.json'
import { testId } from '../test-automation'

function productSort(a, b) {
  // sort internal products (if any) first
  if(a.type === 'Internal' && b.type !== 'Internal') return -1
  if(a.type !== 'Internal' && b.type === 'Internal') return 1
  // then by price (low to high)
  if(a.price !== b.price) return a.price - b.price
  // and finally by name if everything else is the same
  return a.name.localeCompare(b.name)
}

/**
 * Returns products available to the current user for account creation, as well as selectOptions,
 * suitable for use with <Select> components.
 */
export function useProductsForAccountCreation() {
  const authz = useAuthz()
  return useMemo(
    () => {
      // TODO: I'm really not happy with the pattern for preflight checks for products...this business
      // of creating little pseudo-account objects for the purpose of authz (see updateAccountSubscription
      // below) is weak sauce...this is a general problem in our application.  It's relatively easy to
      // construct the action when it's actually ready to do, but prefligthing a *potential* action in the
      // future...less so.
      const account = { id: 'any', status: 'Pending' }
      const products = config.instance.products.slice().sort(productSort)
        .filter(p => authz(updateAccountSubscription(account, p, null)))
      return {
        products,
        productSelectOptions: products.map(p => ({
          ...testId(`select-option`),
          label: p.type === 'Internal'
            ? `${p.name} (free)`
            : `${p.name} ($${p.price} / ${p.interval})`,
          value: p.id,
        })),
      }
    },
    [authz]
  )
}

/**
 * Returns products that the current user and current account could switch to (i.e.,
 * products whose quotas do not support current usage are omitted).
 */
export function useProductsForSubscriptionUpdate() {
  const authz = useAuthz()
  const [account] = useAccount()

  return useMemo(
    () => config.instance.products.slice().sort(productSort)
      .filter(p => authz(updateAccountSubscription(account, p, null)))
      .map(p => {
        const product = { ...p }

        if(p.id === account.productId) {
          product.isCurrentProduct = true
        } else if(p.type !== 'Internal' && !account?.integrations?.stripe?.defaultPaymentMethodId) {
          // if there's no payment method associated with this account, they can't choose commercial products
          product.disabled = true
          product.disabledReason = 'A payment method is required for commercial products'
        } else if(Object.entries(p.quotas).some(([qk, qv]) => qv < account.quotas[qk].used)) {
          product.disabled = true
          product.disabledReason = 'Account usage exceeds plan quotas'
        }

        return product
      }),
    [authz, account]
  )
}
