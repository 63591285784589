import { ReactElement, useCallback, useMemo } from 'react'
import { Participant } from '@vms/vmspro3-core/dist/types'
import { Link } from 'react-router-dom'
import { Column, CellProps, TableInstance } from 'react-table'
import { gql, useQuery } from '@apollo/client'
import { TeamOutlined } from '@ant-design/icons'

import { QueryResult } from '../QueryResult'
import { Table, TableCellRenderer } from '../Table'
import { useShowModal } from '../../redux/hooks'
import { ParticipantFieldsFragment } from '../../graphql'
import { ParticipantsMergeModalData, ParticipantsMergeModalId } from '../../client/modals/ParticipantsMergeModal'
import { Button, Modal, Popover } from '../../client/controls'

type ParticipantForTable = Participant & {
  associatedUser?: {
    fullName: string,
  },
  lastParticipation: number,
}
interface GetParticipantsData {
  decisionNode: {
    participants: ParticipantForTable[]
  }
}
interface GetParticipantsVariables {
  accountId: string,
  decisionId: string,
}
const GET_PARTICIPANTS = gql`
  query GetParticipants($accountId: ID!, $decisionId: ID!) {
    decisionNode(accountId: $accountId, decisionNodeId: $decisionId) {
      ...on Decision {
        participants {
          ...ParticipantFields
          associatedUser {
            fullName
          }
          lastParticipation
        }
      }
    }
  }
  ${ParticipantFieldsFragment}
`

type ParticipantsProps = {
  accountId: string,
  decisionId: string,
}
export function Participants({ accountId, decisionId }: ParticipantsProps): ReactElement {
  const { data, error, loading } = useQuery<GetParticipantsData, GetParticipantsVariables>(GET_PARTICIPANTS, {
    variables: {
      accountId,
      decisionId,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
  })

  const columns = useMemo<Column<ParticipantForTable>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'fullName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Associated User',
        accessor: row => row.associatedUser?.fullName,
        Cell: ({ value, row }: CellProps<ParticipantForTable, string | undefined>) => value
          ? <Link to={`/user/${row.original.userId}`}>{value}</Link>
          : null,
      },
      {
        Header: 'Last Participation',
        accessor: 'lastParticipation',
        Cell: TableCellRenderer.Timestamp,
        sortType: 'numeric',
      },
    ],
    []
  )

  const showMergeParticipantsModal = useShowModal<ParticipantsMergeModalData>(ParticipantsMergeModalId)
  const onMergeParticipants = useCallback(
    (instance: TableInstance<ParticipantForTable>) => {
      const participants = instance.selectedFlatRows.map(row => row.original)

      if(participants.filter(participant => participant.userId).length > 1) {
        return (
          Modal.warning({
            title: 'Merge Participants',
            content: 'You selected two or more participants with an associated user. Merging user ' +
              'data is not supported. Please contact support if you need assistance.',
            cancelButtonProps: {
              style: {
                display: 'none',
              },
            },
          })
        )
      }

      return showMergeParticipantsModal({
        accountId,
        decisionId,
        participants,
      })
    },
    [accountId, decisionId, showMergeParticipantsModal]
  )

  return (
    <QueryResult data={data} error={error} loading={loading}>
      {({ decisionNode }) => (
        <Table
          data={decisionNode.participants}
          columns={columns}
          rowSelectPosition="first"
          extraControls={instance => {
            const { selectedRowIds } = instance.state
            const mergeParticipantsDisabled = selectedRowIds && Object.keys(selectedRowIds).length < 2

            const mergeParticipantsButton = (
              <Button
                disabled={mergeParticipantsDisabled}
                icon={<TeamOutlined />}
                onClick={() => onMergeParticipants(instance)}
              >
                Merge Participants
              </Button>
            )

            if(mergeParticipantsDisabled) {
              return (
                <Popover
                  title="Merge Participants"
                  content="Select two or more participants to merge"
                  placement="bottomLeft"
                >
                  {mergeParticipantsButton}
                </Popover>
              )
            }

            return mergeParticipantsButton
          }}
        />
      )}
    </QueryResult>
  )
}
