import { useCallback, useMemo, ReactElement } from 'react'
import _groupBy from 'lodash/groupBy'

import { Option, RatingVector } from '@vms/vmspro3-core/dist/types'
import { CriterionData } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { updateRating, updateRatingNotes } from '@vms/vmspro3-core/dist/actions/decision'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'
import { identifyRatingNotes } from '@vms/vmspro3-core/dist/utils/ratingNotes'

import { RatingInterface, RatingInterfaceProps } from '../RatingInterface/RatingInterface'

import { useAppDispatch, useAppSelector } from '../../redux'
import { useCycleSelectOptions } from '../../hooks/useCycleSelectOptions'
import { useQuerystringValue } from '../../hooks/useQuerystringValue'
import { getSelectRatings } from '../../redux/selectors'
import { useAuthUserParticipantContext } from './authUserParticipantContext'
import {
  useCriterion,
  useDecision,
  useDecisionChildAncestry,
  useLeafCriteria,
  useLeafCriteriaSelectOptions,
  useLeafCriterionLabel,
  useOptions,
  useRatableOptions,
  useRootPerfCriterionId,
} from '../../redux/hooks'

type OptionRatingInterfaceProps = RatingInterfaceProps<Option>

function useRatingContextProgressProps(
  decisionId: string,
): OptionRatingInterfaceProps['ratingContextProgressProps'] {
  const participantId = useAuthUserParticipantContext()

  const options = useOptions(decisionId)
  const leafCriteria = useLeafCriteria(decisionId)
  const ratings = useAppSelector(
    useMemo(
      () => getSelectRatings<'participantId'>(decisionId, 'OptionRating', { participantId }),
      [decisionId, participantId]
    )
  )

  return useMemo(
    () => {
      const ratingsByContextId = _groupBy(ratings, 'contextId')

      const totalCount = leafCriteria.length
      let inProgressCount = 0
      let completedCount = 0

      // it is possible that a criterion may have been used as a rating context
      // before child criteria were added, leaving stray ratings that should
      // not contribute to rating progress metrics. only use current leaf
      // criteria ratings.
      leafCriteria.forEach(leafCriterion => {
        const contextRatings = ratingsByContextId[leafCriterion.id]
        const subjectHasRating = (option: Option) => contextRatings?.some(rating =>
          rating.subjectId === option.id &&
          rating.ratingVector !== null
        )

        if(options.every(subjectHasRating)) {
          completedCount++
        } else if(options.some(subjectHasRating)) {
          inProgressCount++
        }
      })

      return {
        totalCount,
        inProgressCount,
        completedCount,
      }
    },
    [ratings, options, leafCriteria]
  )
}

type RatingContextDetailsType = OptionRatingInterfaceProps['ratingContextDetailsProps']['detailsType']
function useRatingContextDetailsProps(
  decisionId: string,
  contextCriterion: CriterionData,
): OptionRatingInterfaceProps['ratingContextDetailsProps'] {
  const decision = useDecision(decisionId)
  const rootPerfCriterionId = useRootPerfCriterionId(decisionId)
  const contextLeafCriterionLabel = useLeafCriterionLabel(decisionId, contextCriterion.id, rootPerfCriterionId)

  const options = useOptions(decisionId)

  const [detailsType, setDetailsType] = useQuerystringValue<RatingContextDetailsType>('detail-type', 'context')

  return {
    decisionObjective: decision.objective,
    ratingContextDescription: contextCriterion?.description,
    ratingContextLabel: contextLeafCriterionLabel || '',
    subjects: options,
    subjectTypeLabel: 'Options',
    detailsType,
    setDetailsType,
  }
}

function useRatingNotesProps(
  decisionId: string,
  participationSessionId: string,
  contextCriterion: CriterionData,
): OptionRatingInterfaceProps['ratingNotesProps'] {
  const participantId = useAuthUserParticipantContext()

  const ancestry = useDecisionChildAncestry(decisionId)
  const dispatch = useAppDispatch()
  const onChangeRatingNotesValue = useCallback(
    (notes: string) => dispatch(updateRatingNotes(ancestry, {
      participationSessionId,
      participantId,
      contextType: 'Criterion',
      contextId: contextCriterion.id,
      subjectType: 'Option',
      notes: createHtmlObject(notes || null),
    })),
    [dispatch, ancestry, participationSessionId, participantId, contextCriterion.id]
  )
  const ratingNotesId = useMemo(
    () => identifyRatingNotes({
      participationSessionId,
      participantId,
      contextId: contextCriterion.id,
      subjectType: 'Option',
    }),
    [participationSessionId, participantId, contextCriterion.id]
  )

  const ratingNotesValue = useAppSelector(
    state => ratingNotesId && state.ratingNotes.byId[ratingNotesId]?.notes?.value
  )

  return {
    label: `Notes for "${contextCriterion.name}"`,
    value: ratingNotesValue,
    onChange: onChangeRatingNotesValue,
    key: ratingNotesId,
  }
}

function useRatingProps(
  decisionId: string,
  participationSessionId: string,
  contextCriterion: CriterionData,
): OptionRatingInterfaceProps['ratingProps'] {
  const participantId = useAuthUserParticipantContext()

  const ratableOptions = useRatableOptions(
    decisionId,
    participantId,
    contextCriterion.id,
  )

  const ancestry = useDecisionChildAncestry(decisionId)
  const dispatch = useAppDispatch()
  const onRateOption = useCallback(
    (optionId: string, ratingVector: RatingVector, abstain?: boolean) => {
      if(ancestry && participantId && participationSessionId) {
        const action = updateRating(ancestry, {
          participationSessionId,
          participantId,
          contextType: 'Criterion',
          contextId: contextCriterion.id,
          subjectType: 'Option',
          subjectId: optionId,
          ratingVector,
          abstain,
        })
        dispatch(action)
      }
    },
    [dispatch, ancestry, participationSessionId, participantId, contextCriterion.id]
  )

  return {
    ratingScaleConfig: contextCriterion.optionRatingScaleConfig,
    items: ratableOptions,
    onRateItem: onRateOption,
  }
}

function useRatingContextControls(decisionId: string) {
  const rootPerfCriterionId = useRootPerfCriterionId(decisionId)
  const criteriaSelectOptions = useLeafCriteriaSelectOptions(decisionId, rootPerfCriterionId)
  if(!criteriaSelectOptions.length) {
    throw new Error('participation requires decision to have root performance criterion')
  }

  const [ratingContextId, setRatingContextId] = useQuerystringValue('criterionId', criteriaSelectOptions[0].value)

  const { prevOption, nextOption } = useCycleSelectOptions({
    selectOptions: criteriaSelectOptions,
    value: ratingContextId,
    onChange: setRatingContextId,
  })

  return {
    prevRatingContext: prevOption,
    nextRatingContext: nextOption,
    ratingContextId,
  }
}

type OptionRatingProps = {
  decisionId: string,
  participationSessionId: string,
}
export function OptionRating({
  decisionId,
  participationSessionId,
}: OptionRatingProps): ReactElement {
  const {
    prevRatingContext,
    nextRatingContext,
    ratingContextId: criterionId,
  } = useRatingContextControls(decisionId)

  const contextCriterion = useCriterion(criterionId)
  if(!contextCriterion) {
    throw new Error('Context criterion not found')
  }

  const ratingContextProgressProps = useRatingContextProgressProps(decisionId)
  const ratingContextDetailsProps = useRatingContextDetailsProps(decisionId, contextCriterion)
  const ratingNotesProps = useRatingNotesProps(decisionId, participationSessionId, contextCriterion)
  const ratingProps = useRatingProps(decisionId, participationSessionId, contextCriterion)

  const rootPerfCriterionId = useRootPerfCriterionId(decisionId)
  const contextLeafCriterionLabel = useLeafCriterionLabel(decisionId, criterionId, rootPerfCriterionId)
  const decision = useDecision(decisionId)

  if(!contextLeafCriterionLabel) return <h2>Criterion not found</h2>

  return (
    <RatingInterface<Option>
      ratingContextProgressProps={ratingContextProgressProps}
      ratingContextDetailsProps={ratingContextDetailsProps}
      ratingNotesProps={ratingNotesProps}
      ratingProps={ratingProps}
      prevRatingContext={prevRatingContext}
      nextRatingContext={nextRatingContext}
      ratingContextLabel={contextLeafCriterionLabel}
      decisionName={decision.name}
      participationActivityLabel="Rating"
    />
  )
}
