import { getPolicies } from '../../services/policiesService'

import policiesActionTypes from './actionTypes'

export function fetchPolicies() {
  return async dispatch => {
    try {
      dispatch({
        type: policiesActionTypes.FetchPoliciesRequest,
        meta: { ephemeral: true },
      })

      const policies = await getPolicies()

      dispatch({
        type: policiesActionTypes.FetchPoliciesSuccess,
        payload: { policies },
        meta: { ephemeral: true },
      })
    } catch(error) {
      console.error(error)
    }
  }
}
