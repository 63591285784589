import React from 'react'
import FroalaEditor from 'react-froala-wysiwyg'
import Modal from 'antd/lib/modal'
import Button from 'antd/lib/button'
import { css } from 'glamor'

import 'froala-editor/js/froala_editor.pkgd.min'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'font-awesome/css/font-awesome.css'
import 'froala-editor/js/plugins.pkgd.min'
import '../styles/rich-text.css'

import config from '../../config.json'

// Froala license key; see Ethan Brown <ethan@vms-inc.com>, who holds the Froala account
const froalaKey = (config.instance && config.instance.froalaKey)
  || 'bMA6aD7B5C2A2D2G2xROKLJKYHROLDXDRH1e1YYGRe1Bg1G3I3A2C6D6A3F3E4D2F2=='

const basicToolbarButtons = [
  'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript',
]

const advancedToolbarButtons = [
  'fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily',
  'fontSize', '|', 'color', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL',
  'formatUL', 'outdent', 'indent', 'quote', 'insertHR', '|', 'undo', 'redo', 'clearFormatting', 'selectAll',
  '|', 'insertTable', '|', 'insertLink',
]

// 01/22/2020: REMOVED the table edit options from toolbarButtons.
// These options are included in the tableEditButtons which display in a tooltip when clicking on the Table.
// https://www.froala.com/wysiwyg-editor/docs/options#tableEditButtons
const tableEditButtons = [
  'tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', 'tableStyle', '-', 'tableCells',
  'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign', 'tableCellStyle',
]

const plugins = [
  'align', 'charCounter', 'codeBeautifier', 'costView', 'colors', 'draggable', 'emoticons', 'entities', 'file',
  'fontFamily', 'fontSize', 'fullscreen', 'inlineStyle', 'lineBreaker', 'link', 'lists',
  'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'wordPaste',
]

/**
 * Rich text control without reliance on studies.  For now, file upload
 * support has simply been removed.
 */
class RichText2 extends React.Component {

  state = {
    showModal: false,
    editing: false,
  }

  render() {
    const {
      basic,      // Show only the basic toolbar options
      height,     // if height is set, heightMin and heightMax are ignored
      heightMax,
      heightMin,
      initialFocus, // This prop is for setting initial focus in FormModal
      modal,
      noAutoExpand,
      onChange,
      readOnly,
      title,
      value = '',
    } = this.props

    const toolbarButtons = basic
      ? basicToolbarButtons
      : advancedToolbarButtons

    const options = {
      attribution: false,         // Removes the "Powered By FROALA" banner
      charCounterCount: !basic,
      height,                     // if height is set, heightMin and heightMax are ignored
      heightMax,
      heightMin,
      key: froalaKey,
      pluginsEnabled: plugins,    // This setting is also from this github issue:
                                  // https://github.com/froala/react-froala-wysiwyg/issues/26
      placeholderText: this.placeholder || '',
      spellcheck: true,
      scaytAutoload: false,       // This is set to false to avoid the issue where every editor loads many
                                  // assets: https://github.com/froala/react-froala-wysiwyg/issues/26
      tableEditButtons,
      tableEditButtonsMD: tableEditButtons,
      tableEditButtonsSM: tableEditButtons,
      tableEditButtonsXS: tableEditButtons,
      toolbarSticky: false,
      toolbarButtons,
      toolbarButtonsMD: toolbarButtons,
      toolbarButtonsSM: toolbarButtons,
      toolbarButtonsXS: toolbarButtons,
      tabSpaces: modal ? 4 : 0,   // use tab key for form navigation unless in modal
      events: {
        initialized: (e, editor) => {
          initialFocus && initialFocus(editor)
          modal && editor && editor.events.focus()
        },
        focus: () => this.setState({ editing: true }),
        blur: () => this.setState({ editing: false }),
      },

      quickInsertEnabled: !basic,
    }

    if(modal) {
      return (
        <div
          className='rich-text'
          style={{
            marginTop: '24px',
            border: '1px solid #ddd',
            padding: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h4>{title + ': '}</h4>
            {!readOnly &&
              <Button onClick={() => this.setState({ showModal: true })}>Edit</Button>
            }
          </div>
          <Modal
            className='rich-text-modal rich-editing'
            visible={this.state.showModal}
            title={title}
            footer={<Button type='primary' onClick={() => this.setState({ showModal: false })}>Done</Button>}
            closable={false}
            width='80%'
          >
            <FroalaEditor
              config={options}
              model={value}
              onModelChange={model => onChange(model)}
            />
          </Modal>
          {value && value.trim() &&
            <div
              style={{ padding: '10px' }}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: value }}
            />
          }
        </div>
      )
    }
    const containerClassName = 'input-field rich' + (this.state.editing ? '-editing' : '')
      + (noAutoExpand ? ' no-expand' : '')
    return (
      <div className='rich-text'>
        <span>{title}</span>
        <div {...style.froalaEditor} className={containerClassName}>
          {readOnly ? (
            <div
              className="rich-text-field rich-text-disabled"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: value }}
            />

          ) : (
            <FroalaEditor
              config={options}
              model={value}
              onModelChange={model => onChange(model)}
            />
          )}
        </div>
      </div>
    )
  }
}

const style = {
  froalaEditor: css({
    '& .fr-box.fr-basic .fr-element': {
      padding: '0px 20px',
    },
  }),
}

export default RichText2
