import { ReactElement } from 'react'
import { HttpLink, ApolloClient, InMemoryCache, ApolloProvider as Provider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { Auth } from '../server/AmplifyProxy'
import config from '../config.json'

const httpLink = new HttpLink({
  uri: operation => {
    if(operation.getContext().headers?.['vms-jwtidtoken']) {
      return config.amplify.API.graphql_endpoint
    }
    return config.amplify.API.graphql_endpoint.replace(/\/graphql$/, '/unauth/graphql')
  },
})

const authMiddleware = setContext(async (_, { headers = {} }) => {
  const context = { headers: { ...headers } }

  try {
    const session = await Auth.currentSession()
    context.headers['vms-jwtidtoken'] = session.getIdToken().getJwtToken()
  } catch{ // optional catch binding and eslint/keyword-spacing https://github.com/eslint/eslint/issues/13278
    // no current user
  }

  return context
})

const client = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Rating: {
        keyFields: [
          'participationSessionId',
          'participantId',
          'contextId',
          'subjectId',
        ],
      },
      RatingNotes: {
        keyFields: [
          'participationSessionId',
          'participantId',
          'contextId',
          'subjectType',
        ],
      },
    },
  }),
})

interface Props {
  children: ReactElement,
}
export function ApolloProvider({ children }: Props): ReactElement {
  return (
    <Provider client={client}>
      {children}
    </Provider>
  )
}
