import { createId } from '../idUtils'
import * as valueFormula from './valueFormula'

/**
 * @deprecated see core/src/nextgen/valueGraph/valueNode
 */
export type ValueGraph = {
  nodes: ValueGraphNode[]
  edges: ValueGraphEdge[]
}

/**
 * @deprecated see core/src/nextgen/valueGraph/valueNode
 */
export type ValueGraphNode = {
  id: string
  type: 'Value' | 'PerformanceRoot' | 'Performance' | 'Intrinsic' | 'Intermediate'
  value: number | null
  readonly weightedValue: number | null
  weight?: number
  criterionId?: string
  symbol?: string
}

/**
 * Creates an "empty" value graph node...most importantly, this creates the weightedValue getter.
 *
 * @deprecated see core/src/nextgen/valueGraph/valueNode
 */
 export function createValueGraphNode(type: ValueGraphNode['type'] = 'Intermediate', value: number | null = null) {
  const node: ValueGraphNode = {
    id: createId(),
    type,
    value,
    get weightedValue() {
      return this.weight === undefined
        ? this.value
        : this.value === null ? null : this.value * this.weight
    }
  }
  return node
}

/**
 * @deprecated see core/src/nextgen/valueGraph/valueNode
 */
export type ValueGraphEdge = {
  sourceId: string
  targetId: string
  operation: valueFormula.Operation
}
