import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import describeAction from '@vms/vmspro3-core/dist/utils/describeAction'
import moment from 'moment'

import ConfirmModal from './ConfirmModal'
import { Table } from '../controls'
import { clearLocalStorageQueue } from '../../redux/middleware/isomorphicReduxMiddleware'
import clientId from '../../utils/clientId'

import { useModalData } from '../../redux/hooks/appHooks'
import { selectDateTimeFormat } from '../../redux/user/selectors'

/**
 * Modal to give the opportunity to decide what to do with unprocessed actions that were saved to
 * local storage before they could be processed.
 */
const UnprocessedActionDispositionModal = () => {
  const dispatch = useDispatch()
  const { queuedActions } = useModalData(UnprocessedActionDispositionModal.id)
  const dateTimeFormat = useSelector(selectDateTimeFormat)

  const onOk = () => {
    queuedActions
      // we will have a new clientId, so the actions will have to be updated
      // with the new clientId, otherwise isomorphic Redux will ignore these actions
      .map(action => ({ ...action, meta: { ...action.meta, clientId } }))
      .forEach(dispatch)
    clearLocalStorageQueue()
  }

  const onCancel = () => {
    // user has chosen to discard changes
    clearLocalStorageQueue()
  }

  const tableConfig = {
    columns: [
      {
        title: 'Time',
        dataIndex: ['meta', 'timestamp'],
        render: value => moment(value).format(dateTimeFormat),
      },
      {
        title: 'Activity',
        dataIndex: 'type',
        render: (type, action) => describeAction(action),
      },
    ],
    dataSource: queuedActions.map(action => ({ ...action, seq: action.meta.seq })),
    rowKey: 'seq',
  }

  return (
    <ConfirmModal
      title="Recover Unsaved Changes?"
      id={UnprocessedActionDispositionModal.id}
      onOk={onOk}
      onCancel={onCancel}
      okText="Recover"
      cancelText="Discard"
      closable={false}
    >
      <p>It appears that you closed your browser before VMSPro could save all of your work
        to the cloud.  Fortunately, you can choose to save that work now.  Note that the older
        this unsaved work is, the greater the chance that there will be errors in saving it to
        the cloud.  If the following activity is very old, and you don't recognize it, you may wish
        to discard this work.</p>
      <p>If you have VMSPro open in another window or tab, try reloading this window first.</p>
      <Table {...tableConfig} />
    </ConfirmModal>
  )
}
UnprocessedActionDispositionModal.id = 'UnprocessedActionDispositionModal'
UnprocessedActionDispositionModal.defaulProps = {
  actionMeta: {},
}

export default UnprocessedActionDispositionModal
