import { useCallback, ReactElement } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RollbackOutlined } from '@ant-design/icons'
import invariant from 'tiny-invariant'

import { Page } from '../components'
import { Button } from '../client/controls'
import { CriteriaPrioritization, AuthUserParticipantContextProvider } from '../components/Participation'

import { useLoadDecision } from '../redux/hooks/decisionHooks'

export const CriteriaPrioritizationPage = (): ReactElement => {
  const { decisionId, participationSessionId } = useParams<'decisionId' | 'participationSessionId'>()

  invariant(decisionId, 'decisionId required in URL parameters')
  invariant(participationSessionId, 'participationSessionId required in URL parameters')

  const navigate = useNavigate()
  const backToDecision = useCallback(
    () => navigate(`/decision/${decisionId}`),
    [navigate, decisionId]
  )

  const loading = useLoadDecision(decisionId)

  return (
    <Page
      loading={loading}
      extra={(
        <Button type="primary" onClick={backToDecision} icon={<RollbackOutlined />}>
          Back to Decision
        </Button>
      )}
    >
      <AuthUserParticipantContextProvider decisionId={decisionId}>
        <CriteriaPrioritization
          decisionId={decisionId}
          participationSessionId={participationSessionId}
        />
      </AuthUserParticipantContextProvider>
    </Page>
  )
}
