import { useCallback, useMemo, Ref } from 'react'
import { Option } from '@vms/vmspro3-core/dist/types'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'
import { createOption, updateOption } from '@vms/vmspro3-core/dist/actions/decision'
import { Select } from 'antd'

import FormModal from './FormModal'
import { Form, Input, CostInput, DurationInputWithUnits } from '../controls'

import { useModalData } from '../../redux/hooks/appHooks'
import { useOptions, useDecisionChildAncestry } from '../../redux/hooks/decisionHooks'
import { colorSelectOptions } from '../../utils/appConsts'
import { useAppDispatch } from '../../redux'
import { getRandomColorFromLeastFrequent } from '../../utils/getRandomColorFromLeastFrequent'

const requiredRule = [
  { required: true, validateTrigger: 'onChange' },
  { whitespace: true, validateTrigger: 'onBlur' },
]

function useNextRandomColor(decisionId: string) {
  const options = useOptions(decisionId)

  return useMemo(
    () => getRandomColorFromLeastFrequent(colorSelectOptions.map(o => o.value), options),
    [options]
  )
}

export const OptionModalId = 'OptionModal'
export type OptionModalData = {
  option?: Option,
  decisionId: string,
}
export function OptionModal() {
  const { option, decisionId } = useModalData<OptionModalData>(OptionModalId)

  const childAncestry = useDecisionChildAncestry(decisionId)

  const dispatch = useAppDispatch()
  const onOk = useCallback(
    (data: Omit<Option, 'id'>) => {
      data = { ...data, description: createHtmlObject(data.description?.value || null) }
      if(option) {
        dispatch(updateOption(decisionId, option.id, data))
      } else {
        dispatch(createOption(childAncestry, data))
      }
    },
    [dispatch, decisionId, childAncestry, option]
  )
  const color = useNextRandomColor(decisionId)
  return (
    <FormModal
      modalId={OptionModalId}
      initialValues={option ?? { color, cost: null, time: null, commonId: null }}
      onOk={onOk}
    >
      {(initialFocusRef: Ref<Input>) => (
        <>
          <Form.Item label="Name" name="name" rules={requiredRule}>
            <Input ref={initialFocusRef} />
          </Form.Item>
          <Form.Item label="Abbreviation" name="abbrev" rules={requiredRule}>
            <Input />
          </Form.Item>
          <Form.Item label="Option ID" name="commonId">
            <Input />
          </Form.Item>
          <Form.Item label="Color" name="color" rules={[{ required: true }]}>
            <Select>
              {colorSelectOptions.map(o => (
                <Select.Option key={o.value} value={o.value} style={{ display: 'flex' }}>
                  <div style={{ width: '100%', height: '100%', padding: '4px 0', display: 'flex', gap: '12px' }}>
                    <div style={{ width: '50px', backgroundColor: o.value }} />
                    <span style={{ alignSelf: 'center' }}>{o.label}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Description" name={['description', 'value']}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="Cost" name="cost">
            <CostInput unit="USD" />
          </Form.Item>
          <Form.Item label="Duration" name="time">
            <DurationInputWithUnits defaultUnit="Months" />
          </Form.Item>
        </>
      )}
    </FormModal>
  )
}
OptionModal.id = OptionModalId
