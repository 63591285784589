import React from 'react'
import AntCard from 'antd/lib/card'
import 'antd/lib/card/style/css'

const Card = ({
  bordered,
  ...props
}) => (
  <AntCard
    bordered={bordered}
    {...props}
  />
)
Card.defaultProps = {
  bordered: false,
}

export default Card
