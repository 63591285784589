import _isMatch from 'lodash/isMatch'
import { RatingNotes } from '../types'

type RatingNotesIdKeys = 'participationSessionId' | 'participantId' | 'contextId' | 'subjectType'
type RatingNotesIdProps = Pick<RatingNotes, RatingNotesIdKeys>

/**
 * given an object containing the properties that uniquely identify a rating
 * notes object, returns a composite identifier that can be used as an index or
 * reference key.
 */
export function identifyRatingNotes(ratingNotes: RatingNotesIdProps): string {
  const { participationSessionId, participantId, contextId, subjectType } = ratingNotes
  return [participationSessionId, participantId, contextId, subjectType].join(':')
}

/**
 * given an object or an array of objects containing one or more of the
 * properties that are used in a composite rating notes id, returns a predicate
 * to match rating notes ids where all provided property values are equal in one
 * of the match objects.
 */
export function ratingNotesIdMatchesWith<T extends Partial<RatingNotesIdProps>>(match: T | T[]) {
  return (ratingNotesId: string) => {
    const [participationSessionId, participantId, contextId, subjectType] = ratingNotesId.split(':')
    const ratingNotesProps = {
      participationSessionId,
      participantId,
      contextId,
      subjectType,
    }

    if(Array.isArray(match)) {
      return match.some(m => _isMatch(ratingNotesProps, m))
    }
    return _isMatch(ratingNotesProps, match)
  }
}
