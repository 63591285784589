import { useMemo, ReactElement } from 'react'
import { createSelector } from 'reselect'
import { Column } from 'react-table'
import _keyBy from 'lodash/keyBy'
import { Html } from '@vms/vmspro3-core/dist/types'

import { Table, TableCellRenderer } from '../../Table'

import { useAppSelector } from '../../../redux'
import { getSelectParticipants, getSelectRatingNotes } from '../../../redux/selectors'
import { useCriterion, useParticipationSessionId } from '../../../redux/hooks'

type OptionRatingNotesForCriterionTableData = {
  participantName: string,
  ratingNotes: Html,
}
type OptionRatingNotesForCriterionTableProps = {
  decisionId: string,
  criterionId: string,
}
export function OptionRatingNotesForCriterionTable({
  decisionId,
  criterionId,
}: OptionRatingNotesForCriterionTableProps): ReactElement {
  const criterion = useCriterion(criterionId)

  // TODO: what to do about this if criterion is not in state
  if(!criterion) {
    throw new Error('Criterion not found')
  }

  const columns = useMemo<Column<OptionRatingNotesForCriterionTableData>[]>(
    () => [
      {
        Header: 'Participant',
        accessor: 'participantName',
      },
      {
        Header: `Notes for ${criterion.name} context`,
        accessor: 'ratingNotes',
        Cell: TableCellRenderer.Html,
      },
    ],
    [criterion]
  )

  const participationSessionId = useParticipationSessionId(decisionId, 'OptionRating')
  const data = useAppSelector(useMemo(
    () => createSelector(
      getSelectParticipants(decisionId),
      getSelectRatingNotes<'participationSessionId' | 'contextType' | 'contextId' | 'subjectType'>(decisionId, {
        participationSessionId,
        contextType: 'Criterion',
        contextId: criterionId,
        subjectType: 'Option',
      }),
      (participants, ratingNotes) => {
        if(!participants) return []
        const ratingNotesByParticipantId = _keyBy(ratingNotes, 'participantId')
        return participants.map(participant => ({
          participantName: participant.fullName,
          ratingNotes: ratingNotesByParticipantId[participant.id]?.notes,
        }))
      }
    ),
    [decisionId, participationSessionId, criterionId]
  ))

  return (
    <Table<OptionRatingNotesForCriterionTableData>
      columns={columns}
      data={data}
    />
  )
}
