import { createContext, ReactElement, useCallback, useContext } from 'react'
import classnames from 'classnames'

import style from './Tree.module.css'

interface ITreeNode<T extends ITreeNode<T>> {
  id: string,
  name: string,
  parent: T | null,
  children: T[],
}
type TreeContextValue = {
  readOnly?: boolean,
  selectedItemId?: string,
  onSelectItem?: (itemId: string) => void,
}
const TreeContext = createContext<TreeContextValue | undefined>(undefined)

export const useTreeContext = (): TreeContextValue => {
  const context = useContext(TreeContext)

  if(context === undefined) {
    throw new Error('useTreeContext must be used within TreeContext.Provider')
  }

  return context
}

type NodeProps<T> = {
  node: T,
  renderLabel: (item: T) => string | ReactElement,
  isNodeDisabled?: (item: T) => boolean,
}
export const Node = <T extends ITreeNode<T>>({
  node,
  renderLabel,
  isNodeDisabled,
}: NodeProps<T>): JSX.Element => {
  const { readOnly, selectedItemId, onSelectItem } = useTreeContext()

  const nodeDisabled = isNodeDisabled instanceof Function && isNodeDisabled(node)

  const itemId = node.id
  const onClick = useCallback(
    () => {
      if(nodeDisabled || !onSelectItem) return
      onSelectItem(itemId)
    },
    [nodeDisabled, onSelectItem, itemId]
  )

  return (
    <div>
      <span
        className={classnames(style.node, {
          [style.selected]: itemId === selectedItemId,
          [style.readOnly]: readOnly || !onClick,
          [style.disabled]: nodeDisabled,
          [style.hasClickHandler]: !!onSelectItem,
        })}
        onClick={onClick}
      >
        {renderLabel(node)}
      </span>
      <div className={style.treeNodeChildren}>
        {node.children.map(childNode =>
          <Node<T>
            key={childNode.id}
            node={childNode}
            renderLabel={renderLabel}
            isNodeDisabled={isNodeDisabled}
          />
        )}
      </div>
    </div>
  )
}

type TreeProps<T> = {
  rootNode?: T,
  readOnly?: boolean,
  selectedItemId?: string,
  onSelectItem?: (itemId: string) => void,
  renderLabel?: (item: T) => string | ReactElement,
  isNodeDisabled?: (item: T) => boolean,
}
export const Tree = <T extends ITreeNode<T>>({
  rootNode,
  readOnly,
  selectedItemId,
  onSelectItem,
  renderLabel = c => c.name,
  isNodeDisabled,
}: TreeProps<T>): JSX.Element | null => {
  if(!rootNode) return null

  return (
    <TreeContext.Provider value={{ readOnly, selectedItemId, onSelectItem }}>
      <Node<T>
        key={rootNode.id}
        node={rootNode}
        renderLabel={renderLabel}
        isNodeDisabled={isNodeDisabled}
      />
    </TreeContext.Provider>
  )
}
