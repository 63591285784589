import React from 'react'
import AntTimePicker from 'antd/lib/time-picker'
import 'antd/lib/time-picker/style/css'

function TimePicker(props) {
  const { format } = props
  const use12Hours = props.use12Hours === undefined
    ? format && /a/i.test(format.replace(/\[.*?\]/g, ''))
    : props.use12Hours
  return <AntTimePicker {...props} use12Hours={use12Hours} />
}

export default TimePicker
