import { createSelector } from 'reselect'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'

const { EntityType } = systemConsts

/**
 *
 * @param {string} riskId
 * @returns {<Object> => {}} - selector function returning risk object
 */
const getRisk = riskId => state => state.riskEntities.byId[riskId]

/**
 *
 * @param {string} ancestry
 * @returns {<Object> => Array.<Object>} - selector function returning risks
 */
// TODO: this needs to be turned into a hook with memoization
const getRisksByAncestry = ancestry => createSelector(
  state => state.riskEntities.byAncestry[ancestry],
  risks => risks && risks.filter(r => r.entityType === EntityType.RISK).sort((a, b) => a.num - b.num)
)

export {
  getRisk,
  getRisksByAncestry,
}
