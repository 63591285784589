import { useMemo, useCallback, useEffect } from 'react'
import _matches from 'lodash/matches'
import { UnreachableCaseError } from 'ts-essentials'
import { RatingNotes, ParticipationSessionType, RatingSubjectType } from '@vms/vmspro3-core/dist/types'

import { RatingsTable } from './RatingsTable'
import { Row, Col } from '../../client/controls'
import { CycleSelect } from '../CycleSelect'
import { useQuerystringValue } from '../../hooks/useQuerystringValue'
import {
  useParticipants,
  useParticipationSessionId,
  useRatingNotes,
} from '../../redux/hooks/decisionHooks'

export type PivotOption = {
  id: string
  label: string
}
export type RatingsProps = {
  decisionId: string
  pivotType: 'subject' | 'context'
  pivotLabel: string
  subjectType: 'Option' | 'Criterion'
  pivotOptions: PivotOption[]
  pivotIdQuerystringKey: string,
  defaultPivotId?: string,
}

function getParticipationSessionTypeBySubjectType(subjectType: RatingSubjectType): ParticipationSessionType {
  switch(subjectType) {
    case 'Criterion': {
      return 'CriteriaPrioritization'
    }
    case 'Option': {
      return 'OptionRating'
    }
    default: {
      throw new UnreachableCaseError(subjectType)
    }
  }
}

/**
 * Component for viewing ratings.  Can pivot by either subject (for exmaple, in option rating) or
 * context (criterion).  Provides controls for deleting individual ratings or all data for
 * a participant (including the participant).
 */
export function Ratings({
  decisionId,
  pivotType,
  pivotLabel,
  subjectType,
  pivotOptions,
  pivotIdQuerystringKey,
  defaultPivotId = pivotOptions[0]?.id,
}: RatingsProps): JSX.Element {

  const participants = useParticipants(decisionId)
  const allRatingNotes = useRatingNotes(decisionId)

  const [selectedPivotId, setSelectedPivotId] = useQuerystringValue(pivotIdQuerystringKey, defaultPivotId)
  const pivotSelections = useMemo(() => pivotOptions.map(s => ({ label: s.label, value: s.id })), [pivotOptions])
  const onChangePivot = useCallback(pivotId => {
    setSelectedPivotId(pivotId)
  }, [setSelectedPivotId])
  const selectedPivot = useMemo(() =>
    selectedPivotId ? pivotOptions.find(o => o.id === selectedPivotId) : undefined,
  [selectedPivotId, pivotOptions])

  const [selectedParticipantId, setSelectedParticipantId] = useQuerystringValue(
    'participantId',
    participants[0]?.id
  )
  const selectParticipants = useMemo(() =>
    participants && participants.map(o => ({ label: o.fullName, value: o.id })), [participants])
  const onChangeParticipant = useCallback(participantId =>
    setSelectedParticipantId(participantId),
  [setSelectedParticipantId])
  const selectedParticipant = useMemo(() =>
    selectedParticipantId ? participants.find(p => p.id === selectedParticipantId) : undefined,
  [selectedParticipantId, participants])

  const participationSessionType = getParticipationSessionTypeBySubjectType(subjectType)
  const participationSessionId = useParticipationSessionId(decisionId, participationSessionType)

  // participant may have been deleted, which will cause problems...so we un-set
  // the participant if it isn't in the store
  useEffect(() => {
    if(selectedParticipant && !participants.some(p => p.id === selectedParticipant.id)) {
      setSelectedParticipantId('')
    }
  }, [selectedParticipant, participants, setSelectedParticipantId])

  // if we're pivoting by context, we display the notes on this screen (otherwise
  // they would be the same for all rows
  const participantNotes = useMemo(() => {
    if(!selectedParticipant || pivotType !== 'context') return null
    const matchRatingNotes: Partial<RatingNotes> = {
      participationSessionId,
      participantId: selectedParticipant.id,
      contextType: 'Criterion',
      contextId: selectedPivot?.id,
      subjectType,
    }
    const ratingNotes = allRatingNotes.find(_matches(matchRatingNotes))

    return (!ratingNotes || !ratingNotes?.notes?.value || !/\S/.test(ratingNotes.notes.value))
      ? '<p><i>(none)</i></p>'
      : ratingNotes.notes.value
  }, [pivotType, allRatingNotes, subjectType, selectedPivot, participationSessionId, selectedParticipant])

  return (
    <>
      <div style={{ paddingBottom: '12px' }}>
        <CycleSelect
          label={pivotLabel}
          selectOptions={pivotSelections}
          onChange={onChangePivot}
          value={selectedPivot?.id}
        />
      </div>
      <div style={{ paddingBottom: '12px' }}>
        <CycleSelect
          label="Participant"
          selectOptions={selectParticipants}
          onChange={onChangeParticipant}
          value={selectedParticipant?.id}
        />
      </div>
      {participantNotes &&
        <Row style={{ paddingBottom: '12px' }}>
          <Col xs={4}>Participant notes:</Col>
          <Col xs={20} dangerouslySetInnerHTML={{ __html: participantNotes }} />
        </Row>
      }
      {participationSessionType && selectedPivot && selectedParticipant &&
        <RatingsTable
          decisionId={decisionId}
          participationSessionType={participationSessionType}
          pivotType={pivotType}
          pivotId={selectedPivot.id}
          subjectType={subjectType}
          participantId={selectedParticipant.id}
        />
      }
    </>
  )
}
