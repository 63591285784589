import { createSelector } from 'reselect'

export const selectUsersState = state => state.users

export const selectUsers = createSelector(
  [selectUsersState],
  state => state.ids.map(id => state.byId[id])
)
export const selectUsersLoadingStatus = createSelector(
  [selectUsersState],
  state => state.loadingStatus
)
