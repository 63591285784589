import { createId } from "../../idUtils"
import * as valueFormula from '../../valuemetrics/valueFormula'
import { extractCoefficient } from '../../valuemetrics/util'

import { safeSum, safeMult, safeDiv } from './util'

/**
 * There are subtle differences between a value formula expression and the stylized value graph
 * representation.  In particular:
 *
 *   - Multiplying a numeric constant with a symbol is collapsed into a single node in the
 *     value graph; the constant is the coefficient (or weight).
 *
 *   - A complemented symbol in the value expression is translated into two nodes; a complement
 *     node with a single operand, the uncomplemented node.  This is designed to highlight, in
 *     the value graph output that complemented symbols move in the opposite direciton of value.
 *
 * This type is more suitable for translation into a value graph than a value expression.
 */
type ValueGraphExpression =
  | string
  | number
  | {
    operation: valueFormula.Operation | 'ScoreComplement'
    // note that everything has a coefficient; if there is no coefficient in the value
    // expression, the coefficient will simply be 1
    coefficient: number
    operands: Array<ValueGraphExpression | number>
  }
  | {
    operation: 'Score'
    coefficient: number
    operands: []
    score: number | string
  }

/**
 * Convert a value formula Expression into a ValueGraphExpression,
 * which is easier to convert into a value graph.
 */
export function valueExpressionToValueGraphExpression(expr: valueFormula.Expression): ValueGraphExpression {
  // turn all terminal expressions into a coefficient expression
  if(!Array.isArray(expr)) expr = ['Multiply', 1, expr]
  const coef = extractCoefficient(expr)
  if(coef) {
    const [coefficient, symbol, isComplement] = coef
    if(isComplement) return {
      operation: 'ScoreComplement',
      coefficient,
      operands: [symbol],
    }
    if(coefficient === 1) return symbol
    return {
      operation: 'Score',
      coefficient,
      operands: [],
      score: symbol,
    }
  } else {
    return {
      operation: expr[0],
      coefficient: 1,
      operands: expr.slice(1).map(valueExpressionToValueGraphExpression)
    }
  }
}

/**
 * In a value expression (or graph), each node has a type:
 *  - Value: the root node that represents the final composed value of the expression.
 *  - Intermediate: certain operation types require an intermediate node; for example,
 *      division requires an intermediate node to represent the denominator (if it is
 *      an additive expression, for example).
 *  - PerformanceRoot: the root performance node.
 *  - Performance: a non-root performance attribute (a.k.a. criterion).
 *  - Intrinsic: represents an intrinsic quantitative score (for example "Cost Score" or "Time Score").
 */
export type ValueNodeType =
  | 'Value'
  | 'Intermediate'
  | 'PerformanceRoot'
  | 'Performance'
  | 'Intrinsic'

// TODO: add ValueNodeType
export class ValueNode {
  /**
   * ID of the value node.  Note that in the case of criteria (which have their own ID), this is NOT
   * the same, as it's theoritically possible for criteria to be used more than once in the same
   * value graph.
   */
  id: string = createId()
  /**
   * The symbol to use in the value graph.  Common examples are V for value, C for cost, etc.  Note that
   * LaTeX syntax is supported, so you could have C_c for capital cost, for example.  For performance
   * nodes, the symbol will generally be the criterion abbreviation.
   */
  symbol?: string
  /**
   * The name of the node (for example, "Value" or "Performance root" or the long name of a criterion).
   */
  name?: string
  /**
   * The node's type.
   */
  type: ValueNodeType
  /**
   * Each internal node represents the result of a mathematical operation; the "children"
   * of the node are the operands.  For example, an "Add" node represents the sum
   * of its child nodes.  Note that the order of operands can be significant; for
   * example, in division, the first operand is the numerator, and the remaining
   * operands are divisors.
   *
   * Note that the ValueGraph is subtly different than a value expression (which is
   * essentially a formula).  In particular, some parts of the formula syntax tree
   * are collapsed (such as a multiplication that represents a coefficient) and some
   * are expanded (such as the score complement).
   */
  operation?: valueFormula.Operation | 'Score' | 'ScoreComplement'
  /**
   * The (unweighted) value of the node.
   */
  value: number | null = null
  /**
   * The weight (or priority) of this node.  Note that the weight is generally defined from the
   * perspective of the parent.  For example, if there are two top-level performance attributes
   * A and B that have weights of 80% and 20%, both with a value of 10, their weighted values
   * will be 8 and 2 respectively, and their parent would have a value of 10 (8 + 2).
   */
  weight: number | null = null
  /**
   * The weighted value of this node; simply the product of the value and weight.
   */
  get weightedValue(): number | null {
    return this.weight !== null && this.value !== null ? this.weight * this.value : null
  }
  /**
   * The parent node (if there is no parent node, this is a root node).
   */
  parent?: ValueNode
  /**
   * The children (or operands) of this node.
   */
  children: ValueNode[] = []
  /**
   * Descendants of this node (as flat array).
   */
  get descendants(): ValueNode[] {
    return [...this.children, ...this.children.map(c => c.descendants).flat()]
  }

  private setFromTerminalExpression(
    weight: number,
    expression: string | number,
    vars: Record<string, null | number | ValueNode>
  ) {
    this.weight = weight
    if(typeof expression === 'string') {
      this.name = expression
      const v = vars[expression] || null
      if(v && typeof v === 'object') {
        this.value = v.value
        // currently, only the root performance node is passed in as an object var
        this.type = 'PerformanceRoot'
        this.children = v.children
      } else {
        this.value = v
      }
    } else {
      this.name = expression.toString()
      this.value = expression
    }
  }

  constructor(
    expression?: ValueGraphExpression,
    vars: Record<string, null | number | ValueNode> = {}
  ) {
    this.type = 'Value'
    if(expression) {
      if(typeof expression === 'object') {
        this.type = 'Intermediate'
        this.name = expression.operation
        this.operation = expression.operation
        this.weight = expression.coefficient
        this.children = expression.operands.map(o => new ValueNode(o, vars))
        switch(expression.operation) {
          case 'Add': this.value = safeSum(this.children.map(c => c.weightedValue)); break
          case 'Multiply': this.value = safeMult(this.children.map(c => c.weightedValue)); break
          case 'Divide': this.value = safeDiv(this.children.map(c => c.weightedValue)); break
          case 'Score': {
            this.setFromTerminalExpression(expression.coefficient, expression.score, vars)
            break
          }
          case 'ScoreComplement': {
            const score = this.children[0].weightedValue
            this.value = score === null ? null : 10 - score
            break;
          }
        }
      } else {
        this.setFromTerminalExpression(1, expression, vars)
      }
    }
  }
}
