import { useCallback } from 'react'
import { gql, useQuery, useMutation, MutationResult, QueryResult, FetchResult } from '@apollo/client'

import {
  Option,
  ParticipationSession,
  Rating,
  RatingContextType,
  RatingNotes,
  RatingSubjectType,
  RatingVector,
} from '@vms/vmspro3-core/dist/types'
import { CriterionData } from '@vms/vmspro3-core/dist/nextgen/Criterion'

import {
  DecisionFieldsFragment,
  CriterionFieldsFragment,
  OptionFieldsFragment,
  ParticipationSessionFieldsFragment,
  RatingFieldsFragment,
  RatingNotesFieldsFragment,
} from '../graphql'

const GET_DECISION_FOR_PARTICIPANT = gql`
  query GetDecisionForParticipant($accountId: ID!, $decisionId: ID!, $participantId: ID!) {
    decisionForParticipant(accountId: $accountId, decisionId: $decisionId, participantId: $participantId) {
      ...DecisionFields
      criteria { ...CriterionFields }
      options { ...OptionFields }
      participationSessions { ...ParticipationSessionFields }
      ratings { ...RatingFields }
      ratingNotes { ...RatingNotesFields }
    }
  }
  ${DecisionFieldsFragment}
  ${CriterionFieldsFragment}
  ${OptionFieldsFragment}
  ${ParticipationSessionFieldsFragment}
  ${RatingFieldsFragment}
  ${RatingNotesFieldsFragment}
`
export type DecisionForParticipantData = {
  decisionForParticipant: {
    id: string,
    ancestry: string,
    name: string,
    objective: { value: string },
    description: { value: string },
    criteria: CriterionData[],
    options: Option[],
    ratings: Rating[],
    ratingNotes: RatingNotes[],
    participationSessions: ParticipationSession[],
  }
}
type DecisionForParticipantVariables = {
  accountId: string,
  decisionId: string,
  participantId: string | undefined,
}
export function useDecisionForParticipant(
  accountId: string,
  decisionId: string,
  participantId?: string
): QueryResult<DecisionForParticipantData, DecisionForParticipantVariables> {
  return useQuery<DecisionForParticipantData, DecisionForParticipantVariables>(
    GET_DECISION_FOR_PARTICIPANT,
    {
      variables: { accountId, decisionId, participantId },
      skip: !participantId,
    }
  )
}

const UPDATE_RATING = gql`
  mutation UpdateRating(
    $accountId: ID!
    $decisionId: ID!
    $participationSessionId: ID!
    $participantId: ID!
    $contextType: String!
    $contextId: ID!
    $subjectType: String!
    $subjectId: ID!
    $ratingVector: [Float!]
    $abstain: Boolean
  ) {
    updateRating(
      accountId: $accountId
      decisionId: $decisionId
      participationSessionId: $participationSessionId
      participantId: $participantId
      contextType: $contextType
      contextId: $contextId
      subjectType: $subjectType
      subjectId: $subjectId
      ratingVector: $ratingVector
      abstain: $abstain
    ) {
      ...RatingFields
    }
  }
  ${RatingFieldsFragment}
`
type UpdateRatingData = {
  updateRating: Omit<Rating, 'updated'> & {
    __typename: 'Rating',
  },
}
type UpdateRatingVariables = Omit<Rating, 'updated'> & {
  accountId: string,
  decisionId: string,
}
export function useUpdateRating(
  accountId: string,
  decisionId: string,
  ratingProps: {
    participationSessionId: string,
    participantId: string,
    contextType: RatingContextType,
    contextId: string,
    subjectType: RatingSubjectType,
  }
): [(subjectId: string, ratingVector: RatingVector) => Promise<FetchResult<UpdateRatingData>>, MutationResult] {
  const [updateRatingMutation, mutationResults] = useMutation<UpdateRatingData, UpdateRatingVariables>(
    UPDATE_RATING,
    {
      onError: () => null, // handle errors with error object returned from useMutation hook
      update(cache, { data }) {
        if(data?.updateRating) {
          const cachedDecision = cache.readQuery<DecisionForParticipantData, DecisionForParticipantVariables>({
            query: GET_DECISION_FOR_PARTICIPANT,
            variables: { accountId, decisionId, participantId },
          })

          if(cachedDecision) {
            cache.modify({
              id: cache.identify(cachedDecision.decisionForParticipant),
              fields: {
                ratings(existingRatings = []) {
                  const updatedRatingRef = cache.writeFragment({
                    id: cache.identify(data.updateRating),
                    fragment: RatingFieldsFragment,
                    data: data.updateRating,
                  })

                  return [...existingRatings, updatedRatingRef]
                },
              },
            })
          }
        }
      },
    }
  )

  const { participationSessionId, participantId, contextType, contextId, subjectType } = ratingProps
  const updateRating = useCallback(
    (subjectId: string, ratingVector: RatingVector, abstain?: boolean) => updateRatingMutation({
      variables: {
        accountId,
        decisionId,
        participationSessionId,
        participantId,
        contextType,
        contextId,
        subjectType,
        subjectId,
        ratingVector,
        abstain,
      },
      optimisticResponse: {
        updateRating: {
          __typename: 'Rating',
          participationSessionId,
          participantId,
          contextType,
          contextId,
          subjectType,
          subjectId,
          ratingVector,
          abstain,
        },
      },
    }),
    [
      updateRatingMutation,
      accountId,
      decisionId,
      participationSessionId,
      participantId,
      contextType,
      contextId,
      subjectType,
    ]
  )

  return [updateRating, mutationResults]
}

const UPDATE_RATING_NOTES = gql`
  mutation UpdateRatingNotes(
    $accountId: ID!
    $decisionId: ID!
    $participationSessionId: ID!
    $participantId: ID!
    $contextType: String!
    $contextId: ID!
    $subjectType: String!
    $notesHtml: String!
  ) {
    updateRatingNotes(
      accountId: $accountId
      decisionId: $decisionId
      participationSessionId: $participationSessionId
      participantId: $participantId
      contextType: $contextType
      contextId: $contextId
      subjectType: $subjectType
      notesHtml: $notesHtml,
    ) {
      ...RatingNotesFields
    }
  }
  ${RatingNotesFieldsFragment}
`
type UpdateRatingNotesData = {
  updateRatingNotes: Omit<RatingNotes, 'notes' | 'updated'> & {
    __typename: 'RatingNotes',
    notes: {
      __typename: 'Html',
      value: string,
    },
  },
}
type UpdateRatingNotesVariables = Omit<RatingNotes, 'notes' | 'updated'> & {
  notesHtml: string,
  accountId: string,
  decisionId: string,
}
export function useUpdateRatingNotes(
  accountId: string,
  decisionId: string,
  ratingNotesProps: {
    participationSessionId: string,
    participantId: string,
    contextType: RatingContextType,
    contextId: string,
    subjectType: RatingSubjectType,
  }
): [(notesHtml: string) => Promise<FetchResult<UpdateRatingNotesData>>, MutationResult] {
  const [updateRatingMutation, mutationResults] = useMutation<UpdateRatingNotesData, UpdateRatingNotesVariables>(
    UPDATE_RATING_NOTES,
    {
      onError: () => null, // handle errors with error object returned from useMutation hook
      update(cache, { data }, { variables }) {

        if(data?.updateRatingNotes) {
          const cachedDecision = cache.readQuery<DecisionForParticipantData, DecisionForParticipantVariables>({
            query: GET_DECISION_FOR_PARTICIPANT,
            variables,
          })

          if(cachedDecision) {
            cache.modify({
              id: cache.identify(cachedDecision.decisionForParticipant),
              fields: {
                ratingNotes(existingRatingNotes = []) {
                  const updatedRatingNotesRef = cache.writeFragment({
                    id: cache.identify(data.updateRatingNotes),
                    fragment: RatingNotesFieldsFragment,
                    fragmentName: 'RatingNotesFields',
                    data: data.updateRatingNotes,
                  })

                  return [...existingRatingNotes, updatedRatingNotesRef]
                },
              },
            })
          }
        }
      },
    }
  )

  const { participationSessionId, participantId, contextType, contextId, subjectType } = ratingNotesProps
  const updateRatingNotes = useCallback(
    (notesHtml: string) => updateRatingMutation({
      variables: {
        accountId,
        decisionId,
        participationSessionId,
        participantId,
        contextType,
        contextId,
        subjectType,
        notesHtml,
      },
      optimisticResponse: {
        updateRatingNotes: {
          __typename: 'RatingNotes',
          participationSessionId,
          participantId,
          contextType,
          contextId,
          subjectType,
          notes: {
            __typename: 'Html',
            value: notesHtml,
          },
        },
      },
    }),
    [
      updateRatingMutation,
      accountId,
      decisionId,
      participationSessionId,
      participantId,
      contextType,
      contextId,
      subjectType,
    ]
  )
  return [updateRatingNotes, mutationResults]
}
