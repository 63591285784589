import _sum from 'lodash/sum'

import { Quantity } from '../types'
import { Duration, DurationUnit } from '../utils/qty'
import { Criteria } from './criteria'
import { ParticipationSession } from './participationSession'
import { valueExpressionToValueGraphExpression, ValueNode } from './valueGraph/valueNode'
import { PerformanceValueNode } from './valueGraph/performanceValueNode'
import { ValueFunctionLibrary } from '../valuemetrics/valuemetrics'
import * as valueFormula from '../valuemetrics/valueFormula'
import { OptionData, Option } from './Option'

export function scoreQuantAttr(value: number, attrConfig: QuantAttrConfig, attrSum: number) {
  switch(attrConfig.scoreTransform) {
    case 'Normalize': return 10 * value / attrSum; break
    case 'NormalizeComplement': return 10 - 10 * value / attrSum; break;
    default: throw new Error(`unreocgnized quant attr transform: ${attrConfig.scoreTransform}`)
  }
}

class OptionInternal implements Option {
  _options: Options
  _criteria?: Criteria
  _data: OptionData
  _quant: Record<string, Quantity | null>
  _participationSession?: ParticipationSession
  get id() { return this._data.id }
  get name() { return this._data.name }
  get abbrev() { return this._data.abbrev || '' }
  get description() { return this._data.description }
  get commonId() { return this._data.commonId }
  get color() { return this._data.color }
  get quantAttrs() { return this._quant }
  get quantScores() {
    const { quantAttributes } = this._options
    return Object.fromEntries<number | null>(quantAttributes.map(attrConfig => {
      const qv = this._quant[attrConfig.symbol]
      if(qv === null) return [attrConfig.symbol, null]
      const sum = this._options.quantAttrSums[attrConfig.symbol]
      const score = scoreQuantAttr(this._options.normalizeQuantAttr(qv), attrConfig, sum)
      return [attrConfig.symbol, score]
    }))
  }
  get ratings() {
    return this._participationSession?.ratings.filter(r => r.subjectId === this.id) || []
  }
  constructor(options: Options, data: OptionData, criteria?: Criteria, ps?: ParticipationSession) {
    this._options = options
    this._data = data
    this._quant = {
      C: this._data.cost,
      T: this._data.time,
    }
    this._criteria = criteria
    this._participationSession = ps
  }
  useCriteria(criteria: Criteria) {
    this._criteria = criteria
  }
  get performanceGraph(): PerformanceValueNode | undefined {
    if(!this._criteria || !this._participationSession) return undefined
    return new PerformanceValueNode(this._criteria.perfRoot, this.id, this._participationSession)
  }
  get valueGraph(): ValueNode {
    const vars: Record<string, null | number | ValueNode> = {
      C: this.quantScores.C,
      T: this.quantScores.T,
      P: this.performanceGraph || null,
    }
    const expr = valueExpressionToValueGraphExpression(this._options.valueFormula)
    const root =  new ValueNode(expr, vars)
    root.name = 'Value'
    root.descendants.forEach(n => {
      if(n.operation === 'ScoreComplement') {
        // note use of unicode U+2032 prime marker instead of single quote;
        // for some reason, the single quote causes a rendering error in React/SVG
        n.name = n.children[0].name + `′ (complement)`
      } else if(n.operation !== 'Score' && n.type === 'Intermediate') {
        // currently, the only use of intermediate nodes (other than complement) is the denominator...
        // if we expand to differnt types of value formulas, this may have to get more sophisticated
        n.name = '(denominator)'
      } else if(n.type === 'PerformanceRoot') {
        n.name = 'Performance'
      }
    })
    return root
  }
  get changeInValue(): number | null {
    if(!this._options.baseline) return null
    if(this.id === this._options.baseline.id) return null
    const v = this.valueGraph.value ?? null
    const vb = this._options.baseline?.valueGraph.value ?? null
    if(v === null || vb === null) return null
    return (v - vb) / vb
  }
  get changeInPerf(): number | null {
    if(!this._options.baseline) return null
    if(this.id === this._options.baseline.id) return null
    const p = this.performanceGraph?.value ?? null
    const pb = this._options.baseline?.performanceGraph?.value ?? null
    if(p === null || pb === null) return null
    return (p - pb) / pb
  }
  get changeInQuantScores(): Record<string, number | null> {
    const { quantAttributes } = this._options
    return Object.fromEntries<number | null>(quantAttributes.map(attrConfig => {
      if(!this._options.baseline) return [attrConfig.symbol, null]
      if(this.id === this._options.baseline.id) return [attrConfig.symbol, null]
      const s = this.quantScores[attrConfig.symbol]
      const sb = this._options.baseline.quantScores[attrConfig.symbol]
      if(s === null || sb === null) return [attrConfig.symbol, null]
      return [
        attrConfig.symbol,
        (s - sb) / sb,
      ]
    }))
  }
}

/**
 * Once again, I'm causing chaos by changing the value formula to use symbols instead of keys (e.g. "C" instead
 * of "cost").  Of course I could have just used matching symbols, but this is more consistent with my future
 * visision of intrinsic properties.
 */
function mapValueFormulaVars(vf: valueFormula.Expression, map: Record<string, string>): valueFormula.Expression {
  if(Array.isArray(vf)) {
    return vf.map((op, idx) => idx === 0 ? op : mapValueFormulaVars(op, map)) as valueFormula.Expression
  }
  if(typeof vf === 'string' && map[vf]) return map[vf]
  return vf
}

/**
 * The ways quantitative attributes can be transformed into a score.  Currently, we're
 * only normalizing (dividing each value by the sum of all values), or the complement
 * of the normalized value (for cost and time scores, which increase as the cost and time
 * value decrease).
 */
export type QuantAttrTransform =
  | 'Normalize'
  | 'NormalizeComplement'

/**
 * Option quantitative attribute configuration.
 */
export interface QuantAttrConfig {
  /** Symbol to use for attribute in value formula (such as "C" for cost). */
  symbol: string
  /** Description of attribute (empty string okay). */
  description: string
  /** Base quantity (Cost, Duration, etc). */
  base: Quantity['base'],
  /** Method for converting value to score. */
  scoreTransform: QuantAttrTransform
}

/**
 * A collection of related options in a decision.  These represent options that will be compared
 * directly against one another; this class is necessary to manage the use of participation sessions
 * and to calculate intrinsic scores (the default algorithm of which normalizes the underyling value
 * among the options).
 *
 * When dealing with options, you must use the Options class (i.e. you cannot currently construct
 * a standalone option).
 */
export class Options {
  private _all: OptionInternal[]
  private _participationSession?: ParticipationSession
  private _criteria?: Criteria
  get criteria() { return this._criteria }
  get quantAttributes(): QuantAttrConfig[] {
    // hardcoded for now
    return [
      { symbol: 'C', base: 'Cost', description: 'Cost', scoreTransform: 'NormalizeComplement' },
      { symbol: 'T', base: 'Duration', description: 'Time', scoreTransform: 'NormalizeComplement' },
    ]
  }
  baseline?: Option
  /**
   * Get the sums of all the (non-null) quantiative attributes in this collection
   * of options.  Note that this uses #normalizeQuantAttr to normalize the values.
   *
   * Note that this is used by the individual options to calculate attribute.
   * In this way, this computation can (and should) be cached.  However, we
   * still have work to do in the relationship between OptionData and
   * Options/Option, so this is, for now, a safer approach.
   */
  get quantAttrSums(): Record<string, number> {
    const sums: Record<string, number> = {}
    const normalizer = this.normalizeQuantAttr.bind(this)
    this.quantAttributes.forEach(({ symbol }) => {
      const quantities = this._all.map(o => o.quantAttrs[symbol])
        .filter((qty): qty is Quantity => qty !== null)
      sums[symbol] = _sum(quantities.map(normalizer))
    })
    return sums
  }
  /**
   * Normalizes a quantitative attribute.  For example, the default duration
   * unit is "Months"; if this is passed a duration with unit "Years", the
   * value returned will be in months.
   */
  normalizeQuantAttr(attr: Quantity): number {
    switch(attr.base) {
      case 'Cost':
        if(attr.unit !== 'USD') throw new Error(`currently only USD id supported`)
        return attr.value
      case 'Duration':
        return Duration.convert(attr, DurationUnit.Months).value
      default:
        throw new Error(`unsupported quantity base type: ${attr.base}`)
    }
  }
  private _valueFormula: valueFormula.Expression
  get valueFormula(): valueFormula.Expression { return this._valueFormula }

  /**
   * A list of all options in this collection.
   */
  get all(): Option[] { return this._all }

  /**
   * Creates a collection of Option instances from an array of option data.  You may also
   * attach associated criteria and participation session (option rating).  You can also
   * attach those later, but value graph data won't be availbale without them.
   */

  /**
   * Gets an option by ID.
   */
  byId(id: string): Option | undefined {
    // we may want to keep an itnernal index, but for now just finding it
    return this._all.find(o => o.id === id)
  }

  constructor(
    optionData: OptionData[],
    criteria?: Criteria,
    ps?: ParticipationSession,
    valueFormula?: valueFormula.Expression
  ) {
    this._all = optionData.map(od => new OptionInternal(this, od, criteria))
    this._criteria = criteria
    // TODO: change to new value formulas and update database, etc.; then this map will be unnecessary
    this._valueFormula = mapValueFormulaVars(valueFormula || ValueFunctionLibrary.Standard, {
      perf: 'P',
      "perf'": "P'",
      cost: 'C',
      "cost'": "C'",
      time: 'T',
      "time'": "T'",
    })
    if(ps) this.useParticipationContext(ps)
  }
  /**
   * Select a set of criteria to use in determining valuemetrics for these options.  This
   * is essentially reserved for future functionality or testing; our current application has
   * only one set of criteria for each decision.
   */
  useCriteria(criteria: Criteria) {
    this._criteria = criteria
    this._all.forEach(o => o.useCriteria(criteria))
  }
  /**
   * Select the participation session (option rating) containing option ratings that will be
   * used to determine valuemetrics of the options in this collection.
   */
  useParticipationContext(ps?: ParticipationSession) {
    this._participationSession = ps
    this._all.forEach(o => o._participationSession = ps)
  }
}
