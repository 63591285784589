import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Tree } from '../controls'
import FormModal from './FormModal'
import actions from '../../actions'
import { useModalData } from '../../redux/hooks/appHooks'
import { selectAuthUserId } from '../../redux/auth/selectors'

const RiskTableCustomizationModal = () => {
  const {
    checkedColumns,
    columns: columnConfig,
    configId = 'default', // unique template ID for the table
    tableId,
  } = useModalData(RiskTableCustomizationModal.id)

  const [checkedKeys, setCheckedKeys] = useState(checkedColumns)
  const authUserId = useSelector(selectAuthUserId)

  const dispatch = useDispatch()
  const onOk = () => {
    dispatch(actions.user.setTableConfig(
      { tableId, configId, checkedColumns: checkedKeys },
      { userId: authUserId }
    ))
  }

  return (
    <FormModal modalId={RiskTableCustomizationModal.id} onOk={onOk}>
      <div style={style.treeForm}>
        <h2>Configure Column View</h2>
        <Tree
          checkable
          defaultExpandedKeys={checkedKeys}
          onCheck={setCheckedKeys}
          checkedKeys={checkedKeys}
          treeData={columnConfig}
        />
      </div>
    </FormModal>
  )
}
RiskTableCustomizationModal.id = 'RiskTableCustomizationModal'

const style = {
  treeForm: {
    maxHeight: '75vh',
    overflow: 'auto',
    marginTop: '15px',
  },
}

export default RiskTableCustomizationModal
