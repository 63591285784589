import React from 'react'
import FormModal from './FormModal'

const ConfirmModal = ({
  id,
  onConfirm,
  ...props
}) => <FormModal modalId={id} onOk={onConfirm} {...props} />

export default ConfirmModal
