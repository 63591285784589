import { CostCellRenderer } from './CostCellRenderer'
import { DurationCellRenderer } from './DurationCellRenderer'
import { HtmlCellRenderer } from './HtmlCellRenderer'
import { EntityNameRenderer } from './EntityNameRenderer'
import { RatingCellRenderer } from './RatingCellRenderer'
import { TimestampCellRenderer } from './TimestampCellRenderer'

export const TableCellRenderer = {
  Cost: CostCellRenderer,
  Duration: DurationCellRenderer,
  EntityName: EntityNameRenderer,
  Html: HtmlCellRenderer,
  Rating: RatingCellRenderer,
  Timestamp: TimestampCellRenderer,
}
