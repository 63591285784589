import { EntityType } from '@vms/vmspro3-core/dist/systemConsts'
import { CreateDecisionAction } from '@vms/vmspro3-core/dist/actions/decision'

// TODO: these two imports are causing circular dependencies. Not sure how this wasn't showing up
// as an error previously...
// import { AccountSubscriptionCanceledModalId } from '../../client/modals/AccountSubscriptionCanceledModal'
// import { showModal } from '../actions'

import pubSub from '../../services/pubSubService'
import actions from '../../actions'
import accountActionTypes from '../account/actionTypes'
import riskEntitiesActionTypes from '../riskEntities/actionTypes'
import userActionTypes from '../user/actionTypes'
import usersActionTypes from '../users/actionTypes'
import clientId from '../../utils/clientId'
import { selectAccountId } from '../account/selectors'
import { stage } from '../../utils/env'
import {
  FetchDecisionEntitySuccessAction,
  FetchDecisionFolderChildrenSuccessAction,
  ResetAccountStateAction,
} from '../actionTypes'

function subscribeToTopic(subscribe, topic, dispatch) {
  subscribe(`${stage}/${topic}`, data => {
    const { originClientId, action } = data.value
    if(originClientId !== clientId) dispatch(action)
  })
}

const getPathFromAncestry = (ancestry: string) => ancestry && ancestry !== '/' ? ancestry : ''
type Actions =
  | ResetAccountStateAction
  | FetchDecisionEntitySuccessAction
  | FetchDecisionFolderChildrenSuccessAction
  | CreateDecisionAction
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any // TODO: finish action types

const pubSubMiddleware = store => next => (action?: Actions) => {
  const { dispatch, getState } = store
  const accountId = selectAccountId(getState())

  switch(action?.type) {
    // TODO: there's no IoT/IAM policy to support this case yet
    // case userActionTypes.FetchCurrentUserSuccess: {
    //   if(payload.id) {
    //     subscribeToTopic(`${ROOT_ACCOUNT_ID}/user/${payload.id}`, dispatch)
    //   }
    //   break
    // }
    case userActionTypes.FetchAccountUserSuccess: {
      if(action.payload.id) {
        subscribeToTopic(pubSub.subscribeToAccountTopic, `${accountId}/user/${action.payload.id}`, dispatch)
      }
      break
    }
    case usersActionTypes.FetchUsersSuccess: {
      subscribeToTopic(pubSub.subscribeToAccountTopic, `${accountId}/user/+`, dispatch)
      break
    }
    case riskEntitiesActionTypes.FetchRiskEntitySuccess: {
      const { entity, ancestors } = action.payload
      if(entity) {
        // subscribe to risk entity
        const { entityType, ancestry } = entity
        subscribeToTopic(
          pubSub.subscribeToAccountTopic,
          `${accountId}/${entityType}${getPathFromAncestry(ancestry)}/${action.meta.entityId}`,
          dispatch
        )
      }
      if(ancestors) {
        ancestors.forEach(ancestor => {
          // subscribe to entity ancestors
          const { entityType, ancestry, id: ancestorId } = ancestor
          subscribeToTopic(
            pubSub.subscribeToAccountTopic,
            `${accountId}/${entityType}${getPathFromAncestry(ancestry)}/${ancestorId}`,
            dispatch
          )
        })
      }
      break
    }
    case riskEntitiesActionTypes.FetchRiskEntityChildrenSuccess: {
      // subscribe to messages about risk entity children. if entityType not specified, + filter will
      // allow any value for entityType.
      const { entityType = '+', childAncestry } = action.meta
      subscribeToTopic(
        pubSub.subscribeToAccountTopic,
        `${accountId}/${entityType}${getPathFromAncestry(childAncestry)}/+`,
        dispatch
      )
      break
    }
    case accountActionTypes.FetchAccountSuccess: {
      subscribeToTopic(
        pubSub.subscribeToAccountTopic,
        `${accountId}/${EntityType.ACCOUNT}`,
        action => {
          // if this action is being received here the app is logged in to this
          // account and is not the origin client who dispatched the action.
          if(action.type === actions.account.cancelSubscription.toString()) {
            // showModal(AccountSubscriptionCanceledModalId))
            dispatch({
              type: 'ShowModal',
              payload: {},
              meta: {
                modalId: 'AccountSubscriptionCanceledModal',
                ephemeral: true,
              },
            })
          } else {
            dispatch(action)
          }
        }
      )
      break
    }
    case 'ResetAccountState': {
      pubSub.unsubscribeFromAccountTopics()
      break
    }
    case 'FetchDecisionEntitySuccess': {
      const { decisionEntityId } = action.meta
      if(action.payload.decisions?.[decisionEntityId] || action.payload.decisionFolders?.[decisionEntityId]) {
        subscribeToTopic(pubSub.subscribeToAccountTopic, `${accountId}/decision/${decisionEntityId}`, dispatch)
      }
      break
    }
    case 'FetchDecisionFolderChildrenSuccess': {
      if(action.payload.decisions) {
        Object.keys(action.payload.decisions).forEach(decisionId => {
          subscribeToTopic(pubSub.subscribeToAccountTopic, `${accountId}/decision/${decisionId}`, dispatch)
        })
      }
      if(action.payload.decisionFolders) {
        Object.keys(action.payload.decisionFolders).forEach(decisionFolderId => {
          subscribeToTopic(pubSub.subscribeToAccountTopic, `${accountId}/decision/${decisionFolderId}`, dispatch)
        })
      }
      break
    }
    case 'Create Decision': {
      subscribeToTopic(pubSub.subscribeToAccountTopic, `${accountId}/decision/${action.payload.id}`, dispatch)
      break
    }
    case 'Reload Decision': {
      pubSub.unsubscribeFromAccountTopic(`${stage}/${accountId}/decision/${action.meta.decisionId}`)
      break
    }
    default:
      break
  }

  return next(action)
}

export default pubSubMiddleware
