import { createSelector } from 'reselect'

import { selectUserPolicyIds } from '../user/selectors'

export const selectPoliciesState = state => state.policies
export const selectPoliciesById = createSelector(
  [selectPoliciesState],
  state => state.byId
)
export const selectPoliciesLoadingStatus = createSelector(
  [selectPoliciesState],
  state => state.loadingStatus
)
export const selectSystemPolicies = createSelector(
  [selectPoliciesState],
  state => state.ids.map(id => state.byId[id])
)

export const selectPoliciesByResourceId = createSelector(
  [selectPoliciesState],
  state => state.byResourceId
)

export const selectUserPolicies = createSelector(
  [
    selectUserPolicyIds,
    selectPoliciesById,
  ],
  (
    userPolicyIds,
    policiesById
  ) => userPolicyIds.map(id => policiesById[id])
)
