/* eslint-disable @typescript-eslint/no-var-requires, no-undef */
const { PolicyType } = require('../systemConsts')
const { createId } = require('../idUtils')

const actions = {}

const CreatePolicyActionType = 'Create Policy'
/**
 * Create a policy.
 *
 * @param {Object} payload - action payload
 *  @param {String} payload.policyId
 *  @param {String} payload.resourceId - The ID of the resource to attach this policy to,
 *    e.g. "b65G1FgkC7B7pytQMEXCpd"
 * @param {Object} meta - action metadata
 *
 * @returns {object} Action with type 'policy::create'.
 */
actions.create = function policyCreateActionCreator(payload, meta) {
  const { accountId, policyType, Principal, resourceId, resourceType, Statement } = payload
  const type = CreatePolicyActionType

  if(!accountId) throw new Error(`${type}: missing accountId`)
  if(!payload.policyId) payload.policyId = createId()
  if(policyType === PolicyType.RESOURCE) {
    // if policyType === RESOURCE, extends, Principal, and resourceId are required
    if(!payload.extends) throw new Error(`${type}: missing extends`)
    if(!Principal) throw new Error(`${type}: missing Principal`)
    if(!resourceId) throw new Error(`${type}: missing resourceId`)
    if(!resourceType) throw new Error(`${type}: missing resourceType`)
  } else if(!Statement) {
    // if policyType !== RESOURCE, Statement is required
    throw new Error(`${type}: missing Statement`)
  }

  meta.authz = meta.authz || {}
  if(!meta.authz.resources) meta.authz.resources = [resourceId]
  else meta.authz.resources.push(resourceId)

  return {
    module: 'System',
    type,
    payload,
    meta,
  }
}
actions.create.toString = () => CreatePolicyActionType

const UpdatePolicyActionType = 'Update Policy'
/**
 * Update a policy.
 *
 * @param {Object} payload - action payload
 * @param {Object} meta - action metadata
 *  @param {String} meta.policyId - the ID of the policy to update
 *  @param {String} meta.resourceId - The ID of the resource to attach this policy to,
 *    e.g. "b65G1FgkC7B7pytQMEXCpd"
 *
 * @returns {object} Action with type 'policy::update'.
 */
actions.update = function policyUpdateActionCreator(payload, meta) {
  const { accountId, policyId, policyType, resourceId, resourceType } = meta
  const type = UpdatePolicyActionType

  if(!accountId) throw new Error(`${type}: missing accountId`)
  if(!policyId) throw new Error(`${type}: missing policyId`)
  // if policyType === RESOURCE, resourceId is required in Meta
  if(policyType === PolicyType.RESOURCE && !resourceId) throw new Error(`${type}: missing resourceId`)
  if(policyType === PolicyType.RESOURCE && !resourceType) throw new Error(`${type}: missing resourceType`)

  meta.authz = meta.authz || {}
  if(!meta.authz.resources) meta.authz.resources = [resourceId]
  else meta.authz.resources.push(resourceId)

  return {
    module: 'System',
    type,
    payload,
    meta,
  }
}
actions.update.toString = () => UpdatePolicyActionType

module.exports = actions
