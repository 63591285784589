import React from 'react'
import { useSelector } from 'react-redux'
import _keyBy from 'lodash/keyBy'

import modals from '../../client/modals'

const modalsById = _keyBy(modals, 'id')

function VisibleModals() {
  const stack = useSelector(state => state.app.modals.stack)

  return stack.map(modalId => {
    const Modal = modalsById[modalId]
    return <Modal key={modalId} />
  })
}

export default VisibleModals
