const actions = {}

// TODO: this could be moved into account.j
function read() {
  return {
    module: 'System',
    type: read.toString(),
  }
}
read.toString = () => 'Read User Invitation'
actions.read = read

module.exports = actions  // eslint-disable-line no-undef
