import React from 'react'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'
import AreaChartOutlined from '@ant-design/icons/AreaChartOutlined'
import BookOutlined from '@ant-design/icons/BookOutlined'
import FileAddOutlined from '@ant-design/icons/FileAddOutlined'
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined'

import { Alert, Card, Col, LinkButton2, Row } from '../../controls'
import Image from '../common/Image'

import useAuthz from '../../../hooks/useAuthz'

const { Color } = systemConsts

const alertBanner = ({ message, description, showIcon, type }, style) => (
  <Alert
    type={type}
    message={message}
    description={description}
    showIcon={showIcon}
    style={style}
  />
)

const generateOnClick = ({ modalId, linkTo, externalLink }, navigateTo, launchTo, handleShowModal) => linkTo
  ? navigateTo(linkTo)
  : externalLink
    ? launchTo(externalLink)
    : handleShowModal(modalId)

// TODO: add case for user Icon
const generateIcon = (icon, defaultStyle, warningStyle) => {
  switch(icon) {
    case 'book': return <BookOutlined style={defaultStyle} />
    case 'exclamationCircle': return <ExclamationCircleOutlined style={warningStyle} />
    case 'fileAdd': return <FileAddOutlined style={defaultStyle} />
    case 'area-chart': return <AreaChartOutlined style={defaultStyle} />
    default: return null
  }
}

export const MainLandingPageView = ({
  alert,
  dashboardConfigCards,
  handleShowModal,
  launchTo,
  logoAltText,
  logoAltTitle,
  navigateTo,
  orgName,
}) => {
  const authz = useAuthz()

  return (
    <div style={style.content}>
      {alert && alertBanner(alert, style.alert)}
      <div style={style.imageWrapper}>
        <Row type="flex" justify="center" style={style.imageRow}>
          <Col>
            <Image path="logo.png" alt={logoAltText} title={logoAltTitle} style={style.logoImage} />
          </Col>
        </Row>
        <Row type="flex" justify="center" style={style.orgTitle}>
          <Col>
            <span>{orgName}</span>&nbsp;|&nbsp;
            <span>VMSPro&reg;</span>
          </Col>
        </Row>
      </div>
      <div style={style.dashboardCards}>
        <Row type='flex' gutter={30} justify="center">
          {dashboardConfigCards
            .filter(({ actions }) => !actions || actions.some(authz))
            .map(({ id, title, description, icon, button }) => (
              <Col span={6} key={id}>
                <span style={style.cardTitle}>{title}</span>
                <Card {...style.card}>
                  <div style={style.cardContent}>
                    {generateIcon(icon, style.icon, style.warningIcon)}
                    {description}
                    {button &&
                      <LinkButton2
                        bold
                        style={style.cardLink}
                        onClick={() => generateOnClick(button, navigateTo, launchTo, handleShowModal)}
                      >{button.label}</LinkButton2>
                    }
                  </div>
                </Card>
              </Col>
            ))
          }
        </Row>
      </div>
    </div>
  )
}

const style = {
  alert: {
    width: '95%',
    marginTop: '33px',
  },
  card: {
    bodyStyle: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    style: {
      height: '100%',
    },
  },
  cardContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  cardLink: {
    alignSelf: 'flex-end',
    marginTop: 'auto',
    color: Color.PRIMARY,
  },
  cardTitle: {
    color: Color.BLACK,
    display: 'block',
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '12px',
  },
  content: {
    alignItems: 'center',
    backgroundColor: Color.GRAY_BLUE_LIGHTER,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: '30px',
    width: '100%',
  },
  dashboardCards: {
    margin: '0px 30px',
  },
  icon: {
    fontSize: '48px',
    color: Color.PRIMARY,
    paddingTop: '16px',
    paddingBottom: '32px',
  },
  imageRow: {
    // used to have opacity 0.6; leaving in case we want other
    // styling here
  },
  imageWrapper: {
    padding: '30px 30px 48px',
  },
  logoImage: {
    width: '320px',
  },
  orgTitle: {
    color: Color.GRAY_TEXT,
    paddingTop: '16px',
  },
  warningIcon: {
    fontSize: '48px',
    color: Color.WARNING,
    paddingTop: '16px',
    paddingBottom: '32px',
  },
  boldText: {
    fontWeight: 'bold',
  },
}
