import { css } from 'glamor'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'

const { Color, RiskColor } = systemConsts

export const screenSizeMd = '600px'
export const screenLessThanMd = `@media screen and (max-width: ${screenSizeMd})`

export const vmsBlue = '#3d75b2'
export const vmsGreen = '#06ad53'
export const antDesignMenuDarkBackground = '#001529'

export const contentMargin = '24px 30px'
export const contentArea = {
  margin: '30px',
  backgroundColor: '#fff',
  padding: '24px',
}
export const margin = '24px'

export const calculateVmsSliderColor = ratio => {
  const vmsGreen = '06ad53'
  const vmsBlue = '3d75b2'
  let color = '#'
  for(let i = 0; i < 6; i += 2) {
    const greenDec = parseInt(vmsGreen.substring(i, i + 2), 16)
    const blueDec = parseInt(vmsBlue.substring(i, i + 2), 16)
    const diff = Math.abs(greenDec - blueDec)
    const greenShift = Math.round(diff * ratio)
    // The higher the ratio, the more green it should be
    const colorDec = blueDec + (greenDec > blueDec ? greenShift : -greenShift)
    const colorHex = (colorDec).toString(16).padStart(2, "0")
    color += colorHex
  }
  return color
}

export const buttonBar = css({
  alignItems: 'center',
  background: Color.GRAY_LIGHTER,
  display: 'flex',
  height: '44px',
  justifyContent: 'space-between',
  padding: '0 48px',
  width: '100%',
  '& *': {
    '& :not(:first-child)': {
      marginLeft: '16px',
      paddingLeft: '16px',
    },
    '& :not(:last-child)': {
      marginRight: '16px',
      paddingRight: '16px',
    },
  },
})

export const riskTableStyle = css({
  '#root & th': {
    borderColor: Color.GREY_2,
    // Filter Icons
    ' i': {
      mixBlendMode: 'multiply',
    },
    ' i:not(.ant-table-filter-selected)': {
      color: Color.GREY_2,
    },
    // Managed Top Header Styling
    '.risk-impact-response-managed': {
      backgroundColor: RiskColor.MANAGED_BACKGROUND,
      color: '#fff',
      ':hover': { backgroundColor: RiskColor.MANAGED_BACKGROUND_HOVER },
    },
    // Unmanaged Top Header Styling
    '.risk-impact-response-unmanaged': {
      backgroundColor: Color.GREY_1,
      ':hover': { backgroundColor: Color.GREY_1_DARK },
    },
    // Unmanaged Header Child Styling
    '.risk-impact-measure-unmanaged': {
      backgroundColor: Color.GREY_1,
      ':hover': { backgroundColor: Color.GREY_1_DARK },
    },
    // Managed Header Child Styling
    '.risk-impact-measure-managed': {
      backgroundColor: Color.GREY_1_DARK,
      ':hover': { backgroundColor: Color.GREY_1_DARKER },
    },
    // Cost Header Styling
    '.risk-impact-measure-cost': {
      backgroundColor: RiskColor.COST_IMPACT,
      ':hover': { backgroundColor: RiskColor.COST_IMPACT_HOVER },
    },
    // Schedule Header Styling
    '.risk-impact-measure-time': {
      backgroundColor: RiskColor.TIME_IMPACT,
      ':hover': { backgroundColor: RiskColor.TIME_IMPACT_HOVER },
    },
    // Performance Header Styling
    '.risk-impact-measure-perf': {
      backgroundColor: RiskColor.PERF_IMPACT,
      ':hover': { backgroundColor: RiskColor.PERF_IMPACT_HOVER },
    },
  },
})
