const UseModuleActionType = 'Use Module'
export function useModule(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: UseModuleActionType,
    payload,
    meta,
  }
}
useModule.toString = () => UseModuleActionType
