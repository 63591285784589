import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatus } from '../../utils/appConsts'
import { selectAccountId } from '../account/selectors'
import { fetchUsers } from './actions'
import {
  selectUsers,
  selectUsersLoadingStatus,
} from './selectors'

export function useUsers() {
  const accountId = useSelector(selectAccountId)
  const users = useSelector(selectUsers)
  const loadingStatus = useSelector(selectUsersLoadingStatus)
  const loading = accountId && loadingStatus !== LoadingStatus.Loaded

  return [users, loading]
}

export function useFetchUsers() {
  const accountId = useSelector(selectAccountId)
  const loadingStatus = useSelector(selectUsersLoadingStatus)

  const dispatch = useDispatch()
  useEffect(
    () => {
      if(accountId && loadingStatus === LoadingStatus.NotLoaded) {
        dispatch(fetchUsers(accountId))
      }
    },
    [dispatch, accountId, loadingStatus]
  )
}
