import _sum from 'lodash/sum'

/**
 * Sums the weighted values of a collection of value nodes; if any of the weighted
 * values are null, returns null.
 */
export function safeSum(values: Array<number | null>) {
  return values.some(v => v === null) ? null : _sum(values)
}

/**
 * Multiplies the weighted values of a collection fo value nodes; if any of the weighted
 * values are null, returns null.
 */
export function safeMult(values: Array<number | null>): number | null {
  return values.reduce((p, n) => (p === null || n === null) ? null : p * n, 1)
}

/**
 * Divides the weighted value of the first in a collection of value nodes by the weighted value
 * of the remaining weighted values.  If any of the weighted values are null, returns null.
 */
export function safeDiv(values: Array<number | null>): number | null {
  // note that we must not provide an initial value for Array#reduce here; if we do,
  // the numerator will become part of the denominator!
  return values.reduce((p, n) => (p === null || n === null) ? null : p / n)
}
