import { ReactElement, Ref, useCallback, useMemo } from 'react'
import { Option, Rating, Participant } from '@vms/vmspro3-core/dist/types'
import { Select } from 'antd'
import { gql, useMutation } from '@apollo/client'

import FormModal from './FormModal'
import { Form, Input, Checkbox } from '../controls'

import { useModalData } from '../../redux/hooks'
import { OptionFieldsFragment, RatingFieldsFragment } from '../../graphql'
import { colorSelectOptions } from '../../utils/appConsts'
import { SelectOption } from '../../types'
import { useAuthentication } from '../../redux/auth/hooks'

const requiredStringRule = [
  { required: true, validateTrigger: 'onChange' },
  { whitespace: true, validateTrigger: 'onBlur' },
]

const DUPLICATE_OPTION = gql`
  mutation DuplicateOption(
    $accountId: ID!
    $decisionId: ID!
    $sourceOptionId: ID!
    $input: DuplicateOptionInput!
  ) {
    duplicateOption(
      accountId: $accountId
      decisionId: $decisionId
      sourceOptionId: $sourceOptionId
      input: $input
    ) {
      option { ...OptionFields }
      ratings { ...RatingFields }
    }
  }
  ${OptionFieldsFragment}
  ${RatingFieldsFragment}
`
type DuplicateOptionData = {
  option: Option,
  ratings: Rating[],
}
type DuplicateOptionVariables = {
  accountId: string,
  decisionId: string,
  sourceOptionId: string,
  input: Pick<Option, 'name' | 'abbrev' | 'color'> & {
    assignRatingsToParticipantId?: string,
  },
}
type OptionFormData = DuplicateOptionVariables['input'] & {
  assignRatings: boolean,
}

export type OptionDuplicateModalData = {
  accountId: string
  decisionId: string
  sourceOptionId: string
  initialValues: OptionFormData
  participants: Participant[]
  participantSelectOptions: SelectOption[]
}
export const OptionDuplicateModalId = 'OptionDuplicateModal'
export function OptionDuplicateModal(): ReactElement {
  const {
    accountId,
    decisionId,
    sourceOptionId,
    initialValues,
    participants,
    participantSelectOptions,
  } = useModalData<OptionDuplicateModalData>(OptionDuplicateModalId)

  const [{ userId }] = useAuthentication()
  const userIsParticipant = useMemo(() => (
    !!participants?.some(p => p.userId === userId)
  ), [participants, userId])

  const [duplicateOptionMutation] = useMutation<DuplicateOptionData, DuplicateOptionVariables>(DUPLICATE_OPTION)
  const onOk = useCallback(
    (optionData: OptionFormData) => {
      const { name, abbrev, color, assignRatings, assignRatingsToParticipantId } = optionData

      duplicateOptionMutation({
        variables: {
          accountId,
          decisionId,
          sourceOptionId,
          input: {
            name,
            abbrev,
            color,
            assignRatingsToParticipantId: assignRatings ? assignRatingsToParticipantId : undefined,
          },
        },
      })
    },
    [duplicateOptionMutation, accountId, decisionId, sourceOptionId]
  )

  return (
    <FormModal
      modalId={OptionDuplicateModalId}
      initialValues={initialValues}
      title="Duplicate Option"
      onOk={onOk}
      hideRequiredMark
    >
      {(initialFocusRef: Ref<Input>) => (
        <>
          <Form.Item label="Name" name="name" rules={requiredStringRule}>
            <Input ref={initialFocusRef} />
          </Form.Item>
          <Form.Item label="Abbreviation" name="abbrev" rules={requiredStringRule}>
            <Input />
          </Form.Item>
          <Form.Item label="Color" name="color" rules={[{ required: true }]}>
            <Select>
              {colorSelectOptions.map(o => (
                <Select.Option key={o.value} value={o.value} style={{ display: 'flex' }}>
                  <div style={{ width: '100%', height: '100%', padding: '4px 0', display: 'flex', gap: '12px' }}>
                    <div style={{ width: '50px', backgroundColor: o.value }} />
                    <span style={{ alignSelf: 'center' }}>{o.label}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="assignRatings" valuePropName="checked">
            <Checkbox>
              Add ratings set to the group average of the original ratings
            </Checkbox>
          </Form.Item>
          {!userIsParticipant && (
            <p>
              <b>Note</b>: You are not a participant on this decision; if you wish to assign
              group average ratings to yourself, please go back to Criteria Prioritization
              or Option Rating and add yourself as a participant first.
            </p>
          )}
          <Form.Item shouldUpdate={(prevVals, currVals) => prevVals.assignRatings !== currVals.assignRatings}>
            {formInstance => {
              const assignRatings = formInstance.getFieldValue('assignRatings')
              return (
                <Form.Item
                  label="Assign ratings to"
                  name="assignRatingsToParticipantId"
                  rules={assignRatings && requiredStringRule}
                >
                  <Select disabled={!assignRatings} options={participantSelectOptions} />
                </Form.Item>
              )
            }}
          </Form.Item>
        </>
      )}
    </FormModal>
  )
}
OptionDuplicateModal.id = OptionDuplicateModalId
