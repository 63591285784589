import { ReactElement } from 'react'
import { Criterion, Option, Html } from '@vms/vmspro3-core/dist/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { UnreachableCaseError } from 'ts-essentials'

import { List, Radio } from '../../client/controls'
import { RenderHtmlObject } from '../RenderHtmlObject'
import style from './RatingContextDetails.module.css'

type RatingContextDetailsType = 'context' | 'subject'
export type RatingContextDetailsProps<T extends Option | Criterion> = {
  decisionObjective: Html,
  subjectTypeLabel: 'Options' | 'Child Criteria',
  ratingContextLabel: string,
  ratingContextDescription?: Html,
  subjects: T[],
  detailsType: RatingContextDetailsType,
  setDetailsType: (detailType: RatingContextDetailsType) => void,
}
export function RatingContextDetails<T extends Option | Criterion>({
  decisionObjective,
  subjectTypeLabel,
  ratingContextLabel,
  ratingContextDescription,
  subjects,
  detailsType,
  setDetailsType,
}: RatingContextDetailsProps<T>): ReactElement {
  return (
    <>
      <h2>Decision Objective:</h2>
      <RenderHtmlObject htmlObject={decisionObjective} />
      <Radio.Group
        buttonStyle="solid"
        value={detailsType}
        onChange={e => setDetailsType(e.target.value)}
      >
        <Radio.Button value="context">Context Criterion</Radio.Button>
        <Radio.Button value="subject">{subjectTypeLabel}</Radio.Button>
      </Radio.Group>
      <div className={style.descriptions}>
        {(() => {
          switch(detailsType) {
            case 'context': {
              return (
                <List.Item>
                  <List.Item.Meta
                    title={ratingContextLabel}
                    description={<RenderHtmlObject htmlObject={ratingContextDescription} />}
                  />
                </List.Item>
              )
            }
            case 'subject': {
              return (
                <List
                  dataSource={subjects}
                  renderItem={subject => (
                    <List.Item key={subject.id}>
                      <List.Item.Meta
                        title={
                          <div className={style.subjectDescriptionTitle}>
                            <FontAwesomeIcon icon={faCircle} color={subject.color} />
                            <span>{subject.name} ({subject.abbrev})</span>
                          </div>
                        }
                        description={<RenderHtmlObject htmlObject={subject.description} />}
                      />
                    </List.Item>
                  )}
                />
              )
            }
            default: {
              throw new UnreachableCaseError(detailsType)
            }
          }
        })()}
      </div>
    </>
  )
}
