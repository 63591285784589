import Server from '../server/VMSProServerAdapter'

export const getUsers = async accountId => {
  const query = `
    {
      accountUsers(accountId: "${accountId}") {
        id
        accountId
        email
        fullName
        policyIds
        shortName
        initials
        phone
        disabled
      }
    }
  `
  const response = await Server.graphql({ query })
  return response.data.accountUsers
}
