import _get from 'lodash/get'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'

import * as ls from './localStorageUtils'

const { ASSUMED_AUTHZ_STORAGE_KEY, AssumeAuthzType, SystemPolicyId } = systemConsts

const { ASSUME_ROLE, IMPERSONATE_USER } = AssumeAuthzType

/**
 * Assumes temporary authz by setting ASSUMED_AUTHZ_STORAGE_KEY in browser storage and reloading.
 *  Supported authz assumption types are "ASSUME_ROLE" and "IMPERSONATE_USER".
 *
 * type "ASSUME_ROLE" requires a policy ID for the client role the authenticated user wishes to assume.
 *  The "Base User" system role policy ID is automatically included in the roles to assume. We may want
 *  to modify this in the future to support assuming combinations of multiple roles.
 *
 * type "IMPERSONATE_USER" requires a user ID for the authenticated user to impersonate. Although it is
 *  possible for a user to impersonate another user that  is authorized to impersonate users, we only
 *  support impersonating one user at a time (no "chaining" impersonations).
 *
 * @param {Function} authz - function to authorize the user to perform actions
 * @param {Object} action
 * @param {string} action.type - type of action, must be "user/modifyAuthzCredentials"
 * @param {Object} action.payload
 * @param {string} [action.payload.policyId] - ID of role policy to apply in place of user policy IDs
 * @param {string} [action.payload.userId] - ID of user to impersonate
 * @param {Object} action.meta
 * @param {string} action.meta.type - type of authz modification
 */
function assumeAuthz(authz, action) {
  if(typeof authz !== 'function') throw new Error('Use of "assumeAuthz" without authz is not authorized!')

  const { meta, payload, module, type } = action
  if(module !== 'System' || type !== 'Assume AuthZ') {
    throw new Error(`action.module must be "System" and action.type must be "Assume Policy" to use assumeAuthz`)
  }

  // authz modification is serious business – although this should already be authz'd at the
  // component level, we don't want an unauthorized user who has made it this far to continue on.
  if(authz(action)) {
    switch(meta.type) {
      case ASSUME_ROLE: {
        if(!payload.policyId) throw new Error(`action.payload.policyId is required for type "${ASSUME_ROLE}"!`)
        ls.setObject(ASSUMED_AUTHZ_STORAGE_KEY, {
          type: ASSUME_ROLE,
          assumedRoleIds: [
            // currently we are only supporting assuming one role at a
            // time. all assumable roles need "Base User" permissions.
            SystemPolicyId.BASE_USER,
            payload.policyId,
          ],
        })
        window.location.reload()
        break
      }
      case IMPERSONATE_USER: {
        if(!payload.userId) throw new Error(`action.payload.userId is required for type "${IMPERSONATE_USER}"!`)
        ls.setObject(ASSUMED_AUTHZ_STORAGE_KEY, {
          type: IMPERSONATE_USER,
          assumedUserId: payload.userId,
        })
        window.location.reload()
        break
      }
      default:
        throw new Error(`Assume authz type "${meta.type}" not recognized!`)
    }
  } else {
    console.error('This authz modification is not authorized!', action)
  }
}

function getAssumedAuthz() {
  return ls.getObject(ASSUMED_AUTHZ_STORAGE_KEY)
}

function getAssumedUserId() {
  const assumedAuthz = getAssumedAuthz()
  return assumedAuthz && assumedAuthz.assumedUserId
}

function getAssumedRoleId() {
  const assumedAuthz = getAssumedAuthz()
  return _get(assumedAuthz, `assumedRoleIds[1]`)
}

function getAssumedRoleIds() {
  const assumedAuthz = getAssumedAuthz()
  return assumedAuthz && assumedAuthz.assumedRoleIds
}

function restoreDefaultAuthz() {
  localStorage.removeItem(ASSUMED_AUTHZ_STORAGE_KEY)
  window.location.reload()
}

const AssumedAuthzType = {
  ASSUME_ROLE,
  IMPERSONATE_USER,
}

export {
  assumeAuthz,
  getAssumedAuthz,
  getAssumedRoleId,
  getAssumedRoleIds,
  getAssumedUserId,
  restoreDefaultAuthz,
  AssumedAuthzType,
}
