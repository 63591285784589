import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SettingOutlined from '@ant-design/icons/SettingOutlined'

import { createRootRiskContext } from '@vms/vmspro3-core/dist/utils/risk'
import { ROOT_RISK_PORTFOLIO_ID } from '@vms/vmspro3-core/dist/systemConsts'

import { Button, Result } from '../../controls'

import actions from '../../../actions'
import useAuthz from '../../../hooks/useAuthz'
import { selectAccountName } from '../../../redux/account/selectors'

function UnconfiguredPortfolio() {
  const dispatch = useDispatch()
  const accountName = useSelector(selectAccountName)

  const authz = useAuthz()
  const canCreateConfig = authz({
    module: 'Risk',
    type: actions.riskPortfolio.create.toString(),
  })

  const startConfig = () => {
    const payload = {
      id: ROOT_RISK_PORTFOLIO_ID,
      name: 'Portfolio',
      description: `Implicit root portfolio for VMSPro account ${accountName}`,
      riskContext: createRootRiskContext(),
      ancestry: '/',
    }
    dispatch(actions.riskPortfolio.create(payload))
  }

  const ResultExtra = () => (
    <>
      <p>To get started, you'll first need to create a configuration for VMSPro Risk.
        {canCreateConfig
          ? <span> Don't worry, we'll provide some reasonable defaults; all you have to do is push a button!</span>
          : <span> Ask an authorized user to create a configuration.</span>
        }
      </p>
      {canCreateConfig &&
        <Button type="primary" onClick={startConfig}><SettingOutlined /> Create Configuration</Button>
      }
    </>
  )

  return (
    <Result
      icon={<SettingOutlined />}
      title="Welcome to VMSPro Risk!"
      extra={<ResultExtra />}
    />
  )
}

export default UnconfiguredPortfolio
