export type Colorizer = (input: string) => string[]

/**
 * Strips color tags from colorized output.  Note that the
 * output is an array; this is because some colorizers need
 * arrays (such as the browser colorizer).
 */
export function stripColor(input: string) {
  return [input.replace(/{[\w.]+ (.*?)}/g, '$1')]
}
