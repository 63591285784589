/**
 * Quick and dirty "retry" higher-order function.  Will retry an
 * async function until it's truthy or it runs out of retries.
 *
 * @param {function} fn - The function to retry.  A truthy value will be
 *   returned immediately; a falsey value will be retried up to the limit.
 * @param {number} [delay=50] - The time to delay between retries.
 * @param {number} [n=3] - The number of times to retry.  Note that this
 *   does NOT include the first attempt.  So n=3 could invoke the function 4
 *   times (the first time plus three retries).
 *
 * @returns {*} - The return value of the function's last invocation.  If falsey,
 *   you can infer that it was retried up to the maximum.
 */
export const retry = async (fn: () => Promise<unknown>, delay = 50, n = 3): Promise<unknown> => {
  const res = await fn()
  if(res || n === 0) return res   // got it or ran out of retries
  return new Promise(resolve => setTimeout(() => resolve(retry(fn, delay, n - 1)), delay))
}
