import { batch } from 'react-redux'

import policiesActionTypes from '../policies/actionTypes'
import riskEntitiesActionTypes from './actionTypes'
import {
  getRiskEntity,
  getRiskEntityChildren,
} from '../../services/riskEntitiesService'

export function fetchRiskEntity(entityId) {
  return async dispatch => {
    try {
      dispatch({
        type: riskEntitiesActionTypes.FetchRiskEntityRequest,
        meta: { ephemeral: true },
      })

      const { entity, ancestors, policiesByResourceId } = await getRiskEntity(entityId)
      batch(() => {
        dispatch({
          type: riskEntitiesActionTypes.FetchRiskEntitySuccess,
          payload: {
            entity,
            ancestors,
          },
          meta: { ephemeral: true, entityId },
        })
        dispatch({
          type: policiesActionTypes.LoadResourcePolicies,
          payload: policiesByResourceId,
          meta: { ephemeral: true },
        })
      })
    } catch(error) {
      console.error('fetchRiskEntity error: ', error)
    }
  }
}

export function fetchRiskEntityChildren(childAncestry) {
  return async dispatch => {
    try {
      dispatch({
        type: riskEntitiesActionTypes.FetchRiskEntityChildrenRequest,
        meta: { ephemeral: true },
      })

      const { entities, policiesByResourceId } = await getRiskEntityChildren(childAncestry)
      batch(() => {
        dispatch({
          type: riskEntitiesActionTypes.FetchRiskEntityChildrenSuccess,
          payload: { entities },
          meta: { ephemeral: true, childAncestry },
        })
        dispatch({
          type: policiesActionTypes.LoadResourcePolicies,
          payload: policiesByResourceId,
          meta: { ephemeral: true },
        })
      })
    } catch(error) {
      console.error('fetchRiskEntityChildren error: ', error)
    }
  }
}
