/* eslint-disable @typescript-eslint/no-var-requires, no-undef */
const _get = require('lodash/get')

/**
 * Grabs the parent policy Statement based on policyId
 *
 * @param {String} policyId - parent PolicyId
 * @param {Object} policies - list of policies
 *
 * @returns {Obejct} the policy Statement
 */
const getExtendedPolicyStatement = (policyId, policies) => {
  // TODO: see https://vms.atlassian.net/browse/VP3-2135
  // we really need to get this cleaned up....
  const policy = policies.find(pol => (pol.id || pol.policyId) === policyId)
  return _get(policy, ['Statement'], [])
}

/**
 * Get the resource policies with their extended policies' Statements
 *
 * @param {Array<Object>} resourcePolicies - the resoure
 * @param {Array<Object>} globalPolicies - the available policies
 * @returns {Object} the resource policies
 */
const getResourcePoliciesWithStatements = (resourcePolicies, globalPolicies) => {
  if(!globalPolicies) throw new Error('No policy array provided')
  if(!resourcePolicies) return []
  return resourcePolicies.map(policy =>
    policy.extends
      ? {
        ...policy,
        Statement: [
          ...policy.Statement || [],
          ...getExtendedPolicyStatement(policy.extends, globalPolicies),
        ],
      } : policy
  )
}

module.exports = {
  getResourcePoliciesWithStatements,
}
