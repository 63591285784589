import { createSelector } from 'reselect'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'

import { alphanumericSortByKey } from '../utils/sort-utils'

const { PolicyType } = systemConsts

/**
 *
 * @param {Object} state - redux state object
 * @returns {Array.<string>} - policy IDs
 */
const getPolicyIds = state => state.policies.ids

/**
 *
 * @param {Object} state - redux state object
 * @returns {Object} - policies by ID
 */
const getPoliciesById = state => state.policies.byId

/**
 *
 * @param {Object} state - redux state object
 * @param {string} policyId - policy ID
 * @returns {Object} policy object
 */
const getPolicy = (state, policyId) => state.policies.byId[policyId]

/**
 *
 * @param {Object} state - redux state object
 * @returns {Array} - array containing all policies
 */
const getPolicies = createSelector(
  getPolicyIds,
  getPoliciesById,
  (ids, byId) => ids.map(id => byId[id])
)

/**
 * @param {Object} state - redux state object
 * @param {string[]} policyIds - policy IDs
 * @returns {Array} - array containing policies
 */
const getPoliciesFromIds = (state, policyIds) => policyIds.map(policyId => getPolicy(state, policyId))

/**
 *
 * @param {Object} state - redux state object
 * @returns {Array} - array containing role type policy objects
 */
const getRolePolicies = createSelector(
  getPolicies,
  policies => policies.filter(policy => policy.policyType === PolicyType.ROLE)
)

/**
 *
 * @param {Object} state - redux state object
 * @param {Array} policyIds - array containing policy IDs
 * @returns {Array} - array containing role type policy objects from array of policyIds
 */
const getRolePoliciesFromIds = createSelector(
  getRolePolicies,
  (_, policyIds) => policyIds,
  (rolePolicies, policyIds) => rolePolicies.filter(policy => policyIds.includes(policy.id))
)

/**
 *
 * @param {Object} state - redux state object
 * @param {Array} policyIds - array containing policy IDs
 * @returns {Array} - array containing role policy names from array of policy ids
 */
const getRolePolicyNamesFromIds = createSelector(
  getRolePoliciesFromIds,
  rolePolicies => rolePolicies.map(policy => policy.name)
)

/**
 *
 * @param {Object} state - redux state object
 * @returns {Array.<{{ title: string, items: Array.<{{ key: string, title: string }}> }}>} -
 */
const getRolePolicyMenuItems = createSelector(
  getRolePolicies,
  getPoliciesById,
  (rolePolicies, policiesById) => {
    // separate role policies from template policies, group template policies by templateId
    const policies = rolePolicies.reduce((_policies, policy) => {
      const templateId = policy.instanceOf

      if(templateId) {
        if(!_policies.byTemplateId[templateId]) _policies.byTemplateId[templateId] = []
        _policies.byTemplateId[templateId].push(policy)
      } else {
        _policies.roles.push(policy)
      }

      return _policies
    }, { roles: [], byTemplateId: {} })

    // Fn to convert array of policies to menu items sorted by title
    const policiesToSortedMenuItems = _policies => _policies
      .map(({ id, name }) => ({ key: id, title: name }))
      .sort(alphanumericSortByKey('title'))

    // create menu groupings with template name
    const templatePolicyGroups = Object.entries(policies.byTemplateId)
      .map(([id, policies]) => ({
        title: policiesById[id].name,
        items: policiesToSortedMenuItems(policies),
      }))
      .sort(alphanumericSortByKey('title'))

    return [
      { title: 'Global Roles', items: policiesToSortedMenuItems(policies.roles) },
      ...templatePolicyGroups,
    ]
  }
)

export {
  getPolicies,
  getPoliciesById,
  getPoliciesFromIds,
  getPolicy,
  getPolicyIds,
  getRolePolicyMenuItems,
  getRolePolicies,
  getRolePolicyNamesFromIds,
}
