import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ROOT_RISK_PORTFOLIO_ID, EntityType } from '@vms/vmspro3-core/dist/systemConsts'

import UnconfiguredPortfolio from './UnconfiguredPortfolio'
import RiskPortfolioNode from './RiskPortfolioNode'
import RiskProject from './RiskProject'
import { Spin } from '../../controls'

import { LoadingStatus } from '../../../utils/appConsts'
import { fetchRiskEntity } from '../../../redux/riskEntities/actions'
import { getRiskEntityLoadingStatus } from '../../../selectors'

interface RiskPortfolioProps {
  entityType: 'PORTFOLIO' | 'PROGRAM' | 'PROJECT',
}

/**
 * Top level Risk module component, responsible for loading entity and rendering RiskPortfolioNode.
 * If root portfolio doesn't exist, renders UnconfiguredPortfolio which will allow an authorized user to
 * dispatch and action that will create a root portfolio.
 */
function RiskPortfolio({ entityType }: RiskPortfolioProps) {
  const { entityId = ROOT_RISK_PORTFOLIO_ID } = useParams()

  const dispatch = useDispatch()
  const loadingStatus = useSelector(getRiskEntityLoadingStatus(entityId))

  // load entity
  useEffect(
    () => {
      if(loadingStatus === LoadingStatus.NotLoaded) {
        dispatch(fetchRiskEntity(entityId))
      }
    },
    [dispatch, loadingStatus, entityId]
  )

  switch(loadingStatus) {
    case LoadingStatus.NotLoaded: // fall through to LoadingStatus.Requested
    case LoadingStatus.Requested:
      return <Spin />

    // if root portfolio wasn't found it must be created
    case LoadingStatus.NotFound:
      if(entityId === ROOT_RISK_PORTFOLIO_ID) {
        return <UnconfiguredPortfolio />
      }
      // TODO: 404 return
      return <h1>404 - Entity Not Found</h1>

    default:
      return (
        entityType === EntityType.PROJECT
          ? <RiskProject projectId={entityId} />
          : <RiskPortfolioNode entityId={entityId} entityType={entityType} />
      )
  }
}

export default RiskPortfolio
