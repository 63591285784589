import augmentAction from './augmentAction'
import { selectCurrentUserName } from '../user/selectors'
import { selectAccountId } from '../account/selectors'

const augmentationMiddleware = store => next => action => {
  const state = store.getState()
  const { userId: authUserId, actualUserId, assumedRoleIds } = state.auth || {}
  const authUserName = selectCurrentUserName(state)
  const accountId = selectAccountId(state)
  next(augmentAction(action, { accountId, actualUserId, assumedRoleIds, authUserId, authUserName }))
}

export default augmentationMiddleware
