import { Colorizer, stripColor } from "./colorizer"

/**
 * Takes a node from a tree-like structure, and converts to a string suitable
 * for use in a CLI or browser console, complete with colorization.
 */
export function treeToString<T>(
  node: T,
  getChildren: (node: T) => T[],
  getLabel: (node: T) => string,
  color = false,
  prefixes: string[] = [],
  outputLines: string[] = []
): string {
  const label = color ? `{cyan ${getLabel(node)}}` : getLabel(node)
  outputLines.push(prefixes.join('') + label)
  // we have to "straighten out" the child prefixes...they won't be connectors but
  // empty spaces (if the prefix indicates its a last child) or a straight line
  // (indicating there are more children to come)
  const childPrefixes: string[] = prefixes.map(p => p === '└── ' || p === '    ' ? '    ' : '│   ')
  getChildren(node).forEach((child, idx, { length }) => {
    treeToString(
      child,
      getChildren,
      getLabel,
      color,
      childPrefixes.concat(idx === length - 1 ? '└── ' : '├── '),
      outputLines,
    )
  })
  return outputLines.join('\n')
}

/**
 * Takes a node from a tree-like structure and logs to the conole.  Allows you to provide
 * a colorizer (which will be different for Node or browser).  If you don't provide a colorizer,
 * it simply strips color codes.
 */
export function logTree<T>(
  node: T,
  getChildren: (node: T) => T[],
  getLabel: (node: T) => string,
  colorizer: Colorizer = stripColor,
) {
  console.log(...colorizer(treeToString(node, getChildren, getLabel, true)))
}
