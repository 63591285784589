import React from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { joinAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'

import FormModal from './FormModal'
import { Form, Input } from '../controls'

import { getRiskProject, effectiveRiskContextFromAncestry } from '../../selectors'
import { useModalData } from '../../redux/hooks/appHooks'
import actions from '../../actions'

/**
 * Modal to Create a Risk and add it to the Project Interface.
 *
 * @prop actionMeta - the custom action metadata - including resource authorization
 * @prop projectId - ID of the project the risk is being created in
 */
const RiskCreateModal = () => {
  const {
    actionMeta,
    projectId,
  } = useModalData(RiskCreateModal.id)

  const project = useSelector(getRiskProject(projectId))

  const ancestry = joinAncestry(project.ancestry, project.id)

  const effectiveRiskContext = useSelector(effectiveRiskContextFromAncestry(ancestry))
  const { nextRiskNum } = effectiveRiskContext

  const dispatch = useDispatch()
  const onOk = ({ name }) => batch(() => {
    dispatch(actions.risk.create({
      num: nextRiskNum,
      ancestry,
      effectiveRiskContext,
      name,
    }, { ...actionMeta, projectId }))
    dispatch(actions.riskProject.updateStatistics({
      activeRisks: {
        operation: 'INCREMENT',
        value: 1,
      },
    }, { ...actionMeta, projectId, ancestry }))
  })

  return (
    <FormModal hideRequiredMark allowReuse modalId={RiskCreateModal.id} onOk={onOk}>
      {initialFocusRef => (
        <Form.Item
          label="Risk Name"
          name="name"
          rules={[{ required: true }]}
        >
          <Input ref={initialFocusRef} />
        </Form.Item>
      )}
    </FormModal>
  )
}
RiskCreateModal.id = 'RiskCreateModal'
RiskCreateModal.defaulProps = {
  actionMeta: {},
}

export default RiskCreateModal
