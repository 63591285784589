import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import RiskTableCustomizationModal from '../modals/RiskTableCustomizationModal'
import RiskPortfolioEntityColumns from './riskPortfolioEntityColumns'
import { Col, LinkButton2, Row, Table } from '../controls'

import useAuthz from '../../hooks/useAuthz'
import actions from '../../actions'
import { riskTableStyle } from '../styles/style-consts'
import { useTableConfig } from '../../utils/tableHooks'
import { getTableConfigs } from '../../selectors'
import { selectAuthUserId } from '../../redux/auth/selectors'
import { buildFilterTag, filterTableColumns } from '../../utils/tableUtils'
import { showModal } from '../../redux/actions'

const defaultConfigId = 'custom'

const defaultCheckedColumns = [
  'commonId',
  'type',
  'name',
  'activeRisks',
  'time',
  'cost',
  'created',
]

const RiskPortfolioEntityTable = ({
  loading = false,
  rows,
}) => {
  const dispatch = useDispatch()
  const authUserId = useSelector(selectAuthUserId)
  const authz = useAuthz()

  // Set the default configuration for the table
  const defaultConfigData = {
    [defaultConfigId]: {
      configId: defaultConfigId,
      checkedColumns: defaultCheckedColumns,
      tableId: RiskPortfolioEntityTable.id,
    },
  }

  // Grab the configuration from state (if available). Default to defaultConfigData
  const tableConfiguration =
    useSelector(state => getTableConfigs(state, RiskPortfolioEntityTable.id)) ?? defaultConfigData

  // Grab the filters, pagination and table configuration from the custom hook for configuration
  const { filters, pageSize, tableConfig } = useTableConfig(tableConfiguration)

  // Instantiate the table columns class object
  const riskProjectInventoryColumns = useMemo(() => new RiskPortfolioEntityColumns({ filters }), [filters])

  // get the table columns and sortFns
  const columns = riskProjectInventoryColumns.getColumns()

  // Set the columns for the customization modal
  tableConfig.columns = columns

  // filter out the unset columns
  const filteredCols = filterTableColumns(columns, tableConfig)

  /**
   * When filters or pagination change, persist the data to the database
   *
   * @param {Object} pagination - active pagintation object
   * @param {Object} filters - Available filters for the table
   */
  const onFilterChange = (pagination, filters) => {
    const { pageSize } = pagination
    const payload = {
      ...tableConfiguration,
      tableId: RiskPortfolioEntityTable.id,
      pagination: { pageSize },
      filters,
    }
    dispatch(actions.user.setTableConfig(
      payload,
      { userId: authUserId }
    ))
  }

  const onSortChange = sortFields => {
    dispatch(actions.user.setTableConfig(
      {
        ...tableConfig,
        sortFields,
      },
      { userId: authUserId }
    ))
  }

  const pagination = {
    pageSize,
    defaultCurrent: 1,
    hideOnSinglePage: true,
    showSizeChanger: true,
    pageSizeOptions: ['10', '25', '50', '100'],
    position: 'both',
  }

  /**
   * Handle the close of a tag. Clear the filter for the table.
   *
   * @param {String} dataKey - the dataIndex to be cleared of filters.
   */
  const onClose = dataKey =>
    onFilterChange(pagination, { ...filters, [dataKey]: [] })

  /**
   * Build the tag for the active filters in the table.
   *
   * @param {String} k - Key for the tag
   * @param {Object} v - Value for the tag as an Array
   */
  const buildTag = (k, v) => buildFilterTag(k, v, { onClose })

  const canConfigureCols = useMemo(() => authz({
    module: 'System',
    type: actions.user.setTableConfig.toString(),
    meta: {
      authUserId,
      userId: authUserId,
    },
  }), [authUserId, authz])

  const titleInfo = (
    <Row type="flex" justify="end">
      <Col style={{ float: 'left' }}>
        {Object.entries(filters)
          .filter(v => !!v[1] && v[1].length)
          .map(([k, v]) => buildTag(k, v))
        }
      </Col>
      <Col style={{ textAlign: 'right' }}>
        {canConfigureCols &&
          <LinkButton2
            onClick={() => dispatch(showModal(RiskTableCustomizationModal.id, tableConfig))}
            style={style.button}
          >
            Configure Columns
          </LinkButton2>
        }
      </Col>
    </Row>
  )

  return (
    <Table
      columns={filteredCols}
      dataSource={rows}
      loading={loading}
      onChange={onFilterChange}
      onSortChange={onSortChange}
      pagination={pagination}
      rowKey="id"
      scroll={{ x: 'max-content' }}
      title={() => titleInfo}
      sortFields={tableConfig.sortFields}
      {...riskTableStyle}
    />
  )
}
RiskPortfolioEntityTable.id = 'RiskPortfolioEntityTable'

const style = {
  button: {
    right: '0px',
  },
}

export default RiskPortfolioEntityTable
