import React, { useCallback, useState } from 'react'

export function useLocalStorage<T>(
  key: string,
  initialValue?: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch(error) {
      console.error(error)
      localStorage.removeItem(key)
      return initialValue
    }
  })

  const setValue = useCallback(
    value => {
      try {
        setStoredValue(storedValue => {
          const valueToStore = value instanceof Function ? value(storedValue) : value
          if(valueToStore === undefined) {
            localStorage.removeItem(key)
          } else {
            localStorage.setItem(key, JSON.stringify(valueToStore))
          }
          return valueToStore
        })
      } catch(error) {
        console.error(error)
      }
    },
    [key]
  )

  return [storedValue, setValue]
}
