import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import asSet from 'arraysetjs'

import { authz as coreAuthz } from '@vms/vmspro3-core/dist/security'
import { resolveResourcePolicies } from '@vms/vmspro3-core/dist/security/getResourcePolicies'
import { splitAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'

import actions from '../actions'
import { AssumedAuthzType } from '../utils/authzUtils'
import { useUserPolicies } from '../redux/policies/hooks'
import { selectSystemPolicies, selectPoliciesByResourceId } from '../redux/policies/selectors'
import {
  selectAuthUserId,
  selectAssumedRolePolicies,
  selectAssumedRolePolicyIds,
} from '../redux/auth/selectors'

/**
 * Custom hook that returns a callback to authorize an action to be performed
 * by the authenticated user.
 *
 * @returns {Action => boolean} - function to authorize the authenticated user
 *    to perform a given action.
 */
export default function useAuthz() {
  const authUserId = useSelector(selectAuthUserId)

  const [userPolicies, userPoliciesLoading] = useUserPolicies()

  const assumedRoleIds = useSelector(selectAssumedRolePolicyIds)
  const assumedRolePolicies = useSelector(selectAssumedRolePolicies)

  const systemPolicies = useSelector(selectSystemPolicies)
  const policiesByResourceId = useSelector(selectPoliciesByResourceId)

  /**
   * Function to authorize the authenticated user to perform a given action.
   *
   * @param {Object} action - action to be authorized (if passed a string,
   *  assume an action of this type)
   * @param {Object} [options] - options to be passed to core authz (especially "explain")
   * @returns {boolean} - true if the authenticated user is authorized to
   *    perform the action, otherwise false.
   */
  const authz = useCallback(
    (action, options) => {
      if(!authUserId || userPoliciesLoading) return false

      if(typeof action === 'string') {
        throw new Error('string as action argument no longer supported')
      }
      const authzResourceIds = action.meta?.authz?.resources ?? []
      const ancestorIds = splitAncestry(action?.meta?.ancestry ?? '')
      const resourceIds = asSet(authzResourceIds).union(ancestorIds)

      const authUserResourcePolicies = resourceIds.reduce((authUserResourcePolicies, resourceId) => {
        const principal = 'user:' + authUserId
        const resourcePolicies = policiesByResourceId[resourceId]
        const resolvedPolicies = resolveResourcePolicies(principal, resourcePolicies, systemPolicies)
        return authUserResourcePolicies.concat(resolvedPolicies)
      }, [])

      if(assumedRoleIds) {
        return assumedRoleIds.every(policyId =>
          coreAuthz(
            actions.user.assumeAuthz({ policyId }, { type: AssumedAuthzType.ASSUME_ROLE, authUserId }),
            userPolicies,
            undefined,
            options,
          )
        ) && coreAuthz(action, assumedRolePolicies.concat(authUserResourcePolicies), undefined, options)
      }
      return coreAuthz(action, userPolicies.concat(authUserResourcePolicies), undefined, options)
    },
    [
      authUserId,
      systemPolicies,
      policiesByResourceId,
      userPolicies,
      assumedRoleIds,
      assumedRolePolicies,
      userPoliciesLoading,
    ]
  )

  return authz
}
