import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { createSelector } from 'reselect'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CheckOutlined, DownloadOutlined } from '@ant-design/icons'
import { Color } from '@vms/vmspro3-core/dist/systemConsts'
import _keyBy from 'lodash/keyBy'

import { Table } from '../../client/controls'
import { Page } from '../../components/Page'

import Server from '../../server/VMSProServerAdapter'
import getAugmentAction from '../../selectors/getAugmentAction'
import { selectAccount } from '../../redux/account/selectors'
import { selectDateFormat } from '../../redux/user/selectors'
import { formatCost } from '../../utils/formatUtils'
import config from '../../config.json'

const productsById = _keyBy(config.instance.products, 'id')

const selectProductIsInternal = createSelector(
  [selectAccount],
  account => productsById[account.productId]?.type === 'Internal'
)

export function InvoicesPage() {
  const productIsInternal = useSelector(selectProductIsInternal)

  const [invoices, setInvoices] = useState()

  const augmentAction = useSelector(getAugmentAction)
  useEffect(() => {
    if(productIsInternal) return
    const query = `
      {
        invoices {
          id
          createdOn
          periodStart
          periodEnd
          total { value, unit }
          paid
          invoiceUrl
        }
      }
    `
    Server.graphql({ query }).then(res => {
      setInvoices(res.data.invoices)
    })
  }, [augmentAction, productIsInternal])

  const dateFormat = useSelector(selectDateFormat)
  const columns = useMemo(
    () => {
      const formatTs = ts => moment(ts).format(dateFormat)
      return [
        {
          title: 'Invoice Date',
          dataIndex: 'createdOn',
          align: 'right',
          render: formatTs,
        },
        {
          title: 'Billing Period Start',
          dataIndex: 'periodStart',
          align: 'right',
          render: formatTs,
        },
        {
          title: 'Billing Period End',
          dataIndex: 'periodEnd',
          align: 'right',
          render: formatTs,
        },
        {
          title: 'Amount',
          dataIndex: 'total',
          align: 'right',
          render: cost => formatCost(cost),
        },
        {
          title: 'Paid',
          dataIndex: 'paid',
          align: 'center',
          render: paid => paid && <CheckOutlined style={{ color: Color.Success }} />,
        },
        {
          title: 'Receipt',
          dataIndex: 'id',
          align: 'center',
          render: (_, row) => <a href={row.invoiceUrl}><DownloadOutlined /></a>,
        },
      ]
    },
    [dateFormat]
  )

  return (
    <Page title="Invoices">
      {productIsInternal
        ? (
          <p>
            This account is subscribed to a free internal product and there are no invoices.
          </p>
        )
        : (
          <Table
            rowKey="id"
            loading={!invoices}
            columns={columns}
            dataSource={invoices ?? []}
          />
        )
      }
      <div style={{ marginTop: '32px' }}>
        Looking for <Link to="/subscription">subscription information</Link>?
      </div>
    </Page>
  )
}
