import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { updateAccountSubscription } from '@vms/vmspro3-core/dist/actions/account/updateSubscription'

import FormModal from './FormModal'
import PlanCard from '../../components/Subscription/PlanCard'
import { Modal } from '../controls'

import { useModalData } from '../../redux/hooks/appHooks'
import { selectAccount } from '../../redux/account/selectors'
import { useProductsForSubscriptionUpdate } from '../../hooks/products'
import { hideModal } from '../../redux/actions'

const PlanSelectionModal = () => {

  const { onSubscriptionChange } = useModalData(PlanSelectionModal.id)

  const account = useSelector(selectAccount)
  const { defaultPaymentMethodId } = account.integrations.stripe || {}

  const dispatch = useDispatch()

  const updateSubscription = (account, product) => {
    dispatch(updateAccountSubscription(account, product, defaultPaymentMethodId))
  }

  const onChangePlan = product => {
    Modal.confirm({
      title: 'Change Plan',
      content: `Are you sure you want to change your plan to "${product.name}"?`,
      onOk: () => {
        updateSubscription(account, product)
        onSubscriptionChange({ currentMonthlyBill: product.price })
        dispatch(hideModal(PlanSelectionModal.id))
      },
    })
  }

  const products = useProductsForSubscriptionUpdate()

  if(!products.length) {
    return (
      <FormModal
        modalId={PlanSelectionModal.id}
        title="Select New Plan"
      >
        <i>There are no other available plans that are eligible for your current usage
          of {account.quotas.users.used} users and {account.quotas.projects.used} projects;
          please <Link to="TODO">contact us</Link> for enterprise accounts.
        </i>
      </FormModal>
    )
  }

  const title = products.length ? "Eligible Plans" : "No Eligible Plans"

  return (
    <FormModal
      modalId={PlanSelectionModal.id}
      title={title}
      footer={null}
    >
      <>
        {!defaultPaymentMethodId &&
          <p><i>Note: you do not currently have a payment method configured, which will
            limit your options to internal products.  If you wish to switch to a commercial
            product, please exit this screen, and update your payment information.</i></p>
        }
        <p><i>The following plans can accommodate your current usage:</i></p>
        {products.map(product => (
          <PlanCard
            key={product.id}
            product={product}
            actionText="Select Plan"
            onAction={() => onChangePlan(product)}
          />
        ))}
      </>
    </FormModal>
  )
}
PlanSelectionModal.id = 'PlanSelectionModal'

export default PlanSelectionModal
