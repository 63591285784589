import systemConsts from '@vms/vmspro3-core/dist/systemConsts'

const { RiskImpactType } = systemConsts

// This color function is intended for "pre" and "post" study snapshots. If/when we add other snapshots we will
// need to decide how to manage colors.
export const qualRiskToColor = (type, key, prob, qual) => {
  const h = type === RiskImpactType.THREAT
    ? key === 'pre-study' ? '#88171a' : '#c24147'
    : key === 'pre-study' ? '#2f6c35' : '#77af43'
  const m = type === RiskImpactType.THREAT
    ? key === 'pre-study' ? '#e46c26' : '#f89958'
    : key === 'pre-study' ? '#005571' : '#399db0'
  const l = type === RiskImpactType.THREAT
    ? key === 'pre-study' ? '#ffbc00' : '#fecc7e'
    : key === 'pre-study' ? '#462e65' : '#655288'
  const g = key === 'pre-study' ? '#4f4f4f' : '#6e6e6e'

  switch(prob) {
    case 'VH': return ({ VL: m, L: h, M: h, H: h, VH: h, undefined: g })[qual]
    case 'H': return ({ VL: l, L: m, M: h, H: h, VH: h, undefined: g })[qual]
    case 'M': return ({ VL: l, L: m, M: m, H: h, VH: h, undefined: g })[qual]
    case 'L': return ({ VL: l, L: l, M: m, H: m, VH: h, undefined: g })[qual]
    case 'VL': return ({ VL: l, L: l, M: l, H: l, VH: m, undefined: g })[qual]
    default: return g
  }
}

export const riskSeverity = {
  VL: 0.2,
  L: 0.4,
  M: 0.6,
  H: 0.8,
  VH: 1,
}

export const impactMultiplier = {
  [RiskImpactType.THREAT]: 1,
  [RiskImpactType.NONE]: 0,
  [RiskImpactType.OPPORTUNITY]: -1,
}

export const quantRiskToQual = (value, table) => {
  if(value < table.VL.H) return 'VL'
  if(value < table.L.H) return 'L'
  if(value < table.M.H) return 'M'
  if(value < table.H.H) return 'H'
  return 'VH'
}
