/* eslint-disable @typescript-eslint/no-explicit-any */

const UpdateRiskEntityActionType = 'Update Risk Entity'
/**
 * update a risk entity (portfolio, program, project, or risk)
 *
 * @param {Object} payload - risk entity update payload
 * @param {Object} meta - project metadata
 * @param {string} meta.ancestry - ancestry of entity being updated
 * @param {string} meta.entityId - entity ID to update
 * @param {string} meta.entityType - type of entity being updated
 *
 * @return {Object} - riskEntity update action
 */
function updateRiskEntityActionCreator(payload: any, meta: any) {
  const { ancestry, entityId, entityType } = meta
  const type = UpdateRiskEntityActionType

  if(!entityId) throw new Error(type + ': action.meta.entityId is required')
  if(!ancestry) throw new Error(type + ': action.meta.ancestry is required')
  if(!entityType) throw new Error(type + ': action.meta.entityType is required')
  if(meta.authz) throw new Error('do not provide meta.authz; this is constructed in the action creator')
  meta.authz = { resources: [entityId] }

  return { module: 'Risk', type, payload, meta }
}
updateRiskEntityActionCreator.toString = () => UpdateRiskEntityActionType

const DeleteRiskEntityActionType = 'Delete Risk Entity'
/**
 * Delete risk entity
 *
 * @param {Object} payload
 * @param {Object} meta
 *
 * @return {Object} - riskEntity delete action
 */
function deleteRiskEntity(payload: any, meta: any) {
  const { ancestry, entityId, entityType } = meta
  const type = DeleteRiskEntityActionType

  if(!entityId) throw new Error(type + ': action.meta.entityId is required')
  if(!ancestry) throw new Error(type + ': action.meta.ancestry is required')
  if(!entityType) throw new Error(type + ': action.meta.entityType is required')
  meta.authz = meta.authz || {}
  if(!meta.authz.resources) meta.authz.resources = [entityId]
  else meta.authz.resources.push(entityId)

  return { module: 'Risk', type, payload, meta }
}
deleteRiskEntity.toString = () => DeleteRiskEntityActionType

const MoveRiskEntityActionType = 'Move Risk Entity'
/**
 * Move a risk entity and its descendants' to a new location in the risk
 * portfolio hierarchy.
 *
 * TODO: rename to "moveRiskEntity"
 *
 * @param {Object} [payload]
 * @param {Object} meta
 * @param {string} meta.entityId - ID of entity being moved
 * @param {string} meta.srcParentId - ID of source parent entity (for authz)
 * @param {string} meta.dstParentId - ID of destination parent entity (for authz)
 */
function updateLocation(payload: any, meta: any) {
  const requiredMetaKeys = [
    'entityId',
    'srcParentId',
    'dstParentId',
  ]
  requiredMetaKeys.forEach(key => {
    if(!meta[key]) throw new Error(updateLocation.toString() + `: action.meta.${key} is required`)
  })

  return {
    module: 'Risk',
    type: MoveRiskEntityActionType,
    payload,
    meta,
  }
}
updateLocation.toString = () => MoveRiskEntityActionType

export default {
  update: updateRiskEntityActionCreator,
  delete: deleteRiskEntity,
  updateLocation,
}
