import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { signOut } from './actions'
import {
  selectAssumedUserId,
  selectAuthUserId,
  selectAuthUserEmail,
  selectIsAuthenticated,
  selectAuthLoading,
} from './selectors'

/**
 * @returns {[{ userId: string, email: string }, loading: boolean]}
 */
export function useAuthentication() {
  const userId = useSelector(selectAuthUserId)
  const email = useSelector(selectAuthUserEmail)
  const loading = useSelector(selectAuthLoading)

  return [{ userId, email }, loading]
}

export function useAssumedUserId() {
  useSelector(selectAssumedUserId)
}

/**
 *
 * @returns {[isAuthenticated: boolean, authLoading: boolean]}
 */
export function useIsAuthenticated() {
  return [
    useSelector(selectIsAuthenticated),
    useSelector(selectAuthLoading),
  ]
}

export function useSignOut() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return useCallback(
    () => dispatch(signOut(navigate)),
    [dispatch, navigate]
  )
}
