import React from 'react'
import { connect } from 'react-redux'

import TableCellMultiple from './TableCellMultiple'

import { getRolePolicyNamesFromIds } from '../../selectors'

const UserRolesForTable = ({ roleNames }) => (
  <TableCellMultiple
    items={roleNames}
    title='User Roles'
  />
)

const state2props = (state, props) => ({
  roleNames: props.policyIds ? getRolePolicyNamesFromIds(state, props.policyIds) : [],
})

export default connect(state2props)(UserRolesForTable)
