import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ConfirmModal from './ConfirmModal'
import { Button } from '../controls'

import { accountSubscriptionCanceled } from '../../redux/account/actions'

function AccountSubscriptionCanceledModal() {

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onOk = async () => {
    setLoading(true)
    await dispatch(accountSubscriptionCanceled(navigate))
  }

  return (
    <ConfirmModal
      id={AccountSubscriptionCanceledModal.id}
      confirmLoading={loading}
      closable={false}
      maskClosable={false}
      footer={<Button type="primary" onClick={onOk}>Ok</Button>}
    >
      <h2>Account Subscription Canceled</h2>
      <p>
        An account administrator has cancelled the subscription for this account.
        Please press OK to return to the account selection screen.
      </p>
    </ConfirmModal>
  )
}
const AccountSubscriptionCanceledModalId = 'AccountSubscriptionCanceledModal'
AccountSubscriptionCanceledModal.id = AccountSubscriptionCanceledModalId

// export ID to be used in pubSubMiddleware upon receiving subscription cancelation action
export { AccountSubscriptionCanceledModalId }

export default AccountSubscriptionCanceledModal
