import { ReactElement } from 'react'
import { ROOT_DECISION_NODE_ID } from '@vms/vmspro3-core/dist/systemConsts'

import { Spin } from '../client/controls'
import { Page } from '../components'
import { DecisionFolderChildren } from '../components/Decision'

import { useQuerystringValue } from '../hooks/useQuerystringValue'
import {
  useDecisionFolder,
  useDecisionFolderBreadcrumbRoutes,
  useLoadDecisionFolder,
  useLoadDecisionFolderChildren,
} from '../redux/hooks/decisionHooks'

export function NewDecisionExplorerPageContent(): ReactElement {
  const [decisionFolderId] = useQuerystringValue('folder', ROOT_DECISION_NODE_ID)

  const decisionFolder = useDecisionFolder(decisionFolderId)
  const childrenLoading = useLoadDecisionFolderChildren(decisionFolderId)
  const breadcrumbRoutes = useDecisionFolderBreadcrumbRoutes(decisionFolderId)

  return (
    <Page loading={childrenLoading} title={decisionFolder.name} breadcrumbRoutes={breadcrumbRoutes}>
      <DecisionFolderChildren decisionFolderId={decisionFolderId} />
    </Page>
  )
}

export function DecisionFolderPage(): ReactElement {
  const [decisionFolderId] = useQuerystringValue('folder', ROOT_DECISION_NODE_ID)
  const loading = useLoadDecisionFolder(decisionFolderId)

  if(loading) return <Spin />

  return <NewDecisionExplorerPageContent />
}
