import { useCallback, ReactElement, LegacyRef } from 'react'
import { updateDecisionFolder } from '@vms/vmspro3-core/dist/actions/decision'

import FormModal from './FormModal'
import { Form, Input } from '../controls'

import { useAppDispatch } from '../../redux'
import { useModalData } from '../../redux/hooks/appHooks'
import { useDecisionFolder } from '../../redux/hooks/decisionHooks'

export const DecisionFolderEditModalId = 'DecisionFolderEditModal'
export type DecisionFolderEditModalData = {
  decisionFolderId: string,
}
export function DecisionFolderEditModal(): ReactElement {
  const { decisionFolderId } = useModalData<DecisionFolderEditModalData>(DecisionFolderEditModalId)

  const decisionFolder = useDecisionFolder(decisionFolderId)

  const dispatch = useAppDispatch()
  const onOk = useCallback(
    ({ name }: { name: string }) => dispatch(updateDecisionFolder(decisionFolderId, { name })),
    [dispatch, decisionFolderId]
  )

  return (
    <FormModal modalId={DecisionFolderEditModalId} onOk={onOk} initialValues={decisionFolder}>
      {(initialFocusRef: LegacyRef<Input>) => (
        <>
          <h3>Editing {decisionFolder.name}</h3>
          <Form.Item label="name" name="name">
            <Input ref={initialFocusRef} />
          </Form.Item>
        </>
      )}
    </FormModal>
  )
}
DecisionFolderEditModal.id = DecisionFolderEditModalId
