import { normalize, schema } from 'normalizr'
import { identifyRating } from '@vms/vmspro3-core/dist/utils/ratings'
import { identifyRatingNotes } from '@vms/vmspro3-core/dist/utils/ratingNotes'
import { ThunkAction } from 'redux-thunk'

import { LoadableDecision, LoadableDecisionFolder } from '../types'
import { RootState, AppDispatch } from './store'
import { selectAccountId } from './account/selectors'
import { getDecisionNode, getDecisionNodesByAncestry } from '../services/decisionService'
import {
  FetchDecisionEntityRequestAction,
  FetchDecisionEntitySuccessAction,
  FetchDecisionFolderChildrenRequestAction,
  FetchDecisionFolderChildrenSuccessAction,
  HideModalAction,
  SetMenuStateAction,
  ShowModalAction,
} from './actionTypes'
import { NavigateFunction } from 'react-router-dom'

const optionSchema = new schema.Entity('options', undefined, {})
const criterionSchema = new schema.Entity('criteria', undefined, {})
const participantSchema = new schema.Entity('participants', undefined, {})
const ratingSchema = new schema.Entity('ratings', undefined, {
  idAttribute: identifyRating,
})
const ratingNotesSchema = new schema.Entity('ratingNotes', undefined, {
  idAttribute: identifyRatingNotes,
})
const decisionSchema = new schema.Entity('decisions', {
  children: {
    options: [optionSchema],
    criteria: [criterionSchema],
    participants: [participantSchema],
    ratings: [ratingSchema],
    ratingNotes: [ratingNotesSchema],
  },
}, {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  processStrategy: (input: any): LoadableDecision => {
    const { options, criteria, participants, ratings, ratingNotes, ...data } = input
    return {
      status: 'Success',
      data,
      children: {
        options,
        criteria,
        participants,
        ratings,
        ratingNotes,
      },
    }
  },
})
const decisionFolderSchema = new schema.Entity('decisionFolders', undefined, {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  processStrategy: (data: any): LoadableDecisionFolder => ({
    status: 'Success',
    data,
  }),
})
const decisionEntitiesSchema = new schema.Array(
  {
    decisions: decisionSchema,
    decisionFolders: decisionFolderSchema,
  },
  entity => {
    switch(entity.entityType) {
      case 'Decision': {
        return 'decisions'
      }
      case 'DecisionFolder': {
        return 'decisionFolders'
      }
      default: {
        throw new Error(`entityType "${entity.entityType}" not recognized`)
      }
    }
  }
)

export function fetchDecisionEntity(
  decisionEntityId: string,
  entityType: 'Decision' | 'DecisionFolder',
): ThunkAction<void, RootState, unknown, FetchDecisionEntityRequestAction | FetchDecisionEntitySuccessAction> {
  return async (dispatch, getState) => {
    dispatch({
      type: 'FetchDecisionEntityRequest',
      meta: {
        decisionEntityId,
        entityType,
        ephemeral: true,
      },
    })

    const accountId = selectAccountId(getState())
    // includes ancestor decision folders and children of decision
    const decisionEntities = await getDecisionNode(accountId, decisionEntityId)
    const { entities } = normalize(decisionEntities, decisionEntitiesSchema)

    dispatch({
      type: 'FetchDecisionEntitySuccess',
      payload: entities as FetchDecisionEntitySuccessAction['payload'],
      meta: {
        decisionEntityId,
        ephemeral: true,
      },
    })
  }
}

export function fetchDecisionFolderChildren(
  // TODO: let's just do this by decision folder ID if we can, let the server handle the mapping
  ancestry: string
): ThunkAction<
  void,
  RootState,
  unknown,
  FetchDecisionFolderChildrenRequestAction | FetchDecisionFolderChildrenSuccessAction
> {
  return async (dispatch, getState) => {
    dispatch({
      type: 'FetchDecisionFolderChildrenRequest',
      meta: {
        ancestry,
        ephemeral: true,
      },
    })

    const accountId = selectAccountId(getState()) as string
    const nodes = await getDecisionNodesByAncestry(accountId, ancestry)
    const { entities } = normalize(nodes, decisionEntitiesSchema)

    dispatch({
      type: 'FetchDecisionFolderChildrenSuccess',
      // TODO: maybe consider validating incoming data?
      payload: entities as FetchDecisionFolderChildrenSuccessAction['payload'],
      meta: {
        ancestry,
        ephemeral: true,
      },
    })
  }
}

// TODO: navigation should be handled in an account state controller; when account
// state is reset/removed, user should be redirected using <Navigate to="/" />.
export function resetAccountState(navigate: NavigateFunction) {
  return (dispatch: AppDispatch) => {
    navigate('/')
    dispatch({
      type: 'ResetAccountState',
      meta: { ephemeral: true },
    })
  }
}

export function showModal(modalId: string, modalData: Record<string, unknown> = {}): ShowModalAction {
  return {
    type: 'ShowModal',
    payload: modalData,
    meta: { modalId, ephemeral: true },
  }
}

export function hideModal(modalId: string): HideModalAction {
  return {
    type: 'HideModal',
    meta: { modalId, ephemeral: true },
  }
}

export function setMenuState(menuOpen: boolean): SetMenuStateAction {
  return {
    type: 'SetMenuState',
    payload: { menuOpen },
    meta: { ephemeral: true },
  }
}
