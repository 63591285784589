/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Color } from '@vms/vmspro3-core/dist/systemConsts'

import { Avatar, Dropdown, Menu, Spin } from '../../client/controls'

import { UserPreferencesModalId } from '../../client/modals/UserPreferencesModal'
import { testId } from '../../test-automation'
import { useShowModal } from '../../redux/hooks/appHooks'
import { useAccountId, useUserAccounts } from '../../redux/account/hooks'
import { setAccountId, removeAccountId } from '../../redux/account/actions'
import { selectCurrentUserName } from '../../redux/user/selectors'

function useDropdownVisible() {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const setDropdownHidden = useCallback(() => setDropdownVisible(false), [])

  return {
    dropdownVisible,
    setDropdownHidden,
    handleVisibleChange: setDropdownVisible,
  }
}

function DropdownOverlay({ setDropdownHidden }) {
  const accountId = useAccountId()
  const [accounts, accountsLoading] = useUserAccounts()

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const onRemoveAccountId = () => dispatch(removeAccountId(navigate))

  const showUserPreferencesModal = useShowModal(UserPreferencesModalId)

  const logout = () => navigate('/logout')

  return (
    <Menu
      theme="dark"
      mode="inline"
      style={style.menu}
      selectedKeys={[accountId]}
      onClick={setDropdownHidden}
    >
      <Menu.SubMenu key="select-account" title="Select Account">
        {accountsLoading
          ? <Menu.Item key="loading" icon={<Spin />} disabled>Loading accounts...</Menu.Item>
          : accounts.map(account =>
            <Menu.Item
              key={account.id}
              onClick={() => account.id !== accountId && dispatch(setAccountId(account.id, navigate))}
            >
              {account.name}
            </Menu.Item>
          )
        }
        <Menu.Divider />
        <Menu.Item key="account-management" onClick={onRemoveAccountId}>Account Management</Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="preferences" onClick={() => showUserPreferencesModal()}>Preferences</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" onClick={logout}>Logout</Menu.Item>
    </Menu>
  )
}

export function UserMenu() {
  const userName = useSelector(selectCurrentUserName)

  const {
    dropdownVisible,
    handleVisibleChange,
    setDropdownHidden,
  } = useDropdownVisible()

  return (
    <Dropdown
      overlay={<DropdownOverlay setDropdownHidden={setDropdownHidden} />}
      trigger={['click']}
      visible={dropdownVisible}
      onVisibleChange={handleVisibleChange}
    >
      <div css={style.icon}>
        <Avatar size='small' icon={<UserOutlined />} style={style.avatar} />
        <span {...testId('headerUsername')} style={style.avatarName}>{userName}</span>
      </div>
    </Dropdown>
  )
}

const style = {
  avatar: {
    background: Color.BLUE_LIGHT,
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '16px',
  },
  avatarName: {
    fontSize: '14px',
    marginLeft: '8px',
  },
  icon: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'color 0.3s',
    ':hover': {
      color: Color.BLUE_LINK,
    },
  },
  menu: {
    width: '228px',
  },
}
