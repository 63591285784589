import { useMemo } from 'react'

import { SelectOption } from '../types'

export function useCycleSelectOptions({
  selectOptions,
  onChange,
  value,
}: {
  selectOptions: SelectOption[],
  onChange: (value: string) => void,
  value?: string,
}) {
  return useMemo(
    () => {
      const highestIndex = selectOptions.length - 1

      if(!value) {
        return {
          prevOption: () => onChange(selectOptions[highestIndex].value),
          nextOption: () => onChange(selectOptions[0].value),
        }
      }

      return {
        prevOption: () => {
          const currentIndex = selectOptions.findIndex(o => o.value === value)
          const prevIndex = currentIndex <= 0 ? highestIndex : currentIndex - 1
          onChange(selectOptions[prevIndex].value)
        },
        nextOption: () => {
          const currentIndex = selectOptions.findIndex(o => o.value === value)
          const nextIndex = currentIndex < highestIndex ? currentIndex + 1 : 0
          onChange(selectOptions[nextIndex].value)
        },
      }
    },
    [selectOptions, value, onChange]
  )
}
