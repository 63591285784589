import asSet from 'arraysetjs'
import { produce } from 'immer'

import usersActionTypes from './actionTypes'
import actions from '../../actions'
import authActionTypes from '../auth/actionTypes'
import { LoadingStatus } from '../../utils/appConsts'

const initialState = {
  byId: {},
  ids: [],
  loadingStatus: LoadingStatus.NotLoaded,
}

const usersReducer = produce((state, action) => {
  switch(action.type) {
    case authActionTypes.SignOut: {
      return initialState
    }
    case 'ResetAccountState': {
      return initialState
    }

    case usersActionTypes.FetchUsersRequest: {
      state.loadingStatus = LoadingStatus.Requested
      break
    }
    case usersActionTypes.FetchUsersSuccess: {
      state.loadingStatus = LoadingStatus.Loaded
      action.payload.forEach(user => {
        state.byId[user.id] = user
        asSet.mutable(state.ids).add(user.id)
      })
      break
    }

    case actions.user.disable.toString(): {
      state.byId[action.meta.userId].disabled = true
      break
    }
    case actions.user.enable.toString(): {
      state.byId[action.meta.userId].disabled = false
      break
    }
    case actions.user.addPolicy.toString(): {
      const { userId, policyId } = action.meta
      asSet.mutable(state.byId[userId].policyIds).add(policyId)
      break
    }
    case actions.user.removePolicy.toString(): {
      const { userId, policyId } = action.meta
      asSet.mutable(state.byId[userId].policyIds).remove(policyId)
      break
    }
    default:
      return state
  }
}, initialState)

export default usersReducer
