/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { Color, ROOT_ACCOUNT_ID } from '@vms/vmspro3-core/dist/systemConsts'
import { useLocation, useNavigate } from 'react-router-dom'
import { CSSObject } from '@emotion/react'

import { AppRoutes } from './Routes'
import { Header } from '../Header/Header'
import { Menu } from '../Menu'
import { Footer } from '../Footer'
import ErrorModal from '../../client/modals/ErrorModal'
import AssumedAuthorizationHeader from './AssumedAuthorizationHeader'
import VisibleModals from './VisibleModals'
import UnprocessedActionDispositionModal from '../../client/modals/UnprocessedActionDispositionModal'
import { Spin } from '../../client/controls'

import Server from '../../server/VMSProServerAdapter'
import pubSub from '../../services/pubSubService'
import { getQueueFromLocalStorage } from '../../redux/middleware/isomorphicReduxMiddleware'
import { LoadingStatus } from '../../utils/appConsts'
import { useAuthentication } from '../../redux/auth/hooks'
import { selectAuthLoadingStatus, selectAuthUserId } from '../../redux/auth/selectors'
import { showModal } from '../../redux/actions'
import { fetchAuth } from '../../redux/auth/actions'
import { fetchAccountUser, fetchCurrentUser } from '../../redux/user/actions'
import { fetchPolicies } from '../../redux/policies/actions'
import { fetchAccount, fetchUserAccounts } from '../../redux/account/actions'
import { useAccountId } from '../../redux/account/hooks'
import { useAppDispatch, useAppSelector } from '../../redux'
import { selectAccountLoadingStatus, selectUserAccountsLoadingStatus } from '../../redux/account/selectors'
import { selectAccountUserLoadingStatus, selectCurrentUserLoadingStatus } from '../../redux/user/selectors'
import { selectPoliciesLoadingStatus } from '../../redux/policies/selectors'

function registerErrorHandler(dispatch: ReturnType<typeof useAppDispatch>) {
  /**
   * Calls sentry with the error information and then dispatches the Sentry Id
   * and error to the Error Modal for user notification. Optional customMessage object for
   * custom display data.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorHandler = (error: any, customMessage?: Record<string, unknown>) => {
    // from AWS Amplify documentation: "Under the hood the API category utilizes Axios to execute the
    // HTTP requests. API status code response > 299 are thrown as an exception. If you need to handle
    // errors managed by your API, work with the error.response object."
    // https://aws-amplify.github.io/docs/js/api#using-the-api-client
    if(!customMessage && error.response.status === 403) {
      customMessage = {
        header: 'Not Authorized',
        body: error.response.data.message,
        details: null,
      }
    }

    // Session Expired error type will be common if anyone leaves a session open overnight.
    // Sentry is capturing the error with relevant data and we are displaying a modal to the user.
    // No need to add a console error for this as well.
    let sentryId = undefined
    // 'AMQJS0007E Socket Error:undefined.'
    // is an MQTT Socket error triggered when the pub/sub socket is destroyed.
    if(!(error.errorMessage && error.errorMessage.includes('AMQJS0007E'))) {
      console.error(`>>> server error`)
      console.error(error.response)

      if(error.response.data.sentToSentry) {
        // error already reported to Sentry from server
        sentryId = error.response.data.sentryEventId
      } else {
        sentryId = error
          ? Sentry.captureException(error)
          : Sentry.captureMessage('VMSProServer invoked error handler without an error object')
      }
    }

    dispatch(showModal(ErrorModal.id, { error, sentryId, customMessage }))
  }
  Server.registerErrorHandler(errorHandler)
  pubSub.registerErrorHandler(errorHandler)
}

/**
 * initial auth fetch, these fetches should only happen once when the app mounts
 */
function useInitApp() {
  const dispatch = useAppDispatch()

  useEffect(
    () => {
      registerErrorHandler(dispatch)
    },
    [dispatch]
  )

  const authLoadingStatus = useAppSelector(selectAuthLoadingStatus)
  useEffect(
    () => {
      if(authLoadingStatus === LoadingStatus.NotLoaded) {
        Server.auth.configureAPIHeaders({ accountId: ROOT_ACCOUNT_ID })
        dispatch(fetchAuth())
      }
    },
    [dispatch, authLoadingStatus]
  )
}

/**
 * Post-auth-fetch, fetch global state data. These should only fetch when userId changes
 */
function useInitAuthenticatedApp(): [boolean, boolean] {
  const dispatch = useAppDispatch()
  const [{ userId, email }, authLoading] = useAuthentication()

  const policiesLoadingStatus = useAppSelector(selectPoliciesLoadingStatus)
  useEffect(
    () => {
      if(userId && policiesLoadingStatus === LoadingStatus.NotLoaded) {
        Server.auth.configureAPIHeaders({ accountId: ROOT_ACCOUNT_ID, userId })
        dispatch(fetchPolicies())
      }
    },
    [dispatch, userId, policiesLoadingStatus]
  )

  const navigate = useNavigate()
  const userAccountsLoadingStatus = useAppSelector(selectUserAccountsLoadingStatus)
  useEffect(
    () => {
      if(userId && userAccountsLoadingStatus === LoadingStatus.NotLoaded) {
        dispatch(fetchUserAccounts(userId, navigate))
      }
    },
    [dispatch, userId, userAccountsLoadingStatus, navigate]
  )

  const currentUserLoadingStatus = useAppSelector(selectCurrentUserLoadingStatus)
  useEffect(
    () => {
      if(userId && currentUserLoadingStatus === LoadingStatus.NotLoaded) {
        dispatch(fetchCurrentUser(userId))
      }
    },
    [dispatch, userId, currentUserLoadingStatus]
  )

  useEffect(
    () => {
      Sentry.configureScope(scope => scope.setUser({
        id: userId,
        email,
        sessionStarted: Date.now(),
      }))
    },
    [dispatch, userId, email]
  )

  return [Boolean(userId), authLoading]
}

/**
 * If user is authenticated and accountId is set, initializes account configuration
 * for API headers, configures pub-sub, and fetches account data.
 */
function useInitAccountConfig() {
  const [pubSubAccountId, setPubSubAccountId] = useState(null)
  const accountId = useAccountId()
  const userId = useAppSelector(selectAuthUserId)
  const dispatch = useAppDispatch()

  useEffect(
    () => {
      if(accountId && userId) {
        Server.auth.configureAPIHeaders({ accountId, userId })
          // PubSub can only be configured after account headers are configured
          .then(pubSub.configure)
          .then(() => {
            setPubSubAccountId(accountId)
            // check for any actions that might have been pending when the application was last closed & give
            // the user an opportunity to recover them
            const queuedActions = getQueueFromLocalStorage() || []
            if(queuedActions.length) {
              dispatch(showModal(UnprocessedActionDispositionModal.id, { queuedActions }))
            }
          })
      }
    },
    [dispatch, accountId, userId]
  )

  // this is a consequence of our current pub-sub architecture where we have to
  // authorize the current user to subscribe to account topics -- only then is
  // it safe to dispatch fetch actions that generate subscriptions to those
  // topics. during account switching this value is still set, so comparing
  // the pubsub configuration value with the accountId in state is the only
  // way to prevent hooks from fetching and then getting rejected at the time
  // of subscription.
  const isPubSubConfigured = accountId === pubSubAccountId
  const accountLoadingStatus = useAppSelector(selectAccountLoadingStatus)
  useEffect(
    () => {
      if(userId && accountId && isPubSubConfigured && accountLoadingStatus === LoadingStatus.NotLoaded) {
        dispatch(fetchAccount(accountId, userId))
      }
    },
    [dispatch, userId, accountId, isPubSubConfigured, accountLoadingStatus]
  )

  const accountUserLoadingStatus = useAppSelector(selectAccountUserLoadingStatus)
  useEffect(
    () => {
      if(accountId && userId && isPubSubConfigured && accountUserLoadingStatus === LoadingStatus.NotLoaded) {
        dispatch(fetchAccountUser(accountId, userId))
      }
    },
    [dispatch, accountId, userId, isPubSubConfigured, accountUserLoadingStatus]
  )

  return [accountId && !isPubSubConfigured]
}

export function App() {
  useInitApp()
  const [isAuthenticated, authenticationLoading] = useInitAuthenticatedApp()
  const [accountConfigLoading] = useInitAccountConfig()

  const location = useLocation()

  if(authenticationLoading) return <Spin />

  return (
    <div css={style.container}>
      <AssumedAuthorizationHeader />
      <VisibleModals />
      <Header />
      <div css={style.content} id="content">
        {isAuthenticated && <Menu />}
        <main css={style.main}>
          {accountConfigLoading ? <Spin /> : <AppRoutes />}
          {!isAuthenticated && location.pathname === '/' && (
            <Footer />
          )}
        </main>
      </div>
    </div>
  )
}

const style: Record<string, CSSObject> = {
  container: {
    minHeight: [
      '100vh',
      'stretch',
    ],
    height: '100%',
    maxWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    backgroundColor: Color.GRAY_BLUE_LIGHTER,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    minHeight: '100%',
  },
}
