import { useCallback, useEffect, useMemo } from 'react'
import { createSelector } from 'reselect'
import _isMatch from 'lodash/isMatch'
import _matches from 'lodash/matches'
import _pickBy from 'lodash/pickBy'
import _sumBy from 'lodash/sumBy'

import {
  Decision,
  DecisionFolder,
  Option,
  Participant,
  ParticipationSession,
  ParticipationSessionType,
  Ratable,
  Rating,
  RatingNotes,
} from '@vms/vmspro3-core/dist/types'
import { joinAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { TreeNode, createTree, traverse, getInternalNodes } from '@vms/vmspro3-core/dist/utils/createTree'
import { CriterionData } from '@vms/vmspro3-core/dist/nextgen/Criterion'

import { SelectOption } from '../../types'
import { useCurrentUser } from '../user/hooks'
import { RootState, useAppDispatch, useAppSelector } from '../store'
import { fetchDecisionEntity, fetchDecisionFolderChildren } from '../actions'
import {
  getSelectCriteria,
  getSelectCriteriaIds,
  getSelectDecision,
  getSelectDecisionAncestorFolders,
  getSelectDecisionAncestry,
  getSelectDecisionFolder,
  getSelectDecisionFolderAncestorFolders,
  getSelectDecisionFolderAncestry,
  getSelectDecisionFolderChildrenCount,
  getSelectDescendantCriteria,
  getSelectLeafCriteria,
  getSelectLeafCriteriaSelectOptions,
  getSelectLeafCriterionLabel,
  getSelectOptionIds,
  getSelectOptionSelectOptions,
  getSelectOptionsCount,
  getSelectParticipantIds,
  getSelectParticipationSession,
  getSelectParticipationSessionId,
  getSelectRatingNotesIds,
  getSelectRatings,
  RatingFilterKeys,
} from '../selectors/decisionSelectors'

export function useLoadDecisionFolder(decisionFolderId: string): boolean {
  const status = useAppSelector(state => state.decisionFolders[decisionFolderId]?.status ?? 'Idle')

  const dispatch = useAppDispatch()
  useEffect(
    () => {
      if(decisionFolderId && status === 'Idle') {
        dispatch(fetchDecisionEntity(decisionFolderId, 'DecisionFolder'))
      }
    },
    [dispatch, decisionFolderId, status]
  )

  const loading = status !== 'Success'
  return loading
}
export function useLoadDecision(decisionId: string): boolean {
  const status = useAppSelector(state => state.decisions[decisionId]?.status ?? 'Idle')

  const dispatch = useAppDispatch()
  useEffect(
    () => {
      if(decisionId && status === 'Idle') {
        dispatch(fetchDecisionEntity(decisionId, 'Decision'))
      }
    },
    [dispatch, decisionId, status]
  )

  const loading = status !== 'Success'
  return loading
}
export function useLoadDecisionFolderChildren(decisionFolderId: string) {
  const loadableDecisionFolder = useAppSelector(
    useCallback(
      (state: RootState) => {
        const loadableDecisionFolder = state.decisionFolders[decisionFolderId]
        if(loadableDecisionFolder?.status === 'Success') {
          return loadableDecisionFolder
        }
      },
      [decisionFolderId]
    )
  )

  const ancestry = loadableDecisionFolder?.data.ancestry
  const childAncestry = ancestry ? joinAncestry(ancestry, decisionFolderId) : undefined

  const dispatch = useAppDispatch()
  const loadChildren = loadableDecisionFolder ? !loadableDecisionFolder.children : false
  useEffect(
    () => {
      if(loadChildren && childAncestry) {
        dispatch(fetchDecisionFolderChildren(childAncestry))
      }
    },
    [loadChildren, dispatch, childAncestry]
  )

  return loadChildren
}
export function useDecisionFolderHasChildren(decisionFolderId: string) {
  const decisionFolderChildrenCount = useAppSelector(
    useMemo(
      () => getSelectDecisionFolderChildrenCount(decisionFolderId),
      [decisionFolderId]
    )
  )

  return typeof decisionFolderChildrenCount === 'number' && decisionFolderChildrenCount > 0
}

export function useDecisionFolder(decisionFolderId: string, allowUndefined?: false): DecisionFolder
export function useDecisionFolder(decisionFolderId: string, allowUndefined: true): DecisionFolder | undefined
export function useDecisionFolder(decisionFolderId: string, allowUndefined = false): DecisionFolder | undefined {
  const decisionFolder = useAppSelector(
    useCallback(
      (state: RootState) => {
        const loadableDecisionFolder = state.decisionFolders[decisionFolderId]
        if(loadableDecisionFolder?.status === 'Success') {
          return loadableDecisionFolder.data
        }
      },
      [decisionFolderId]
    )
  )

  if(!allowUndefined && !decisionFolder) {
    throw new Error('useDecisionFolder can only be rendered after decision folder is loaded')
  }
  return decisionFolder
}
export function useDecision(decisionId: string, allowUndefined?: false): Decision
export function useDecision(decisionId: string, allowUndefined: true): Decision | undefined
export function useDecision(decisionId: string, allowUndefined = false): Decision | undefined {
  const decision = useAppSelector(
    useCallback(
      (state: RootState) => {
        const loadableDecision = state.decisions[decisionId]
        if(loadableDecision?.status === 'Success') {
          return loadableDecision.data
        }
      },
      [decisionId]
    )
  )

  if(!allowUndefined && !decision) {
    throw new Error('useDecision can only be rendered after decision is loaded')
  }
  return decision
}

export function useDecisionFolderAncestry(decisionFolderId: string): string {
  return useAppSelector(
    useMemo(
      () => getSelectDecisionFolderAncestry(decisionFolderId),
      [decisionFolderId]
    )
  )
}
export function useDecisionAncestry(decisionId: string): string {
  return useAppSelector(
    useMemo(
      () => getSelectDecisionAncestry(decisionId),
      [decisionId]
    )
  )
}

export function useDecisionFolderChildAncestry(decisionFolderId: string): string {
  const decisionFolderAncestry = useDecisionFolderAncestry(decisionFolderId)
  return joinAncestry(decisionFolderAncestry, decisionFolderId)
}
export function useDecisionChildAncestry(decisionId: string): string {
  const decisionAncestry = useDecisionAncestry(decisionId)
  return joinAncestry(decisionAncestry, decisionId)
}

type BreadcrumbRoute = { path: string, breadcrumbName: string }
export function useDecisionFolderBreadcrumbRoutes(
  decisionFolderId: string,
  appendRoute?: BreadcrumbRoute
): BreadcrumbRoute[] {
  const selectDecisionFolderBreadcrumbRoutes = useMemo(
    () => createSelector(
      getSelectDecisionFolderAncestorFolders(decisionFolderId),
      getSelectDecisionFolder(decisionFolderId),
      (ancestorDecisionFolders, decisionFolder) => {
        const breadcrumbRoutes: BreadcrumbRoute[] = [
          { path: '/', breadcrumbName: 'Home' },
          ...ancestorDecisionFolders
            .concat(decisionFolder)
            .map(decisionFolder => ({
              path: `/decision?folder=${decisionFolder.id}`,
              breadcrumbName: decisionFolder.name,
            })),
        ]

        if(appendRoute) {
          breadcrumbRoutes.push(appendRoute)
        }

        return breadcrumbRoutes
      }
    ),
    [decisionFolderId, appendRoute]
  )

  return useAppSelector(selectDecisionFolderBreadcrumbRoutes)
}
export function useDecisionBreadcrumbRoutes(
  decisionId: string,
  appendRoute?: BreadcrumbRoute
): BreadcrumbRoute[] {
  const selectDecisionBreadcrumbRoutes = useMemo(
    () => createSelector(
      getSelectDecisionAncestorFolders(decisionId),
      getSelectDecision(decisionId),
      (ancestorDecisionFolders, decision) => {
        const breadcrumbRoutes: BreadcrumbRoute[] = [
          { path: '/', breadcrumbName: 'Home' },
          ...ancestorDecisionFolders.map(decisionFolder => ({
            path: `/decision?folder=${decisionFolder.id}`,
            breadcrumbName: decisionFolder.name,
          })),
          { path: `/decision/${decisionId}`, breadcrumbName: decision.name },
        ]

        if(appendRoute) {
          breadcrumbRoutes.push(appendRoute)
        }

        return breadcrumbRoutes
      }
    ),
    [decisionId, appendRoute]
  )

  return useAppSelector(selectDecisionBreadcrumbRoutes)
}

export function useParticipationSession(
  decisionId: string,
  participationSessionType: ParticipationSessionType
): ParticipationSession {
  return useAppSelector(
    useMemo(
      () => getSelectParticipationSession(decisionId, participationSessionType),
      [decisionId, participationSessionType]
    )
  )
}
export function useParticipationSessionId(
  decisionId: string,
  participationSessionType: ParticipationSessionType,
): string {
  return useAppSelector(
    useMemo(
      () => getSelectParticipationSessionId(decisionId, participationSessionType),
      [decisionId, participationSessionType]
    )
  )
}

type RatingFilters = {
  participationSessionId?: string,
  participantId?: string,
  subjectId?: string,
  subjectType?: Rating['subjectType'],
}
function useRatingsFilter<T extends RatingFilters>({
  participationSessionId,
  participantId,
  subjectId,
  subjectType,
}: RatingFilters = {}): ((arg: T) => boolean) {
  return useCallback(
    (arg: T) => {
      const filters = _pickBy({
        participationSessionId,
        participantId,
        subjectId,
        subjectType,
      }, value => typeof value === 'string')

      return _isMatch(arg, filters)
    },
    [participationSessionId, participantId, subjectId, subjectType]
  )
}

/**
 * Use decision ratings by participation session type. Allows filtering on participantId,
 * contextId, and subjectId.
 */
export function useRatings<K extends RatingFilterKeys>(
  decisionId: string,
  participationSessionType: ParticipationSessionType,
  filterBy?: Pick<Rating, K>
): Rating[] {
  const selectValidatedAndFilteredRatings = useMemo(
    () => getSelectRatings<K>(decisionId, participationSessionType, filterBy),
    [decisionId, participationSessionType, filterBy]
  )

  return useAppSelector(selectValidatedAndFilteredRatings)
}

/**
 * Use decision rating notes, allowing filtering by participation session, participant,
 * and subject type.
 */
export function useRatingNotes(
  decisionId: string,
  filters?: RatingFilters
): RatingNotes[] {
  const ratingNotesFilter = useRatingsFilter(filters)
  const selectFilteredRatingNotes = useMemo(
    () => createSelector(
      getSelectRatingNotesIds(decisionId),
      (state: RootState) => state.ratingNotes.byId,
      (ids, byId) => {
        const ratingNotes = ids?.map(id => byId[id]) ?? []
        return ratingNotes.filter(ratingNotesFilter)
      }
    ),
    [decisionId, ratingNotesFilter]
  )

  return useAppSelector(selectFilteredRatingNotes)
}

function useSelectDecisionCriteria(decisionId: string): (state: RootState) => CriterionData[] {
  return useMemo(
    () => createSelector(
      getSelectCriteriaIds(decisionId),
      state => state.criteria.byId,
      (ids: string[] | undefined, byId) => ids?.map(id => byId[id]) ?? []
    ),
    [decisionId]
  )
}

export function useCriteria(decisionId: string): CriterionData[] {
  const selectDecisionCriteria = useSelectDecisionCriteria(decisionId)

  return useAppSelector(selectDecisionCriteria)
}
export function useCriterion(criterionId?: string): CriterionData | undefined {
  return useAppSelector(state => criterionId ? state.criteria.byId[criterionId] : undefined)
}

export function useRootPerfCriterion(decisionId: string): CriterionData {
  const selectDecisionCriteria = useSelectDecisionCriteria(decisionId)

  const selectPerformanceCriterion = useMemo(
    () => createSelector(
      selectDecisionCriteria,
      criteria => criteria.find(c => c.type === 'Performance')
    ),
    [selectDecisionCriteria]
  )
  const rootPerfCriterion = useAppSelector(selectPerformanceCriterion)

  if(!rootPerfCriterion) {
    throw new Error(`Root performance criterion not found in decision ${decisionId}`)
  }

  return rootPerfCriterion
}

export function useRootPerfCriterionId(
  decisionId: string
): string {
  const rootPerfCriterion = useRootPerfCriterion(decisionId)
  return rootPerfCriterion.id
}

/**
 * @deprecated use Criteria to generate criterion instances with tree linking
 */
function useCriteriaTree(
  decisionId: string,
  rootCriterionId?: string
): TreeNode<CriterionData> {
  const criteria = useCriteria(decisionId)

  const criteriaTree = useMemo(
    () => {
      const criteriaTreeData = createTree(criteria)

      if(rootCriterionId) {
        return criteriaTreeData.byId[rootCriterionId]
      }
      return criteriaTreeData.all.find(c => c.parent === null) as TreeNode<CriterionData>
    },
    [criteria, rootCriterionId]
  )

  return criteriaTree
}

export function usePerformanceCriteriaTreeData(decisionId: string): TreeNode<CriterionData> | undefined {
  return useAppSelector(useMemo(
    () => createSelector<
      RootState,
      Array<string> | undefined,
      Record<string, CriterionData>,
      TreeNode<CriterionData> | undefined
    >(
      getSelectCriteriaIds(decisionId),
      state => state.criteria.byId,
      (criteriaIds, criteriaById) => {
        const criteria = criteriaIds?.map(criterionId => criteriaById[criterionId])
        const criteriaTreeData = criteria ? createTree(criteria) : undefined
        const performanceTreeData = criteriaTreeData?.all.find(c => c.data.type === 'Performance')

        return performanceTreeData
      }
    ),
    [decisionId]
  ))
}

export function useChildCriteria(
  decisionId: string,
  contextCriterionId: string
): CriterionData[] {
  const selectDecisionCriteria = useSelectDecisionCriteria(decisionId)

  const selectChildCriteria = useMemo(
    () => createSelector(
      selectDecisionCriteria,
      criteria => criteria.filter(c => c.parentId === contextCriterionId)
    ),
    [selectDecisionCriteria, contextCriterionId]
  )

  return useAppSelector(selectChildCriteria)
}

export function useRatableChildCriteria(
  decisionId: string,
  participantId: string,
  contextCriterionId: string,
): Ratable<CriterionData>[] {
  const childCriteria = useChildCriteria(decisionId, contextCriterionId)
  const ratings = useRatings<'participantId'>(decisionId, 'CriteriaPrioritization', { participantId })

  const ratableChildCriteria = useMemo<Ratable<CriterionData>[]>(
    () => {
      if(!participantId) return []
      return childCriteria
        .map(c => {
          const rating = (participantId && contextCriterionId)
            ? (
              ratings.find(_matches<Pick<Rating, 'participantId' | 'contextId' | 'subjectId'>>({
                participantId,
                contextId: contextCriterionId,
                subjectId: c.id,
              }))
            )
            : undefined

          return {
            ...c,
            ratingVector: rating?.ratingVector ?? null,
            abstain: rating?.abstain,
          }
        })
    },
    [childCriteria, ratings, contextCriterionId, participantId]
  )

  return ratableChildCriteria
}
export function useChildCriteriaWithLocalPriority(
  decisionId: string,
  contextCriterionId: string,
) {
  return useAppSelector(
    useMemo(
      () => createSelector(
        getSelectCriteria<'parentId'>(decisionId, { parentId: contextCriterionId }),
        getSelectRatings<'contextId'>(decisionId, 'CriteriaPrioritization', { contextId: contextCriterionId }),
        (criteria, ratings = []) => {
          const localPrioritySum = _sumBy(ratings, 'ratingVector.0')
          return criteria?.map(criterion => {
            const groupRatings = ratings.filter(_matches({ subjectId: criterion.id }))
            const avgRatingSum = groupRatings.length > 0 ? _sumBy(groupRatings, 'ratingVector.0') : null
            return {
              ...criterion,
              priority: typeof avgRatingSum === 'number' ? (avgRatingSum / localPrioritySum) : null,
            }
          })
        }
      ),
      [decisionId, contextCriterionId]
    )
  )
}

export function useContextCriteria(
  decisionId: string,
  rootCriterionId?: string
): CriterionData[] {
  const criteriaTreeData = useCriteriaTree(decisionId, rootCriterionId)

  return useMemo(
    () => getInternalNodes(criteriaTreeData).map(n => n.data),
    [criteriaTreeData]
  )
}

export function useContextCriteriaSelectOptions(
  decisionId: string,
  rootCriterionId?: string,
): SelectOption[] {
  const criteriaTreeData = useCriteriaTree(decisionId, rootCriterionId)

  const selectOptions = useMemo(
    () => {
      const contextCriteria: SelectOption[] = []
      traverse(criteriaTreeData, (node, path) => {
        if(node.children.length > 0) {
          // root criterion and direct children of root criterion should only have own name in label,
          // indirect descendants of root should show path in label. if root criterion children are
          // not in path, no prefix will be added to the label.
          const rootPathNames = path.slice(1).map(p => p.data.name)
          const label = [...rootPathNames, node.data.name].join(': ')

          contextCriteria.push({
            value: node.data.id,
            label,
          })
        }
      })
      return contextCriteria
    },
    [criteriaTreeData]
  )

  return selectOptions
}

export function useLeafCriteria(
  decisionId: string,
  rootCriterionId?: string,
): CriterionData[] {
  return useAppSelector(
    useMemo(
      () => getSelectLeafCriteria(decisionId, rootCriterionId),
      [decisionId, rootCriterionId]
    )
  )
}
export function useLeafCriterionLabel(
  decisionId: string,
  criterionId: string,
  rootCriterionId?: string,
): string | undefined {
  return useAppSelector(
    useMemo(
      () => getSelectLeafCriterionLabel(decisionId, criterionId, rootCriterionId),
      [decisionId, criterionId, rootCriterionId]
    )
  )
}
export function useLeafCriteriaSelectOptions(
  decisionId: string,
  rootCriterionId?: string,
): SelectOption[] {
  return useAppSelector(
    useMemo(
      () => getSelectLeafCriteriaSelectOptions(decisionId, rootCriterionId),
      [decisionId, rootCriterionId]
    )
  )
}

export function useDescendantCriteria(
  decisionId: string,
  rootCriterionId?: string,
): CriterionData[] {
  return useAppSelector(
    useMemo(
      () => getSelectDescendantCriteria(decisionId, rootCriterionId),
      [decisionId, rootCriterionId]
    )
  )
}

export function useOptions(decisionId: string): Option[] {
  const selectOptions = useMemo(
    () => createSelector(
      getSelectOptionIds(decisionId),
      (state: RootState) => state.options.byId,
      (ids: string[] | undefined, byId) => ids?.map(id => byId[id]) ?? []
    ),
    [decisionId]
  )

  return useAppSelector(selectOptions)
}

export function useOptionsCount(decisionId: string): number {
  return useAppSelector(
    useMemo(
      () => getSelectOptionsCount(decisionId),
      [decisionId]
    )
  )
}

export function useOption(optionId: string): Option | undefined {
  return useAppSelector(state => state.options.byId[optionId])
}

export function useOptionSelectOptions(decisionId: string): SelectOption[] {
  return useAppSelector(
    useMemo(
      () => getSelectOptionSelectOptions(decisionId),
      [decisionId]
    )
  )
}

export function useRatableOptions(
  decisionId: string,
  participantId: string,
  criterionId: string,
): Ratable<Option>[] {
  const options = useOptions(decisionId)
  const ratings = useRatings<'participantId'>(decisionId, 'OptionRating', { participantId })

  const ratableOptions = useMemo(
    () => options
      .map(o => {
        const rating = (participantId && criterionId)
          ? (
            ratings.find(_matches<
              Pick<Rating, 'participantId' | 'contextId' | 'subjectId'>
            >({
              participantId,
              contextId: criterionId,
              subjectId: o.id,
            }))
          )
          : undefined

        return {
          ...o,
          ratingVector: rating?.ratingVector ?? null,
          abstain: rating?.abstain,
        }
      }),
    [options, ratings, participantId, criterionId]
  )

  return ratableOptions
}

export function useParticipants(decisionId: string): Participant[] {
  const selectParticipants = useMemo(
    () => createSelector(
      getSelectParticipantIds(decisionId),
      (state: RootState) => state.participants.byId,
      (ids: string[] | undefined, byId) => ids?.map(id => byId[id]) ?? []
    ),
    [decisionId]
  )

  return useAppSelector(selectParticipants)
}

export function useParticipant(participantId: string): Participant | undefined {
  const participant = useAppSelector(state => state.participants.byId[participantId])

  return participant
}

/**
 * Returns the decision participant record for the current authenticated user
 * or undefined if a participant record does not exist.
 */
export function useAuthUserParticipant(decisionId: string): Participant | undefined {
  const [user] = useCurrentUser()

  const decisionParticipants = useParticipants(decisionId)
  const participant = decisionParticipants.find(p => p.userId === user?.id)

  return participant
}

export function useAuthUserParticipantId(decisionId: string): string | undefined {
  const [user] = useCurrentUser()

  const decisionParticipants = useParticipants(decisionId)
  const participantId = decisionParticipants.find(p => p.userId === user?.id)?.id

  return participantId
}
