import * as React from 'react'

import { store, Store, Provider } from '../redux'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cypress?: any,
    store?: Store,
  }
}
if(window.Cypress) {
  window.store = store
}

type ReduxProviderProps = {
  children: React.ReactNode,
}

export function ReduxProvider({ children }: ReduxProviderProps): JSX.Element {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}
