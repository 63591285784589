import React from 'react'

import { Spin } from '../../../client/controls'

import { useCurrentUser } from '../../../redux/user/hooks'

/**
 * @prop {string} assumedUserId
 */
function ImpersonatingUser() {
  const [assumedUser, loading] = useCurrentUser()

  if(loading) return <Spin />

  return (
    <h3 style={style.message}>
      You are impersonating user &quot;{assumedUser.fullName || assumedUser.email}&quot; (id: {assumedUser.id})!
    </h3>
  )
}

const style = {
  message: {
    margin: '0',
  },
}

export default ImpersonatingUser
