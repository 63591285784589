import { useCallback, ReactElement } from 'react'
import { deleteDecisionFolder } from '@vms/vmspro3-core/dist/actions/decision'

import FormModal from './FormModal'
import { Spin } from '../controls'

import { useModalData } from '../../redux/hooks/appHooks'
import { useAppDispatch } from '../../redux'
import {
  useDecisionFolder,
  useDecisionFolderHasChildren,
  useLoadDecisionFolderChildren,
} from '../../redux/hooks/decisionHooks'

export const DecisionFolderDeleteModalId = 'DecisionFolderDeleteModal'
export type DecisionFolderDeleteModalData = {
  decisionFolderId: string,
}

export function DecisionFolderDeleteModal(): ReactElement {
  const { decisionFolderId } = useModalData<DecisionFolderDeleteModalData>(DecisionFolderDeleteModalId)

  const decisionFolder = useDecisionFolder(decisionFolderId, true)
  const childrenLoading = useLoadDecisionFolderChildren(decisionFolderId)
  const decisionFolderHasChildren = useDecisionFolderHasChildren(decisionFolderId)

  const dispatch = useAppDispatch()
  const onOk = useCallback(
    () => {
      if(decisionFolderHasChildren) return
      dispatch(deleteDecisionFolder(decisionFolderId))
    },
    [decisionFolderHasChildren, dispatch, decisionFolderId]
  )

  return (
    <FormModal
      modalId={DecisionFolderDeleteModalId}
      onOk={onOk}
      okButtonProps={{
        disabled: childrenLoading || decisionFolderHasChildren,
      }}
    >
      {decisionFolder && !childrenLoading
        ? (
          <>
            <h3>Deleting {decisionFolder.name}</h3>
            <p>
              {decisionFolderHasChildren
                ? 'This folder has children and cannot be deleted. You must delete the contents ' +
                  'of this folder before you can delete it.'
                : `Are you sure you want to delete ${decisionFolder.name}?`
              }
            </p>
          </>
        )
        : <Spin />
      }
    </FormModal>
  )
}
DecisionFolderDeleteModal.id = DecisionFolderDeleteModalId
