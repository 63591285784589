import React from 'react'

import { Divider, Menu } from '../../controls'

const PageMenu = ({
  menuItems,
  menuTitle,
  onSelectKey,
  selectedKey,
}) => {
  const onClick = ({ key }) => onSelectKey(key)

  return (
    <div>
      {menuTitle &&
        <>
          <span style={style.title}>{menuTitle}</span>
          <Divider style={style.divider} />
        </>
      }
      <Menu
        onClick={onClick}
        selectedKeys={[selectedKey]}
        style={style.menu}
      >
        {menuItems.map(({ key, title }) => <Menu.Item key={key}>{title}</Menu.Item>)}
      </Menu>
    </div>
  )
}

const style = {
  divider: {
    margin: '8px 0 0',
  },
  menu: {
    background: 'transparent',
    border: 'none',
    marginBottom: '30px',
  },
  title: {
    fontSize: '20px',
    fontWeight: '400',
  },
}

export default PageMenu
