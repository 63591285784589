import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import FormModal from './FormModal'
import { Form, Input } from '../controls'

import { cancelSubscription } from '../../redux/account/actions'
import { selectAccountId } from '../../redux/account/selectors'

/**
 * Modal to confirm subscription cancelation.  We use the terminology "end" instead of "cancel"
 * to avoid confusion with the common "Cancel" button (cancelliing a cancellation is confusing!)
 */
const EndSubscriptionConfirmModal = () => {

  const randNumRef = React.useRef(Math.random().toFixed(4).substring(2, 6))
  const randNum = randNumRef.current

  const dispatch = useDispatch()
  const accountId = useSelector(selectAccountId)

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate
  const onOk = async ({ reason }) => {
    setLoading(true)
    await dispatch(cancelSubscription(accountId, reason, navigate))
  }

  return (
    <FormModal
      confirmLoading={loading}
      modalId={EndSubscriptionConfirmModal.id}
      onOk={onOk}
    >
      {initialFocus => (
        <>
          <h3>End Subscription</h3>
          <p>Are you sure you want to end this subscription? Once you do, all users
          currently logged into the account will be logged out immediately. <b>None
          of the users in this organization, including yourself, will have access to
          this VMSPro® account, and you will no longer have access to your data</b>.
          This action is permanent and cannot be undone.</p>
          <Form.Item name="reason" label="Please let us know why you're leaving (optional):">
            <Input.TextArea disabled={loading} ref={initialFocus} />
          </Form.Item>
          <Form.Item
            name="confirmation"
            label={`Please type ${randNum} to confirm:`}
            rules={[
              {
                required: true,
                message: 'Please type the confirmation code',
                validateTrigger: 'onChange',
              },
              {
                pattern: new RegExp(`^${randNum}$`),
                message: 'Confirmation code does not match',
                validateTrigger: 'onBlur',
              },
            ]}
          >
            <Input disabled={loading} />
          </Form.Item>
        </>
      )}
    </FormModal>
  )
}
EndSubscriptionConfirmModal.id = 'EndSubscriptionConfirmModal'

export default EndSubscriptionConfirmModal
