import { gql } from '@apollo/client'

const TimestampAndLocationFieldsFragment = gql`
  fragment TimestampAndLocationFieldsFragment on TimestampAndLocation {
    timestamp
    location
  }
`
const CostFieldsFragment = gql`
  fragment CostFields on Cost {
    base
    value
    unit
    updated
  }
`
const DurationFieldsFragment = gql`
  fragment DurationFields on Duration {
    base
    value
    unit
  }
`
const HtmlFieldsFragment = gql`
  fragment HtmlFields on Html {
    value
  }
`
const RatingScaleConfigFragment = gql`
  fragment RatingScaleConfigFields on RatingScaleConfig {
    maxRating
    maxRatingLabel
    minRating
    minRatingLabel
    abstainLabel
    ratingScale {
      label
      maxValue
    }
  }
`

export const DecisionFolderFieldsFragment = gql`
  fragment DecisionFolderFields on DecisionFolder {
    id
    ancestry
    name
  }
`
export const DecisionFieldsFragment = gql`
  fragment DecisionFields on Decision {
    id
    ancestry
    name
    description { ...HtmlFields }
    objective { ...HtmlFields }
    valueFunctionJson
    ratingsToPrioritizationAlgorithm
    baselineOptionId
  }
  ${HtmlFieldsFragment}
`
export const CriterionFieldsFragment = gql`
  fragment CriterionFields on Criterion {
    id
    name
    description { ...HtmlFields }
    abbrev
    parentId
    type
    color
    optionRatingScaleConfig { ...RatingScaleConfigFields }
  }
  ${HtmlFieldsFragment}
  ${RatingScaleConfigFragment}
`
export const OptionFieldsFragment = gql`
  fragment OptionFields on Option {
    id
    name
    abbrev
    color
    commonId
    description { ...HtmlFields }
    cost { ...CostFields }
    time { ...DurationFields }
  }
  ${CostFieldsFragment}
  ${DurationFieldsFragment}
  ${HtmlFieldsFragment}
`
export const ParticipantFieldsFragment = gql`
  fragment ParticipantFields on Participant {
    id
    userId
    trackingId
    fullName
    email
    phone
    created { ...TimestampAndLocationFieldsFragment }
    updated { ...TimestampAndLocationFieldsFragment }
  }
  ${TimestampAndLocationFieldsFragment}
`
export const ParticipationSessionFieldsFragment = gql`
  fragment ParticipationSessionFields on ParticipationSession {
    id
    name
    type
    status
    method
  }
`
export const RatingFieldsFragment = gql`
  fragment RatingFields on Rating {
    participationSessionId
    participantId
    contextType
    contextId
    subjectType
    subjectId
    ratingVector
    abstain
  }
`
export const RatingNotesFieldsFragment = gql`
  fragment RatingNotesFields on RatingNotes {
    participationSessionId
    participantId
    contextType
    contextId
    subjectType
    notes { ...HtmlFields }
  }
  ${HtmlFieldsFragment}
`
export const CompleteDecisionFragment = gql`
  fragment CompleteDecision on Decision {
    ...DecisionFields
    criteria { ...CriterionFields }
    options { ...OptionFields }
    participants { ...ParticipantFields }
    participationSessions { ...ParticipationSessionFields }
    ratings { ...RatingFields }
    ratingNotes { ...RatingNotesFields }
  }
  ${DecisionFieldsFragment}
  ${CriterionFieldsFragment}
  ${OptionFieldsFragment}
  ${ParticipantFieldsFragment}
  ${ParticipationSessionFieldsFragment}
  ${RatingFieldsFragment}
  ${RatingNotesFieldsFragment}
`
