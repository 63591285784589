import React from 'react'
import { MinusCircleFilled } from '@ant-design/icons'

import { LinkButton2, Select, Table } from '../controls'

/**
 * A simple table to display the users and their respective policies with respect to a given Resource ID
 *
 * @param {Object} props
 *  @param {Object} authzOptions - Select options for permission level
 *  @param {Object} dataRows - rows for the table
 *  @param {Boolean} editable - check if edits are allowed
 *  @param {(principal: string, oldPolicyId: string, newPolicyId: string) => undefined} onPolicyChange -
 *    called when the access level for a given principal ("*" for all users) changes
 *  @param {(principal: string) => undefined} onPolicyRemove - called when a user's access is removed
 */
const ResourceAuthorizationTable = ({
  authzOptions,
  dataRows,
  editable,
  onPolicyChange,
  onPolicyRemove,
}) => {

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      sortType: 'string',
      title: 'Name',
    },
    {
      dataIndex: 'email',
      key: 'email',
      sortType: 'string',
      title: 'Email',
    },
    {
      dataIndex: 'extends',
      key: 'extends',
      sortType: 'string',
      title: 'Risk Access',
      render: (val, row) => editable ? (
        <Select
          allowClear={false}
          value={val}
          onChange={(value, opt) => onPolicyChange(row.key, row.extends, opt.value)}
        >
          {authzOptions.map(({ key, label, value }) =>
            <Select.Option key={key} value={value}>{label}</Select.Option>)
          }
        </Select>
      ) : authzOptions.find(opt => opt.value === val)?.label,
    },
    editable && dataRows.length > 1 && {
      dataIndex: 'operation',
      key: 'operation',
      title: '',
      render: (text, row) =>
        row.removable ? (
          <LinkButton2 onClick={() => onPolicyRemove(row.key, row.extends)}>
            <MinusCircleFilled twoToneColor='#1890ff' /> Remove
          </LinkButton2>
        ) : null,
    },
  ].filter(Boolean)

  return (
    <Table
      columns={columns}
      dataSource={dataRows}
      rowKey='name'
    />
  )
}

export default ResourceAuthorizationTable
