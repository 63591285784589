import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Modal } from '../client/controls'

import { selectAccountProjectsQuota } from '../redux/account/selectors'
import { useCanUpdateSubscription } from '../redux/account/hooks'

/**
 * Returns a function wrapper to enforce account project quotas before invoking the
 * function.
 *
 * @returns [enforceProjectsQuota]
 */
function useEnforceProjectsQuota() {
  const accountProjectsQuota = useSelector(selectAccountProjectsQuota)

  const canUpdateSubscription = useCanUpdateSubscription()

  const navigate = useNavigate()
  const enforceProjectsQuota = useCallback(
    callback => (...args) => {
      const { allowed, used } = accountProjectsQuota ?? {}
      if(typeof allowed === 'number' && used >= allowed) {
        if(canUpdateSubscription) {
          Modal.confirm({
            title: 'Plan Limits',
            okText: 'Change Plan',
            onOk: () => navigate('/subscription'),
            content: (
              <p>
                You're using {used} of {allowed} of the projects allowed on
                your plan.  If you need more projects, you can change your plan
                on the subscriptions page.
              </p>
            ),
          })
        } else {
          Modal.info({
            title: 'Plan Limits',
            content: (
              <p>
                You're using {used} of {allowed} of the projects allowed on
                your plan.  If you need more projects, please contact an administrator,
                who can upgrade your account's subscription.
              </p>
            ),
          })
        }
      } else {
        callback(...args)
      }
    },
    [canUpdateSubscription, navigate, accountProjectsQuota]
  )

  return [enforceProjectsQuota]
}

export default useEnforceProjectsQuota
