import { updateDecision } from '@vms/vmspro3-core/dist/actions/decision'
import { Decision } from '@vms/vmspro3-core/dist/types'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'
import { ReactElement, useCallback } from 'react'

import { Button, Form, Input } from '../../client/controls'

import style from './Overview.module.css'
import { useAppDispatch } from '../../redux'
import { useDecision } from '../../redux/hooks'

type DecisionOverviewFormFields = Pick<Decision, 'name' | 'objective' | 'description'>
export function Overview({ decisionId }: { decisionId: string }): ReactElement {
  const decision = useDecision(decisionId)

  const [formInstance] = Form.useForm()

  const dispatch = useAppDispatch()
  const onFinish = useCallback(
    (formValues: DecisionOverviewFormFields) => {
      dispatch(updateDecision(decisionId, {
        name: formValues.name,
        description: createHtmlObject(formValues.description?.value ?? ''),
        objective: createHtmlObject(formValues.objective?.value ?? ''),
      }))
      formInstance.resetFields()
    },
    [dispatch, decisionId, formInstance]
  )

  return (
    <Form<DecisionOverviewFormFields>
      form={formInstance}
      onFinish={onFinish}
      initialValues={decision}
      layout="vertical"
      className={style.overviewForm}
    >
      <Form.Item name="name" label="Name" rules={[{ required: true, whitespace: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={['objective', 'value']} label="Objective">
        <Input.TextArea showCount maxLength={1200} rows={4} />
      </Form.Item>
      <Form.Item name={['description', 'value']} label="Description">
        <Input.TextArea rows={10} />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {form => {
          const disableFormControls = !form.isFieldsTouched()
          return (
            <div className={style.formControls}>
              <Button disabled={disableFormControls} type="primary" htmlType="submit">Save</Button>
              <Button disabled={disableFormControls} onClick={() => form.resetFields()}>Cancel</Button>
            </div>
          )
        }}
      </Form.Item>
    </Form>
  )
}
