import { createContext, ReactElement, useCallback, useContext, useState } from 'react'
import { Ratable, RatingVector } from '@vms/vmspro3-core/dist/types'
import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  RectEntry,
  ViewRect,
  CollisionDetection,
  Modifier,
  DragStartEvent,
} from '@dnd-kit/core'

export interface RatableContextValue<T extends Ratable> {
  minRating: number,
  maxRating: number,
  onRateItem: (itemId: string, ratingVector: RatingVector, abstain?: boolean) => void,
  renderItem: (item: T, ratingVector: RatingVector, abstain: boolean) => ReactElement,
  zIndexOrder: string[],
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RatableContext = createContext<RatableContextValue<Ratable<any>> | undefined>(undefined)

export function useRatableContext<T extends Ratable>() {
  const context = useContext<RatableContextValue<T> | undefined>(RatableContext)

  if(context === undefined) {
    throw new Error('useRatableContext must be used inside a RatableContext provider')
  }

  return context
}

const snapThreshold = 50
function getWithinContainer(entry: RectEntry[1], rect: ViewRect) {
  const boundaryLeft = entry.offsetLeft - snapThreshold
  const boundaryTop = entry.offsetTop - snapThreshold
  const boundaryRight = entry.offsetLeft + entry.width + snapThreshold
  const boundaryBottom = entry.offsetTop + entry.height + snapThreshold

  const withinLeft = rect.left >= boundaryLeft
  const withinTop = rect.top >= boundaryTop
  const withinRight = rect.right <= boundaryRight
  const withinBottom = rect.bottom <= boundaryBottom

  return withinLeft && withinTop && withinRight && withinBottom
}
const withinContainerCollisionDetection: CollisionDetection = (entries, rect) => {
  const entry = entries.find(([, entry]) => getWithinContainer(entry, rect))
  return entry?.[0] ?? null
}

const snapWithinContainer: Modifier = ({
  transform,
  activeNodeRect,
  over,
}) => {
  if(!activeNodeRect || !over) {
    return transform
  }

  const value = {
    ...transform,
  }

  const element = document.getElementById('content')
  const scrollY = element?.scrollTop ?? 0
  const scrollX = element?.scrollLeft ?? 0

  const boundingRect = {
    ...over.rect,
    top: over.rect.offsetTop - scrollY,
    bottom: over.rect.offsetTop + over.rect.height - scrollY,
    left: over.rect.offsetLeft - scrollX,
    right: over.rect.offsetLeft + over.rect.width - scrollX,
  }

  const transformedRect = {
    top: activeNodeRect.top + transform.y,
    bottom: activeNodeRect.bottom + transform.y,
    left: activeNodeRect.left + transform.x,
    right: activeNodeRect.right + transform.x,
  }

  if(
    transformedRect.top < boundingRect.top &&
    transformedRect.top >= boundingRect.top - snapThreshold
  ) {
    value.y = Math.floor(boundingRect.top - activeNodeRect.top)
  } else if(
    transformedRect.bottom > boundingRect.bottom &&
    transformedRect.bottom <= boundingRect.bottom + snapThreshold
  ) {
    value.y = Math.ceil(boundingRect.bottom - activeNodeRect.bottom)
  }

  if(
    transformedRect.left < boundingRect.left &&
    transformedRect.left >= boundingRect.left - snapThreshold
  ) {
    value.x = Math.floor(boundingRect.left - activeNodeRect.left)
  } else if(
    transformedRect.right > boundingRect.right &&
    transformedRect.right <= boundingRect.right + snapThreshold
  ) {
    value.x = Math.floor(boundingRect.right - activeNodeRect.right)
  }

  return value
}

type RatingProviderProps<T extends Ratable> = Omit<RatableContextValue<T>, 'renderRatableItem' | 'zIndexOrder'> & {
  children: React.ReactNode,
}
export const RatingProvider = <T extends Ratable>({
  children,
  minRating,
  maxRating,
  onRateItem,
  renderItem,
}: RatingProviderProps<T>): ReactElement => {
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor)
  )

  const [zIndexOrder, setZIndexOrder] = useState<string[]>(() => [])
  const onDragStart = useCallback(
    (event: DragStartEvent) => {
      setZIndexOrder(state => {
        const oldIdx = state.indexOf(event.active.id)
        if(oldIdx > -1) {
          state.splice(oldIdx, 1)
        }
        state.push(event.active.id)
        return state
      })
    },
    []
  )

  return (
    <DndContext
      collisionDetection={withinContainerCollisionDetection}
      modifiers={[snapWithinContainer]}
      sensors={sensors}
      onDragStart={onDragStart}
    >
      <RatableContext.Provider
        value={{
          minRating,
          maxRating,
          onRateItem,
          renderItem,
          zIndexOrder,
        }}
      >
        {children}
      </RatableContext.Provider>
    </DndContext>
  )
}
