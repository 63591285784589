import { ReactElement, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

import { Page } from '../components'
import { AdHocParticipationProvider } from '../components/AdHocParticipation/adHocParticipationContext'
import { AdHocCriteriaPrioritization, AdHocUnsetParticipantButton } from '../components/AdHocParticipation'

import { useLocalStorage } from '../hooks/useLocalStorage'

export function AdHocCriteriaPrioritizationPage(): ReactElement {
  const {
    accountId,
    decisionId,
    participationSessionId,
  } = useParams<'accountId' | 'decisionId' | 'participationSessionId'>()

  invariant(accountId, 'accountId required in URL parameters')
  invariant(decisionId, 'decisionId required in URL parameters')
  invariant(participationSessionId, 'participationSessionId required in URL parameters')

  const participantIdLsKey = `ad-hoc-participant-id/accountId:${accountId}/decisionId:${decisionId}`
  const [participantId, setParticipantId] = useLocalStorage<string | undefined>(participantIdLsKey)

  const unsetParticipantId = useCallback(() => setParticipantId(undefined), [setParticipantId])

  return (
    <Page
      extra={participantId && (
        <AdHocUnsetParticipantButton
          accountId={accountId}
          decisionId={decisionId}
          participationSessionId={participationSessionId}
          participantId={participantId}
          unsetParticipantId={unsetParticipantId}
        />
      )}
    >
      <AdHocParticipationProvider
        accountId={accountId}
        decisionId={decisionId}
        participationSessionId={participationSessionId}
        participantId={participantId}
        setParticipantId={setParticipantId}
      >
        <AdHocCriteriaPrioritization />
      </AdHocParticipationProvider>
    </Page>
  )
}
