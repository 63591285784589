/* eslint-disable @typescript-eslint/no-var-requires, no-undef */
const { EntityType } = require('../systemConsts')
const { createId } = require('../idUtils')
const { splitAncestry } = require('../utils/ancestry')

const actions = {}

const CreateProjectActionType = 'Create Project'
/**
 * create a risk project
 *
 * @params [data.id] - If omitted, a UUID will be generated.  Providing this can be useful for testing.
 * @params data.name - Project name.
 * @params data.description - Project description.
 * @params data.cost - Project cost (Cost object).
 * @params data.time - Project duration (Duration object).
 * @params data.created - If omitted, defaults to now.  Providing this can be useful for testing.
 * @params [data.commonId] - User-supplied ID (if any).  Note that if this is provided, it is expected to be
 *  unique in the system.  This action creator does no validation of that.
 * @params data.riskContext - The effective risk context for this project.
 * @params data.ancestry - The ancestry of this project.
 */
actions.create = function createRiskProjectActionCreator(data) {
  const {
    id = createId(),
    name,
    description,
    cost,
    time,
    created = Date.now(),
    commonId,
    riskContext,
    ancestry,
  } = data

  const type = CreateProjectActionType

  if(!name) throw new Error(type + ': data.name is required')
  if(!cost) throw new Error(type + ': data.cost is required')
  if(!time) throw new Error(type + ': data.time is required')
  if(!riskContext) throw new Error(type + ': data.riskContext is required')
  if(!ancestry) throw new Error(type + ': data.ancestry is required')
  const payload = {
    id,
    entityType: EntityType.PROJECT,
    name,
    description,
    cost,
    time,
    created,
    riskContext,
  }
  if(commonId) payload.commonId = commonId

  const meta = {
    authz: {
      resources: splitAncestry(ancestry),
    },
    ancestry,
  }

  return { module: 'Risk', type, payload, meta }
}
actions.create.toString = () => CreateProjectActionType

const UpdateProjectActionType = 'Update Project'
/**
 * update a risk project
 *
 * @param {Object} payload - project update payload
 * @param {Object} meta - project metadata
 *
 * @return {Object} - riskProject update action
 */
actions.update = function updateRiskProjectActionCreator(payload, meta) {
  const { ancestry, projectId } = meta
  const type = UpdateProjectActionType

  if(!projectId) throw new Error(type + ': action.meta.projectId is required')
  if(!ancestry) throw new Error(type + ': action.meta.ancestry is required')
  meta.authz = meta.authz || {}
  if(!meta.authz.resources) meta.authz.resources = [projectId]
  else meta.authz.resources.push(projectId)

  return { module: 'Risk', type, payload, meta }
}
actions.update.toString = () => UpdateProjectActionType

const UpdateProjectStatisticsActionType = 'Update Project Statistics'
/**
 * Updates cached statistics about a risk project. The only operations supported at this
 * time are INCREMENT and DECREMENT, which either raise or lower a number by 1. If the value
 * of the property specified in the payload does not exist in project.statistics or the
 * value is not a finite number, the value is set to 0 before incrementing or decrementing.
 *
 * @example
 *    // project record before update: {
 *      name: 'Example Project',
 *      id: 'example-project-id',
 *    }
 *
 *    // updateStatistics action: {
 *      module: 'Risk',
 *      type: 'Update Project Statistics',
 *      payload: {
 *        activeRisks: 'INCREMENT',
 *      },
 *      meta: {
 *        projectId: 'example-project-id,
 *      }
 *    }
 *
 *    // project record after update: {
 *      name: 'Example Project',
 *      id: 'example-project-id',
 *      statistics: {
 *        activeRisks: 1,
 *      },
 *    }
 *
 * @param {Object} payload
 * @param {string} payload[*] - property with any name, string value in payload represents operation to
 *   apply to property value in project record. If the property does not exist in project.statistics, it
 *   will be created as 0 before the operation. Current supported operations are "INCREMENT" and "DECREMENT".
 * @param {Object} meta
 * @param {string} meta.projectId - id of project to update
 * @param {string} meta.ancestry - ancestry of project to update (required for creating PubSub topic name)
 */
actions.updateStatistics = function updateStatisticsActionCreator(payload, meta) {
  const type = UpdateProjectStatisticsActionType
  const { ancestry, projectId } = meta

  if(!projectId) throw new Error(type + ': action.meta.projectId is required')
  if(!ancestry) throw new Error(type + ': action.meta.ancestry is required')
  if(Object.values(payload).some(o => !['INCREMENT', 'DECREMENT'].includes(o.operation))) {
    throw new Error(type + ': only "INCREMENT" and "DECREMENT" operations are currently supported')
  }
  meta.authz = meta.authz || {}
  if(!meta.authz.resources) meta.authz.resources = [projectId]
  else meta.authz.resources.push(projectId)

  return {
    module: 'Risk',
    type,
    payload,
    meta,
  }
}
actions.updateStatistics.toString = () => UpdateProjectStatisticsActionType

module.exports = actions
