import { useQuery, gql } from '@apollo/client'
import { useMediaQuery } from 'react-responsive'

import { Button, Spin } from '../../client/controls'

import { screenSizeMd } from '../../client/styles/style-consts'

const GET_DECISION_PARTICIPANTS = gql`
  query GetDecisionParticipants(
    $accountId: ID!,
    $decisionId: ID!,
    $participationSessionId: ID!
  ) {
    decisionParticipants(
      accountId: $accountId,
      decisionId: $decisionId,
      participationSessionId: $participationSessionId
    ) {
      id
      fullName
    }
  }
`
interface DecisionParticipantsData {
  decisionParticipants: { id: string, fullName: string }[],
}
interface DecisionParticipantsVariables {
  accountId: string,
  decisionId: string,
  participationSessionId: string,
}

interface AdHocUnsetParticipantButtonProps {
  accountId: string,
  decisionId: string,
  participationSessionId: string,
  participantId: string,
  unsetParticipantId: () => void,
}
export function AdHocUnsetParticipantButton({
  accountId,
  decisionId,
  participationSessionId,
  participantId,
  unsetParticipantId,
}: AdHocUnsetParticipantButtonProps): JSX.Element | null {

  const { data, error, loading } = useQuery<DecisionParticipantsData, DecisionParticipantsVariables>(
    GET_DECISION_PARTICIPANTS,
    {
      variables: {
        accountId,
        decisionId,
        participationSessionId,
      },
    }
  )

  const participant = data?.decisionParticipants.find(participant => participant.id === participantId)

  const isSmallScreen = useMediaQuery({ maxWidth: screenSizeMd })
  const buttonSize = isSmallScreen ? 'small' : 'middle'

  if(loading) return <Spin />
  if(error) return <p>Error: {error.message}</p>

  return (
    <span>
      {participant && `Not ${participant.fullName}? `}
      <Button type="primary" size={buttonSize} onClick={unsetParticipantId}>
        Go Back
      </Button>
    </span>
  )
}
