import { useMemo, useCallback, ReactElement } from 'react'
import {
  ComposedChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

export type PrioritizationChartProps = {
  items: Array<{
    id: string,
    name: string,
    abbrev: string,
    priority: number | null,
    color?: string,
  }>
}

const priFormatter = Intl.NumberFormat(undefined, {
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
}).format

export const PrioritizationChart = ({ items }: PrioritizationChartProps): ReactElement => {
  const data = useMemo(
    () => items
      .map(({ id, abbrev, priority, color }) => ({ id, name: abbrev, priority, color }))
      .sort((a, b) => typeof a.priority === 'number' && typeof b.priority === 'number'
        ? b.priority - a.priority
        : 0
      ),
    [items]
  )
  const tooltipFormatter = useCallback((value: number) => (
    typeof value === 'number' ? priFormatter(value) : '???'
  ), [])
  return (
    <>
      <h3>Local Priorities</h3>
      <ResponsiveContainer minHeight={300}>
        <ComposedChart
          layout="vertical"
          width={500}
          height={400}
          data={data}
        >
          <XAxis type="number" domain={[0, 1]} />
          <YAxis dataKey="name" type="category" scale="band" />
          <Tooltip formatter={tooltipFormatter} />
          <Bar dataKey="priority" barSize={90} fill="#413ea0">
            {data.map(cri => (
              <Cell key={cri.id} fill={cri.color || '#413ea0'} />
            ))}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </>
  )
}
