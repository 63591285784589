import { ROOT_ACCOUNT_ID } from '@vms/vmspro3-core/dist/systemConsts'

import Server from '../server/VMSProServerAdapter'

export async function getCurrentUser(userId) {
  // TODO: this needs to be a GQL query, not using initial state...
  // configuring API headers with "global" acccountId is dangerous...
  await Server.auth.configureAPIHeaders({
    accountId: ROOT_ACCOUNT_ID,
    userId,
  })
  const { authUser } = await Server.fetchInitialState('auth', userId)
  return authUser
}

export async function getAccountUser(accountId, userId) {
  // TODO: again, this needs a GQL query, not initial state...
  // also still sketchy with API headers...
  await Server.auth.configureAPIHeaders({
    accountId,
    userId,
  })
  const { user } = await Server.fetchInitialState('account', userId)
  return user
}
