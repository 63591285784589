import React from 'react'
import { RiskColor } from '@vms/vmspro3-core/dist/systemConsts'

import { formatNumValue } from '../../../utils/formatUtils'

// TODO: see https://vms.atlassian.net/browse/VP3-1847
// looks like this got missed on the above card and we should upddate it to use the new severity Class.
const SeverityDisplay = React.memo(({ severity }) => {
  if(!Number.isFinite(severity)) return null

  const absSev = Math.abs(severity)
  const riskColorKey = (severity <= 0 ? 'OPPORTUNITY_' : 'THREAT_') +
    (absSev < 0.18 ? 'LOW' : absSev < 0.38 ? 'MED' : 'HIGH')
  const style = {
    backgroundColor: RiskColor[riskColorKey],
    borderRadius: '4px',
    color: '#ffffff',
    display: 'inline-block',
    fontFamily: 'monospace',
    padding: '0px 5px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    wordWrap: 'normal',
  }

  const formattedSev = formatNumValue(severity, true, 2)
  // negative values will already have a "-" sign prefix. we have to check for
  // formatted values of "0.00" because the actual severity value may be greater
  // than zero--prefixing some but not other "0.00" formatted values with "+"
  // would be confusing.
  const prefix = formattedSev === '0.00' ? ' ' : severity > 0 ? '+' : ''

  return (
    <span style={style}>
      {prefix + formattedSev}
    </span>
  )
})

export default SeverityDisplay
