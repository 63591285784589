import * as valueFormula from './valueFormula'

export type VectorStats = {
  sample: {
    count: number,
    mean: number,
    variance: number,
    stddev: number,
    sum: number,
  },
  population: {
    variance: number,
    stddev: number,
  },
}
/**
 * Calculate parameters & statistics (mean, variance, standard deviation) from a
 * collection of vectors, treating each "column" as the sample space.  For example:
 *
 * vector1 = [2, 3]
 * vector2 = [4, 6]
 *
 * The column-wise average of these two vectors would be [3, 4.5].  Note missing
 * entries are not included in the calculation.  That is:
 *
 * vector1 = [2, 3]
 * vector2 = [4]
 *
 * Would have average of [3, 3] (the missing element in vector2 is not included in
 * the calculation rather than being taken to be zero).
 *
 * It is the caller's responsibility to ensure that the vector entries represent
 * consistent data.  That is, all the numbers in the first column (if present) should
 * represent parameter "A", all the numbers in the second column (if present) should
 * represent parameter "B", etc.  For example, consider the following:
 *
 * const data = [
 *   { x: 2, y: 3 },
 *   { y: 6 },
 * ]
 * const vectors = data.map(({ x, y }) =>
 *  [x, y].filter(elt => typeof elt === 'number') // note the filter!  this is bad!
 * )
 *
 * The filter here maybe well intentioned, but results in placing a "y" value in the "x"
 * vector column!  This is not what you want.
 */
export function vectorStats(vectors: number[][]): Array<VectorStats | undefined> {
  return vectors
    // variance and standard deviation calculated according to BP Welford's 1962 algorithm
    // as described here:
    //
    // https://en.wikipedia.org/wiki/Standard_deviation#Rapid_calculation_methods)
    //
    // this algorithm has good performance with respect to rounding & overflow errors
    //
    // note that "sum" is not part of Welford's algorithm, but it's useful to have
    .reduce<Array<{ k: number, a: number, q: number, sum: number } | undefined>>((vstats, v) => {
      v.forEach((x, i) => {
        if(typeof x !== 'number') return
        const _vstats: { k: number, a: number, q: number, sum: number } = vstats[i] || { k: 0, a: 0, q: 0, sum: 0 }
        vstats[i] = _vstats
        const k = _vstats.k + 1
        const a = _vstats.a + (x - _vstats.a) / k
        const q = _vstats.q + (x - _vstats.a) * (x - a)
        const sum = _vstats.sum + x
        vstats[i] = { k, a, q, sum }
      })
      return vstats
    }, [])
    // convert Welford algo results (k=count, a=mean, q=variance sum) to nice stats
    .map(args => {
      if(!args) return undefined
      const { k, a, q, sum } = args
      const varS = k > 1 ? q / (k - 1) : 0
      const varP = q / k
      return {
        sample: {
          count: k,
          mean: a,
          variance: varS,
          stddev: Math.sqrt(varS),
          sum,
        },
        population: {
          variance: varP,
          stddev: Math.sqrt(varP),
        },
      }
    })
}

/**
 * Just like vectorStats except only calculates the sample mean (which is all we need
 * right now).
 */
export function vectorMean(vectors: number[][]): Array<number> {
  return vectors
    .reduce<Array<{ k: number, a: number }>>((vstats, v) => {
      v.forEach((x, i) => {
        if(typeof x !== 'number') return
        const _vstats: { k: number, a: number } = vstats[i] || { k: 0, a: 0 }
        vstats[i] = _vstats
        const k = _vstats.k + 1
        const a = _vstats.a + (x - _vstats.a) / k
        vstats[i] = { k, a }
      })
      return vstats
    }, [])
    .map(s => s && s.a)
}

/**
 * Helper function that, when given a variable expression, will return the variable name
 * (without any trailing apostrophe, indicating the complement), and a boolean indicating
 * whether or not it is a complement value.
 *
 * @example
 *
 *   parseVarExpression("a")  // --> ['a', false]
 *   parseVarExpression("a'") // --> ['a', true]
 */
export function parseVarExpression(expr: string): [string, boolean] {
  return expr.endsWith("'") ? [expr.replace(/'$/, ''), true] : [expr, false]
}

/**
 * Helper function that, when given a value formula expression, will return the coefficient details
 * IF the expression is a 'Multiply' expression with two operands, one of which is a string (variable)
 * and the other of which is a number.  Otherwise it will return null.
 *
 * Note that it strips off any apostrophes (indicating the complement), and includes a boolean indicating
 * whether the variable is a complement or not.
 *
 * @examnple
 *
 *   extractCoefficient(['Multiply', 1, 2])      // --> null
 *   extractCoefficient(['Multiply', 1, 'a'])    // --> [1, 'a', false]
 *   extractCoefficient(['Multiply', 'a', 1])    // --> [1, 'a', false]
 *   extractCoefficient(['Multiply', 'a\'', 1])    // --> [1, 'a', true]
 *   extractCoefficient(['Multiply', 1, 'a', 3]) // --> null
 *   extractCoefficient(['Add', 1, 'a'])         // --> null
 */
export function extractCoefficient(expr: valueFormula.Expression): [number, string, boolean] | null {
  if(!Array.isArray(expr)) return null
  if(expr[0] !== 'Multiply') return null
  if(expr.length !== 3) return null
  switch(typeof expr[1]) {
    case 'number': if(typeof expr[2] === 'string') return [expr[1], ...parseVarExpression(expr[2])]; break
    case 'string': if(typeof expr[2] === 'number') return [expr[2], ...parseVarExpression(expr[1])]; break
    default: break // not a coefficient!
  }
  return null
}
