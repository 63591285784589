export default {
  FetchAccountRequest: 'FetchAccountRequest',
  FetchAccountSuccess: 'FetchAccountSuccess',
  FetchAccountFailure: 'FetchAccountFailure',

  FetchUserAccountsRequest: 'FetchUserAccountsRequest',
  FetchUserAccountsSuccess: 'FetchUserAccountsSuccess',
  FetchUserAccountsFailure: 'FetchUserAccountsFailure',

  FetchPendingInvitationsForAccountRequest: 'FetchPendingInvitationsForAccountRequest',
  FetchPendingInvitationsForAccountSuccess: 'FetchPendingInvitationsForAccountSuccess',
  FetchPendingInvitationsForAccountFailure: 'FetchPendingInvitationsForAccountFailure',

  SetAccountId: 'SetAccountId',
  RemoveAccountId: 'RemoveAccountId',
  AcceptInvitation: 'AcceptInvitation',
}
