/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import LockOutlined from '@ant-design/icons/LockOutlined'
import { createId } from '@vms/vmspro3-core/dist/idUtils'
import qs from 'querystringify'

import EmailField from '../../components/EmailField'
import PasswordField from '../../components/PasswordField'
import { Button, Form, Input, Card } from '../../client/controls'

import Server from '../../server/VMSProServerAdapter'

const unknownErrorCopy = 'An unknown error occurred. Please contact support.'

const userFields = [
  { label: 'Full Name', name: 'user.fullName', required: true },
  { label: 'Short Name', name: 'user.shortName', required: true },
  { label: 'Initials', name: 'user.initials', required: true },
  { label: 'Phone', name: 'user.phone' },
]

const setFormFieldError = (formInstance, name, errorMessage) => formInstance.setFields([{
  name,
  errors: errorMessage && [errorMessage],
}])

function InitSignUpForm({
  formInstance,
  handleInitSignUp,
  initialValues,
  loading,
  setConfirmSignUp,
}) {

  return (
    <Form
      initialValues={initialValues}
      form={formInstance}
      onFinish={handleInitSignUp}
      layout="vertical"
      hideRequiredMark
    >
      <h2>Sign Up</h2>
      <span css={style.signUpCopy}>
        To create an account for your organization, get started by signing
        yourself up; we'll ask you for your organization details next.
      </span>
      <EmailField disabled={initialValues.email} />
      <PasswordField.SetNewPassword showSuggestion />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Sign Up
        </Button>
        <Button
          type="link"
          css={{ float: 'right' }}
          onClick={setConfirmSignUp}
        >
          <i>Already have a code?</i>
        </Button>
      </Form.Item>
      <i>Already a VMSPro user? <Link to="/signin">Sign in.</Link></i>
    </Form>
  )
}

const generateFormField = ({ label, extra, name, required }) => (
  <Form.Item
    label={label}
    extra={extra}
    name={name.split('.')}
    rules={required ? [{ required }] : undefined}
    key={name}
  >
    <Input />
  </Form.Item>
)

function ConfirmSignUpForm({
  formInstance,
  handleConfirmSignUp,
  initialValues,
  loading,
}) {
  return (
    <Form
      form={formInstance}
      initialValues={initialValues}
      onFinish={handleConfirmSignUp}
      layout="vertical"
      scrollToFirstError={{
        behavior: 'smooth',
        scrollMode: 'if-needed',
        block: 'center',
      }}
    >
      <h2>Check your email for a confirmation code</h2>
      <EmailField disabled={initialValues.email} />
      <Form.Item
        label="Confirmation Code"
        name="confirmationCode"
        normalize={code => code.trim()}
        rules={[
          {
            required: true,
            message: 'Please enter the verification code',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined css={style.inputPrefix} />}
          inputMode="numeric"
          placeholder="confirmation code"
          autoComplete="one-time-code"
        />
      </Form.Item>
      <h2>And complete your registration</h2>
      <h3>User Details:</h3>
      {userFields.map(generateFormField)}
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Complete Registration
        </Button>
      </Form.Item>
    </Form>
  )
}

function SignUp({ initialStep }) {
  const [formInstance] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [signUpFlowStep, setSignUpFlowStep] = useState(initialStep)

  const location = useLocation()
  const initialValues = React.useMemo(
    () => qs.parse(location.search),
    [location.search]
  )
  const setConfirmSignUp = () => setSignUpFlowStep('confirmation')

  const handleInitSignUp = ({ email, newPassword }) => {
    setLoading(true)
    return Server.auth.signUp({
      username: email,
      attributes: {
        preferred_username: createId(),
      },
      password: newPassword,
    })
      .then(setConfirmSignUp)
      .catch(err => {
        switch(err.code) {
          case 'UsernameExistsException': {
            setFormFieldError(
              formInstance,
              'email',
              'An account with this email already exists.'
            )
            break
          }
          default: {
            setFormFieldError(
              formInstance,
              'email',
              unknownErrorCopy
            )
          }
        }
      })
      .finally(() => setLoading(false))
  }

  const navigate = useNavigate()
  const handleConfirmSignUp = async ({ email, confirmationCode, user }) => {
    setLoading(true)

    return Server.auth.confirmSignUp(email, confirmationCode, {
      clientMetadata: {
        user: JSON.stringify({ ...user, email }),
      },
    })
      .then(() => {
        setLoading(false)
        navigate(`/signin?email=${encodeURIComponent(email)}`)
      })
      .catch(err => {
        switch(err.code) {
          case 'CodeMismatchException': {
            setFormFieldError(
              formInstance,
              'confirmationCode',
              'The confirmation code you provided does not match.'
            )
            break
          }
          case 'NotAuthorizedException': {
            if(err.message.includes('CONFIRMED')) {
              setFormFieldError(
                formInstance,
                'email',
                'This user is already confirmed. Please sign in.'
              )
              break
            }
            // otherwise log error and fall through to default case
            console.error(err)
          }
          /* eslint-disable-next-line no-fallthrough */
          default: {
            setFormFieldError(formInstance, 'confirmationCode', unknownErrorCopy)
          }
        }
        setLoading(false)
      })
  }

  switch(signUpFlowStep) {
    case 'init': {
      return (
        <InitSignUpForm
          formInstance={formInstance}
          handleInitSignUp={handleInitSignUp}
          initialValues={initialValues}
          setConfirmSignUp={setConfirmSignUp}
          loading={loading}
        />
      )
    }
    case 'confirmation': {
      return (
        <ConfirmSignUpForm
          formInstance={formInstance}
          handleConfirmSignUp={handleConfirmSignUp}
          initialValues={initialValues}
          loading={loading}
        />
      )
    }
    default: {
      return null
    }
  }
}
SignUp.defaultProps = {
  initialStep: 'init',
}

export function SignUpPage(props) {
  return (
    <div css={style.container}>
      <Card css={style.card}>
        <SignUp {...props} />
      </Card>
    </div>
  )
}

const style = {
  container: {
    padding: '60px 24px',
  },
  card: {
    maxWidth: '400px',
    margin: '0 auto',
  },
  inputPrefix: {
    color: 'rgba(0,0,0,.25)',
  },
  signUpCopy: {
    display: 'inline-block',
    marginBottom: '24px',
  },
}
