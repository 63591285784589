export type TreeNode<T> = {
  data: T,
  parent: TreeNode<T> | null,
  children: TreeNode<T>[]
}
export type Tree<T> = {
  all: TreeNode<T>[],
  byId: Record<string, TreeNode<T>>,
}

/**
 * Given an array of hierarchical data where children have a parentId property, organizes a hierarchical
 * structure with parent and children references at each node and returns both an array containing all
 * nodes and an object where each node is keyed by the id property.
 */
export function createTree<T extends { id: string, parentId: string | null }>(flatData: T[]): Tree<T> {
  const tree: Tree<T> = {
    all: [],
    byId: {},
  }

  flatData.forEach(data => {
    tree.byId[data.id] = { data, parent: null, children: [] }
  })
  flatData.forEach(data => {
    if(data.parentId) {
      if(tree.byId[data.parentId]) {
        tree.byId[data.id].parent = tree.byId[data.parentId]
        tree.byId[data.parentId].children.push(tree.byId[data.id])
      } else {
        console.error(`parent=${data.parentId} not found for tree node=${data.id}`)
      }
    }
    tree.all.push(tree.byId[data.id])
  })

  return tree
}

export function traverse<T>(
  node: TreeNode<T>,
  visit: (node: TreeNode<T>, path: TreeNode<T>[]) => void,
  path: TreeNode<T>[] = []
): void {
  visit(node, path)
  node.children.forEach(c => traverse(c, visit, [...path, node]))
}

export function getTreeNodeDescendantIds<T extends { id: string }>(node: TreeNode<T>): string[] {
  const descendantIds: string[] = []
  node.children.forEach(child => traverse(child, n => descendantIds.push(n.data.id)))
  return descendantIds
}

/**
 * Given a tree or sub-tree, returns a flat list of all leaf nodes (nodes without children).
 */
export function getLeafNodes<T extends { id: string }>(rootNode: TreeNode<T>): TreeNode<T>[] {
  const leafNodes: TreeNode<T>[] = []
  traverse(rootNode, node => {
    if(!node.children.length) leafNodes.push(node)
  })
  return leafNodes
}

/**
 * Given a tree or sub-tree, returns a flat list of all internal nodes (nodes with children).
 */
export function getInternalNodes<T extends { id: string }>(rootNode: TreeNode<T>): TreeNode<T>[] {
  const internalNodes: TreeNode<T>[] = []
  traverse(rootNode, node => {
    if(node.children.length) internalNodes.push(node)
  })
  return internalNodes
}

export function getDescendantNodes<T extends { id: string }>(rootNode: TreeNode<T>): TreeNode<T>[] {
  const descendantNodes: TreeNode<T>[] = []
  traverse(rootNode, node => {
    if(node === rootNode) return
    descendantNodes.push(node)
  })
  return descendantNodes
}

export type TreeNodeData = {
  id: string,
  name: string,
  abbrev: string,
  parentId?: string | null,
}
export type GetNodeLabelsOptions = {
  abbrev?: boolean,
  omitRootNode?: boolean,
  separator?: string,
}
/**
 * Given a tree or sub-tree, returns a map of of node IDs to labels that include
 * their ancestry, e.g. "Root: Child: Grandchild".
 *
 * @argument rootNode - The root of the tree.
 * @argument options.separator - Hierarchy separator character; defaults to ": "
 * @argument options.omitRootNode - If true, root label is omitted for children
 *  (e.g. "Child" instead of "Root: Child").  Defaults to true.
 * @argument options.abbrev - If true, uses "abbrev" property of node, otherwise uses
 *  "name" property.  Defaults to false.
 */
export function getNodeLabels<T extends TreeNodeData>(
  rootNode: TreeNode<T>,
  options?: GetNodeLabelsOptions,
): Record<string, string> {
  const {
    separator = ': ',
    omitRootNode = true,
    abbrev = false,
  } = options ?? {}
  const labels: Record<string, string> = {}
  const accessor = abbrev ? 'abbrev' : 'name'
  traverse(rootNode, (node, path) => {
    if(node === rootNode) return labels[node.data.id] = node.data[accessor]
    labels[node.data.id] = (omitRootNode ? path.slice(1) : path)
      .map(n => n.data[accessor])
      .concat(node.data[accessor])
      .join(separator)
  })
  return labels
}

export function getLeafNodeData<T extends TreeNodeData>(
  rootNode: TreeNode<T>,
  options?: GetNodeLabelsOptions
): T[] {
  const leafNodes = getLeafNodes(rootNode)
  const nodeLabels = getNodeLabels(rootNode, options)
  return leafNodes.map(node => ({ ...node.data, name: nodeLabels[node.data.id] }))
}
export function getInternalNodeData<T extends TreeNodeData>(
  rootNode: TreeNode<T>,
  options?: GetNodeLabelsOptions
): T[] {
  const internalNodes = getInternalNodes(rootNode)
  const nodeLabels = getNodeLabels(rootNode, options)
  return internalNodes.map(node => ({ ...node.data, name: nodeLabels[node.data.id] }))
}
export function getDescendantNodeData<T extends TreeNodeData>(
  rootNode: TreeNode<T>,
  options?: GetNodeLabelsOptions
): T[] {
  const descendantNodes = getDescendantNodes(rootNode)
  const nodeLabels = getNodeLabels(rootNode, options)
  return descendantNodes.map(node => ({ ...node.data, name: nodeLabels[node.data.id] }))
}
