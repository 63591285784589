import { ASSUMED_USER_ID_KEY } from '../../utils/appConsts'
import { getAuth, signOut as _signOut } from '../../services/authService'

import authActionTypes from './actionTypes'

export function setAuth({
  userId,
  email,
  assumedAuthz,
}) {
  return {
    type: authActionTypes.SetAuth,
    payload: {
      userId,
      email,
      assumedAuthz,
    },
    meta: { ephemeral: true },
  }
}

export function fetchAuth() {
  return async dispatch => {
    try {
      dispatch({
        type: authActionTypes.FetchAuthRequest,
        meta: { ephemeral: true },
      })

      const { userId, email, assumedAuthz } = await getAuth()

      if(!userId) localStorage.removeItem(ASSUMED_USER_ID_KEY)

      dispatch(setAuth({
        userId,
        email,
        assumedAuthz,
      }))
    } catch(error) {
      console.error('fetchAuth error', error)
    }
  }
}

export function signOut(navigate) {
  return async dispatch => {
    try {
      await _signOut()
      localStorage.removeItem(ASSUMED_USER_ID_KEY)
      navigate('/')

      dispatch({
        type: authActionTypes.SignOut,
        meta: { ephemeral: true },
      })
    } catch(error) {
      console.error('signOut error: ', error)
    }
  }
}
