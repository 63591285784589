import getSeverityColor from './color'

export default {
  label: {
    singular: "Risk",
    plural: "Risks",
  },
  defaultSettings: {
    scale: 18000,
    statGroups: 10,
    hasRelativeScale: false,
  },
  stats: [
    {
      label: {
        long: "Severity",
        short: "Sev",
      },
      path: "severity",
      overlay: [
        {
          path: "managed",
          label: {
            long: "Managed",
            short: "Mgd",
          },
        },
        {
          path: null,
          label: {
            long: "Unmanaged",
            short: "Unm",
          },
        },
      ],
      bounds: {
        min: 0,
        max: 1,
      },
      displayFactor: 100,
      groupAs: mag => Math.abs(mag),
      getColor: mag => getSeverityColor(mag),
      pivot: {
        label: "Attribute",
        options: [
          {
            path: "total",
            label: {
              long: "Total",
              short: "Total",
            },
          },
          {
            path: "cost",
            label: {
              long: "Cost",
              short: "Cost",
            },
          },
          {
            path: "time",
            label: {
              long: "Time",
              short: "Time",
            },
          },
          {
            path: "perf",
            label: {
              long: "Performance",
              short: "Perf",
            },
          },
        ],
      },
      clusters: [
        {
          label: "Threats",
          portion: 0.7,
          direction: "column-reverse",
          heuristic: stat => stat > 0,
        },
        {
          label: "Opportunities",
          portion: 0.3,
          direction: "column",
          heuristic: stat => stat <= 0,
        },
      ],
      style: {
        chunk: [
          {
            basis: "magnitude",
            property: "background-color",
            value: sev => getSeverityColor(sev),
          },
        ],
      },
      groupTemplate: [0, "label", 1],
    },
  ],
  traits: [
    {
      label: {
        long: "Category",
        short: "Cat",
      },
      path: "category",
      options: [],
    },
    {
      label: {
        long: "Phase",
        short: "Phs",
      },
      path: "phase",
      options: [],
    },
  ],
}
