import { useMemo } from 'react'
import style from './StatsLegend.module.css'

export function StatsLegend({ formatter }: { formatter: Intl.NumberFormat }) {
  const num = useMemo(() => formatter.format(0).replace(/0/g, 'x'), [formatter])
  const outlierStyle = { fontWeight: 'bold', color: '#be5504' } as const
  return (
    <>
      <dl className={style.definitions}>
        <dt>Highest result(s):</dt><dd>⇡ {num}</dd>
        <dt>Lowest result(s):</dt><dd>⇣ {num}</dd>
        <dt>Outlier(s):</dt>
        <dd>
          <span style={outlierStyle}>⇡ {num}</span> or <span style={outlierStyle}>⇣ {num}</span>
        </dd>
      </dl>
      <p className={style.footnotes}>
        Outliers are below Q1 - 1.5·IQR or above Q3 + 1.5·IQR, where IQR is the interquartile
          range, Q1 is the 25th percentile, and Q3 is the 75th percentile.
      </p>
    </>
  )
}
