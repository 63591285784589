import { createSelector } from 'reselect'

export const selectUserState = state => state.user

// current user
export const selectCurrentUser = createSelector(
  [selectUserState],
  state => state.currentUser
)
export const selectCurrentUserLoadingStatus = createSelector(
  [selectUserState],
  state => state.currentUserLoadingStatus
)
export const selectCurrentUserName = createSelector(
  [selectCurrentUser],
  user => user?.fullName
)
export const selectDateFormat = createSelector(
  [selectCurrentUser],
  user => user?.dateFormat ?? 'YYYY-MM-DD'
)
export const selectDateTimeFormat = createSelector(
  [selectCurrentUser],
  user => user?.dateTimeFormat ?? 'YYYY-MM-DD hh:mm a'
)

// account user
export const selectAccountUser = createSelector(
  [selectUserState],
  state => state.accountUser
)
export const selectAccountUserLoadingStatus = createSelector(
  [selectUserState],
  state => state.accountUserLoadingStatus
)

// user policy IDs
export const selectCurrentUserPolicyIds = createSelector(
  [selectCurrentUser],
  currentUser => currentUser?.policyIds
)
export const selectAccountUserPolicyIds = createSelector(
  [selectAccountUser],
  accountUser => accountUser?.policyIds
)
export const selectUserPolicyIds = createSelector(
  [
    selectCurrentUserPolicyIds,
    selectAccountUserPolicyIds,
  ],
  (
    currentUserPolicyIds,
    accountUserPolicyIds
  ) => {
    const policyIds = []
    if(currentUserPolicyIds) policyIds.push(...currentUserPolicyIds)
    if(accountUserPolicyIds) policyIds.push(...accountUserPolicyIds)
    return policyIds
  }
)

// user invitations
export const selectUserInvitations = state => state.user.invitations
export const selectUserInvitationsLoadingStatus = state => state.user.invitationsLoadingStatus
