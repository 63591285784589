import { produce } from 'immer'

import authActionTypes from './actionTypes'
import { LoadingStatus } from '../../utils/appConsts'

const initialState = {
  userId: null,
  email: null,
  loadingStatus: LoadingStatus.NotLoaded,
}

const authReducer = produce((state, action) => {
  switch(action.type) {
    case authActionTypes.SignOut: {
      return initialState
    }
    case authActionTypes.FetchAuthRequest: {
      state.loadingStatus = LoadingStatus.Requested
      break
    }
    case authActionTypes.SetAuth: {
      const { userId, email, assumedAuthz } = action.payload

      state.userId = userId
      state.email = email
      state.loadingStatus = LoadingStatus.Loaded

      if(assumedAuthz) state.assumedAuthz = assumedAuthz

      break
    }
    default: {
      break
    }
  }
}, initialState)

export default authReducer
