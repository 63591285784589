const actions = {}

/**
 * update a risk context for a given entity
 *
 * @param {Object} payload
 * @param {Object} meta
 *
 * @return {Object} - riskContext update action
 */
const UpdateRiskContextActionType = 'Update Risk Context'
actions.update = function updateRiskContextActionCreator(payload, meta) {
  const { ancestry, entityType, entityId } = meta
  const type = UpdateRiskContextActionType

  if(!ancestry) throw new Error(type + ': action.meta.ancestry is required')
  if(!entityType) throw new Error(type + ': action.meta.entityType is required')
  if(!entityId) throw new Error(type + ': action.meta.entityId is required')

  meta.authz = meta.authz || {}
  if(!meta.authz.resources) meta.authz.resources = [entityId]
  else meta.authz.resources.push(entityId)

  return { module: 'Risk', type, payload, meta }
}
actions.update.toString = () => UpdateRiskContextActionType
module.exports = actions  // eslint-disable-line no-undef
