import * as React from 'react'

import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from '../client/controls'
import { ReduxProvider } from './ReduxProvider'

import { ApolloProvider } from './ApolloProvider'

const antDesignConfig = {
  form: {
    validateMessages: {
      /* eslint-disable no-template-curly-in-string */
      required: '${label} is required',
      whitespace: '${label} cannot be empty',
      /* eslint-enable no-template-curly-in-string */
    },
  },
}

type AppProvidersProps = {
  children: React.ReactNode,
}
export function AppProviders({ children }: AppProvidersProps): JSX.Element {
  return (
    <BrowserRouter>
      <ConfigProvider form={antDesignConfig.form}>
        <ApolloProvider>
          <ReduxProvider>
            {children}
          </ReduxProvider>
        </ApolloProvider>
      </ConfigProvider>
    </BrowserRouter>
  )
}
