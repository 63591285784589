import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Module } from '@vms/vmspro3-core/dist/systemConsts'

import { Menu as AntMenu } from '../../client/controls'

import actions from '../../actions'
import useAuthz from '../../hooks/useAuthz'
import config from '../../config.json'
import { setMenuState } from '../../redux/actions'
import { selectAccountDecisionsQuota } from '../../redux/account/selectors'
import { useCanUpdateSubscription } from '../../redux/account/hooks'
import { antDesignMenuDarkBackground } from '../../client/styles/style-consts'

/**
 * Renders application menu. Menu is shown when state.app.menuOpen is true, nothing
 * when false. Component memoization is added on export to preserve component name
 * in the React devtools component explorer.
 */
export function Menu() {
  const menuOpen = useSelector(state => state.app.menuOpen)
  const location = useLocation()
  const canUpdateSubscription = useCanUpdateSubscription()

  const dispatch = useDispatch()
  const closeMenu = useCallback(
    () => dispatch(setMenuState(false)),
    [dispatch]
  )

  const navigate = useNavigate()
  const onMenuClick = useCallback(
    ({ key }) => {
      navigate(key)
      closeMenu()
    },
    [navigate, closeMenu]
  )

  const authz = useAuthz()
  const showRiskManagement = useMemo(
    () => authz(actions.feature.useModule(null, { moduleKey: Module.PORTFOLIO_RISK })),
    [authz]
  )

  const accountDecisionsQuota = useSelector(selectAccountDecisionsQuota)
  const showDecisionSupport = (accountDecisionsQuota?.allowed ?? 0) > 0

  const helpdesk = config.instance?.helpdesk
  const showHelpdesk = !!helpdesk

  return (
    <>
      <div style={style.container(menuOpen)}>
        <AntMenu
          mode="inline"
          onClick={onMenuClick}
          selectedKeys={[location.pathname]}
          theme="dark"
        >
          <AntMenu.Item key="/">Home</AntMenu.Item>
          {showRiskManagement &&
            <AntMenu.Item key="/port">Risk Management</AntMenu.Item>
          }
          {showDecisionSupport &&
            <AntMenu.Item key="/decision">Decision Support</AntMenu.Item>
          }
          <AntMenu.SubMenu key="admin" title="Admin">
            <AntMenu.Item key="/user-management">User Management</AntMenu.Item>
            <AntMenu.Item key="/user-management/roles">Roles</AntMenu.Item>
            {canUpdateSubscription &&
              <AntMenu.Item key="/subscription">Subscription</AntMenu.Item>
            }
            {authz(actions.account.readInvoices()) &&
              <AntMenu.Item key="/invoices">Invoices</AntMenu.Item>
            }
          </AntMenu.SubMenu>
          {showHelpdesk &&
            <AntMenu.Item key={helpdesk.key} onClick={() => window.open(helpdesk.url, '_blank')}>
              {helpdesk.title}
            </AntMenu.Item>
          }
        </AntMenu>
        <div style={style.footer}>
          Version {process.env.REACT_APP_VMSPRO_VERSION} (build {process.env.REACT_APP_GIT_HEAD_COMMIT_ID})
          <br />
          &copy; {new Date().getFullYear()} VMS, Inc.
        </div>
      </div>
      <div onClick={closeMenu} style={menuOpen ? style.overlayBlock : style.overlayHidden} />
    </>
  )
}

const style = {
  container: menuOpen => ({
    '--menu-open-width': '300px',
    width: menuOpen ? 'var(--menu-open-width)' : '0',
    height: 'calc(100vh - var(--header-height))',
    zIndex: 10001,
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: antDesignMenuDarkBackground,
    color: 'rgba(255, 255, 255, 0.65)',
    fontWeight: '300',
    boxShadow: '4px 0px 13px -2px rgba(0, 0, 0, .3)',
    transition: 'width 120ms ease-in-out',
    overflowX: 'hidden',
  }),
  footer: {
    marginBottom: '20px',
    textAlign: 'center',
    width: 'var(--menu-open-width)',
  },
  overlayHidden: {
    display: 'none',
  },
  overlayBlock: {
    display: 'block',
    zIndex: 10000,
    height: '100vh',
    width: '100vw',
    position: 'fixed',
  },
  sider: {
    fontWeight: '300',
    boxShadow: '4px 0px 13px -2px rgba(0, 0, 0, .3)',
    position: 'fixed',
    height: 'calc(100vh - 52px)',
    zIndex: 10001,
  },
}
