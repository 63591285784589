import React from 'react'

import TableFilter from './TableFilter'
import { Input } from '../controls'

import { createUserFilterFunctions } from '../../utils/createFilterFunctions'

const UserTableFilter = ({
  children,
  users,
}) => {
  const inputs = {
    search: {
      key: 'search',
      label: 'Search by Name or Email',
      field: ({ keyword }, updateInputState, applyFilterDefinitions) => (
        <Input
          onChange={e => updateInputState({ keyword: e.target.value })}
          onPressEnter={applyFilterDefinitions}
          placeholder='Search'
          value={keyword}
        />
      ),
    },
  }

  const formInputs = [inputs.search]

  return (
    <TableFilter
      createFilterFunctions={createUserFilterFunctions}
      formInputs={formInputs}
      rows={users}
    >
      {filteredUsers => children(filteredUsers)}
    </TableFilter>
  )
}

export default UserTableFilter
