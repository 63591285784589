import { useSelector } from 'react-redux'

import { selectAccountId } from '../redux/account/selectors'
import { LoadingStatus } from './appConsts'

/**
 * Returns a loading status and an array of users by accountId
 */
function useUsers() {
  const accountId = useSelector(selectAccountId)
  const usersState = useSelector(state => state.users)
  const { byId, loadingStatus } = usersState

  const loadingUsers = accountId && loadingStatus !== LoadingStatus.Loaded

  return [byId, loadingUsers]
}

export {
  useUsers,
}
