import { forwardRef, useRef, useEffect, useImperativeHandle } from 'react'

interface IndeterminateCheckboxProps {
  indeterminate?: boolean,
}
export const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(({
  indeterminate,
  ...rest
}, ref) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(
    ref,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => inputRef.current!,
    [inputRef]
  )

  useEffect(
    () => {
      if(inputRef.current) {
        inputRef.current.indeterminate = indeterminate ?? false
      }
    },
    [indeterminate]
  )

  return (
    <>
      <input type="checkbox" ref={inputRef} {...rest} />
    </>
  )
})
