import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { css } from 'glamor'
import { createSelector } from 'reselect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import UsersAddRoleModal from '../../client/modals/UsersAddRoleModal'
import UsersRemoveRoleConfirmModal from '../../client/modals/UsersRemoveRoleConfirmModal'
import { LinkButton2, Table } from '../../client/controls'

import actions from '../../actions'
import useAuthz from '../../hooks/useAuthz'
import { useUsers } from '../../utils/hooks'
import { getActiveUsers } from '../../selectors'
import { showModal } from '../../redux/actions'

/**
 * Custom hook that returns active users with specified policyId
 * @param {string} policyId
 */
function useActiveUsersWithPolicyId(policyId) {
  /**
   * @param {Object} state - redux state object
   * @returns {Array} - array of user objects containing policyId
   */
  const getActiveUsersWithPolicyId = useCallback(
    state => createSelector(
      getActiveUsers,
      users => users.filter(user => user.policyIds.includes(policyId))
    )(state),
    [policyId]
  )

  return useSelector(getActiveUsersWithPolicyId)
}

/**
 * @prop {Function} assumeRole
 * @prop {boolean} canAssumeRole
 * @prop {string} policyId
 */
const RoleDetails = ({
  assumeRole,
  canAssumeRole,
  policyId,
}) => {
  const authUserId = useSelector(state => state.auth.userId)
  const rolePolicy = useSelector(state => state.policies.byId[policyId])
  const { name: roleName, description } = rolePolicy ?? {}

  const [, loadingUsers] = useUsers()

  const usersWithPolicy = useActiveUsersWithPolicyId(policyId)

  const dispatch = useDispatch()
  const showAddRoleModal = () => dispatch(showModal(UsersAddRoleModal.id, { policyId }))
  const showRemoveRoleModal = (userId, userName) => dispatch(
    showModal(UsersRemoveRoleConfirmModal.id, {
      userId,
      userName,
      policyId,
      roleName,
    })
  )

  const authz = useAuthz()
  const getCanRemovePolicy = userId => authz(actions.user.removePolicy(null, { userId, policyId, authUserId }))
  const canAddPolicy = authz(actions.user.addPolicy(null, { userId: 'ANY', policyId, authUserId }))

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      render: name => <LinkButton2>{name}</LinkButton2>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    getCanRemovePolicy('ANY') && {
      dataIndex: 'id',
      align: 'right',
      render: (userId, { fullName }) => (
        getCanRemovePolicy(userId) && (
          <LinkButton2 onClick={() => showRemoveRoleModal(userId, fullName)}>
            Remove User
          </LinkButton2>
        )
      ),
    },
  ].filter(Boolean)

  return (
    <div>
      <span style={style.name}>{roleName}</span>
      <span style={style.description}>{description}</span>
      <div style={style.tableHeadingBar}>
        <span style={style.tableHeading} />
        <div {...style.tableHeadingControls}>
          {canAssumeRole && <LinkButton2 onClick={assumeRole}>Assume Role</LinkButton2>}
          {canAddPolicy &&
            <LinkButton2 onClick={showAddRoleModal}>
              <FontAwesomeIcon icon={faPlusCircle} />
              &nbsp;Add User
            </LinkButton2>
          }
        </div>
      </div>
      <Table
        loading={loadingUsers}
        columns={columns}
        dataSource={usersWithPolicy}
      />
    </div>
  )
}

const style = {
  description: {
    fontWeight: '300',
  },
  name: {
    display: 'block',
    fontSize: '20px',
    marginBottom: '12px',
  },
  tableHeadingBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 8px',
  },
  tableHeading: {
    fontSize: '20px',
    margin: '16px 0 10px',
  },
  tableHeadingControls: css({
    display: 'flex',
    alignItems: 'center',
    '> :not(:last-child)': {
      marginRight: '20px',
    },
  }),
}

export default RoleDetails
