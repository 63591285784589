import { ReactElement, useMemo } from 'react'

import { getInternalNodes, getNodeLabels } from '@vms/vmspro3-core/dist/utils/createTree'

import { CriteriaPriorityTable } from './CriteriaPriorityTable'
import { ContextCriteriaPriorityChart } from './ContextCriteriaPriorityChart'
import { ContextRatingNotes } from './ContextRatingNotes'
import { Ratings } from './Ratings'
import { StatsLegend } from '../common/StatsLegend'
import { Tabs, Empty } from '../../client/controls'

import { useQuerystringValue } from '../../hooks/useQuerystringValue'
import {
  useParticipationSessionId,
  usePerformanceCriteriaTreeData,
  useRootPerfCriterionId,
} from '../../redux/hooks/decisionHooks'

const maximumFractionDigits = 1
const formatPercentage = Intl.NumberFormat(undefined, {
  minimumFractionDigits: maximumFractionDigits,
  maximumFractionDigits,
  style: 'percent',
})

export type CriteriaRatingsProps = {
  decisionId: string
}
export function CriteriaRatings({ decisionId }: CriteriaRatingsProps): ReactElement {
  const [tab, setTab] = useQuerystringValue('priorities-tab', 'all-priorities')

  const participationSessionId = useParticipationSessionId(decisionId, 'CriteriaPrioritization')
  const rootPerfCriterionId = useRootPerfCriterionId(decisionId)
  const perfCriTree = usePerformanceCriteriaTreeData(decisionId)

  const contextCriteriaOptions = useMemo(() => {
    if(!perfCriTree) throw new Error(`decision ${decisionId} missing root performance criterion!`)
    const labels = getNodeLabels(perfCriTree)
    return getInternalNodes(perfCriTree).map(n => ({ id: n.data.id, label: labels[n.data.id] }))
  }, [perfCriTree, decisionId])

  if(!perfCriTree?.children.length) {
    return (
      <Empty
        description={(
          <div>
            <h2>No Sub-Criteria</h2>
            <p>In order to have criteria prioritization results, you must have at least one sub-criterion.</p>
          </div>
        )}
      />
    )
  }

  return (
    <Tabs tabPosition="left" activeKey={tab} onChange={setTab}>
      <Tabs.TabPane tab="Prioritization Summary" key="all-priorities">
        <CriteriaPriorityTable decisionId={decisionId} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', margin: '30px 0' }}>
          {contextCriteriaOptions.map(contextCriterionOption => (
            <ContextRatingNotes
              key={contextCriterionOption.id}
              decisionId={decisionId}
              participationSessionId={participationSessionId}
              participantId="*"
              contextId={contextCriterionOption.id}
              contextLabel={`${contextCriterionOption.label} notes`}
              subjectType="Criterion"
            />
          ))}
        </div>
        <StatsLegend formatter={formatPercentage} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Context Priorities" key="context">
        <ContextCriteriaPriorityChart decisionId={decisionId} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Participant Priorities" key="by-participant">
        <Ratings
          decisionId={decisionId}
          subjectType="Criterion"
          pivotType="context"
          pivotLabel="Context Criterion"
          pivotOptions={contextCriteriaOptions}
          pivotIdQuerystringKey="contextCriterionId"
          defaultPivotId={rootPerfCriterionId}
        />
      </Tabs.TabPane>
    </Tabs>
  )
}

