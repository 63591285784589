import { EntityType } from '@vms/vmspro3-core/dist/systemConsts'
import { UpdateAccountSubscriptionType } from '@vms/vmspro3-core/dist/actions/account/updateSubscription'
import { produce } from 'immer'
import _set from 'lodash/set'

import actions from '../../actions'

import accountActionTypes from './actionTypes'
import authActionTypes from '../auth/actionTypes'
import { LoadingStatus } from '../../utils/appConsts'

const initialState = {
  accountId: null,
  account: null,
  accountLoadingStatus: LoadingStatus.NotLoaded,
  userAccounts: null,
  userAccountsLoadingStatus: LoadingStatus.NotLoaded,
  pendingInvitations: null,
  pendingInvitationsLoadingStatus: LoadingStatus.NotLoaded,
}

const accountReducer = produce((state, action) => {
  switch(action?.type) {
    case authActionTypes.SignOut: {
      return initialState
    }
    case 'ResetAccountState': {
      state.accountId = null
      state.account = null
      state.accountLoadingStatus = LoadingStatus.NotLoaded
      state.pendingInvitations = null
      state.pendingInvitationsLoadingStatus = LoadingStatus.NotLoaded
      break
    }

    case accountActionTypes.FetchAccountRequest: {
      state.accountLoadingStatus = LoadingStatus.Requested
      break
    }
    case accountActionTypes.FetchAccountSuccess: {
      state.accountLoadingStatus = LoadingStatus.Loaded
      state.account = action.payload
      break
    }

    case accountActionTypes.FetchUserAccountsRequest: {
      state.userAccountsLoadingStatus = LoadingStatus.Requested
      break
    }
    case accountActionTypes.FetchUserAccountsSuccess: {
      state.userAccounts = action.payload
      state.userAccountsLoadingStatus = LoadingStatus.Loaded
      break
    }

    case accountActionTypes.FetchPendingInvitationsForAccountRequest: {
      state.pendingInvitationsLoadingStatus = LoadingStatus.Requested
      break
    }
    case accountActionTypes.FetchPendingInvitationsForAccountSuccess: {
      state.pendingInvitations = action.payload
      state.pendingInvitationsLoadingStatus = LoadingStatus.Loaded
      break
    }

    case accountActionTypes.SetAccountId: {
      state.accountId = action.payload
      break
    }
    case accountActionTypes.RemoveAccountId: {
      state.accountId = null
      break
    }

    case actions.riskProject.create.toString(): {
      state.account.quotas.projects.used++
      break
    }
    case actions.riskEntity.delete.toString(): {
      if(action.meta.entityType === EntityType.PROJECT) state.account.quotas.projects.used--
      break
    }
    case actions.account.updatePaymentMethod.toString(): {
      _set(state, ['account', 'integrations', 'stripe', 'defaultPaymentMethodId'], action.payload.paymentMethodId)
      break
    }
    case UpdateAccountSubscriptionType: {
      if(!state.account) break  // this will happen when creating an account from the account mgmt screen
      const { product } = action.payload
      state.account.productId = product.id
      Object.entries(product.quotas).forEach(([k, v]) => state.account.quotas[k].allowed = v)
      break
    }
    case actions.account.inviteUser.toString(): {
      if(!Array.isArray(state.pendingInvitations)) {
        state.pendingInvitations = []
      }

      state.pendingInvitations.push(action.payload)
      break
    }
    default: {
      break
    }
  }
}, initialState)

export { initialState }
export default accountReducer
