import React, { useMemo } from 'react'
import { getRiskCategoryLabelFactory } from '@vms/vmspro3-core/dist/utils/risk'

import Explorer from '../common/FlexChart/Structure/Explorer'
import riskConfig from '../common/FlexChart/_riskConfig/map'
import RiskControlSet from '../common/FlexChart/_riskConfig/ControlBar'
import RiskCard from '../common/FlexChart/_riskConfig/Tooltip'
import useEffectiveRiskContext from '../../../hooks/useEffectiveRiskContext'

const RiskExplorer = ({ risks, entityId }) => {
  const effectiveRiskContext = useEffectiveRiskContext(entityId)

  const _risks = useMemo(() => {
    const getCatLabel = getRiskCategoryLabelFactory(effectiveRiskContext.types.category.values)

    // set riskConfig category and phase options to local config object.
    riskConfig.traits.forEach(trait =>
      trait.options = effectiveRiskContext.types[trait.path].values
        .filter(v => !v.isDeleted)
        .map(v => {
          if(trait.path !== 'category') return v
          return { ...v, label: { long: getCatLabel(v.value), short: getCatLabel(v.value) } }
        })
    )

    return risks.map(r => {
      const phase = effectiveRiskContext.types.phase.values.find(v => v.value === r.phase)

      return {
        ...r,
        category: getCatLabel(r.category),
        phase: phase?.label.long,
      }
    })
  }, [risks, effectiveRiskContext])

  return (
    <Explorer
      articles={_risks}
      articleConfig={riskConfig}
      ControBar={RiskControlSet}
      displayHeightPortion={0.5}
      TooltipContent={RiskCard}
      entityId={entityId}
    />
  )
}

export default RiskExplorer
