import { useMemo } from 'react'
import { Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom'
import { Module } from '@vms/vmspro3-core/dist/systemConsts'
import _keyBy from 'lodash/keyBy'

import Home from '../../client/components/Home'
import Dashboard from '../../client/components/Dashboard'
import Logout from '../../client/components/Logout'
import User from '../../client/components/User'
import Users from '../../client/components/Users'
import RiskPortfolio from '../../client/components/Risk/RiskPortfolio'
import RiskEntityConfig from '../../client/components/Risk/RiskEntityConfig'
import RiskDetail from '../../client/components/Risk/Risk'
import {
  AdHocCriteriaPrioritizationPage,
  AdHocOptionRatingPage,
  CriteriaPrioritizationPage,
  DecisionPage,
  DecisionFolderPage,
  InvoicesPage,
  OptionRatingPage,
  RolesPage,
  SignInPage,
  SignUpPage,
  SubscriptionPage,
} from '../../pages'
import AccountSelection from './AccountSelection'
import Eula from './Eula'
import { Spin } from '../../client/controls'

import { useAccount, useAccountId, useCanUpdateSubscription } from '../../redux/account/hooks'
import { useIsAuthenticated } from '../../redux/auth/hooks'
import { useAccountUser } from '../../redux/user/hooks'
import { selectAccountDecisionsQuota } from '../../redux/account/selectors'
import useAuthz from '../../hooks/useAuthz'

import config from '../../config.json'
import actions from '../../actions'
import { useAppSelector } from '../../redux'
import { useUserPolicies } from '../../redux/policies/hooks'
import { Product } from '@vms/vmspro3-core/dist/types'

const eulasById = _keyBy(config.instance?.eulas, 'id')
const productsById = _keyBy(config.instance.products as Product[], 'id')

interface WithConditionProps {
  loading: boolean,
  condition: boolean,
}
const WithCondition = ({ loading, condition }: WithConditionProps) => {
  if(loading) {
    return <Spin />
  }
  if(condition) {
    return <Outlet />
  }
  return <Navigate to="/" replace />
}

const WithAccount = () => {
  const accountId = useAccountId()

  const [account, accountLoading] = useAccount()
  const [accountUser, accountUserLoading] = useAccountUser()

  const eula = account?.productId ? eulasById[productsById[account.productId]?.eulaId] : undefined
  const authUserHasAgreedToLatestEula = useMemo(
    () => {
      const eulaVersion = eula?.version
      return eulaVersion && accountUser?.eulas?.[eula?.id] === eulaVersion
    },
    [eula, accountUser]
  )

  if(!accountId) {
    return <AccountSelection />
  } else if(accountLoading || accountUserLoading) {
    return <Spin />
  } else if(!authUserHasAgreedToLatestEula) {
    return <Eula />
  }

  return <Outlet />
}

const PrivateRoutes = () => {
  const authz = useAuthz()
  const [, userPoliciesLoading] = useUserPolicies()
  const [, accountLoading] = useAccount()

  const withUseRiskModule = (
    <WithCondition
      loading={userPoliciesLoading}
      // TODO: remove "any" type when actions are refactored
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      condition={authz((actions.feature as any).useModule(null, { moduleKey: Module.PORTFOLIO_RISK }))}
    />
  )

  const withReadInvoices = (
    <WithCondition
      loading={userPoliciesLoading}
      condition={authz(actions.account.readInvoices())}
    />
  )

  const withUpdateSubscription = (
    <WithCondition
      loading={userPoliciesLoading}
      condition={useCanUpdateSubscription()}
    />
  )

  const accountDecisionsQuota = useAppSelector(selectAccountDecisionsQuota)
  const withUseDecisionModule = (
    <WithCondition
      loading={accountLoading}
      condition={accountDecisionsQuota?.allowed > 0}
    />
  )

  return (
    <Routes>
      <Route path="/" element={<WithAccount />}>
        <Route index element={<Dashboard />} />
        <Route path="user/:userId" element={<User />} />
        <Route path="user-management" element={<Users />} />
        <Route path="user-management/roles" element={<RolesPage />} />
        <Route path="subscription" element={withUpdateSubscription}>
          <Route index element={<SubscriptionPage />} />
        </Route>
        <Route path="invoices" element={withReadInvoices}>
          <Route index element={<InvoicesPage />} />
        </Route>
        <Route path="port" element={withUseRiskModule}>
          <Route index element={<RiskPortfolio entityType="PORTFOLIO" />} />
          <Route path="config" element={<RiskEntityConfig />} />
          <Route path=":entityId" element={<RiskPortfolio entityType="PORTFOLIO" />} />
          <Route path=":entityId/config" element={<RiskEntityConfig />} />
        </Route>
        <Route path="prog" element={withUseRiskModule}>
          <Route index element={<Navigate to="/port" />} />
          <Route path=":entityId" element={<RiskPortfolio entityType="PROGRAM" />} />
          <Route path=":entityId/config" element={<RiskEntityConfig />} />
        </Route>
        <Route path="proj" element={withUseRiskModule}>
          <Route index element={<Navigate to="/port" />} />
          <Route path=":entityId" element={<RiskPortfolio entityType="PROJECT" />} />
          <Route path=":entityId/config" element={<RiskEntityConfig />} />
          <Route path=":projectId/risk/:riskId" element={<RiskDetail />} />
        </Route>
        <Route path="decision" element={withUseDecisionModule}>
          <Route index element={<DecisionFolderPage />} />
          <Route path=":decisionId" element={<DecisionPage />} />
        </Route>
        <Route
          path="/:accountId/decision/:decisionId/option-rating/:participationSessionId"
          element={<OptionRatingPage />}
        />
        <Route
          path="/:accountId/decision/:decisionId/criteria-prioritization/:participationSessionId"
          element={<CriteriaPrioritizationPage />}
        />
      </Route>
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

const PublicRoutes = () => {
  const location = useLocation()

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route
        path="/:accountId/decision/:decisionId/option-rating/:participationSessionId"
        element={<AdHocOptionRatingPage />}
      />
      <Route
        path="/:accountId/decision/:decisionId/criteria-prioritization/:participationSessionId"
        element={<AdHocCriteriaPrioritizationPage />}
      />
      <Route path="*" element={<Navigate to="/signin" state={{ from: location }} />} />
    </Routes>
  )
}

export const AppRoutes = () => {
  const [isAuthenticated] = useIsAuthenticated()

  if(isAuthenticated) {
    return <PrivateRoutes />
  }

  return <PublicRoutes />
}
