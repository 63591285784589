import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import QRCode from 'react-qr-code'
import { ParticipationSessionType } from '@vms/vmspro3-core/dist/types'
import { startParticipationSession, endParticipationSession } from '@vms/vmspro3-core/dist/actions/decision'
import _kebabCase from 'lodash/kebabCase'

import { Button, Modal, Spin, Empty } from '../../client/controls'

import Server from '../../server/VMSProServerAdapter'
import getAugmentAction from '../../selectors/getAugmentAction'
import { useAccountId } from '../../redux/account/hooks'
import {
  useOptions,
  useParticipationSession,
  usePerformanceCriteriaTreeData,
} from '../../redux/hooks/decisionHooks'
import { ParticipationSessionLabel } from '../../utils/appConsts'

import { ReactComponent as Closed } from '../../svg/closed.svg'
import style from './ParticipationTab.module.css'

function useParticipationTabControls(decisionId: string, participationSessionId: string) {
  const dispatch = useDispatch()

  const augmentAction = useSelector(getAugmentAction)
  const [loadingParticipation, setLoadingParticipation] = useState(false)
  const onOpenParticipation = useCallback(
    () => {
      const action = augmentAction(startParticipationSession(decisionId, participationSessionId))
      setLoadingParticipation(true)
      Server.tryAction(action)
        .then(() => {
          action.meta.ephemeral = true
          dispatch(action)
          setLoadingParticipation(false)
        })
    },
    [dispatch, decisionId, participationSessionId, augmentAction]
  )
  const openParticipationButton = (
    <Button
      loading={loadingParticipation}
      onClick={onOpenParticipation}
      type="primary"
    >
      Open Participation
    </Button>
  )

  const onCloseParticipation = useCallback(
    () => Modal.confirm({
      title: 'Close Participation',
      content: 'Are you sure you want to close participation?',
      onOk: () => dispatch(endParticipationSession(decisionId, participationSessionId)),
    }),
    [dispatch, decisionId, participationSessionId]
  )
  const closeParticipationButton = (
    <Button onClick={onCloseParticipation} danger>
      Close Participation
    </Button>
  )
  return {
    openParticipationButton,
    closeParticipationButton,
  }
}

type ParticipationTabProps = {
  decisionId: string,
  participationSessionType: ParticipationSessionType,
  joinSessionLinkLabel: string,
}
export function ParticipationTab({
  decisionId,
  participationSessionType,
  joinSessionLinkLabel,
}: ParticipationTabProps): JSX.Element {
  const accountId = useAccountId()
  const participationSession = useParticipationSession(decisionId, participationSessionType)
  const { status, id: participationSessionId } = participationSession

  const perfCriTree = usePerformanceCriteriaTreeData(decisionId)
  const options = useOptions(decisionId)

  const {
    openParticipationButton,
    closeParticipationButton,
  } = useParticipationTabControls(decisionId, participationSessionId)

  const participationPathname = `/${accountId}/decision/${decisionId}/` +
    `${_kebabCase(participationSessionType)}/${participationSessionId}`

  if(!participationSession) return <Spin />

  const sessionLabel = ParticipationSessionLabel[participationSessionType]

  if(participationSessionType === 'CriteriaPrioritization' && !perfCriTree?.children.length) {
    return (
      <Empty
        description={(
          <div>
            <h2>No Sub-Criteria</h2>
            <p>In order to prioritize criteria, you must have at least one sub-criterion.</p>
          </div>
        )}
      />
    )
  }
  if(participationSessionType === 'OptionRating' && options.length === 0) {
    return (
      <Empty
        description={(
          <div>
            <h2>No Options</h2>
            <p>In order to rate options, you must have at least one option.</p>
          </div>
        )}
      />
    )
  }

  return (
    <>
      <h2>{sessionLabel}</h2>
      {status === 'Active' && (
        <>
        <div className={style.tabStyle}>
          <div className={style.linkAndCopy}>
            <Link to={participationPathname}>{joinSessionLinkLabel}</Link>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}${participationPathname}`)
              }}
            >Copy Link</Button>
          </div>
          <div className={style.qrCode}>
            <QRCode value={`${window.location.origin}${participationPathname}`} />
          </div>
          <div className={style.openCloseSession}>
           {closeParticipationButton}
          </div>
        </div>
        </>
      )}
      {status === 'Inactive' && (
        <div className={style.tabStyle}>
          <p>Participation is closed.</p>
          <Closed className={style.closedSvg} />
          <div className={style.openCloseSession}>
          {openParticipationButton}
          </div>
        </div>
      )}
    </>
  )
}
