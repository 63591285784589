import { Ref, useMemo } from 'react'
import { Participant, Rating, RatingNotes } from '@vms/vmspro3-core/dist/types'
import { Select } from 'antd'
import { RefSelectProps } from 'antd/lib/select'
import _mapValues from 'lodash/mapValues'
import { gql, useMutation } from '@apollo/client'

import FormModal from './FormModal'
import { Form } from '../controls'

import { useModalData } from '../../redux/hooks'
import { ParticipantFieldsFragment, RatingFieldsFragment, RatingNotesFieldsFragment } from '../../graphql'

interface MergeParticipantsData {
  mergeParticipants: {
    participant: Participant,
    ratings: Rating[],
    ratingNotes: RatingNotes[],
  }
}
interface MergeParticipantsVariables {
  accountId: string,
  decisionId: string,
  participantIds: string[],
  participantData: {
    fullName: string,
    email?: string,
    phone?: string,
  },
}
const MERGE_PARTICIPANTS = gql`
  mutation MergeParticipants(
    $accountId: ID!
    $decisionId: ID!
    $participantIds: [ID!]!
    $participantData: MergeParticipantsInput!
  ) {
    mergeParticipants(
      accountId: $accountId
      decisionId: $decisionId
      participantIds: $participantIds
      participantData: $participantData
    ) {
      participant {
        ...ParticipantFields,
        associatedUser { fullName }
      }
      ratings { ...RatingFields }
      ratingNotes { ...RatingNotesFields }
    }
  }
  ${ParticipantFieldsFragment}
  ${RatingFieldsFragment}
  ${RatingNotesFieldsFragment}
`

export const ParticipantsMergeModalId = 'ParticipantsMergeModal'
export type ParticipantsMergeModalData = {
  accountId: string,
  decisionId: string,
  participants: Participant[]
}
export function ParticipantsMergeModal() {
  const {
    accountId,
    decisionId,
    participants,
  } = useModalData<ParticipantsMergeModalData>(ParticipantsMergeModalId)

  const { selectOptions, initialValues } = useMemo(
    () => {
      const optionValues = {
        fullName: participants
          .map(participant => participant.fullName)
          .sort((a, b) => b.length - a.length),
        email: participants
          .slice()
          .sort((a, b) => a.created.timestamp - b.created.timestamp)
          .map(participant => participant.email),
        phone: participants.map(participant => participant.phone),
      }

      const selectOptions = _mapValues(optionValues, (values: (string | undefined)[]) => {
        const definedValues = values.filter((value): value is string => Boolean(value))
        const uniqueValues = Array.from(new Set(definedValues))
        return uniqueValues.map(value => ({ value, label: value }))
      })
      const initialValues = _mapValues(selectOptions, options => options[0]?.value)

      return {
        selectOptions,
        initialValues,
      }
    },
    [participants]
  )

  const [mergeParticipantsMutation] = useMutation<MergeParticipantsData, MergeParticipantsVariables>(
    MERGE_PARTICIPANTS,
    {
      refetchQueries: ['GetParticipants'],
    }
  )

  const onOk = (participantData: { fullName: string, email?: string, phone?: string }) => {
    const participantIds = participants.map(participant => participant.id)
    mergeParticipantsMutation({
      variables: {
        accountId,
        decisionId,
        participantIds,
        participantData,
      },
    })
  }

  return (
    <FormModal
      modalId={ParticipantsMergeModalId}
      onOk={onOk}
      initialValues={initialValues}
    >
      {(initialFocusRef: Ref<RefSelectProps>) => (
        <>
          <Form.Item label="Name" name="fullName">
            <Select ref={initialFocusRef} options={selectOptions.fullName} />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Select options={selectOptions.email} />
          </Form.Item>
          <Form.Item label="Phone" name="phone">
            <Select options={selectOptions.phone} />
          </Form.Item>
        </>
      )}
    </FormModal>
  )
}
ParticipantsMergeModal.id = ParticipantsMergeModalId
