import React from 'react'
import { connect } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Card, Form, Spin } from '../controls'
import PageHeader from './common/PageHeader'

import { contentMargin } from '../styles/style-consts'
import { selectAccountId } from '../../redux/account/selectors'
import { fetchUsers } from '../../redux/users/actions'

class User extends React.Component {
  componentDidMount() {
    const { users, userId, accountId, dispatch } = this.props
    // TODO: we no longer have a async pattern for loading a single user (other
    // than the login user on initial payload), so this loads all users to see
    // details about a single user, but this component needs a refresh anyway
    if(userId && !users.ids.includes(userId)) {
      dispatch(fetchUsers(accountId))
    }
  }

  render() {
    const { users, userId } = this.props
    if(!users.byId[userId]) return <Spin />
    const { fullName, email, phone, initials, shortName, jobTitle, id } = users.byId[userId]
    return (
      <div>
        <PageHeader title="User Information" />
        <div style={style.content}>
          <Card title={`User Details for ${fullName || email}`}>
            <Form>
              <Form.Item label="Email">
                <a href={`mailto:${email}`}>{email}</a>
              </Form.Item>
              <Form.Item label="Phone Number">
                {phone || '-'}
              </Form.Item>
              <Form.Item label="Job Title">
                {jobTitle || '-'}
              </Form.Item>
              <Form.Item label="Short Name">
                {shortName || '-'}
              </Form.Item>
              <Form.Item label="Initials">
                {initials || '-'}
              </Form.Item>
              <h6 style={style.userIdStyle}>User ID:</h6>
              <h6>{id}</h6>
            </Form>
          </Card>
        </div>
      </div>
    )
  }
}

const style = {
  content: {
    margin: contentMargin,
  },
  userIdStyle: {
    marginTop: '20px',
  },
}

const state2props = (state, props) => {
  const { router: { params } } = props
  const { users } = state
  const { userId } = params
  const accountId = selectAccountId(state)
  return {
    userId,
    accountId,
    users,
  }
}

// courtesy https://reactrouterdotcom.fly.dev/docs/en/v6/faq#what-happened-to-withrouter-i-need-it
function customWithRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    )
  }

  return ComponentWithRouterProp
}

export default customWithRouter(connect(state2props)(User))
