import { customAlphabet } from 'nanoid'

const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 22)

/**
 * Generates a unique ID.  Currently using a 22-character [nanoid|https://github.com/ai/nanoid] with
 * an alphabet that includes 0-9, a-z, A-Z.
 *
 * @returns {string} A unique ID.
 */
export function createId() {
  return nanoid()
}
