import { useCallback, ReactElement } from 'react'
import { deleteDecision } from '@vms/vmspro3-core/dist/actions/decision'

import FormModal from './FormModal'
import { Spin } from '../controls'

import { useAppDispatch } from '../../redux'
import { useModalData } from '../../redux/hooks/appHooks'
import { useDecision } from '../../redux/hooks/decisionHooks'

export const DecisionDeleteModalId = 'DecisionDeleteModal'
export type DecisionDeleteModalData = {
  decisionId: string,
  parentDecisionFolderId: string,
}
export function DecisionDeleteModal(): ReactElement {
  const { decisionId, parentDecisionFolderId } = useModalData<DecisionDeleteModalData>(DecisionDeleteModalId)

  const decision = useDecision(decisionId, true)

  const dispatch = useAppDispatch()
  const onOk = useCallback(
    () => dispatch(deleteDecision(decisionId, parentDecisionFolderId)),
    [dispatch, decisionId, parentDecisionFolderId]
  )

  return (
    <FormModal modalId={DecisionDeleteModalId} onOk={onOk}>
      {decision
        ? (
          <>
            <h3>Deleting {decision.name}</h3>
            <p>Are you sure you want to delete {decision.name}?</p>
          </>
        )
        : <Spin />
      }
    </FormModal>
  )
}
DecisionDeleteModal.id = DecisionDeleteModalId
