import { createSelector } from 'reselect'

export const selectAccountState = state => state.account

// current account
export const selectAccountId = createSelector(
  [selectAccountState],
  state => state.accountId
)
export const selectAccount = createSelector(
  [selectAccountState],
  state => state.account
)
export const selectAccountLoadingStatus = createSelector(
  [selectAccountState],
  state => state.accountLoadingStatus
)
export const selectAccountName = createSelector(
  [selectAccount],
  account => account?.name
)

// current account quotas
export const selectAccountQuotas = createSelector(
  [selectAccount],
  account => account?.quotas
)
export const selectAccountUsersQuota = createSelector(
  [selectAccountQuotas],
  quotas => quotas?.users
)
export const selectAccountProjectsQuota = createSelector(
  [selectAccountQuotas],
  quotas => quotas?.projects
)
export const selectAccountDecisionsQuota = createSelector(
  [selectAccountQuotas],
  quotas => quotas?.decisions
)

// all user accounts
export const selectUserAccounts = createSelector(
  [selectAccountState],
  state => state.userAccounts
)
export const selectUserAccountsLoadingStatus = createSelector(
  [selectAccountState],
  state => state.userAccountsLoadingStatus
)

// pending invitations
export const selectPendingInvitationsForAccount = createSelector(
  [selectAccountState],
  state => state.pendingInvitations
)
export const selectPendingInvitationsLoadingStatusForAccount = createSelector(
  [selectAccountState],
  state => state.pendingInvitationsLoadingStatus
)
