/* eslint-disable @typescript-eslint/no-var-requires, @typescript-eslint/no-explicit-any */
const { createId } = require('../idUtils')
const { EntityType, RiskAnalysisStatus } = require('../systemConsts')
const unflattenDeep = require('../utils/unflattenDeep')
const { flattenRisk, recomputeQuantValues } = require('../utils/risk')

const UpdateRiskActionType = 'Update Risk'
/**
 * Update a risk.
 *
 * @param {Object} payload - action payload
 * @param {Object} meta - action metadata
 *  @prop {String} meta.riskId - the ID of the risk to update
 *  @prop {string} meta.ancestry - The hierarchical ancestry of the risk (required for creating PubSub topic name).
 *  @prop {String} meta.projectId - the ID of the risk project to which this risk belongs
 *
 * @returns {object} Action with type 'Update Risk'.
 */
function riskUpdateActionCreator(payload: any, meta: any) {
  const { projectId, riskId, ancestry } = meta
  const type = UpdateRiskActionType
  if(!projectId) throw new Error(`${type}: missing projectId`)
  if(!riskId) throw new Error(`${type}: missing riskId`)
  if(!ancestry) throw new Error(`${type}: missing action.meta.ancestry`)

  meta.authz = meta.authz || {}
  if(!meta.authz.resources) meta.authz.resources = [riskId, projectId]
  else meta.authz.resources.push(riskId, projectId)

  return {
    module: 'Risk',
    type,
    payload,
    meta,
  }
}
riskUpdateActionCreator.toString = () => UpdateRiskActionType

const DeleteRiskActionType = 'Delete Risk'
/**
 * Delete a risk.
 *
 * @param {Object} payload - action payload
 * @param {Object} meta - action metadata
 *  @prop {String} meta.riskId - the ID of the risk to delete
 *  @prop {string} meta.ancestry - The hierarchical ancestry of the risk (required for creating PubSub topic name).
 *  @prop {String} meta.projectId - the ID of the risk project to which this risk belongs
 *
 * @returns {object} Action with type 'Delete Risk'.
 */
function riskDeleteActionCreator(payload: any, meta: any) {
  const { projectId, riskId, ancestry } = meta
  const type = DeleteRiskActionType
  if(!projectId) throw new Error(`${type}: missing projectId`)
  if(!riskId) throw new Error(`${type}: missing riskId`)
  if(!ancestry) throw new Error(`${type}: missing action.meta.ancestry`)

  meta.authz = meta.authz || {}
  if(!meta.authz.resources) meta.authz.resources = [riskId, projectId]
  else meta.authz.resources.push(riskId, projectId)

  return {
    module: 'Risk',
    type,
    payload,
    meta,
  }
}
riskDeleteActionCreator.toString = () => DeleteRiskActionType

const CreateRiskActionType = 'Create Risk'
/**
 * Create new risk.  Each risk is assigned a number as it is added to the context; these
 * numbers are intended to be a unique identifier, but there are two subtlies.  First,
 * collisions can occur and the server will have to sort it out and update the "loser".
 * Second, an authorized user can request risk renumbering, meaning these numbers, while
 * nominally unique, are not necessarily unique for the life of a risk; only at a moment
 * in time within the risk context (after the server has resolved any collisions).
 *
 * @param {number} num - The number of the risk within the context; see comments above.
 * @param {string} [id] - The ID of the risk (usually for testing; autogenerated by default).
 * @param {string} ancestry - The ancestry of the risk
 * @param {object} effectiveRiskContext - The effective risk context
 * @param {string} name - The name of the risk (must be non-empty, non-whitespace).
 * @param {object} [sourceRisk] - The source risk if this is a copy of an existing risk.
 * @param {boolean} [isCopy=false] - is the new risk a copy of an existing risk
 *
 * @returns {object} Action with type 'Create Risk'.
 */
function riskCreateActionCreator(
  {
    num,
    id = createId(),
    ancestry,
    effectiveRiskContext,
    name,
    sourceRisk,
    isCopy = false,
  }: {
    num: number
    id: string
    ancestry: string
    effectiveRiskContext: any
    name: string
    sourceRisk: any
    isCopy: boolean
  },
  meta: any
) {

  const { projectId, sourceProjectId } = meta
  const type = CreateRiskActionType
  if(!ancestry) throw new Error(`${type}: missing ancestry`)
  if(!projectId) throw new Error(`${type}: missing project ID`)
  if(!effectiveRiskContext) throw new Error(`${type}: missing effective risk context`)
  if(typeof name !== 'string' || !/\S/.test(name)) throw new Error(`${type}: missing or invalid name`)

  meta.authz = meta.authz || {}
  if(!meta.authz.resources) meta.authz.resources = [id, projectId]
  else meta.authz.resources.push(id, projectId)

  // TODO: better typing for payload
  const payload: Record<string, any> = {}

  const riskTypeNone = effectiveRiskContext.types.riskType.nullProxyValue

  // defaults - these may be overriden if this is a risk copy
  payload.type = effectiveRiskContext.types.riskType.defaultValue
  payload.status = RiskAnalysisStatus.ACTIVE
  const defaultProb = effectiveRiskContext.riskScales.prob.find((v: any) => v.isDefault)
  if(!defaultProb) throw new Error('effective risk context missing default probability')
  const [min, max] = defaultProb.quantRange
  payload['managed.prob.qual'] = payload['prob.qual'] = defaultProb.key
  payload['managed.prob.quant'] = payload['prob.quant'] = (min + max) / 2
  payload['managed.prob.linked'] = true
  payload['managed.impact.cost.type'] = payload['impact.cost.type'] = riskTypeNone
  payload['managed.impact.time.type'] = payload['impact.time.type'] = riskTypeNone
  payload['managed.impact.perf.type'] = payload['impact.perf.type'] = riskTypeNone
  payload['managed.impact.cost.linked'] = true
  payload['managed.impact.time.linked'] = true
  payload['managed.impact.perf.linked'] = true
  payload['managed.severity.cost'] = payload['severity.cost'] = null
  payload['managed.severity.time'] = payload['severity.time'] = null
  payload['managed.severity.perf'] = payload['severity.perf'] = null
  payload['managed.severity.total'] = payload['severity.total'] = null

  if(isCopy) {
    Object.assign(payload, flattenRisk(sourceRisk))
    payload.copiedFromRiskId = sourceRisk.id

    if(projectId !== sourceProjectId) {
      const { update } = recomputeQuantValues(effectiveRiskContext, unflattenDeep(payload))
      Object.assign(payload, update)
    }
  }

  // these fields get overriden if this is a "copy risk" creation
  payload.num = num
  payload.name = name
  payload.id = id
  payload.entityType = EntityType.RISK

  return {
    module: 'Risk',
    type,
    payload,
    meta: { ancestry, projectId: meta.projectId, ...meta },
  }
}
riskCreateActionCreator.toString = () => CreateRiskActionType

export default {
  update: riskUpdateActionCreator,
  delete: riskDeleteActionCreator,
  create: riskCreateActionCreator,
}
