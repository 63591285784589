import { createContext, useContext, Provider } from 'react'

export function createTypedContext<
  C extends unknown | null
>(): readonly [useTypedContext: () => C, TypedContextProvider: Provider<C | undefined>] {
  const context = createContext<C | undefined>(undefined)
  function useTypedContext() {
    const c = useContext(context)
    if(c === undefined) {
      throw new Error('useTypedContext must be used inside a Provider with a value')
    }
    return c
  }
  return [useTypedContext, context.Provider]
}
