import { produce } from 'immer'
import { RatingNotes } from '@vms/vmspro3-core/dist/types'
import { DeleteParticipantAction, UpdateRatingNotesAction } from '@vms/vmspro3-core/dist/actions/decision'
import _omitBy from 'lodash/omitBy'
import _matches from 'lodash/matches'

import { FetchDecisionEntitySuccessAction, FetchDecisionFolderChildrenSuccessAction } from '../actionTypes'

export type RatingNotesState = {
  byId: Record<string, RatingNotes>
}

export const initialState: RatingNotesState = {
  byId: {},
}
type Actions =
  | FetchDecisionEntitySuccessAction
  | FetchDecisionFolderChildrenSuccessAction
  | UpdateRatingNotesAction
  | DeleteParticipantAction

export const ratingNotesReducer = produce<RatingNotesState, [Actions?]>((state, action) => {
  if(!action?.type) return

  switch(action.type) {
    case 'FetchDecisionFolderChildrenSuccess': // fall through to fetchDecisionEntitySuccess
    case 'FetchDecisionEntitySuccess': {
      if(action.payload.ratings) {
        Object.assign(state.byId, action.payload.ratingNotes)
      }
      break
    }

    case 'Update Rating Notes': {
      state.byId[action.meta.id] = {
        ...action.payload,
        updated: {
          timestamp: Date.now(),
          location: '{}',
        },
      }
      break
    }

    case 'Delete Participant': {
      state.byId = _omitBy(state.byId, _matches({ participantId: action.meta.participantId }))
      break
    }

    default: {
      break
    }
  }
}, initialState)
