import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Duration, DurationUnit } from '@vms/vmspro3-core/dist/utils/qty'
import { createOverlayRiskContext } from '@vms/vmspro3-core/dist/utils/risk'
import { EntityType } from '@vms/vmspro3-core/dist/systemConsts'

import FormModal from './FormModal'
import RiskEntityIdentityFormFields from '../components/Risk/RiskEntityIdentityFormFields'

import actions from '../../actions'
import { getEntityTypeLabel } from '../../utils/formatUtils'
import { effectiveRiskContextFromAncestry } from '../../selectors'
import { useModalData } from '../../redux/hooks/appHooks'

const actionKeyByEntityType = {
  [EntityType.PORTFOLIO]: 'riskPortfolio',
  [EntityType.PROGRAM]: 'riskProgram',
  [EntityType.PROJECT]: 'riskProject',
}

/**
 * Create risk project details (name, description, cost, time).  Note that
 * cost and time need to be synchronized with the risk context.
 *
 * The following properties must be included in the modal data:
 *   ancestry - the ancestry of the entity being created. use joinAncestry(parent.ancestry, parent.id)
 */
const RiskEntityCreateModal = () => {
  const {
    ancestry,
    entityType,
  } = useModalData(RiskEntityCreateModal.id)

  const effectiveRiskContext = useSelector(effectiveRiskContextFromAncestry(ancestry))
  const entityTypeLabel = getEntityTypeLabel(entityType, ancestry)

  const dispatch = useDispatch()
  const onOk = values => {
    if(entityType === EntityType.PROJECT) {
      values.commonId = values.commonId?.trim()
      values.name = values.name.trim()
      // TODO: see https://vms.atlassian.net/browse/VP3-2220
      values.time = Duration.convert(values.time, DurationUnit.Months)
      values.riskContext = createOverlayRiskContext(effectiveRiskContext, values.cost, values.time)
    } else {
      values.riskContext = createOverlayRiskContext(effectiveRiskContext)
    }

    const actionKey = actionKeyByEntityType[entityType]
    const entityCreateAction = actions[actionKey].create({ ...values, ancestry })
    dispatch(entityCreateAction)
  }

  return (
    <FormModal modalId={RiskEntityCreateModal.id} onOk={onOk}>
      {initialFocusRef =>
        <RiskEntityIdentityFormFields
          initialFocusRef={initialFocusRef}
          entityType={entityType}
          entityTypeLabel={entityTypeLabel}
          ancestry={ancestry}
        />
      }
    </FormModal>
  )
}
RiskEntityCreateModal.id = 'RiskEntityCreateModal'

export default RiskEntityCreateModal
