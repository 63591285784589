import React, { CSSProperties, useCallback, useEffect, useState } from 'react'
import Input from './Input'

import { formatNumValue, parseNumValue } from '../../utils/inputUtils'
import { InputProps } from 'antd'

interface NumberInputProps {
  align?: CSSProperties['textAlign'],
  allowNull?: boolean,
  className?: string,
  decimalPlaces?: number,
  disabled?: boolean,
  inputMode?: InputProps['inputMode'],
  isPercentage?: boolean,
  max?: number,
  min?: number,
  onBlur?: React.FocusEventHandler<HTMLInputElement>,
  onChange?: (value: number | null) => void,
  readOnly?: boolean,
  style?: CSSProperties,
  trimDecimals?: boolean,
  value?: number | null,
}

const NumberInput = React.forwardRef<Input, NumberInputProps>(({
  align = 'right',
  allowNull = true,
  className,
  decimalPlaces = 0,
  disabled = false,
  inputMode = 'numeric',
  isPercentage = false,
  max,
  min,
  onBlur,
  onChange,
  readOnly = false,
  style,
  trimDecimals = false,
  value,
  ...props
}, ref) => {
  const formatNum = useCallback(v => formatNumValue(v, {
    allowNull,
    decimalPlaces,
    isPercentage,
    trimDecimals,
  }), [allowNull, decimalPlaces, isPercentage, trimDecimals])
  const [displayValue, setDisplayValue] = useState(() => formatNum(value))
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    if(!editing) setDisplayValue(formatNum(value))
  }, [formatNum, editing, value])

  if(readOnly) {
    return (
      <div ref={ref as React.LegacyRef<HTMLDivElement>} style={{ ...readOnlyStyle, textAlign: align }}>
        {displayValue}
      </div>
    )
  }

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = event => {
    setEditing(false)
    if(onBlur) onBlur(event)
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const v = event.target.value.replace(/[^\d.,-]/g, '')
    setEditing(true)
    setDisplayValue(v)

    if(onChange) {
      const parsedValue = parseNumValue(v, allowNull)

      if(parsedValue === null) {
        return onChange(null)
      }

      const normalizedValue = parsedValue / (isPercentage ? 100 : 1)
      if(typeof max === 'number' && normalizedValue > max) {
        onChange(max)
      } else if(typeof min === 'number' && normalizedValue < min) {
        onChange(min)
      } else {
        onChange(normalizedValue)
      }
    }
  }

  return (
    <Input
      {...props}
      className={className}
      inputMode={inputMode}
      onBlur={handleBlur}
      onChange={handleChange}
      disabled={disabled}
      ref={ref}
      style={{ ...style, textAlign: align }}
      value={displayValue}
    />
  )
})

const readOnlyStyle: React.CSSProperties = {
  minHeight: '32px',
  padding: '6px 12px 5px',
  width: '100%',
}

export default NumberInput
