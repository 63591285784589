/* eslint-disable @typescript-eslint/no-var-requires, no-undef */
const { EntityType, ROOT_ACCOUNT_ID } = require('../systemConsts')

const actions = {}

actions.read = (() => {
  const type = 'Read Account'
  function read() {
    return {
      module: 'System',
      type,
      payload: {},
      meta: {},
    }
  }
  read.toString = () => type
  return read
})()

actions.cancelSubscription = (() => {
  const type = 'Cancel Subscription'
  /**
   * For now, this aciton is stubbed out.
   */
  function cancelSubscriptionActionCreator(accountId, reason) {
    return {
      module: 'System',
      type,
      payload: {},
      meta: { accountId, reason },
    }
  }
  cancelSubscriptionActionCreator.toString = () => type
  return cancelSubscriptionActionCreator
})()

actions.updatePaymentMethod = (() => {
  const type = 'Update Payment Method'
  function updatePaymentMethodActionCreator(paymentMethodId) {
    return {
      module: 'System',
      type,
      payload: { paymentMethodId },
      meta: {},
    }
  }
  updatePaymentMethodActionCreator.toString = () => type
  return updatePaymentMethodActionCreator
})()

actions.readInvoices = (() => {
  const type = 'Read Invoices'
  /**
   * For now, this aciton is stubbed out.
   */
  function readInvoicesActionCreator() {
    return {
      module: 'System',
      type,
      payload: {},
      meta: {},
    }
  }
  readInvoicesActionCreator.toString = () => type
  return readInvoicesActionCreator
})()

actions.readSubscription = (() => {
  const type = 'Read Subscription'
  /**
   * For now, this aciton is stubbed out.
   */
  function readSubscriptionActionCreator() {
    return {
      module: 'System',
      type,
      payload: {},
      meta: {},
    }
  }
  readSubscriptionActionCreator.toString = () => type
  return readSubscriptionActionCreator
})()

actions.delete = (() => {
  const type = 'Delete Account'
  /**
   * Action creator for marking an account entity as "deleted".
   *
   * @param {undefined} payload - unused payload argument, to keep with
   *   payload/meta action creator function signature convention.
   * @returns {{ type: string, payload: object, meta: object }}
   */
  function deleteAccountActionCreator(payload, { accountId }) {
    if(!accountId) throw new Error(type + ': action.meta.accountId is required')
    return {
      module: 'System',
      type,
      payload: {},
      meta: { accountId },
    }
  }
  deleteAccountActionCreator.toString = () => type
  return deleteAccountActionCreator
})()

/**
 * Action creator for inviting a user to a VMSPro account.
 *
 * @param {Object} payload
 * @param {string} payload.email
 * @param {string} payload.fullName
 * @param {string} payload.accountId
 * @param {string} payload.accountName
 * @param {string} payload.invitedById
 * @param {string} payload.invitedByName
 * @param {Array} payload.policyIds - account authorization policy IDs
 */
function inviteUser(payload, meta = {}) {
  const requiredKeys = [
    'email',
    'fullName',
    'accountId',
    'accountName',
    'invitedById',
    'invitedByName',
    'policyIds',
  ]
  requiredKeys.forEach(key => {
    if(!payload[key]) throw new Error(inviteUser.toString() + `: action.payload.${key} is required`)
  })

  const { accountId, email, fullName, accountName, invitedById, invitedByName, policyIds } = payload

  return {
    module: 'System',
    type: inviteUser.toString(),
    payload: {
      accountId: ROOT_ACCOUNT_ID,
      // accountId first for queries about all invites from an account
      id: `${payload.accountId}/${payload.email}`,
      // email first for queries about all invites for a user
      ancestry: `${payload.email}/${payload.accountId}`,
      entityType: EntityType.USER_INVITATION,
      fullName,
      email,
      accountName,
      // accountId reserved for database primary index key
      inviteToAccountId: accountId,
      invitedById,
      invitedByName,
      created: Date.now(),
      policyIds,
    },
    meta,
  }
}
inviteUser.toString = () => 'Invite User'
actions.inviteUser = inviteUser

/**
 *
 * @param {Object} payload
 * @param {string} payload.accountId
 * @param {string} payload.userId
 * @param {string} payload.email
 */
function acceptInvitation(payload, meta = {}) {
  const requiredKeys = [
    'accountId',
    'userId',
    'email',
  ]
  requiredKeys.forEach(key => {
    if(!payload[key]) throw new Error(acceptInvitation.toString() + `: action.payload.${key} is required`)
  })

  return {
    module: 'System',
    type: acceptInvitation.toString(),
    payload,
    meta,
  }
}
acceptInvitation.toString = () => 'Accept User Invitation'
actions.acceptInvitation = acceptInvitation

module.exports = actions
