import { createSelector } from 'reselect'
import augmentAction from '../redux/middleware/augmentAction'

import { selectAccountId } from '../redux/account/selectors'
import { selectCurrentUserName } from '../redux/user/selectors'
import { selectAuthState } from '../redux/auth/selectors'

/**
 * Selector to return "Redux safe" augmentAction function.
 */
const getAugmentAction = createSelector(
  selectAuthState,
  selectCurrentUserName,
  selectAccountId,
  (auth, authUserName, accountId) => {
    // set nullish fallback value here, not in the input function or else the
    // input function's return value will change with every invocation!
    const { userId: authUserId, actualUserId, assumedRoleIds } = auth ?? {}
    return action => augmentAction(action, { accountId, actualUserId, assumedRoleIds, authUserId, authUserName })
  }
)

export default getAugmentAction
