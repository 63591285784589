import { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from '../store'
import { setMenuState, showModal } from '../actions'

export function useModalData<T extends Record<string, unknown>>(modalId: string): T {
  return useAppSelector(state => state.app.modals.byId[modalId] as T)
}

export function useShowModal<T extends Record<string, unknown>>(modalId: string): (modalData: T) => void {
  const dispatch = useAppDispatch()

  return useCallback(
    modalData => dispatch(showModal(modalId, modalData)),
    [dispatch, modalId]
  )
}

export function useMenuState(): [menuOpen: boolean, toggleMenuOpen: () => void] {
  const menuOpen = useAppSelector(state => state.app.menuOpen)

  const dispatch = useAppDispatch()
  const toggleMenuOpen = useCallback(
    () => dispatch(setMenuState(!menuOpen)),
    [dispatch, menuOpen]
  )

  return [menuOpen, toggleMenuOpen]
}
