import { z } from 'zod'
import { Html } from '../types/html'
import { createId } from '../idUtils'
import { BaseQuantity, CostUnit, DurationUnit } from '../utils/qty'
import { Option } from '../types/option'
import { CriterionData } from '../nextgen/Criterion'
import { Decision, DecisionFolder } from '../types/decision'
import { Participant } from '../types/participant'
import { ParticipationSession } from '../types/participationSession'
import { ValueFunctionLibrary } from '../valuemetrics/valuemetrics'
import { criteriaDataFactory } from '../nextgen/sampleFactories/criteriaData'
import { Rating, RatingNotes } from '../types/rating'
import { RatingToPrioritizationAlgorithm } from '../valuemetrics/quickPrioritization'
import { identifyRating } from '../utils/ratings'
import { identifyRatingNotes } from '../utils/ratingNotes'
import { defaultOptionRatingScaleConfig } from '../nextgen/criteria'

// TODO: these validation functions are very simple, and are just a stopgap until
// we have a better mechanism

// custom validators (zod doesn't have a built-in extension mechanism, so this is zod-like)
const $z = {
  ancestry: z.string().regex(/^(?:\/|(?:\/[a-z0-9-]+)+)$/i),
  id: z.string().regex(/\S/),
  name: z.string().regex(/\S/),
  html: z.object({
    $type: z.literal('html'),
    value: z.nullable(z.string()),
  }),
  cost: z.object({
    base: z.literal(BaseQuantity.Cost),
    // TODO: will need to fix when the app supports more than USD
    unit: z.literal(CostUnit.USD),
    value: z.number(),
  }),
  time: z.object({
    base: z.literal(BaseQuantity.Duration),
    // TODO: will need to add more units when the app supports more
    unit: z.enum([
      DurationUnit.Days,
      DurationUnit.Weeks,
      DurationUnit.Months,
      DurationUnit.Quarters,
      DurationUnit.Years,
    ]),
    value: z.number(),
  }),
  ratingScaleConfig: z.object({
    maxRating: z.number(),
    minRating: z.number(),
    maxRatingLabel: z.string(),
    minRatingLabel: z.string(),
    abstainLabel: z.string(),
    ratingScale: z.array(
      z.object({
        label: z.string(),
        maxValue: z.number(),
      })
    ),
  }).strict(),
}

// CREATE DECISION FOLDER
export const CreateDecisionFolderActionType = 'Create Folder'
export type CreateDecisionFolderProps = {
  name: string
  ancestry: string
}
export type CreateDecisionFolderAction = {
  module: 'Decision',
  type: typeof CreateDecisionFolderActionType
  payload: {
    id: string
    name: string
  }
  meta: {
    ancestry: string
  }
}
export function createDecisionFolder(props: CreateDecisionFolderProps) : CreateDecisionFolderAction {
  const { name, ancestry } = props
  z.object({
    name: $z.name,
    ancestry: $z.ancestry,
  }).parse(props)
  return {
    module: 'Decision',
    type: CreateDecisionFolderActionType,
    payload: {
      id: createId(),
      name,
    },
    meta: {
      ancestry,
    },
  }
}

export const UpdateDecisionFolderActionType = 'Update Folder'
export type UpdateDecisionFolderAction = {
  module: 'Decision',
  type: typeof UpdateDecisionFolderActionType,
  payload: Partial<Pick<DecisionFolder, 'name' | 'owner'>>,
  meta: { decisionFolderId: string },
}
export function updateDecisionFolder(
  decisionFolderId: string,
  payload: UpdateDecisionFolderAction['payload']
): UpdateDecisionFolderAction {
  z.object({
    decisionFolderId: $z.id,
    payload: z.object({
      name: $z.name,
      owner: z.object({
        userName: $z.name,
        userId: $z.id,
      }),
    }).partial()
      .strict(),
  }).parse({
    decisionFolderId,
    payload,
  })

  return {
    module: 'Decision',
    type: UpdateDecisionFolderActionType,
    payload,
    meta: {
      decisionFolderId,
    },
  }
}

export const DeleteDecisionFolderActionType = 'Delete Folder'
export type DeleteDecisionFolderAction = {
  module: 'Decision',
  type: typeof DeleteDecisionFolderActionType,
  payload: Record<string, never>,
  meta: { decisionFolderId: string }
}
export function deleteDecisionFolder(decisionFolderId: string): DeleteDecisionFolderAction {
  $z.id.parse(decisionFolderId)

  return {
    module: 'Decision',
    type: DeleteDecisionFolderActionType,
    payload: {},
    meta: { decisionFolderId },
  }
}

// CREATE DECISION
export const CreateDecisionActionType = 'Create Decision'
export type CreateDecisionProps = {
  name: string
  ancestry: string
  description: Html
  objective: Html,
}
export type CreateDecisionAction = {
  module: 'Decision',
  type: typeof CreateDecisionActionType
  payload: {
    id: string
    name: string
    description: Html
    objective: Html,
    participationSessions: ParticipationSession[]
    criteria: CriterionData[]
    valueFunctionJson: string
    ratingsToPrioritizationAlgorithm: RatingToPrioritizationAlgorithm
    baselineOptionId: string | null
  }
  meta: {
    ancestry: string
  }
}
export function createDecision(props: CreateDecisionProps) : CreateDecisionAction {
  const { name, description, objective, ancestry } = props
  z.object({
    name: $z.name,
    ancestry: $z.ancestry,
  }).parse(props)
  return {
    module: 'Decision',
    type: 'Create Decision',
    payload: {
      id: createId(),
      name,
      description,
      objective,
      participationSessions: [
        {
          id: createId(),
          name: 'Option Ratings (Default)',
          type: 'OptionRating',
          status: 'Inactive',
          method: 'Direct',
        },
        {
          id: createId(),
          name: 'Criteria Prioritizations (Default)',
          type: 'CriteriaPrioritization',
          status: 'Inactive',
          method: 'Direct',
        },
      ],
      criteria: criteriaDataFactory.createDefaultCriteriaData(),
      valueFunctionJson: JSON.stringify(ValueFunctionLibrary.Standard),
      ratingsToPrioritizationAlgorithm: 'RecenterAndNormalize',
      baselineOptionId: null,
    },
    meta: {
      ancestry,
    },
  }
}

// UPDATE DECISION
export const UpdateDecisionActionType = 'Update Decision'
export type UpdateDecisionAction = {
  module: 'Decision'
  type: typeof UpdateDecisionActionType
  payload: Partial<Pick<Decision,
    'name' |
    'description' |
    'objective' |
    'valueFunctionJson' |
    'ratingsToPrioritizationAlgorithm' |
    'baselineOptionId'
  >>
  meta: { decisionId: string }
}
export function updateDecision(
  decisionId: string,
  decision: UpdateDecisionAction['payload']
): UpdateDecisionAction {
  z.object({
    decisionId: $z.id,
    decision: z.object({
      name: $z.name,
      valueFunctionJson: z.string(),
      ratingsToPrioritizationAlgorithm: z.string(),
      objective: $z.html,
      description: $z.html,
      baselineOptionId: z.nullable($z.id),
    }).partial()
      .strict(),
  }).parse({
    decisionId,
    decision,
  })
  return {
    module: 'Decision',
    type: UpdateDecisionActionType,
    payload: decision,
    meta: { decisionId },
  }
}

export const DeleteDecisionActionType = 'Delete Decision'
export type DeleteDecisionAction = {
  module: 'Decision'
  type: typeof DeleteDecisionActionType
  payload: Record<string, never>
  meta: {
    decisionId: string
    parentDecisionFolderId: string
  }
}
export function deleteDecision(decisionId: string, parentDecisionFolderId: string): DeleteDecisionAction {
  z.object({
    decisionId: $z.id,
    parentDecisionFolderId: $z.id,
  }).parse({
    decisionId,
    parentDecisionFolderId,
  })

  return {
    module: 'Decision',
    type: DeleteDecisionActionType,
    payload: {},
    meta: {
      decisionId,
      parentDecisionFolderId,
    },
  }
}

export const ReloadDecisionActionType = 'Reload Decision'
export type ReloadDecisionAction = {
  module: 'Decision',
  type: typeof ReloadDecisionActionType,
  payload: Record<string, never>,
  meta: {
    decisionId: string,
  }
}
export function reloadDecision(decisionId: string): ReloadDecisionAction {
  return {
    module: 'Decision',
    type: ReloadDecisionActionType,
    payload: {},
    meta: {
      decisionId,
    },
  }
}

// CREATE OPTION
export const CreateOptionActionType = 'Create Option'
export type CreateOptionAction = {
  module: 'Decision',
  type: typeof CreateOptionActionType
  payload: Option
  meta: {
    ancestry: string
  }
}
export function createOption(ancestry: string, option: Omit<Option, 'id'>): CreateOptionAction {
  const payload: Option = { id: createId(), ...option }
  z.object({
    ancestry: $z.ancestry,
    option: z.object({
      id: $z.id,
      name: $z.name,
      abbrev: z.nullable($z.name),
      color: z.string(),
      description: $z.html,
      commonId: z.nullable(z.string()),
      cost: z.nullable($z.cost),
      time: z.nullable($z.time),
    }).strict(),
  }).parse({
    ancestry,
    option: payload,
  })
  return {
    module: 'Decision',
    type: CreateOptionActionType,
    payload,
    meta: { ancestry },
  }
}

// UPDATE OPTION
export const UpdateOptionActionType = 'Update Option'
export type UpdateOptionAction = {
  module: 'Decision'
  type: typeof UpdateOptionActionType
  payload: Partial<Omit<Option, 'id'>>
  meta: {
    decisionId: string
    optionId: string
  }
}
export function updateOption(
  decisionId: string,
  optionId: string,
  option: UpdateOptionAction['payload']
): UpdateOptionAction {
  z.object({
    decisionId: $z.id,
    optionId: $z.id,
    option: z.object({
      name: $z.name,
      abbrev: z.nullable($z.name),
      color: z.string(),
      description: $z.html,
      commonId: z.nullable(z.string()),
      cost: z.nullable($z.cost),
      time: z.nullable($z.time),
    }).partial()
      .strict(),
  }).parse({
    decisionId,
    optionId,
    option,
  })
  return {
    module: 'Decision',
    type: UpdateOptionActionType,
    payload: option,
    meta: { decisionId, optionId },
  }
}

// DELETE OPTION
export const DeleteOptionActionType = 'Delete Option'
export type DeleteOptionAction = {
  module: 'Decision'
  type: typeof DeleteOptionActionType
  payload: Record<string, never>,
  meta: {
    ancestry: string
    optionId: string
  }
}
export function deleteOption(ancestry: string, optionId: string): DeleteOptionAction {
  z.object({
    ancestry: $z.ancestry,
    optionId: $z.id,
  }).parse({
    ancestry,
    optionId,
  })
  return {
    module: 'Decision',
    type: DeleteOptionActionType,
    payload: {},
    meta: { ancestry, optionId },
  }
}

// START PARTICIPATION SESSION
export const StartParticipationSessionActionType = 'Open Participation Session'
export type StartParticipationSessionAction = {
  module: 'Decision'
  type: typeof StartParticipationSessionActionType
  payload: {
    status: 'Active',
  }
  meta: {
    decisionId: string
    participationSessionId: string
  }
}
export function startParticipationSession(
  decisionId: string,
  participationSessionId: string
): StartParticipationSessionAction {
  z.object({
    decisionId: $z.id,
    participationSessionId: $z.id,
  }).parse({
    decisionId,
    participationSessionId,
  })
  return {
    module: 'Decision',
    type: StartParticipationSessionActionType,
    payload: {
      status: 'Active',
    },
    meta: { decisionId, participationSessionId },
  }
}

// END PARTICIPATION SESSION
export const EndParticipationSessionActionType = 'Close Participation Session'
export type EndParticipationSessionAction = {
  module: 'Decision'
  type: typeof EndParticipationSessionActionType
  payload: {
    status: 'Inactive',
  }
  meta: {
    decisionId: string
    participationSessionId: string
  }
}
export function endParticipationSession(
  decisionId: string,
  participationSessionId: string
): EndParticipationSessionAction {
  z.object({
    decisionId: $z.id,
    participationSessionId: $z.id,
  }).parse({
    decisionId,
    participationSessionId,
  })
  return {
    module: 'Decision',
    type: EndParticipationSessionActionType,
    payload: {
      status: 'Inactive',
    },
    meta: { decisionId, participationSessionId },
  }
}

// CREATE PARTICIPANT
export const CreateParticipantActionType = 'Create Participant'
export type CreateParticipantAction = {
  module: 'Decision'
  type: typeof CreateParticipantActionType
  payload: Omit<Participant, 'created' | 'updated'>
  meta: {
    ancestry: string
  }
}
export function createParticipant(
  ancestry: string,
  participant: Omit<Participant, 'id' | 'created' | 'updated'>
): CreateParticipantAction {
  z.object({
    ancestry: $z.ancestry,
    participant: z.object({
      fullName: $z.name,
      userId: z.nullable($z.id),
      trackingId: z.nullable($z.id),
    }).strict(),
  }).parse({
    ancestry,
    participant,
  })
  const payload = { id: createId(), ...participant }
  return {
    module: 'Decision',
    type: CreateParticipantActionType,
    payload,
    meta: { ancestry },
  }
}

// UPDATE PARTICIPANT
export const UpdateParticipantActionType = 'Update Participant'
export type UpdateParticipantAction = {
  module: 'Decision'
  type: typeof UpdateParticipantActionType
  payload: Partial<Pick<Participant, 'fullName'>>
  meta: {
    participantId: string
  }
}
export function updateParticipant(
  participantId: string,
  participant: UpdateParticipantAction['payload']
): UpdateParticipantAction {
  z.object({
    participantId: $z.id,
    participant: z.object({
      fullName: $z.name,
    }).partial()
      .strict(),
  }).parse({
    participantId,
    participant,
  })
  return {
    module: 'Decision',
    type: UpdateParticipantActionType,
    payload: participant,
    meta: { participantId },
  }
}

// DELETE PARTICIPANT
export const DeleteParticipantActionType = 'Delete Participant'
export type DeleteParticipantAction = {
  module: 'Decision'
  type: typeof DeleteParticipantActionType
  payload: Record<string, never>,
  meta: {
    decisionId: string
    participantId: string
  }
}
export function deleteParticipant(
  decisionId: string,
  participantId: string,
): DeleteParticipantAction {
  z.object({
    decisionId: $z.id,
    participantId: $z.id,
  }).parse({
    decisionId,
    participantId,
  })
  return {
    module: 'Decision',
    type: DeleteParticipantActionType,
    payload: {},
    meta: { decisionId, participantId },
  }
}

// CREATE CHILD CRITERION
export const CreateChildCriterionActionType = 'Create Criterion'
export type CreateChildCriterionAction = {
  module: 'Decision'
  type: typeof CreateChildCriterionActionType
  payload: CriterionData & { parentId: string }
  meta: {
    decisionId: string
  }
}
export function createChildCriterion(
  decisionId: string,
  criterion: Omit<CreateChildCriterionAction['payload'], 'optionRatingScaleConfig'>
): CreateChildCriterionAction {
  z.object({
    decisionId: $z.id,
    criterion: z.object({
      id: $z.id,
      name: $z.name,
      abbrev: $z.name,
      parentId: $z.id,
      type: z.literal('Rated'), // only option for now!
      color: z.string(),
      description: z.optional($z.html),
    }),
  }).parse({
    decisionId,
    criterion,
  })
  return {
    module: 'Decision',
    type: CreateChildCriterionActionType,
    payload: {
      ...criterion,
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
    meta: { decisionId },
  }
}

// DELETE CRITERION
export const DeleteCriterionActionType = 'Delete Criterion'
export type DeleteCriterionAction = {
  module: 'Decision',
  type: typeof DeleteCriterionActionType
  payload: Record<string, never>,
  meta: {
    decisionId: string
    criterionId: string
    descendantCriteriaIds?: string[]
  }
}
export function deleteCriterion(
  decisionId: string,
  criterionId: string,
  descendantCriteriaIds?: string[]
): DeleteCriterionAction {
  z.object({
    decisionId: $z.id,
    criterionId: $z.id,
    descendantCriteriaIds: z.optional(z.array($z.id)),
  }).parse({
    decisionId,
    criterionId,
    descendantCriteriaIds,
  })
  return {
    module: 'Decision',
    type: DeleteCriterionActionType,
    payload: {},
    meta: { decisionId, criterionId, descendantCriteriaIds },
  }
}

// UPDATE CRITERION
export const UpdateCriterionActionType = 'Update Criterion'
export type UpdateCriterionAction = {
  module: 'Decision'
  type: typeof UpdateCriterionActionType
  payload: Partial<Pick<CriterionData, 'name' | 'abbrev' | 'description' | 'color' | 'optionRatingScaleConfig'>>,
  meta: {
    decisionId: string
    criterionId: string
  }
}
export function updateCriterion(
  decisionId: string,
  criterionId: string,
  criterion: UpdateCriterionAction['payload']
): UpdateCriterionAction {
  z.object({
    decisionId: z.string().regex(/\S/),
    criterionId: z.string().regex(/\S/),
    criterion: z.object({
      name: $z.name,
      abbrev: $z.name,
      description: $z.html,
      color: z.string(),
      optionRatingScaleConfig: $z.ratingScaleConfig,
    }).partial()
      .strict(),
  }).parse({
    decisionId,
    criterionId,
    criterion,
  })
  return {
    module: 'Decision',
    type: UpdateCriterionActionType,
    payload: criterion,
    meta: { decisionId, criterionId },
  }
}

// UPDATE PARTICIPATION SESSION

export const UpdateParticipationSessionActionType = 'Update Participation Session'
export type UpdateParticipationSessionAction = {
  module: 'Decision'
  type: typeof UpdateParticipationSessionActionType
  payload: Partial<Pick<ParticipationSession, 'name'>>,
  meta: {
    participationSessionId: string
  }
}
export function updateParticipationSession(
  participationSessionId: string,
  update: UpdateParticipationSessionAction['payload']
): UpdateParticipationSessionAction {
  z.object({
    participationSessionId: z.string().regex(/\S/),
    update: z.object({
      name: $z.name,
    }).partial()
      .strict(),
  }).parse({
    participationSessionId,
    update,
  })
  return {
    module: 'Decision',
    type: UpdateParticipationSessionActionType,
    payload: update,
    meta: { participationSessionId },
  }
}

// UPDATE RATING
export const UpdateRatingActionType = 'Update Rating'
export type UpdateRatingAction = {
  module: 'Decision',
  type: typeof UpdateRatingActionType
  payload: Omit<Rating, 'updated'>  // will be filled in on server
  meta: {
    ancestry: string
    id: string
  }
}
export function updateRating(
  ancestry: string,
  rating: Omit<Rating, 'updated'>,
): UpdateRatingAction {
  z.object({
    ancestry: $z.ancestry,
    rating: z.object({
      participationSessionId: $z.id,
      participantId: $z.id,
      contextType: z.literal('Criterion'),
      contextId: $z.id,
      subjectType: z.union([z.literal('Criterion'), z.literal('Option')]),
      subjectId: $z.id,
      ratingVector: z.nullable(z.array(z.number())),
      abstain: z.boolean().optional(),
    }).strict(),
  }).parse({
    ancestry,
    rating,
  })

  const id = identifyRating(rating)

  return {
    module: 'Decision',
    type: UpdateRatingActionType,
    payload: rating,
    meta: {
      ancestry,
      id,
    },
  }
}

// DELETE RATING
export const DeleteRatingActionType = 'Delete Rating'
export type DeleteRatingAction = {
  module: 'Decision'
  type: typeof DeleteRatingActionType
  payload: Record<string, never>,
  meta: { decisionId: string, id: string },
}
export function deleteRating(
  decisionId: string,
  rating: Pick<Rating, 'participationSessionId' | 'participantId' | 'contextId' | 'subjectId'>,
): DeleteRatingAction {
  z.object({
    decisionId: $z.id,
    rating: z.object({
      participationSessionId: $z.id,
      participantId: $z.id,
      contextId: $z.id,
      subjectId: $z.id,
    }),
  }).parse({
    decisionId,
    rating,
  })

  const id = identifyRating(rating)

  return {
    module: 'Decision',
    type: DeleteRatingActionType,
    payload: {},
    meta: {
      decisionId,
      id,
    },
  }
}

// UPDATE RATING NOTES
export const UpdateRatingNotesActionType = 'Update Rating Notes'
export type UpdateRatingNotesAction = {
  module: 'Decision'
  type: typeof UpdateRatingNotesActionType
  payload: Omit<RatingNotes, 'updated'>
  meta: {
    ancestry: string
    id: string
  }
}
export function updateRatingNotes(
  ancestry: string,
  ratingNotes: Omit<RatingNotes, 'updated'>
): UpdateRatingNotesAction {
  z.object({
    ancestry: $z.ancestry,
    ratingNotes: z.object({
      participationSessionId: $z.id,
      participantId: $z.id,
      contextType: z.literal('Criterion'),
      contextId: $z.id,
      subjectType: z.union([z.literal('Criterion'), z.literal('Option')]),
      notes: z.object({
        value: z.nullable(z.string()),
      }),
    }).strict(),
  }).parse({
    ancestry,
    ratingNotes,
  })

  const id = identifyRatingNotes(ratingNotes)

  return {
    module: 'Decision',
    type: UpdateRatingNotesActionType,
    payload: ratingNotes,
    meta: {
      ancestry,
      id,
    },
  }
}
