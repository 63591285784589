import { COLOR } from '../../_riskConfig/color'

const button = {
  userSelect: 'none',
  cursor: 'pointer',
  '--bg-color': `${COLOR.GREY_3}11`,
  '--fg-color': COLOR.GREY_3,
  color: 'var(--fg-color)',
  backgroundColor: 'var(--bg-color)',
  fontWeight: '600',
  fontSize: '16px',
  boxShadow: '0 0 1px rgba(0, 0, 0, 0)',
  transition: 'box-shadow transform background-color',
  transitionDuration: '0.1s',
  transitionTimingFunction: 'ease-out',
  transformOrigin: 'center center',
  '&:hover,&:focus': {
    '--bg-color': 'white',
    transform: 'scale(1.02)',
    boxShadow: '0 0px 0px 2px var(--fg-color)',
    zIndex: '2',
  },
  '&:active': {
    '--fg-color': 'black',
    transform: 'scale(0.98)',
    transitionDuration: '0.03s',
  },
}

const flexControl = {
  position: 'relative',
  display: 'inline-flex',
  ...button,
}

const toggleWrap = {
  '&:hover label,&:focus label': {
    '&::before': {
      borderWidth: '2px',
      // clarify the box
    },
  },
  '&:active label': {
    // make everything except the box shadow 'snap'
    transitionProperty: 'box-shadow',
    '&::before': {
      borderWidth: '10px',
      // fill the box
    },
  },
  input: {
    // the box itself is actually hidden
    position: 'absolute',
    right: '30',
    opacity: '0',
    height: '40px',
    width: '40px',
    marginRight: '-30px',
    '&:checked + label': {
      '&::before': {
        borderWidth: '10px',
        // keep box filled
      },
      '&::after': {
        transform: 'rotate(-45deg) scale(1)',
        opacity: '1',
      },
    },
  },
  label: {
    // the label is what people see and click
    ...flexControl,
    whiteSpace: 'nowrap',
    padding: '7px 37px 0 12px',
    height: '40px',
  },
}

const dropdownWrap = {
  ...flexControl,
  width: '158px',
  paddingLeft: '9px',
  '&::after': {
    content: '""',
    pointerEvents: 'none',
    position: 'absolute',
    borderLeft: '3px solid var(--fg-color)',
    borderBottom: '3px solid var(--fg-color)',
    height: '10px',
    width: '10px',
    right: '13px',
    top: '13px',
    transform: 'rotate(-45deg)',
  },
  '&::-ms-expand': {
    display: 'none',
  },
  '&:hover': {
    overflow: 'visible',
    label: {
      transform: 'translateY(-15px) scaleY(1)',
      opacity: 1,
    },
  },
}

const sliderWrap = {
  ...flexControl,
  '&:hover': {
    overflow: 'visible',
    label: {
      transform: 'translateY(-15px) scaleY(1)',
      opacity: '1',
    },
  },
}

const sliderInput = {
  WebkitAppearance: 'none',
  cursor: 'pointer',
  appearance: 'none',
  width: '150px',
  height: '40px',
  background: 'var(--bg-color)',
  outline: 'none',
  borderRadius: '0',
  '&:hover,&:focus': {
    '&::-webkit-slider-thumb': {
      transform: 'scaleX(1.25) scaleY(1.05)',
      background: 'var(--fg-color)',
    },
    '&::-moz-slider-thumb': {
      transform: 'scaleX(1.25) scaleY(1.05)',
      background: 'var(--fg-color)',
      color: 'var(--fg-color)',
    },
  },
  '&::-webkit-slider-thumb': {
    WebkitAppearance: 'none', /* Override default look */
    border: 'none',
    appearance: 'none',
    width: '20px', /* Set a specific slider handle width */
    height: '40px', /* Slider handle height */
    background: COLOR.GREY_2D,
    cursor: 'pointer',
    transition: 'transform 0.05s',
  },
  '&::-moz-range-thumb': {
    borderRadius: '0px',
    width: '20px',
    height: '40px',
    background: COLOR.GREY_2D,
    cursor: 'pointer',
    transition: 'transform 0.05s',
  },
  '&::before,&::after': {
    color: COLOR.GREY_2D,
    display: 'inline',
    fontSize: '24px',
    margin: '0px 6px',
  },
  '&::before': {
    content: "–", // endash
  },
  '&::after': {
    content: "+",
  },
}

const numberInput = {
  height: '40px',
  margin: 'none',
  border: '3px solid var(--bg-color)',
  fontWeight: '600',
  padding: '0 0 0 8px',
  backgroundColor: COLOR.GREY_1,
  borderRadius: '0px',
  minWidth: '45px',
  maxWidth: '50px',
  '&:hover,&:focus': {
    boxShadow: '0 0 0 0px -moz-mac-focusring',
    outline: 'none',
  },
}

const subOption = {
  transitionProperty: 'flex transform box-shadow background-color',
  overflow: 'hidden',
  '--bg-color': COLOR.GREY_1DR,
}

const closed = {
  display: 'none',
}

export {
  button,
  dropdownWrap,
  sliderWrap,
  sliderInput,
  numberInput,
  toggleWrap,
  subOption,
  closed,
}
