import { useMemo, useCallback, ReactElement } from 'react'
import { Column } from 'react-table'
import { Link } from 'react-router-dom'
import { UnreachableCaseError } from 'ts-essentials'
import { createSelector } from 'reselect'
import { FolderAddOutlined, PlusOutlined } from '@ant-design/icons'
import { Html } from '@vms/vmspro3-core/dist/types'

import { DecisionNodeCreateModal, DecisionNodeCreateModalData } from '../../client/modals/DecisionNodeCreateModal'
import { Dropdown, Button, Menu } from '../../client/controls'
import { Table, TableCellRenderer } from '../Table'
import { DecisionDeleteModalId, DecisionDeleteModalData } from '../../client/modals/DecisionDeleteModal'
import { DecisionEditModalId, DecisionEditModalData } from '../../client/modals/DecisionEditModal'
import {
  DecisionFolderDeleteModalId,
  DecisionFolderDeleteModalData,
} from '../../client/modals/DecisionFolderDeleteModal'
import {
  DecisionFolderEditModalData,
  DecisionFolderEditModalId,
} from '../../client/modals/DecisionFolderEditModal'

import { useAppSelector } from '../../redux'
import { useShowModal } from '../../redux/hooks/appHooks'
import { useDecisionFolderChildAncestry } from '../../redux/hooks/decisionHooks'
import { testId } from '../../test-automation'
import {
  getSelectLoadableChildDecisionFolders,
  getSelectLoadableChildDecisions,
} from '../../redux/selectors/decisionSelectors'

type DecisionFolderChildNode = {
  id: string,
  entityType: 'Decision' | 'DecisionFolder',
  typeLabel: 'Decision' | 'Decision Folder',
  name: string,
  optionsCount?: number,
  description?: Html,
}

type DecisionFolderProps = {
  decisionFolderId: string,
}
export function DecisionFolderChildren({ decisionFolderId }: DecisionFolderProps): ReactElement {
  const childAncestry = useDecisionFolderChildAncestry(decisionFolderId)

  const showDecisionNodeCreateModal = useShowModal<DecisionNodeCreateModalData>(DecisionNodeCreateModal.id)
  const onClickCreateDecisionEntity = useCallback(
    ({ key }) => showDecisionNodeCreateModal({
      ancestry: childAncestry,
      decisionNodeType: key,
    }),
    [showDecisionNodeCreateModal, childAncestry]
  )

  const columns = useMemo<Column<DecisionFolderChildNode>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: cell => {
          const entity = cell.row.original
          switch(entity.entityType) {
            case 'Decision': {
              return <Link to={`/decision/${entity.id}`}>{entity.name}</Link>
            }
            case 'DecisionFolder': {
              return <Link to={`/decision?folder=${entity.id}`}>{entity.name}</Link>
            }
            default: {
              throw new UnreachableCaseError(entity.entityType)
            }
          }
        },
      },
      {
        Header: 'Type',
        accessor: 'typeLabel',
      },
      {
        Header: 'Description',
        accessor: 'description',
        disableSortBy: true,
        Cell: TableCellRenderer.Html,
      },
      {
        Header: 'Options',
        accessor: 'optionsCount',
        sortType: 'numeric',
        align: 'right',
      },
    ],
    []
  )

  const decisionFolderChildNodes = useAppSelector(useMemo(
    () => createSelector(
      getSelectLoadableChildDecisionFolders(decisionFolderId),
      getSelectLoadableChildDecisions(decisionFolderId),
      (decisionFolders, decisions) => {
        const childNodes: DecisionFolderChildNode[] = []
        decisionFolders.forEach(decisionFolder => {
          childNodes.push({
            id: decisionFolder.data.id,
            entityType: decisionFolder.data.entityType,
            name: decisionFolder.data.name,
            typeLabel: 'Decision Folder',
          })
        })
        decisions.forEach(decision => {
          childNodes.push({
            id: decision.data.id,
            entityType: decision.data.entityType,
            name: decision.data.name,
            description: decision.data.description,
            typeLabel: 'Decision',
            optionsCount: decision.children && (decision.children?.options?.length ?? 0),
          })
        })
        return childNodes
      }
    ),
    [decisionFolderId]
  ))

  const showDecisionFolderEditModal = useShowModal<DecisionFolderEditModalData>(DecisionFolderEditModalId)
  const showDecisionEditModal = useShowModal<DecisionEditModalData>(DecisionEditModalId)
  const onEditChildNode = useCallback(
    (childNode: DecisionFolderChildNode) => {
      switch(childNode.entityType) {
        case 'DecisionFolder': {
          showDecisionFolderEditModal({ decisionFolderId: childNode.id })
          break
        }
        case 'Decision': {
          showDecisionEditModal({ decisionId: childNode.id })
          break
        }
        default: {
          throw new UnreachableCaseError(childNode.entityType)
        }
      }
    },
    [showDecisionFolderEditModal, showDecisionEditModal]
  )

  const showDecisionFolderDeleteModal = useShowModal<DecisionFolderDeleteModalData>(DecisionFolderDeleteModalId)
  const showDecisionDeleteModal = useShowModal<DecisionDeleteModalData>(DecisionDeleteModalId)
  const onDeleteChildNode = useCallback(
    (childNode: DecisionFolderChildNode) => {
      switch(childNode.entityType) {
        case 'DecisionFolder': {
          showDecisionFolderDeleteModal({ decisionFolderId: childNode.id })
          break
        }
        case 'Decision': {
          showDecisionDeleteModal({
            decisionId: childNode.id,
            parentDecisionFolderId: decisionFolderId,
          })
          break
        }
        default: {
          throw new UnreachableCaseError(childNode.entityType)
        }
      }
    },
    [showDecisionFolderDeleteModal, showDecisionDeleteModal, decisionFolderId]
  )

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '12px',
          justifyContent: 'flex-end',
        }}
      >
        <Dropdown
          placement="bottomRight"
          overlay={(
            <Menu onClick={onClickCreateDecisionEntity} theme="dark">
              <Menu.Item
                {...testId('folder')}
                key="DecisionFolder"
                icon={<FolderAddOutlined />}
              >
                Decision Folder
              </Menu.Item>
              <Menu.Item
                {...testId('decision')}
                key="Decision"
                icon={<PlusOutlined />}
              >
                Decision
              </Menu.Item>
            </Menu>
          )}
        >
          <Button type="primary" icon={<PlusOutlined />}>
            Create
          </Button>
        </Dropdown>
      </div>
      <Table<DecisionFolderChildNode>
        columns={columns}
        data={decisionFolderChildNodes}
        onEditRow={onEditChildNode}
        onDeleteRow={onDeleteChildNode}
      />
    </>
  )
}
