import { useCallback, ReactElement, LegacyRef } from 'react'
import { updateDecision } from '@vms/vmspro3-core/dist/actions/decision'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'

import FormModal from './FormModal'
import { Form, Input } from '../controls'

import { useAppDispatch } from '../../redux'
import { useModalData } from '../../redux/hooks/appHooks'
import { useDecision } from '../../redux/hooks/decisionHooks'

export const DecisionEditModalId = 'DecisionEditModal'
export type DecisionEditModalData = {
  decisionId: string,
}
export function DecisionEditModal(): ReactElement {
  const { decisionId } = useModalData<DecisionEditModalData>(DecisionEditModalId)

  const decision = useDecision(decisionId)

  const dispatch = useAppDispatch()
  const onOk = useCallback(
    ({ name, description }) => dispatch(updateDecision(decisionId, {
      name,
      description: createHtmlObject(description?.value || null),
    })),
    [dispatch, decisionId]
  )

  return (
    <FormModal modalId={DecisionEditModalId} onOk={onOk} initialValues={decision}>
      {(initialFocusRef: LegacyRef<Input>) => (
        <>
          <h3>Editing {decision.name}</h3>
          <Form.Item label="Name" name="name">
            <Input ref={initialFocusRef} />
          </Form.Item>
          <Form.Item label="Description" name={['description', 'value']}>
            <Input.TextArea rows={3} />
          </Form.Item>
        </>
      )}
    </FormModal>
  )
}
DecisionEditModal.id = DecisionEditModalId
