const _get = require('lodash/get')  // eslint-disable-line @typescript-eslint/no-var-requires, no-undef

/**
 * Check if the policy principal matches the auth principal.  Note that policies
 * with wildcard principals ("*" or "user:*") will always match.
 *
 * @param {Object} policy - the policy to check
 * @param {String} principal - the actor/user dispatching the action
 *
 * @returns {Boolean}
 */
const hasPrincipal = (policy, principal) => {
  const principals = _get(policy, ['Principal'], [])
  return (
    principals.includes('*') ||
    principals.includes('user:*') ||
    principals.includes(principal)
  )
}

// eslint-disable-next-line no-undef
module.exports = {
  hasPrincipal,
}
