import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingStatus } from '../../utils/appConsts'
import { selectAccountId } from '../account/selectors'
import { fetchUserInvitations } from './actions'
import {
  selectCurrentUser,
  selectCurrentUserLoadingStatus,
  selectAccountUser,
  selectAccountUserLoadingStatus,
  selectUserInvitations,
  selectUserInvitationsLoadingStatus,
} from './selectors'

export function useCurrentUser() {
  const currentUser = useSelector(selectCurrentUser)
  const loadingStatus = useSelector(selectCurrentUserLoadingStatus)
  const loading = loadingStatus !== LoadingStatus.Loaded

  return [currentUser, loading]
}

export function useAccountUser() {
  const accountId = useSelector(selectAccountId)

  const accountUser = useSelector(selectAccountUser)
  const loadingStatus = useSelector(selectAccountUserLoadingStatus)
  // account user loading status is only relevant if accountId is set
  const loading = accountId && loadingStatus !== LoadingStatus.Loaded

  return [accountUser, loading]
}

export function useUserInvitations() {
  const userInvitations = useSelector(selectUserInvitations)
  const loadingStatus = useSelector(selectUserInvitationsLoadingStatus)
  const loading = loadingStatus !== LoadingStatus.Loaded

  return [userInvitations, loading]
}

export function useFetchUserInvitations() {
  const dispatch = useDispatch()
  const loadingStatus = useSelector(selectUserInvitationsLoadingStatus)

  const email = useSelector(selectCurrentUser)?.email

  useEffect(
    () => {
      if(email && loadingStatus === LoadingStatus.NotLoaded) {
        dispatch(fetchUserInvitations(email))
      }
    },
    [dispatch, email, loadingStatus]
  )
}

