import './polyfills'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import moment from 'moment'

import { stage } from './utils/env'
import config from './config.json'

import { App } from './components/App'
import { AppProviders } from './providers/AppProviders'

import 'antd/lib/style/index.css'
import 'font-awesome/css/font-awesome.min.css'
import './index.css'
import './client/styles/antd.custom.css'
import './client/styles/tables.css'

document.title = config.instance.appName || 'VMSPro'

const enableSentry = (
  stage.startsWith('prod') ||
  stage === 'staging' ||
  stage === 'risk' ||
  stage.startsWith('qa') ||
  process.env.REACT_APP_ENABLE_SENTRY === '1'
)

if(enableSentry) {
  console.log('[initializing Sentry client]')
  Sentry.init({
    dsn: "https://d778f42f4a3940759a35a2eba973d61e@o138263.ingest.sentry.io/1365392",
    environment: (config.instance && config.instance.awsAccount) + ':' + stage,
    release: 'vmspro3:' + (process.env.REACT_APP_GIT_HEAD_COMMIT_ID || 'UNKNOWN_RELEASE'),
    beforeSend(event) {
      if(event.user && event.user.sessionStarted) {
        const duration = moment.duration(Date.now() - event.user.sessionStarted)
        event.user.sessionAge = duration.asMinutes() < 1
          ? `${duration.asSeconds()} second(s)`
          : `${duration.hours()} hour(s), ${duration.minutes()} minute(s)`
      }
      return event
    },
    // attachStacktrace: appends a stackTrace for the error and will show the exact line the error was triggered.
    attachStacktrace: true,
  })
}

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById('root')
)
