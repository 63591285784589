import { useCallback, ReactElement } from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {
  EditOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons'

import { Criterion, Option, Ratable, RatingVector } from '@vms/vmspro3-core/dist/types'

import { Rating, RatingProps } from '../Rating'
import { RatingContextProgress, RatingContextProgressProps } from './RatingContextProgress'
import { RatingContextDetails, RatingContextDetailsProps } from './RatingContextDetails'
import { DrawerWrapper } from './DrawerWrapper'
import { DebounceTextArea, DebounceTextAreaProps } from './DebounceTextArea'
import { Button } from '../../client/controls'

import style from './RatingInterface.module.css'
import { RenderHtmlObject } from '../RenderHtmlObject'

export type RatingInterfaceProps<T extends Option | Criterion> = {
  ratingContextProgressProps: RatingContextProgressProps,
  ratingContextDetailsProps: RatingContextDetailsProps<T>,
  ratingProps: Omit<RatingProps<Ratable<T>>, 'renderItem' | 'extra'>,
  ratingNotesProps: DebounceTextAreaProps & {
    /**
     * key should be the rating notes ID which will force internal component
     * state to reset when the rating context changes
     */
    key: string,
  },
  prevRatingContext: () => void,
  nextRatingContext: () => void,
  ratingContextLabel: string,
  hideRating?: boolean,
  decisionName: string,
  participationActivityLabel: 'Rating' | 'Prioritization'
}
export function RatingInterface<T extends Option | Criterion>({
  ratingContextProgressProps,
  ratingContextDetailsProps,
  ratingNotesProps,
  ratingProps,
  prevRatingContext,
  nextRatingContext,
  ratingContextLabel,
  hideRating,
  decisionName,
  participationActivityLabel,
}: RatingInterfaceProps<T>): ReactElement {

  const renderRatableItem = useCallback(
    (ratableItem: Ratable<T>, ratingVector: RatingVector, abstain: boolean) => {
      const formattedRating = ratingVector?.[0]?.toFixed(1)

      const ratableItemColorStyle = { '--ratable-item-color': ratableItem.color } as React.CSSProperties

      return (
        <div className={style.ratableItem} style={ratableItemColorStyle}>
          <span>{ratableItem.abbrev || ratableItem.name}</span>
          <span
            className={cn(style.ratableItemRating, {
              [style.hidden]: !formattedRating || abstain,
              [style.displayNone]: hideRating,
            })}
          >
            {formattedRating ?? '-'}
          </span>
          <div className={style.ratableItemBackground}>
            <div className={style.ratableItemBackgroundColor} />
          </div>
        </div>
      )
    },
    [hideRating]
  )

  return (
    <div className={style.container}>
      <div className={style.ratingInterfaceContainer}>
        <div className={style.ratingInterface}>
          <div className={style.decisionLabel}>
            <div className={style.decisionLabelInner}>
              {decisionName}
            </div>
            <div>:&nbsp;{participationActivityLabel}</div>
          </div>
          <DrawerWrapper
            trigger={setVisible => (
              <div className={style.contextLabel} onClick={setVisible}>
                <h2>{ratingContextLabel}</h2>
                <FontAwesomeIcon icon={faInfoCircle} className={style.infoIcon} />
              </div>
            )}
            content={<RatingContextDetails<T> {...ratingContextDetailsProps} />}
          />
          <div className={style.ratingCanvasContainer}>
            <div className={style.ratingCanvas}>
              <Rating<Ratable<T>>
                {...ratingProps}
                renderItem={renderRatableItem}
                extra={(
                  <div className={style.notesDrawerTrigger}>
                    <DrawerWrapper
                      trigger={setVisible => (
                        <Button type="primary" onClick={setVisible}>
                          Notes
                          <EditOutlined />
                        </Button>
                      )}
                      content={<DebounceTextArea {...ratingNotesProps} />}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <div className={style.desktopRatingNotesContainer}>
          <div className={style.desktopRatingNotesContainerSpace}>
            <h2>Decision Objective:</h2>
            <RenderHtmlObject htmlObject={ratingContextDetailsProps.decisionObjective} />
          </div>
          <div className={style.desktopRatingNotesTextArea}>
            <DebounceTextArea {...ratingNotesProps} />
          </div>
        </div>
      </div>
      <div className={style.controls}>
        <StepBackwardOutlined
          className={style.controlIcon}
          onClick={prevRatingContext}
        />
        <RatingContextProgress {...ratingContextProgressProps} />
        <StepForwardOutlined
          className={style.controlIcon}
          onClick={nextRatingContext}
        />
      </div>
    </div>
  )
}
