import { Product } from '../../types/product'
import { Account, AccountStatus } from '../../types/account'

export const UpdateAccountSubscriptionType = 'Update Subscription'
export type UpdateAccountSubscriptionAction = {
  module: 'System',
  type: typeof UpdateAccountSubscriptionType,
  payload: {
    product: Product
    paymentMethodId: string | null    // not required for internal products
  }
  meta: {
    accountId: string
    accountStatus: AccountStatus,
  }
}
export function updateAccountSubscription(
  account: Account,
  product: Product,
  paymentMethodId: string | null
): UpdateAccountSubscriptionAction {
  return {
    module: 'System',
    type: UpdateAccountSubscriptionType,
    payload: { product, paymentMethodId },
    meta: {
      accountId: account.id,
      accountStatus: account.status,
    },
  }
}
updateAccountSubscription.toString = () => UpdateAccountSubscriptionType
