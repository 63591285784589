import React from 'react'
/** @jsxImportSource @emotion/react */
import _isEqual from 'lodash/isEqual'

import Cluster from './Cluster'
import { COLOR } from '../_riskConfig/color'
import findClusterWidth from '../utils/findClusterWidth'

function articlesIntoClusters(
  articleGroup,
  chunkMargin,
  columnWidth,
  displayHeightPortion,
  greatestMagnitude,
  hasRelativeScale,
  statSelections,
  windowHeight,
) {
  const articleClusters = statSelections[0].clusters.map(
    cluster => ({
      key: cluster.key,
      portion: cluster.portion,
      direction: cluster.direction,
      width: 0,
      style: {},
      articles: [],
    })
  )
  for(let index = articleGroup.articles.length - 1; index >= 0; index--) {
    const article = articleGroup.articles.pop()
    statSelections[0].clusters.forEach((cluster, idx) => {
      if(cluster.heuristic(article.magnitude)) {
        articleClusters[idx].articles.unshift(article)
      }
    })
  }
  for(let index = 0; index < articleClusters.length; index++) {
    const articleCluster = articleClusters[index]
    articleCluster.width = findClusterWidth(
      articleCluster,
      chunkMargin,
      columnWidth,
      displayHeightPortion,
      greatestMagnitude,
      hasRelativeScale,
      windowHeight,
    )
    articleCluster.style = {
      width: `${articleCluster.width}px`,
      height: `${articleCluster.portion * ((windowHeight * displayHeightPortion) - 16)}px`,
      flexDirection: articleCluster.direction,
    }
  }
  return articleClusters
}

/**
 *
 * @param {articleGroup} - contains the risks we'll be visualizing
 */
export default function Group({
  articleGroup,
  changeCurrentTooltip,
  changeHowToFilterIdcs,
  currentTooltip,
  displayHeightPortion,
  displayRange,
  groupIdx,
  hasRelativeScale,
  pinnedTooltip,
  pinTooltip,
  scale,
  statSelections,
  windowDimensions,
}) {
  const empty = (articleGroup.articles.length === 0)
  const chunkMargin = 2
  const magnitudes = articleGroup.articles.map(article => article.magnitude)
  const greatestMagnitude = Math.max(Math.max(...magnitudes), Math.abs(Math.min(...magnitudes)), 0)
  const columnWidth = hasRelativeScale
    ? Math.sqrt(greatestMagnitude * scale)
    : Math.sqrt(scale) / 3

  const clusters = articlesIntoClusters(
    articleGroup,
    chunkMargin,
    columnWidth,
    displayHeightPortion,
    greatestMagnitude,
    hasRelativeScale,
    statSelections,
    windowDimensions.height,
  )

  const contentsOfGroup = []
  for(let index = 0; index < statSelections[0].groupTemplate.length; index++) {
    const groupComponent = statSelections[0].groupTemplate[index]
    switch(typeof groupComponent) {
      case "number":
        contentsOfGroup.push(
          <Cluster
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            changeCurrentTooltip={changeCurrentTooltip}
            chunkMargin={chunkMargin}
            columnWidth={columnWidth}
            content={clusters[groupComponent]}
            displayRange={displayRange}
            greatestMagnitude={greatestMagnitude}
            hasRelativeScale={hasRelativeScale}
            statSelections={statSelections}
            pinnedTooltip={pinnedTooltip}
            pinTooltip={pinTooltip}
            windowDimensions={windowDimensions}
          />
        )
        break
      case "string":
        contentsOfGroup.push(
          <h2
            key={index}
            css={{
              color: empty ? COLOR.GREY_2 : 'black',
              fontSize: '15px',
              fontWeight: 'bold',
              textAlign: 'right',
              height: '16px',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            }}
          >
            {articleGroup.label ? articleGroup.label : articleGroup.key}
          </h2>
        )
        break
      default:
        break
    }
  }

  const contents = [...contentsOfGroup]
  //  console.log("group content", contents)

  return (
    <>
    <div
      css={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: '0 15px',
        borderLeft: '3px solid white',
        transitionProperty: 'width',
        transitionDuration: '0.9s',
        alignItems: 'center',
        cursor: 'pointer',
        '&:first-of-type': {
          border: 'none',
        },
        '&:hover': {
          backgroundColor: COLOR.GREY_1DR,
        },
      }}
      onClick={currentTooltip
        ? null
        : () => changeHowToFilterIdcs(groupIdx + 2)
      }
    >
      {contents}
    </div>
    </>
  )
}

export const MemoGroup = React.memo(Group, _isEqual)
