import React from 'react'

import './Pagination.css'

const pageSizeOptions = [10, 25, 50]

export function Pagination({
  disabled,
  tableInstance,
}) {
  if(disabled) return null

  const {
    gotoPage,
    previousPage,
    nextPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    state: { pageIndex, pageSize },
    setPageSize,
  } = tableInstance

  return (
    <div className="pagination">
      <span className="pagination__transport">
        <button type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'|<'}
        </button>
        <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <span>
          Page{' '}
          <strong>{' '}
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button type="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>|'}
        </button>
      </span>
      <span className="pagination__page-size">
        <select
          value={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
        >
          {pageSizeOptions.map(size => (
            <option key={size} value={size}>Show {size}</option>
          ))}
        </select>
      </span>
    </div>
  )
}
