import { getUsers } from '../../services/usersService'

import usersActionTypes from './actionTypes'

export function fetchUsers(accountId) {
  return async dispatch => {
    try {
      dispatch({
        type: usersActionTypes.FetchUsersRequest,
        meta: { ephemeral: true },
      })

      const users = await getUsers(accountId)
      dispatch({
        type: usersActionTypes.FetchUsersSuccess,
        payload: users,
        meta: { ephemeral: true },
      })
    } catch(error) {
      console.error('fetchUsers error: ', error)
    }
  }
}
