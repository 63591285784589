import { CellProps, CellValue, Renderer } from 'react-table'
import { useAppSelector } from '../../../redux'
import { selectDateTimeFormat } from '../../../redux/user/selectors'
import { formatTimestamp } from '../../../utils/formatUtils'

type TimestampCellRendererProps = CellProps<Record<string, unknown>, number | undefined>
export function TimestampCellRenderer({
  value: timestamp,
}: TimestampCellRendererProps): Renderer<CellValue> | null {
  const dateTimeFormat = useAppSelector(selectDateTimeFormat)

  if(typeof timestamp !== 'number' || !Number.isFinite(timestamp)) return null

  return formatTimestamp(timestamp, dateTimeFormat)
}
