import _sumBy from 'lodash/sumBy'

import { createValueGraphNode, ValueGraph, ValueGraphEdge, ValueGraphNode } from './valueGraph'

import * as valueFormula from './valueFormula'

type ValueExpressionResult = {
  value: number | null
  valueGraph: ValueGraph
}

function safeSum(nodes: ValueExpressionResult[]) {
  return nodes.some(({ value }) => value === null) ? null : _sumBy(nodes, 'value')
}
function safeMult(nodes: ValueExpressionResult[]): number | null {
  return nodes.map(n => n.value).reduce((p, n) => (p === null || n === null) ? null : p * n, 1)
}
function safeDiv(nodes: ValueExpressionResult[]): number | null {
  // note that we must not provide an initial value for Array#reduce here; if we do,
  // the numerator will become part of the denominator!
  return nodes.map(n => n.value).reduce((p, n) => (p === null || n === null) ? null : p / n)
}

function extractExprCoefficient(expr: valueFormula.Expression): [valueFormula.Expression, number | null] {
  if(!Array.isArray(expr) || expr[0] !== 'Multiply') {
    throw new Error('"Multiply" expression must be passed to extractExprCoefficient')
  }
  if(expr.length !== 3) return [expr, null]
  if(typeof expr[1] === 'number' && typeof expr[2] !== 'number') {
    return [expr[2], expr[1]]
  } else if(typeof expr[1] !== 'number' && typeof expr[2] === 'number') {
    return [expr[1], expr[2]]
  } else {
    return [expr, null]
  }
}

type ValueExpressionVars = {
  [key: string]: number | null | ValueGraphNode
}

const DEBUG = false

function evaluatePrimateiveExpression(
  node: ValueGraphNode,
  expr: string | number,
  vars: ValueExpressionVars,
  parent?: {
    node: Readonly<ValueGraphNode>,
    operation: valueFormula.Operation,
  },
  edge?: ValueGraphEdge,
  valueGraph: ValueGraph = { nodes: [], edges: [] },
): ValueExpressionResult {
  switch(typeof expr) {
    case 'string': {
      if(!(expr in vars)) throw new Error(`no value provided for variable "${expr}"`)
      // note that there are degenerate cases where the value type itself would normally be called intrinsic
      if(node.type !== 'Value') node.type = 'Intrinsic'
      const v = vars[expr]
      if(v !== null && typeof v === 'object') {
        // this variable resolves to a value graph node, so we don't need to create one.

        // note that the node weight (if any) will override the node weight of the
        // node in vars; for now, only ONE use of each variable is allowed (see
        // checkValueFormula below)
        v.weight = node.weight

        // this is also a special case where we want to connect a node that isn't generated in this graph;
        // note the source ID is different than what would be provided in evaluateValueExpression
        if(edge) edge.sourceId = v.id

        if(DEBUG) console.log(`${JSON.stringify(expr)} ->`, v?.weightedValue)
        return { value: v?.weightedValue ?? null, valueGraph }
      } else {
        node.value = v
        node.symbol = expr
        valueGraph.nodes.push(node)
        if(DEBUG) console.log(`${JSON.stringify(expr)} ->`, node.weightedValue)
        return { value: node.weightedValue, valueGraph }
      }
    }
    case 'number': {
      node.value = expr
      valueGraph.nodes.push(node)
      if(DEBUG) console.log(`${JSON.stringify(expr)} ->`, node.weightedValue)
      return { value: node.weightedValue, valueGraph }
    }
    default: throw new Error(`unrecognized expression type: ${typeof expr}`)
  }
}

function checkValueFormula(expression: valueFormula.Expression) {
  // right now, we just want to make sure we're only using each variable at most once;
  // there are other things that we could check for, but this would be the most frustrating
  // if it happened by accident (see evaluatePrimativeExpression for reasons why)
  const varsSeen = new Set<string>()
  const exprs = [expression]
  while(exprs.length) {
    const expr = exprs.pop()
    if(Array.isArray(expr)) {
      exprs.push(...expr.slice(1))
    } else if(typeof expr === 'string') {
      const v = expr.replace(/'$/, '')
      if(varsSeen.has(v)) throw new Error(`variable ${v} used more than once in value formula`)
      varsSeen.add(v)
    }
  }
}

/**
 * Given a value formula, and the variables it references, evalutes the formula and
 * returns the result.
 *
 * @example:
 *
 *   const expr = ['Divide',  // standard value formula
 *    'P',
 *    ['Add',
 *      ['Multiply', "C'", 0.5],
 *      ['Multiply', "T'", 0.5],
 *    ],
 *   ]
 *   const vars = {
 *     P: 8,
 *     C: 4,
 *     T: 6,
 *     ["P'"]: 2,  // note we also need to provide the score complements
 *     ["C'"]: 6,
 *     ["T'"]: 4,
 *   }
 *   const value = evaluateValueExpression(expr, vars)
 *   // value will be 8/(6*0.5 + 4*0.5) = 1.6
 *
 * TODO: known edge cases:
 *   - the user of perf complement ("perf'") yields incorrect value graph; this is possible to produce
 *     in app by putting perf in the denominator (which automatically uses the coefficient)
 */
export default function evaluateValueExpression(expr: valueFormula.Expression, vars: ValueExpressionVars) {
  checkValueFormula(expr)
  return _evaluateValueExpression(expr, vars)
}

function _evaluateValueExpression(
  expression: valueFormula.Expression,
  vars: ValueExpressionVars,
  parent?: {
    node: Readonly<ValueGraphNode>,
    operation: valueFormula.Operation,
  },
  valueGraph: ValueGraph = { nodes: [], edges: [] }
): ValueExpressionResult {

  const node: ValueGraphNode = createValueGraphNode()
  if(!parent) node.type = 'Value'
  // we will always have an edge pointing back to the parent, even though we haven't created the node yet;
  // note that we want to pass this down to evaluatePrimativeExpression, becuase it may modify the sourceId
  // in the case of connecting to a node from an external value graph
  const edge: ValueGraphEdge | undefined = parent && {
    sourceId: node.id,
    targetId: parent.node.id,
    operation: parent.operation,
  }
  if(edge) valueGraph.edges.push(edge)

  if(Array.isArray(expression)) {


    switch(expression[0]) {
      case 'Add': {
        const resolved = expression.slice(1).map(
          e => _evaluateValueExpression(e, vars, { node, operation: 'Add' }, valueGraph)
        )
        node.value = safeSum(resolved)
        if(DEBUG) console.log(`${JSON.stringify(expression)} ->`, node.weightedValue)
        valueGraph.nodes.push(node)
        return { value: node.weightedValue, valueGraph }
      }
      case 'Multiply': {
        const [expr, coefficient] = extractExprCoefficient(expression)
        if(Array.isArray(expr)) {
          // need to create an intrinsic node
          const resolved = expr.slice(1).map(
            e => _evaluateValueExpression(e, vars, { node, operation: 'Multiply' }, valueGraph)
          )
          node.value = safeMult(resolved)
          valueGraph.nodes.push(node)
          if(DEBUG) console.log(`${JSON.stringify(expression)} ->`, node.weightedValue)
          return { value: node.weightedValue, valueGraph }
        } else {
          // primative
          node.weight = coefficient ?? undefined
          return evaluatePrimateiveExpression(node, expr, vars, parent, edge, valueGraph)
        }
      }
      case 'Divide': {
        const resolved = expression.slice(1).map((e, i) =>
          _evaluateValueExpression(e, vars, { node, operation: i === 0 ? 'Multiply' : 'Divide' }, valueGraph)
        )
        node.value = safeDiv(resolved)
        valueGraph.nodes.push(node)
        if(DEBUG) console.log(`${JSON.stringify(expression)} ->`, node.weightedValue)
        return { value: node.weightedValue, valueGraph }
      }
      default: throw new Error(`unsupported operation: ${expression[0]}`)
    }
  } else {
    return evaluatePrimateiveExpression(node, expression, vars, parent, edge, valueGraph)
  }

}
