import numeral from 'numeral'

/**
 * Formats a number for display.
 *
 * @param {string|number|null|undefined} value - value to format, could be number or string.
 * @param {Object} config - optional format configuration
 * @param {boolean} [config.allowNull = true] - if true, returns an empty string for null value. if
 *  false, returns 0.
 * @param {number} [config.decimalPlaces = 0] - number of decimal places to show.
 * @param {boolean} [config.isPercentage = false] - if true, format value as percentage.
 * @param {boolean} [config.trimDecimals = false] - if true, trims decimal places (12.12500 --> 12.125).
 * @returns {string} - formatted number
 */
export const formatNumValue = (value, config) => {
  const {
    allowNull = false,
    decimalPlaces = 0,
    isPercentage = false,
    trimDecimals = false,
  } = config

  if(allowNull && ((typeof value === 'string' && !value.trim()) || value === null || value === undefined)) {
    return ''
  }

  const decimals = '0'.repeat(decimalPlaces)
  const decimalFormat = decimals ? '.' + (trimDecimals ? `[${decimals}]` : decimals) : ''
  const format = `0,0${decimalFormat}${isPercentage ? '%' : ''}`

  return numeral(value).format(format)
}

const suffixMatchers = {
  'financial': s => {
    const suffix = s.toLowerCase().trim().match(/[\d\s]([kmbt])$/)
    switch(suffix && suffix[1]) {
      case 'k': return 1e3
      case 'm': return 1e6
      case 'b': return 1e9
      case 't': return 1e12
      default: return 1
    }
  },
}

/**
 * Utility function for parsing user input strings that represent numeric data.
 *
 * @example
 *   parseNumValue('3,500')                    // 3500 - thousands separators respected
 *   parseNumValue(' 3500 ')                   // 3500 - whitespace ignored
 *   parseNumValue('3 500')                    // 3500 - internal whitespace ignored
 *   parseNumValue('')                         // 0    - default behavior
 *   parseNumValue('', true)                   // null - unparsable -> null
 *   parseNumValue('3.5k', false, 'financial') // 3500 - common suffixes
 *
 * @param {string} value - The string value that represents a number.
 * @param {boolean} [allowNull = false] - If true, strings that do not
 *   contain numeric data will result in a value of null.  If false, such
 *   strings will result in the number 0.  Note that this argument would
 *   probably better be named nonNumberEqualsNull or some such, but leaving
 *   it for historic reasons.
 * @param {string} [allowSuffix = 'none'] - Allows for the use of common
 *   suffixes such as 'k' for 'thousand', 'm', for 'million', etc.  Currently
 *   the only supported value other than 'none' is 'financial', which respects
 *   k=thousand, m=million, b=billion, t=trillion.
 * @param {string} [locale] - Allows for the specification of the locale
 *   for number parsing (which primarily affects thousands separators, which
 *   are commas in English, but periods in many other languages).  This
 *   argument is used primarily for testing.
 *
 * @returns {number|null} - Returns the parsed numeric value; if the number
 *   couldn't be parsed, it will return null (if allowNull is true) or 0
 *   (if allowNull is false).
 */
export const parseNumValue = (value, allowNull = false, allowSuffix = 'none', locale) => {
  const decimalSep = (1.1).toLocaleString(locale)[1]
  const suffixMatcher = suffixMatchers[allowSuffix]
  const multiplier = suffixMatcher ? suffixMatcher(value) : 1
  const localizedValue = (decimalSep === ','
    ? value.replace('.', '').replace(',', '.')
    : value)
    .replace(/[^\d.-]/g, '')
  const numValue = parseFloat(localizedValue) * multiplier
  return localizedValue === ''
    ? (allowNull ? null : 0)
    : (Number.isFinite(Number(numValue)) ? numValue : 0)
}

export const getFileIcon = ext => ([
  [['jpg', 'jpeg', 'gif', 'png'], 'picture'],
  [['doc', 'docx'], 'word'],
  [['xls', 'xlsx'], 'excel'],
  [['ppt', 'pptx'], 'ppt'],
  [['pdf'], 'pdf'],
  [['mp4', 'mov', 'avi', 'flv'], 'video'],
  [null, 'file'],
].find(([exts]) => !exts || exts.includes(ext))[1])

export const getOptionLabel = (value, options) => {
  const o = options.find(o => (o.key || o.value) === value)
  return o ? (o.readOnlyLabel || o.label) : '-'
}
