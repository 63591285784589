import { CSSProperties } from 'react'

import style from './EntityLabel.module.css'

interface EntityLabelProps {
  color?: string,
  abbrev: string,
  name: string,
}
export function EntityLabel({ abbrev, color, name }: EntityLabelProps) {
  return (
    <span>
      {color && (
        <div
          className={style.entityColor}
          style={{ '--entity-color': color } as CSSProperties}
        />
      )}
      {name}
      {abbrev !== name && ` (${abbrev})`}
    </span>
  )
}
