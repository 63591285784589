import { ReactElement } from 'react'

import { Progress } from '../../client/controls'

export type RatingContextProgressProps = {
  totalCount: number,
  inProgressCount: number,
  completedCount: number,
}
export function RatingContextProgress({
  totalCount,
  inProgressCount,
  completedCount,
}: RatingContextProgressProps): ReactElement {
  const getPercent = (count: number) => (count / totalCount) * 100

  return (
    <Progress
      type="line"
      percent={getPercent(inProgressCount + completedCount)}
      strokeColor="#99ceff"
      success={{ percent: getPercent(completedCount), strokeColor: '#1890ff' }}
      trailColor="lightgray"
      format={() => `${completedCount}/${totalCount}`}
    />
  )
}
