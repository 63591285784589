// TODO: this is going to be a PITA to convert to TS.

const _set = require('lodash/set')  // eslint-disable-line @typescript-eslint/no-var-requires, no-undef

/**
 * Convenience function to "unflatten" (aka "inflate") dotted properties
 * from the database into a hierarchical object.  This traversces the object
 * and unflattens descendents as well.
 *
 * @example
 *   const fromDb = { 'foo.bar': 'baz', 'foo.blur': 'bloo', bar: { 'baz.a': 1, 'baz.b': 2 } }
 *   unflattenDeep(fromDb) // -> { foo: { bar: 'baz', blur: 'bloo' }, baz: { a: 1, b: 2 } }
 *
 * @param {Object} o - The object to unflatten.  Properites with periods will be expanded
 *  and combined into a hierarchial object.
 * @returns {Object} - The unflattened representation.
 */
const unflattenDeep = o => {
  if(Array.isArray(o)) {
    return o.map(unflattenDeep)
  } else if(o && typeof o === 'object') {
    return Object.entries(o).reduce((o, [k, v]) => _set(o, k, unflattenDeep(v)), {})
  }
  return o
}

module.exports = unflattenDeep  // eslint-disable-line no-undef
