import React from 'react'
import { gql, useMutation, makeReference } from '@apollo/client'

import { Form, Input, Button, Space } from '../../client/controls'

import style from './AdHocParticipantPickerAndForm.module.css'

const ADD_PARTICIPANT = gql`
  mutation AddParticipant(
    $accountId: ID!,
    $decisionId: ID!,
    $participationSessionId: ID!,
    $fullName: String!,
  ) {
    createAdHocParticipant(
      accountId: $accountId,
      decisionId: $decisionId,
      participationSessionId: $participationSessionId,
      fullName: $fullName,
    ) {
      id
      fullName
    }
  }
`
type AdHocParticipant = {
  id: string,
  fullName: string,
}
type CreateAdHocParticipantData = {
  createAdHocParticipant: AdHocParticipant,
}
type CreateAdHocParticipantVariables = {
  accountId: string,
  decisionId: string,
  participationSessionId: string,
  fullName: string,
}

const sessionClosedErrorMessage = 'Participation for this decision is currently closed.\n' +
  'Please contact the decision facilitator for more information.'

type NewParticipantFormProps = {
  accountId: string,
  decisionId: string,
  participationSessionId: string,
  onCancel: () => void,
  setParticipantId: (participantId: string) => void,
}
export function AdHocParticipantForm({
  onCancel,
  accountId,
  decisionId,
  participationSessionId,
  setParticipantId,
}: NewParticipantFormProps): JSX.Element {
  const [createAdHocParticipant, { error, loading }] = useMutation<
    CreateAdHocParticipantData,
    CreateAdHocParticipantVariables
  >(
    ADD_PARTICIPANT,
    {
      onCompleted: data => setParticipantId(data.createAdHocParticipant.id),
      onError: () => null,
      update(cache, { data }) {
        if(data?.createAdHocParticipant) {
          cache.modify({
            id: cache.identify(makeReference('ROOT_QUERY')),
            fields: {
              decisionParticipants(existingParticipants = []) {
                const newParticipantRef = cache.writeFragment({
                  data: data.createAdHocParticipant,
                  fragment: gql`
                    fragment NewParticipant on Participant {
                      id
                      fullName
                      __typename
                    }
                  `,
                })

                return [...existingParticipants, newParticipantRef]
              },
            },
          })
        }
      },
    }
  )

  if(error) {
    if(error.graphQLErrors.some(err => err.message === 'participation session is not active')) {
      return (
        <span>{sessionClosedErrorMessage}</span>
      )
    }

    return <span>Unknown Error: {error.graphQLErrors.map(err => err.message).join(', ')}</span>
  }

  return (
    <div className={style.formContainer}>
      <h3>Welcome to VMSPro® Decision Participation</h3>
      <Form
        layout="vertical"
        style={{ maxWidth: '300px' }}
        requiredMark={false}
        onFinish={({ fullName }) => createAdHocParticipant({
          variables: {
            accountId,
            decisionId,
            participationSessionId,
            fullName,
          },
        })}
      >
        <Form.Item
          name="fullName"
          label="Enter your name"
          rules={[
            { required: true, message: 'A name is required' },
            { whitespace: true, message: 'Name cannot be empty' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item shouldUpdate>
          {form => (
            <Space size="large">
              <Button
                loading={loading}
                type="primary"
                disabled={!form.isFieldsTouched()}
                htmlType="submit"
              >
                Create Participant
              </Button>
              <Button onClick={onCancel}>Cancel</Button>
            </Space>
          )}
        </Form.Item>
      </Form>
    </div>
  )
}
