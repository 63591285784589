/** @jsxImportSource @emotion/react */
import React from 'react'

export function Footer() {
  return (
    <div css={style}>
      <span>&copy; {new Date().getFullYear()} VMS, Inc.</span>
    </div>
  )
}

const style = {
  minHeight: '54px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}
