export default {
  FetchCurrentUserRequest: 'FetchCurrentUserRequest',
  FetchCurrentUserSuccess: 'FetchCurrentUserSuccess',
  FetchCurrentUserFailure: 'FetchCurrentUserFailure',

  FetchAccountUserRequest: 'FetchAccountUserRequest',
  FetchAccountUserSuccess: 'FetchAccountUserSuccess',
  FetchAccountUserFailure: 'FetchAccountUserFailure',

  FetchUserInvitationsRequest: 'FetchUserInvitationsRequest',
  FetchUserInvitationsSuccess: 'FetchUserInvitationsSuccess',
  FetchUserInvitationsFailure: 'FetchUserInvitationsFailure',

  AcceptAccountInvitation: 'AcceptAccountInvitation',
}
