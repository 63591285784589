import React from 'react'
import { stage, profile } from '../../../utils/env'

function Image({ path, ...props }) {
  return (props.src
    ? <img {...props} />
    : <img src={`/${profile}/${stage}/${path}`} {...props} />
  )
}

export default Image
