/**
 * Type to contain a number along with some of its quartile stats.  This is
 * currently only used for min/max/outlier presentation in the UI, but may
 * be expanded to include other stats as well.
 */
export type NumberWithStats =
  | { value: number, isMax: boolean, isMin: boolean, isOutlier: boolean }
  | { value: undefined, isMax: false, isMin: false, isOutlier: false }

/**
 * Quartlies for a given data set.  See "quartiles" function for more information.
 */
export type Quartiles = {
  q0: number
  q1: number
  q2: number
  q3: number
  q4: number
  iqr: number
  minWhisker: number
  maxWhisker: number
  outliers: number[]
}

/**
 * Returns the value that represents the p-th percentile of a given set
 * of sorted numbers.
 *
 * This uses linear interpolation between closes ranks, second variant, C = 1,
 * as described here:
 *
 * https://en.wikipedia.org/wiki/Percentile#Second_variant,_C_=_1
 *
 * This algorithm was chosen because it is what Excel's QUARTILE.INC (and
 * PERCENTILE.INC) functions use, which will be the most common way to
 * validate output.
 */
function percentile(sortedValues: number[], p: number) {
  const X = sortedValues
  if(p === 1) return X[X.length - 1]  // special case; interpolation would fail for 100%th percentile
  const n = p * (X.length - 1)        // fractional index into sortedValues
  const belowIdx = Math.floor(n)      // index of value below (or equal to) desired percentile
  const interpolation = n - belowIdx  // interpolation ratio between below value and above value
  const belowValue = X[belowIdx]
  const aboveValue = X[belowIdx + 1]
  return belowValue + interpolation * (aboveValue - belowValue)
}

/**
 * Returns quartiles, interquartile range (IQR), whiskers, and a list of outliers.  An exception
 * will be thrown if fewer than 4 values are provided.
 *
 * The "whiskers" are used for box plots.
 *
 *  - q0: 0% of values are below or equal to this number (minimum value)
 *  - q1: 25% of values are below or equal to this number
 *  - q2: 50% of values are below or equal to this number (median)
 *  - q3: 75% of values are below or equal to this number
 *  - q4: 100% of values are below or equal to this number (maximum value)
 *  - iqr: interquartile range (q3 - q1); the "middle 50%"
 *  - minWhisker: lowest value  greater than or equal to q1 - 1.5*iqr; numbers below this are considered outliers
 *  - maxWhisker: highest value less than or equal to q3 + 1.5*iqr; numbers above this are considered outliers
 *  - outliers: numbers identified as outliers (see minWhisker and maxWhisker)
 *
 * The quartiles provided will be consistent with Excel's QUARTILE.INC function.
 */
export function quartiles(values: number[]): Quartiles {
  const sortedValues = values.slice().sort((a, b) => a - b)
  if(sortedValues.length < 4) throw new Error('must have at least 4 values to get meaningful quartiles')
  const q0 = sortedValues[0]
  const q1 = percentile(sortedValues, 0.25)
  const q2 = percentile(sortedValues, 0.50)
  const q3 = percentile(sortedValues, 0.75)
  const q4 = sortedValues[sortedValues.length - 1]
  const iqr = q3 - q1
  const minWhisker = sortedValues.find(v => v >= q1 - 1.5*iqr) ?? q0
  const maxWhisker = sortedValues.slice().reverse().find(v => v <= q3 + 1.5*iqr) ?? q4
  const outliers = sortedValues.filter(v => v > maxWhisker || v < minWhisker)
  return { q0, q1, q2, q3, q4, iqr, maxWhisker, minWhisker, outliers }
}

/**
 * Returns a function that will normalize a number to have a maximum
 * number of fractional digits.  This is useful when trying to display
 * consistent results of arbitrary-precision numbers that are rounded
 * for display purposes.  Note that if style is 'percent', it multiplies
 * the number by 100.
 *
 * @example
 *
 *   const normalizer = getNormalizer(3)
 *   normalizer(3.11111) // 3.111
 *   normalizer(3.55555) // 3.556
 *   normalizer(3)       // 3
 */
function getNormalizer(
  maximumFractionDigits: number,
  style: 'decimal' | 'percent' = 'decimal',
) {
  const formatter = Intl.NumberFormat(undefined, {
    maximumFractionDigits,
    useGrouping: false,
    style,
  }).format
  // i'm usually pretty allergic to doing round-trip formatting,
  // but in this case it guarantees parity witht he rounding
  // algorithm used by Intl.NumberFormat
  return (n: number | undefined) => {
    if(n === undefined) return undefined
    return parseFloat(formatter(n))
  }
}

/**
 * Given an array of data with some numeric property, and a maximum number
 * of fractional digits, will normalize the numeric data (to the specified
 * number of fractional digits), get statistics on them, and return the
 * value augmented with information about whether each number is a min, max
 * or outlier.  Note that for min and max identification, it's relative to
 * the formatted (maximum fractional digits) value.  That is, if the maximum
 * fractional digits is one, and the data set is [3.12, 3.11, 2], both the
 * first elements will have isMax: true (since they are both formatted as
 * 3.1 for display purposes).
 */
export function augmentWithStats<T>(
  data: T[],
  accessor: (datum: T) => number | undefined,
  maximumFractionDigits: number,
  style: 'decimal' | 'percent' = 'decimal',
): { datum: T, numberWithStats: NumberWithStats }[] {
  const normalizer = getNormalizer(maximumFractionDigits, style)
  const normalizedValues = data.map(datum => {
    const value = accessor(datum)
    return {
      datum,
      value,
      normalValue: normalizer(value),
    }
  })
  const values = normalizedValues.map(nv => nv.value).filter((v): v is number => v !== undefined)
  const { q0, q4, outliers }: { q0: number, q4: number, outliers: number[] } = values.length < 4
    ? { q0: Math.min(...values), q4: Math.max(...values), outliers: [] }
    : quartiles(values)
  const min = normalizer(q0)
  const max = normalizer(q4)
  return normalizedValues.map(({ datum, value, normalValue }) => ({
    datum,
    numberWithStats: value === undefined
      ? { value, isMax: false, isMin: false, isOutlier: false }
      : {
        value,
        isMax: normalValue === max,
        isMin: normalValue === min,
        isOutlier: outliers.includes(value),
      },
  }))
}
