import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _merge from 'lodash/merge'
import _cloneDeep from 'lodash/cloneDeep'
import _keyBy from 'lodash/keyBy'
import { RiskColor, RiskAnalysisStatus } from '@vms/vmspro3-core/dist/systemConsts'
import { completeRiskUpdate, flattenRisk } from '@vms/vmspro3-core/dist/utils/risk'

import NavConfirmation from '../controls/NavConfirmation'
import RiskContextScaleReference from '../components/Risk/RiskContextScaleReference'
import RiskInventoryColumns from './riskInventoryColumns'
import RiskInventoryHeatmapFilter from '../filters/RiskInventoryHeatmapFilter'
import RiskTableCustomizationModal from '../modals/RiskTableCustomizationModal'
import { Col, Form, LinkButton2, Row, Table, Drawer } from '../controls'

import actions from '../../actions'
import useAuthz from '../../hooks/useAuthz'
import { getTableConfigs } from '../../selectors'
import { selectAuthUserId } from '../../redux/auth/selectors'
import { riskTableStyle } from '../styles/style-consts'
import { buildFilterTag, filterTableColumns } from '../../utils/tableUtils'
import { useTableConfig } from '../../utils/tableHooks'
import { showModal } from '../../redux/actions'

const defaultConfigId = 'custom'

const defaultCheckedColumns = [
  'num',
  'name',
  'category',
  'phase',
  'riskType',
  'prob.qual',
  'prob.quant',
  'impact.cost.type',
  'impact.cost.quant.min',
  'impact.cost.quant.ml',
  'impact.cost.quant.max',
  'impact.cost.quant.ev',
]

const RiskInventoryTable = ({
  effectiveRiskContext,
  loading = false,
  includePerformance = true,
  heatmapFilter = false,
  project,
  rows,
}) => {
  const dispatch = useDispatch()
  const [displayData, setDisplayData] = useState(null)
  const { pathname } = useLocation()
  const authUserId = useSelector(selectAuthUserId)
  const label = 'long'
  const { id: projectId, ancestry } = project

  // Set the default configuration for the table
  const defaultConfigData = {
    [defaultConfigId]: {
      configId: defaultConfigId,
      checkedColumns: defaultCheckedColumns,
      tableId: RiskInventoryTable.id,
    },
  }

  const authz = useAuthz()
  const canEditRisk = authz(actions.risk.update(null, { riskId: '*', projectId, ancestry }))

  // Grab the configuration from state (if available). Default to defaultConfigData
  const tableConfiguration =
    useSelector(state => getTableConfigs(state, RiskInventoryTable.id)) ?? defaultConfigData

  // Grab the filters, pagination and table configuration from the custom hook for configuration
  const { filters, pageSize, tableConfig } = useTableConfig(tableConfiguration)

  // editingKey is the id of the editable row
  const [editingKey, setEditingKey] = useState(null)

  // dataRows is the local state array of rows merged with editing row changes
  // Used for rendering the additional risk changes among the other row columns
  const [dataRows, setDataRows] = useState(_cloneDeep(rows))

  // riskUpdate keeps track of the row updates in a flattened Object. This is what gets dispatched
  // for the risk:update action.
  const [riskUpdates, setRiskUpdate] = useState({})

  // ==============================
  // TODO: verify this will not cause issues if pub/sub changes come rolling in!
  // when rows changes, update dataRows
  useEffect(() => setDataRows(_cloneDeep(rows)), [rows])

  const riskIdx = dataRows.findIndex(row => editingKey === row.id)
  const risk = useMemo(
    () => ({ ...dataRows[riskIdx] }),
    [dataRows, riskIdx]
  ) // spread the properties so we don't update the location in state

  const [form] = Form.useForm()

  // this is using `react-redux#batch` which is essentially just a re-export of the
  // `ReactDOM#unstable_batchedUpdates` API that React uses internally on it's own
  // event handler callbacks. If batching actions becomes more of a regular pattern
  // for us, we should consider exploring some of the other options available like `
  // redux-batch`, etc. More information can be found in this post on Mark Erikson's blog:
  // https://blog.isquaredsoftware.com/2020/01/blogged-answers-redux-batching-techniques/
  const submitRisk = useCallback(() => {
    batch(() => {
      dispatch(actions.risk.update(
        flattenRisk(riskUpdates),
        { projectId, riskId: risk.id, ancestry: risk.ancestry }
      ))

      if(riskUpdates.status && risk.status !== riskUpdates.status) {
        const dispatchUpdateActiveRisks = operation => dispatch(actions.riskProject.updateStatistics({
          activeRisks: {
            value: 1,
            operation,
          },
        }, { ancestry: risk.ancestry, projectId: risk.projectId }))

        // if risk status is moving to "ACTIVE" from any other status, increment activeRisks count in project
        if(riskUpdates.status === RiskAnalysisStatus.ACTIVE) dispatchUpdateActiveRisks('INCREMENT')
        // if risk status is moving from "ACTIVE" to any other status, decrement activeRisks count in project
        else if(risk.status === RiskAnalysisStatus.ACTIVE) dispatchUpdateActiveRisks('DECREMENT')
      }
    })

  }, [dispatch, projectId, risk, riskUpdates])

  // complete risk updates come in a flat format, but the table expects this unflattened.
  const unflatten = o => Object.entries(o).reduce((o, [k, v]) => _set(o, k, v), {})

  /**
   * update the table form on field changes. Use the user keyed changes to grab a completeRiskUpdate and merge
   * that complete update into the riskUpdate and the dataRows.
   *
   * @params {object} update - the user keyed changes
   */
  const updateForm = useCallback(update => {
    const completeUpdate = completeRiskUpdate(risk, update, effectiveRiskContext)
    setRiskUpdate(Object.assign(riskUpdates, completeUpdate))

    const newData = [...dataRows]
    if(riskIdx > -1) {
      newData.splice(riskIdx, 1, _merge(risk, unflatten(completeUpdate)))
      setDataRows(newData)
    }
  }, [effectiveRiskContext, dataRows, risk, riskIdx, riskUpdates])

  // Instantiate the table columns class object
  const riskInventoryColumns = useMemo(() => {
    /**
     * Set the form values and update the active editingKey coordinating
     * with the selected editable row.
     *
     * @param {object} record - the affected row record
     */
    const onBeginEdit = record => {
      setEditingKey(record.id)
    }

    /*
     * Deselect the editingKey, clear the dataRows rows, and reset the form.
     */
    const onCancelEdit = () => {
      setDataRows(_cloneDeep(rows))
      form.resetFields()
      setEditingKey(null)
    }

    /**
     * When leaving an edited field, perform a completeRiskUpdate to automatically spread in any
     * changes for the rest of the risk.
     *
     * @param {String} dataIndex - the edited field
     */
    const onFieldChange = async dataIndex => {
      const idx = Array.isArray(dataIndex) ? dataIndex.join('.') : dataIndex
      const row = await form.validateFields()
      const update = { [idx]: _get(row, idx) || null }
      updateForm(update)
    }

    /**
     * Submit the risk as an update, reset the form, and rest the editing key.
     */
    const onSave = async () => {
      submitRisk()
      form.resetFields()
      setEditingKey(null)
      setRiskUpdate({})
    }

    return new RiskInventoryColumns({
      effectiveRiskContext,
      editable: canEditRisk,
      editingKey,
      filters,
      formFieldsTouched: form.isFieldsTouched(),
      includePerformance,
      label,
      onBeginEdit,
      onCancelEdit,
      onFieldChange,
      onSave,
      pathname,
    })
  }, [
    canEditRisk,
    editingKey,
    effectiveRiskContext,
    filters,
    form,
    includePerformance,
    pathname,
    rows,
    submitRisk,
    updateForm,
  ])

  // get the table columns and sortFns
  const columns = riskInventoryColumns.getColumns()
  const components = riskInventoryColumns.getComponents()
  const sortFns = riskInventoryColumns.getCustomSortFns()

  /**
   * Process the dataRows rows. Add managed overlay, and clear qual/quant values when
   * impactType is nullish.
   */
  const dataSource = useMemo(
    () => dataRows.map(r => {
      const risk = _cloneDeep(r)
      // if the risk row is editable, spread the risk values into the form
      if(r.id === editingKey) {
        form.resetFields()
        form.setFieldsValue({ ...risk })
      }
      return risk
    }),
    [dataRows, editingKey, form]
  )

  // Set the columns for the customization modal.
  tableConfig.columns = columns

  // filter out the unset columns
  const filteredCols = filterTableColumns(columns, tableConfig)

  /**
   * When filters or pagination change, persist the data to the database
   *
   * @param {Object} pagination - active pagintation object
   * @param {Object} filters - Available filters for the table
   */
  const onFilterChange = (pagination, filters) => {
    const { pageSize } = pagination
    const payload = {
      ...tableConfiguration,
      tableId: RiskInventoryTable.id,
      pagination: { pageSize },
      filters,
    }
    dispatch(actions.user.setTableConfig(
      payload,
      { userId: authUserId }
    ))
  }

  const onSortChange = sortFields => {
    dispatch(actions.user.setTableConfig(
      {
        ...tableConfig,
        sortFields,
      },
      { userId: authUserId }
    ))
  }

  const pagination = {
    pageSize,
    defaultCurrent: 1,
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '25', '50', '100'],
    position: 'both',
  }

  /**
   * Handle the close of a tag. Clear the filter for the table.
   *
   * @param {String} dataKey - the dataIndex to be cleared of filters.
   */
  const onClose = dataKey =>
    onFilterChange(pagination, { ...filters, [dataKey]: [] })

  const contextTypesByValue = {
    category: _keyBy(effectiveRiskContext.types.category.values, 'value'),
    phase: _keyBy(effectiveRiskContext.types.phase.values, 'value'),
  }

  /**
   * Build the tag for the active filters in the table.
   *
   * @param {String} k - Key for the tag
   * @param {Object} v - Value for the tag as an Array
   */
  const buildTag = (k, values) => {
    const isManaged = k.startsWith('managed.')
    // need to map category & phase IDs to labels
    if(k === 'category' || k === 'phase') {
      values = values.map(v => contextTypesByValue[k][v].label?.short || 'ERROR')
    }
    return buildFilterTag(k, values, { onClose, color: isManaged && RiskColor.MANAGED_BACKGROUND })
  }

  /**
   * Sort the filters with Unmanaged options first then managed options at the end.
   *
   * @param {Object} a - filter with key value pairing
   * @param {Object} b - filter with key value pairing
   */
  const filterSort = (a, b) => {
    if(a[0].startsWith('managed') && b[0].startsWith('managed')) return 0
    if(a[0].startsWith('managed')) return 1
    if(b[0].startsWith('managed')) return -1
    return 0
  }

  const [riskScaleReferenceVisible, setRiskScaleReferenceVisible] = useState(false)

  const showRiskScaleReference = () => setRiskScaleReferenceVisible(true)
  const hideRiskScaleReference = () => setRiskScaleReferenceVisible(false)

  const canConfigureCols = useMemo(() => authz({
    module: 'System',
    type: actions.user.setTableConfig.toString(),
    meta: {
      authUserId,
      userId: authUserId,
    },
  }), [authUserId, authz])

  const titleInfo = (
    <Row type="flex" justify="space-between" align="middle">
      <Col>
        {Object.entries(filters).sort(filterSort)
          .filter(v => !!v[1] && v[1].length)
          .map(([k, v]) => buildTag(k, v))
        }
      </Col>
      <Col style={{ textAlign: 'right' }}>
        {canConfigureCols &&
          <LinkButton2
            onClick={() => dispatch(showModal(RiskTableCustomizationModal.id, tableConfig))}
            style={style.button}
          >
            Configure Columns
          </LinkButton2>
        }
        <LinkButton2 onClick={showRiskScaleReference}>Risk Scale Reference</LinkButton2>
      </Col>
    </Row>
  )

  return (
    <>
      <NavConfirmation when={!!form.isFieldsTouched()} />
      {heatmapFilter &&
        <RiskInventoryHeatmapFilter
          effectiveRiskContext={effectiveRiskContext}
          dataSource={dataSource}
          setDisplayData={setDisplayData}
        />
      }
      <Drawer
        onClose={hideRiskScaleReference}
        title="Risk Scale Reference"
        visible={riskScaleReferenceVisible}
        placement="top"
        mask={false}
        height={280}
      >
        <RiskContextScaleReference effectiveRiskContext={effectiveRiskContext} headerHeight={30} />
      </Drawer>
      <Form form={form} component={false}>
        <Table
          columns={filteredCols}
          components={components}
          dataSource={displayData || dataSource}
          loading={loading}
          // TODO: investigate use of onChange; it's not a valid Ant Design Table prop,
          // and is not used in our augmented Table component.
          onChange={onFilterChange}
          onSortChange={onSortChange}
          pagination={pagination}
          rowKey="id"
          scroll={{ x: 'max-content' }}
          sortFns={sortFns}
          title={() => titleInfo}
          sortFields={tableConfig.sortFields}
          {...riskTableStyle}
        />
      </Form>
    </>
  )
}
RiskInventoryTable.id = 'RiskInventoryTable'

const style = {
  button: {
    marginRight: '24px',
  },
}

export default RiskInventoryTable
