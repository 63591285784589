/* @jsxImportSource @emotion/react */
import React, { useMemo } from 'react'
import { Form, Button, Card, Statistic } from '../../client/controls'

function usePlanFeatureBullets(product) {
  return useMemo(() => Object.entries(product.quotas)
    .filter(a => a[1] !== 0)
    .map(([k, v]) => `${k}: ${v >= 10e6 ? 'unlimited' : v}`),
  [product])
}

function PlanCard({ product, actionText, actionButtonProps, onAction }) {
  const bullets = usePlanFeatureBullets(product)

  return (
    <Card title={product.name} css={style.priceCard}>
      <div>
        {product.description}
        <div css={style.featureContainer}>
          <ul>
            {bullets.map(bullet => <li key={bullet}>{bullet}</li>)}
          </ul>
        </div>
        {product.price &&
          <Statistic value={product.price} prefix='$' suffix='/ month' />
        }
        {onAction && (
          <Form.Item
            validateStatus={product.disabledReason ? 'error' : undefined}
            help={product.disabledReason}
          >{product.isCurrentProduct
            ? (
              <i css={style.currentPlan}>Current account plan</i>
            )
            : (
              <Button
                disabled={product.disabled || product.isCurrentProduct}
                css={style.actionButton}
                type="primary"
                onClick={onAction}
                {...actionButtonProps}
              >{actionText}</Button>
            )
          }
          </Form.Item>
        )}
      </div>
    </Card>
  )
}

const style = {
  priceCard: {
    border: 'solid 1px #b2bccc',
    boxShadow: '4px 4px 1px 1px #c2cad6',
    margin: '18px 0',
  },
  actionButton: {
    marginTop: '18px',
  },
  currentPlan: {
    display: 'inline-block',
    marginTop: '18px',
  },
}

export default PlanCard
