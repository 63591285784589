import { CriterionData } from '../Criterion'
import { createId } from '../../idUtils'
import { defaultOptionRatingScaleConfig } from '../criteria'

function createDefaultCriteriaData({ semanticIds }: { semanticIds?: boolean } = {}): CriterionData[] {
  const valueId = semanticIds ? 'V' : createId()
  return [
    {
      id: valueId,
      name: 'Value',
      abbrev: 'Val',
      parentId: null,
      description: { $type: 'html', value: null },
      type: 'Value',
      color: '#3cb7ce',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
    {
      id: semanticIds ? 'P' : createId(),
      name: 'Performance',
      abbrev: 'Perf',
      parentId: valueId,
      description: {
        $type: 'html',
        value: '<p>The first step in making a decision is determining the criteria you\'ll use to' +
        ' evaluate options. We use the word “performance” to describe how valuable an option is;' +
        ' a high-performing option is more valuable than a low-performing one</p>' +
        '<p>Performance can be broken down into sub-criteria.  For example, in a car-buying decision,' +
        ' performance may be broken down into “Aesthetics”, “Comfort”, “Fuel Efficiency” and “Safety”.' +
        ' For simple decisions, you may not need to break performance down, but you might want to re-name' +
        ' it to be clearer.  For example, if you were making a group decision about where to go to lunch,' +
        ' you might rename performance to “Personal Preference” (people don’t normally think about the' +
        ' “performance” of restaurants).</p>' +
        '<p>To break down perfomance, click on the "Add Child" button.</p>' +
        '<p>Note that the <em>cost</em> or <em>duration</em> (for a project, for example) are considered' +
        ' separately, and should not normally be used as performance criteria.</p>',
      },
      type: 'Performance',
      color: '#f66b84',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
  ]
}

function createMonsterTruckCriteriaData({ semanticIds = false }): CriterionData[] {
  if(semanticIds) throw new Error('semantic IDs not available for this data set')
  const criteria = createDefaultCriteriaData()
  const perfRoot = criteria.find(c => c.type === 'Performance')
  if(!perfRoot) throw new Error('missing performance root')
  criteria.push(
    {
      id: createId(),
      name: 'Intimidation Factor',
      abbrev: 'INTIMIDATE',
      parentId: perfRoot.id,
      description: { $type: 'html', value: null },
      type: 'Rated',
      color: '#97968e',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
    {
      id: createId(),
      name: 'Destruction Factor',
      abbrev: 'DESTRUCTION',
      parentId: perfRoot.id,
      description: { $type: 'html', value: null },
      type: 'Rated',
      color: '#85ca43',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
    {
      id: createId(),
      name: 'Popularity',
      abbrev: 'POP',
      parentId: perfRoot.id,
      description: { $type: 'html', value: null },
      type: 'Rated',
      color: '#d280e1',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
    {
      id: createId(),
      name: 'Noise',
      abbrev: 'NOISE',
      parentId: perfRoot.id,
      description: { $type: 'html', value: null },
      type: 'Rated',
      color: '#59cd8b',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
    {
      id: createId(),
      name: 'Diversity, Equity, & Inclusion',
      abbrev: 'DEI',
      parentId: perfRoot.id,
      description: { $type: 'html', value: null },
      type: 'Rated',
      color: '#f66b84',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
  )
  return criteria
}


function createAnimalCriteriaData({ semanticIds }: { semanticIds?: boolean } = {}): CriterionData[] {
  const criteria = createDefaultCriteriaData({ semanticIds })
  const perfRoot = criteria.find(c => c.type === 'Performance')
  if(!perfRoot) throw new Error('missing performance root')
  const aesId = semanticIds ? 'AEST' : createId()
  criteria.push(
    // aesthetics & children
    {
      id: aesId,
      name: 'Aesthetics',
      abbrev: 'AEST',
      parentId: perfRoot.id,
      description: { $type: 'html', value: null },
      type: 'Rated',
      color: '#97968e',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
    {
      id: semanticIds ? 'CUTE' : createId(),
      name: 'Cute',
      abbrev: 'CUTE',
      parentId: aesId,
      description: { $type: 'html', value: 'How cute is this animal?' },
      type: 'Rated',
      color: '#85ca43',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
    {
      id: semanticIds ? 'SCRY' : createId(),
      name: 'Scary',
      abbrev: 'SCRY',
      parentId: aesId,
      description: { $type: 'html', value: 'How scary is this animal?' },
      type: 'Rated',
      color: '#d280e1',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
    {
      id: semanticIds ? 'XOTC' : createId(),
      name: 'Exotic',
      abbrev: 'XOTC',
      parentId: aesId,
      description: { $type: 'html', value: 'How exotic or weird is this animal?' },
      type: 'Rated',
      color: '#59cd8b',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
    {
      id: semanticIds ? 'VELO' : createId(),
      name: 'Velocity',
      abbrev: 'VELO',
      parentId: perfRoot.id,
      description: { $type: 'html', value: 'How fast is this animal?' },
      type: 'Rated',
      color: '#f66b84',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
  )
  return criteria
}

function createABCriteriaData({ semanticIds }: { semanticIds?: boolean } = {}): CriterionData[] {
  const criteria = createDefaultCriteriaData({ semanticIds })
  const perfRoot = criteria.find(c => c.type === 'Performance')
  if(!perfRoot) throw new Error('missing performance root')
  criteria.push(
    {
      id: semanticIds ? 'A' : createId(),
      name: 'A',
      abbrev: 'A',
      parentId: perfRoot.id,
      description: { $type: 'html', value: null },
      type: 'Rated',
      color: '#97968e',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
    {
      id: semanticIds ? 'B' : createId(),
      name: 'B',
      abbrev: 'B',
      parentId: perfRoot.id,
      description: { $type: 'html', value: null },
      type: 'Rated',
      color: '#85ca43',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
  )
  return criteria
}

function createABCCriteriaData({ semanticIds }: { semanticIds?: boolean } = {}): CriterionData[] {
  const criteria = createDefaultCriteriaData({ semanticIds })
  const perfRoot = criteria.find(c => c.type === 'Performance')
  if(!perfRoot) throw new Error('missing performance root')
  criteria.push(
    {
      id: semanticIds ? 'A' : createId(),
      name: 'A',
      abbrev: 'A',
      parentId: perfRoot.id,
      description: { $type: 'html', value: null },
      type: 'Rated',
      color: '#97968e',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
    {
      id: semanticIds ? 'B' : createId(),
      name: 'B',
      abbrev: 'B',
      parentId: perfRoot.id,
      description: { $type: 'html', value: null },
      type: 'Rated',
      color: '#85ca43',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
    {
      id: semanticIds ? 'C' : createId(),
      name: 'C',
      abbrev: 'C',
      parentId: perfRoot.id,
      description: { $type: 'html', value: null },
      type: 'Rated',
      color: '#d280e1',
      optionRatingScaleConfig: defaultOptionRatingScaleConfig,
    },
  )
  return criteria
}

const sampleCriterionSets = {
  'UnitaryPerformance': (semanticIds = false) => createDefaultCriteriaData({ semanticIds }),
  'MonsterTrucks': (semanticIds = false) => createMonsterTruckCriteriaData({ semanticIds }),
  'Animals': (semanticIds = true) => createAnimalCriteriaData({ semanticIds }),
  'AB': (semanticIds = true) => createABCriteriaData({ semanticIds }),
  'ABC': (semanticIds = true) => createABCCriteriaData({ semanticIds }),
}

/**
 * Generates criteria sets.  Simple for now, only generating the two default criteria (Value -> Performance).
 */
export const criteriaDataFactory = {
  createDefaultCriteriaData,
  sample(dataSet: keyof typeof sampleCriterionSets = 'UnitaryPerformance', semanticIds?: boolean) {
    return sampleCriterionSets[dataSet](semanticIds)
  },
}
