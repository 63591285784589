import { produce } from 'immer'
import { Rating } from '@vms/vmspro3-core/dist/types'
import _omitBy from 'lodash/omitBy'
import _matches from 'lodash/matches'
import {
  UpdateRatingAction,
  DeleteParticipantAction,
  DeleteCriterionAction,
  DeleteOptionAction,
} from '@vms/vmspro3-core/dist/actions/decision'

import { FetchDecisionEntitySuccessAction, FetchDecisionFolderChildrenSuccessAction } from '../actionTypes'

export type RatingsState = {
  byId: Record<string, Rating>
}

export const initialState: RatingsState = {
  byId: {},
}

type Actions =
  | FetchDecisionEntitySuccessAction
  | FetchDecisionFolderChildrenSuccessAction
  | UpdateRatingAction
  | DeleteParticipantAction
  | DeleteCriterionAction
  | DeleteOptionAction

export const ratingsReducer = produce<RatingsState, [Actions?]>((state, action) => {
  if(!action?.type) return

  switch(action.type) {
    case 'FetchDecisionFolderChildrenSuccess': // fall through to fetchDecisionEntitySuccess
    case 'FetchDecisionEntitySuccess': {
      if(action.payload.ratings) {
        Object.assign(state.byId, action.payload.ratings)
      }
      break
    }

    case 'Update Rating': {
      state.byId[action.meta.id] = {
        ...action.payload,
        updated: {
          timestamp: Date.now(),
          location: '{}',
        },
      }
      break
    }

    case 'Delete Participant': {
      state.byId = _omitBy(state.byId, _matches({ participantId: action.meta.participantId }))
      break
    }

    case 'Delete Criterion': {
      const { criterionId, descendantCriteriaIds = [] } = action.meta
      const criteriaIdsToDelete = [criterionId, ...descendantCriteriaIds]
      state.byId = _omitBy(state.byId, rating => criteriaIdsToDelete.some(criterionId =>
        rating.subjectId === criterionId ||
        rating.contextId === criterionId
      ))
      break
    }

    case 'Delete Option': {
      state.byId = _omitBy(state.byId, _matches({ subjectId: action.meta.optionId }))
      break
    }

    default: {
      break
    }
  }
}, initialState)
