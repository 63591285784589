import React from 'react'
import qs from 'querystringify'
import Server from '../../server/VMSProServerAdapter'
import { ASSUMED_USER_ID_KEY } from '../../utils/appConsts'

class Logout extends React.Component {
  componentDidMount() {
    const query = qs.parse(document.location.search)
    Server.auth.signOut()
      .then(() => {
        localStorage.removeItem(ASSUMED_USER_ID_KEY)
      })
      .then(() => {
        if(!query.noreload) window.location = '/'
      })
  }

  render() {
    return (
      <div style={{ padding: '2vh 2vw' }}>
        <h3>You have been logged out.</h3>
      </div>
    )
  }
}

export default Logout
