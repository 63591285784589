import { produce } from 'immer'
import _set from 'lodash/set'

import unflattenDeep from '@vms/vmspro3-core/dist/utils/unflattenDeep'
import { EntityType } from '@vms/vmspro3-core/dist/systemConsts'
import { recomputeQuantValues } from '@vms/vmspro3-core/dist/utils/risk'
import { joinAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'

import actions from '../../actions'
import riskEntitiesActionTypes from './actionTypes'
import { LoadingStatus } from '../../utils/appConsts'

const initialState = {
  byId: {},
  byAncestry: {},
}

// convenience reducer function for portfolio, program, project creation
function createEntity(mutableState, { payload, meta }) {
  const entity = {
    ...unflattenDeep(payload),
    ancestry: meta.ancestry,
    loadingStatus: LoadingStatus.Loaded,
  }
  mutableState.byId[entity.id] = entity
  if(mutableState.byAncestry[entity.ancestry]) {
    mutableState.byAncestry[entity.ancestry].push(entity)
  }
}

// convenience reducer function for portfolio, program, project updating
function updateEntity(mutableState, id, { payload }) {
  const entity = mutableState.byId[id]
  Object.entries(payload).forEach(([k, v]) => {
    _set(entity, k, v)
  })
  if(mutableState.byAncestry[entity.ancestry]) {
    mutableState.byAncestry[entity.ancestry] = mutableState.byAncestry[entity.ancestry].map(sibling =>
      sibling.id === entity.id ? entity : sibling
    )
  }
}

function deleteEntity(mutableState, id) {
  const entity = mutableState.byId[id]
  delete mutableState.byId[id]
  const siblings = mutableState.byAncestry[entity.ancestry]
  if(siblings) mutableState.byAncestry[entity.ancestry] = siblings.filter(sibling => sibling.id !== entity.id)
}

const riskEntitiesReducer = produce((state, action) => {
  switch(action.type) {
    case 'ResetAccountState': {
      return initialState
    }
    case riskEntitiesActionTypes.FetchRiskEntityRequest: {
      state.byId[action.meta.entityId] = { loadingStatus: LoadingStatus.Requested }
      break
    }
    case riskEntitiesActionTypes.FetchRiskEntitySuccess: {
      if(!action.payload.entity) {
        state.byId[action.meta.entityId] = { loadingStatus: LoadingStatus.NotFound }
      } else {
        // we're loading not just he specific entity, but its ancestors as well (required for crumb trail)
        const entities = [action.payload.entity, ...action.payload.ancestors]
        entities.forEach(entity => {
          state.byId[entity.id] = {
            ...entity,
            loadingStatus: LoadingStatus.Loaded,
          }
          // note that we don't update byAncestry index; the byAncestry index is a group
          // index that's updated all at once for all children of a given ancestry; it
          // undermines this purpose of the byAncestry index if we try to load single
          // entities into that index
        })
      }
      break
    }

    case riskEntitiesActionTypes.FetchRiskEntityChildrenRequest: {
      // nothing to do here; we know we need to load children if the
      // corresponding ancestry array isn't present (i.e., no loading state
      // is necessary), though we may add a loading status in the future.
      break
    }
    case riskEntitiesActionTypes.FetchRiskEntityChildrenSuccess: {
      const { entities } = action.payload
      entities.forEach(entity => {
        if(state.byId[entity.id]) return  // already loaded
        state.byId[entity.id] = {
          ...entity,
          loadingStatus: LoadingStatus.Loaded,
        }
      })
      // update byAncestry index
      state.byAncestry[action.meta.childAncestry] = entities
      break
    }

    //
    // Portfolios
    //
    case actions.riskPortfolio.create.toString(): {
      createEntity(state, action)
      break
    }
    case actions.riskPortfolio.update.toString(): {
      updateEntity(state, action.meta.portfolioId, action)
      break
    }

    //
    // Programs
    //
    case actions.riskProgram.create.toString(): {
      createEntity(state, action)
      break
    }
    case actions.riskProgram.update.toString(): {
      updateEntity(state, action.meta.programId, action)
      break
    }

    //
    // Projects
    //
    case actions.riskProject.create.toString(): {
      createEntity(state, action)
      break
    }
    case actions.riskProject.update.toString(): {
      updateEntity(state, action.meta.projectId, action)
      break
    }
    case actions.riskProject.updateStatistics.toString(): {
      const { statistics = {} } = state.byId[action.meta.projectId]
      Object.entries(action.payload).forEach(([key, { operation, value }]) => {
        switch(operation) {
          case 'INCREMENT': statistics[key] = (statistics[key] || 0) + value; break
          case 'DECREMENT': statistics[key] = (statistics[key] || 0) - value; break
          default: throw new Error(`unrecognized statistics operation: ${operation}`)
        }
      })
      updateEntity(state, action.meta.projectId, { payload: { statistics } })
      break
    }

    //
    // Risks
    //
    case actions.risk.create.toString(): {
      // create the risk
      createEntity(state, action)
      // get the project the risk is being created in
      const project = state.byId[action.meta.projectId]
      const projectUpdate = { payload: { 'riskContext.nextRiskNum': project.riskContext.nextRiskNum + 1 } }
      // update the nextRiskNumber property in the project's context
      updateEntity(state, action.meta.projectId, projectUpdate)
      break
    }
    case actions.risk.update.toString(): {
      updateEntity(state, action.meta.riskId, action)
      break
    }
    case actions.risk.delete.toString(): {
      deleteEntity(state, action.meta.riskId)
      break
    }

    //
    // Risk Entity (generic)
    //
    case actions.riskEntity.update.toString(): {
      updateEntity(state, action.meta.entityId, action)
      break
    }
    case actions.riskEntity.delete.toString(): {
      deleteEntity(state, action.meta.entityId, action)
      break
    }

    // risk contexts are now just a property of their associated entity, and the update
    // includes the entire risk context
    case actions.riskContext.update.toString(): {
      const { meta: { entityId, ancestry }, payload } = action
      const entity = state.byId[entityId]

      // set payload as risk context
      entity.riskContext = payload

      // set updated entity in byAncestry array
      const siblings = state.byAncestry[ancestry] ?? []
      const idx = siblings.findIndex(s => s.id === entityId)
      if(idx >= 0) siblings[idx] = entity

      // update child risks in byAncestry array
      const childAncestry = joinAncestry(ancestry, entityId)
      const children = state.byAncestry[childAncestry] ?? []
      children
        .filter(c => c.entityType === EntityType.RISK)
        .forEach(risk => {
          const { update, warnings } = recomputeQuantValues(payload, risk)

          // log warnings
          if(warnings.length) {
            console.warn(
              `Received ${warnings.length} errors when recomputing risk impacts ` +
              `for children of risk entity ${entityId}: ` +
              `\n${JSON.stringify(warnings, null, 2)}`
            )
          }

          // apply impact update to risk
          Object.entries(update).forEach(([k, v]) => _set(risk, k, v))

          // set updated risk in state.byId
          state.byId[risk.id] = risk
        })

      break
    }

    case actions.riskEntity.updateLocation.toString(): {
      const { entityId, dstParentId } = action.meta
      const dstAncestry = joinAncestry(state.byId[dstParentId].ancestry, dstParentId)

      const entity = state.byId[entityId]

      // get source ancestry and create source descendant ancestry before changing
      const srcEntityAncestry = entity.ancestry
      const srcDescendantAncestry = joinAncestry(srcEntityAncestry, entityId)

      // update entity ancestry
      entity.ancestry = dstAncestry

      // if loaded, filter entity from source ancestry siblings
      if(state.byAncestry[srcEntityAncestry]) {
        state.byAncestry[srcEntityAncestry] = state.byAncestry[srcEntityAncestry].filter(sibling => (
          sibling.id !== entityId
        ))
      }
      // if loaded, add entity to destination ancestry siblings
      if(state.byAncestry[dstAncestry]) {
        state.byAncestry[dstAncestry].push(entity)
      }

      // update descendants
      Object.entries(state.byAncestry)
        // find descendants by source ancestry
        .filter(([key, value]) => key?.startsWith(srcDescendantAncestry) && Array.isArray(value))
        .forEach(([descendantAncestry, descendants]) => {
          // create the destination descendant ancestry key
          const dstDescendantAncestry = joinAncestry(dstAncestry,
            descendantAncestry.substring(srcEntityAncestry.length))

          // update entity ancestry and set to destination byAncestry location
          state.byAncestry[dstDescendantAncestry] = descendants.map(descendant => {
            descendant.ancestry = dstDescendantAncestry
            state.byId[descendant.id] = descendant
            return descendant
          })
          // source ancestry location no longer exists
          delete state.byAncestry[descendantAncestry]
        })

      break
    }

    default: {
      return state
    }
  }
}, initialState)

export default riskEntitiesReducer
