import { ReactElement, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { RollbackOutlined } from '@ant-design/icons'
import invariant from 'tiny-invariant'

import { Page } from '../components/Page'
import { Button } from '../client/controls'
import { AuthUserParticipantContextProvider, OptionRating } from '../components/Participation'

import { useLoadDecision } from '../redux/hooks/decisionHooks'

export function OptionRatingPage(): ReactElement {
  const { decisionId, participationSessionId } = useParams<'decisionId' | 'participationSessionId'>()

  invariant(decisionId, 'decisionId required in URL parameters')
  invariant(participationSessionId, 'participationSessionId required in URL parameters')

  const navigate = useNavigate()
  const backToDecision = useCallback(
    () => navigate(`/decision/${decisionId}`),
    [navigate, decisionId]
  )

  const loading = useLoadDecision(decisionId)

  return (
    <Page
      loading={loading}
      extra={(
        <Button type="primary" onClick={backToDecision} icon={<RollbackOutlined />}>
          Back to Decision
        </Button>
      )}
    >
      <AuthUserParticipantContextProvider decisionId={decisionId}>
        <OptionRating
          decisionId={decisionId}
          participationSessionId={participationSessionId}
        />
      </AuthUserParticipantContextProvider>
    </Page>
  )
}
