/* eslint-disable @typescript-eslint/no-var-requires, no-undef */
const _get = require('lodash/get')

const escapeRegex = /[-/\\^$*+?.()|[\]{}]/g

/**
 * Tests a value against a policy wildcard.  Policy wildcards are similar to Unix filename
 * globbing in that they support '*' (zero or more characters) and '?' (exactly one character).
 *
 * The current implementation converts the pattern into an equivalent regex -- after escaping
 * all regex metacharacters to prevent evil regexes that could lead to DDOS or other attacks.
 * This is undoubtedly not the most efficient implementation, but a proper implementation would
 * not be trivial.
 *
 * Note that the pattern is case sensitive, and must match the entire string.
 *
 * Examples:
 *   testPolicyWildcard('f*r', 'foobar')       // -> true
 *   testPolicyWildcard('foo?ar', 'foobar')    // -> true
 *   testPolicyWildcard('foobar', 'xfoobarx')  // -> false
 *   testPolicyWildcard('FOOBAR', 'foobar')    // -> false
 *
 * @param {string} pattern - The wildcard pattern.  '*' and '?' are special characters; no escape support.
 * @param {string} value - The value to test.
 */
function testPolicyWildcard(pattern, value) {
  pattern = pattern
    .replace(escapeRegex, '\\$&')
    .replace(/\\\*+/g, '.*')
    .replace(/\\\?+/g, '.')
  const re = new RegExp('^' + pattern + '$')
  return re.test(value)
}

/**
 * Given an action, and a property access string, returns the value of the proprty, or undefined if
 * no such property exists.
 *
 * Note that, on the surface, this appears simply to be replacing colons for periods as a way of dynamically
 * accessing object properties, but in the future it may need to do a more sophisticated lookup.
 *
 * @param {Object} action - The action in which to look for the property.
 * @param {string} propStr - A property access string (ex: "action:meta:foo").
 * @returns {string|undefined} The value of the corresponding property from the action, or undefined.
 */
function getProp(action, propStr) {
  const elts = propStr.split(':')
  if(elts[0] === 'action') {
    if(elts[1] === 'meta') return action.meta && _get(action.meta, elts[2])
    if(elts[1] === 'payload') return action.payload && _get(action.payload, elts[2])
    return undefined
  }
  return undefined
}

function interpolateProp(action, valStr) {
  return valStr.replace(/{(.*?)}/g, (m, prop) => getProp(action, prop))
}

const condEvaluators = {
  StringEquals: (action, operands) => Object.entries(operands).every(([k, v]) => {
    const prop = getProp(action, k)
    if(typeof prop !== 'string') return false
    if(Array.isArray(v)) return v.some(v => interpolateProp(action, v) === prop)
    return interpolateProp(action, v) === prop
  }),
  StringLike: (action, operands) => Object.entries(operands).every(([k, v]) => {
    const prop = getProp(action, k)
    if(typeof prop !== 'string') return false
    if(Array.isArray(v)) return v.some(v => testPolicyWildcard(interpolateProp(action, v), prop))
    return testPolicyWildcard(interpolateProp(action, v), prop)
  }),
}
condEvaluators.StringNotEquals = (action, operands) => !condEvaluators.StringEquals(action, operands)
condEvaluators.StringNotLike = (action, operands) => !condEvaluators.StringLike(action, operands)

/**
 * Given a condition, tests if a given action meets the condition.  Note that a null or undefined condition
 * is considered to be implicitly met.  That is, evalCondition(action, undefined) will return true.
 *
 * @param {Object} action - The action being tested.
 * @param {Object} cond - A condition object.
 * @returns {boolean} true if the action meets the condition, false otherwise.
 */
function evalCondition(action, cond, explain) {
  if(!cond) return true   // no condition; implicit success
  const allConditionsMet =  Object.entries(cond).every(([op, operands]) => {
    const evaluator = condEvaluators[op]
    if(!evaluator) throw new Error('unrecognized operator: ' + op)
    if(evaluator(action, operands)) {
      return true
    } else {
      explain.logObj({ [op]: operands }, 'condition not met: ')
    }
  })
  if(allConditionsMet) {
    explain.log('all conditions met')
  } else {
    explain.log('conditions not met')
  }
  return allConditionsMet
}

function evalModule(actionModule, statementModule, explain) {
  if(!statementModule) {
    explain.log('statement is missing "Module" property')
    return false
  }
  if(actionModule !== statementModule) {
    explain.log('action module does not match statement module')
    return false
  }
  return true
}

/**
 * Given a policy statement action, tests if attempted action type matches or passes wildcard test.
 *
 * @param {string} actionType - The action being tested (ex: "study/create")
 * @param {string} statementAction - Action type from policy statement (ex: "study/*")
 * @param {Object} explain - An instance of Explain
 * @returns {boolean} - true if statementAction matches actionType, or evaluation from testWildcardPattern()
 */
function evalAction(actionType, statementAction, explain) {
  const actions = Array.isArray(statementAction) ? statementAction : [statementAction]
  if(actions.some(a => testPolicyWildcard(a, actionType))) {
    explain.log('statement action matches')
    return true
  } else {
    explain.log('statement action does not match')
    return false
  }
}

/**
 * Given a policy statement resource, tests if resource in action matches.
 *
 * @param {string} actionResource - The resource being tested
 * @param {string} statementResource - The resource from the policy statement
 * @param {Object} explain - An instance of Explain
 * @returns {boolean} - true if statementResource is '*' or matches actionResource
 */
function evalResource(actionMeta, statementResource, resourceEquivalents, explain) {
  if(!Array.isArray(statementResource)) statementResource = [statementResource]

  return statementResource.some(r => {
    if(r === '*') {
      explain.log('statement matches resource by wildcard')
      return true
    }
    const [resourceType, resourceId] = r.split(':')
    const resourceIdKey = resourceType + 'Id'

    const actionResource = actionMeta && actionMeta[resourceIdKey]
    if(actionResource && (actionResource === resourceId || resourceId === '*')) {
      explain.log('statement matches resource explicitly')
      return true
    }
    if(resourceEquivalents && resourceEquivalents.some(e => e === r)) {
      explain.log('statement matches resource equivalent')
      return true
    } else {
      explain.log('statement resource does not match')
      return false
    }
  })
}

/**
 * Given a policy statement, tests if the statement applices to a given action.
 *
 * @param {Object} action - The action being tested
 * @param {Object} statement - A policy statement
 * @param {Object} explain - An instance of Explain
 *
 * @returns {boolean} true if the statement applies to the action, false otherwise.
 */
function evalStatement(action, statement, resourceEquivalents, explain) {
  explain.push(`\nevaluating statement ${explain.statementNums.get(statement)}`)
  const moduleResult = evalModule(action.module, statement.Module, explain)
  const actionResult = evalAction(action.type, statement.Action, explain)
  const resourceResult = evalResource(action.meta, statement.Resource, resourceEquivalents, explain)
  const conditionResult = evalCondition(action, statement.Condition, explain)
  explain.pop()
  return (
    moduleResult &&
    actionResult &&
    resourceResult &&
    conditionResult
  )
}

const getStatements = policy => Array.isArray(policy.Statement) ? policy.Statement : [policy.Statement]

/**
 * Logs an explanation of the evaluation of an action against a set of policies.
 *
 * The log output is very verbose.  It logs the action being evaluated, then the policies,
 * numbered for convenient reference, then the statements that those policies result in,
 * also numbered (for examnple, 3.5 = the fifth statement in policy 3).
 */
class Explain {
  constructor(action, policies) {
    this.indentLevel = 0
    this.policyNums = new Map(policies.map((policy, i) => [policy, String(i + 1)]))
    this.statementNums = new Map()
    policies.forEach(policy => {
      const policyNum = this.policyNums.get(policy)
      getStatements(policy).forEach((statement, i) => {
        this.statementNums.set(statement, `${policyNum}.${i+1}`)
      })
    })
    this.log('\n\n*************\n** EXPLAIN **\n*************\n\n')
    this.logObj(action, 'ACTION: ')
    this.log('\n\nPOLICIES:')
    this.policyNums.forEach((policyNum, policy) => {
      this.logObj(policy, `  ${policyNum} `)
    })
    this.log('\n\nSTATEMENTS:')
    this.statementNums.forEach((statementNum, statement) => {
      this.logObj(statement, `  ${statementNum} `)
    })
  }
  logObj(obj, prefix = '') {
    const firstLineIndent = ' '.repeat(this.indentLevel)
    const indent = firstLineIndent + ' '.repeat(prefix.length)
    console.log(firstLineIndent + prefix + JSON.stringify(obj, null, 2).replace(/\n/g, '\n' + indent))
  }
  log(msg) {
    const indent = ' '.repeat(this.indentLevel)
    console.log(indent + msg)
  }
  push(msg) {
    console.log(' '.repeat(this.indentLevel) + msg)
    this.indentLevel++
  }
  pop() {
    this.indentLevel--
  }
}

/**
 * Default Explain instance that explains nothing...nice and quiet.
 */
class NoExplain extends Explain {
  constructor() {
    super([], [])
  }
  logObj() {} // eslint-disable-line @typescript-eslint/no-empty-function
  log() {} // eslint-disable-line @typescript-eslint/no-empty-function
  push() {} // eslint-disable-line @typescript-eslint/no-empty-function
  pop() {} // eslint-disable-line @typescript-eslint/no-empty-function
}

/**
 * Given a list of policies, tests if an action is authorized for the Principal performing the action.
 *
 * @param {Object} action - The action being authorized.
 * @param {Object[]} policies - The policies to authorize against.
 * @param {boolean} options.explain - true to log an explanation of why the authz
 *   succeeded / failed; note that the output is extremely verbose and should only be used for dev/debugging.
 * @returns {boolean} true if the action is authorized, false otherwise.
 */
function authz(action, policies, resourceEquivalents, { explain } = {}) {
  explain = explain
    ? new Explain(action, policies)
    : new NoExplain()

  if(!action.module) {
    explain.log('\nFINAL RESULT: action denied because "Module" property is missing.')
  }

  const statements = policies.reduce((statements, policy) => {
    getStatements(policy).forEach(statement => statements[statement.Effect.toLowerCase()].push(statement))
    return statements
  }, { allow: [], deny: [] })

  explain.push('\nevaluating "Deny" statements:')
  if(statements.deny.some(s => evalStatement(action, s, resourceEquivalents, explain))) {
    explain.pop()
    explain.log('\nFINAL RESULT: action denied because of matching "Deny" statement.')
    return false
  }
  explain.pop()
  explain.push('\nevaluating "Allow" statements:')
  if(statements.allow.some(s => evalStatement(action, s, resourceEquivalents, explain))) {
    explain.pop()
    explain.log('\nFINAL RESULT: action allowed because of matching "Allow" statement.')
    return true
  }
  explain.pop()
  explain.log('\nFINAL RESULT: action denied because of no matching "Allow" statement.')
  return false
}

module.exports = { testPolicyWildcard, authz }
