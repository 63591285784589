import { produce } from 'immer'
import asSet from 'arraysetjs'

import authActionTypes from '../auth/actionTypes'
import {
  HideModalAction,
  ResetAccountStateAction,
  SetMenuStateAction,
  ShowModalAction,
} from '../actionTypes'

export type AppState = {
  modals: {
    stack: string[],
    byId: Record<string, Record<string, unknown>>,
  },
  menuOpen: boolean,
}
export const initialState: AppState = {
  modals: { stack: [], byId: {} },
  menuOpen: false,
}

type Action =
  | ResetAccountStateAction
  | ShowModalAction
  | HideModalAction
  | SetMenuStateAction
  | { type: 'SignOut' }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const appReducer = produce<AppState, [Action?]>((state, action) => {
  switch(action?.type) {
    case authActionTypes.SignOut: {
      return initialState
    }
    case 'ResetAccountState': {
      return initialState
    }
    case 'ShowModal': {
      const { modalId } = action.meta
      state.menuOpen = false
      state.modals.byId[modalId] = action.payload
      asSet.mutable(state.modals.stack).add(modalId)
      break
    }
    case 'HideModal': {
      const { modalId } = action.meta
      asSet.mutable(state.modals.stack).remove(modalId)
      delete state.modals.byId[modalId]
      break
    }
    case 'SetMenuState': {
      state.menuOpen = action.payload.menuOpen
      break
    }
    default: {
      return state
    }
  }
}, initialState)
