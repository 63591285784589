/**
 *
 * @param {string} projectId
 * @returns {<Object> => {}} - selector function returning project object
 */
const getRiskProject = projectId => state => state.riskEntities.byId[projectId]

export {
  getRiskProject,
}
