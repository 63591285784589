import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloudUploadOutlined from '@ant-design/icons/CloudUploadOutlined'
import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import EditOutlined from '@ant-design/icons/EditOutlined'
import mime from 'mime'

import EntityAttachmentAddModal from '../../modals/EntityAttachmentAddModal'
import EntityAttachmentEditModal from '../../modals/EntityAttachmentEditModal'
import AttachmentLink from '../common/AttachmentLink'
import ProjectDateDisplay from '../common/ProjectDateDisplay'
import { Button, Modal, Space, Table } from '../../controls'

import actions from '../../../actions'
import useAuthz from '../../../hooks/useAuthz'
import { showModal } from '../../../redux/actions'

const RiskEntityAttachmentManager = ({ entityId }) => {
  const dispatch = useDispatch()
  const entity = useSelector(state => state.riskEntities.byId[entityId])
  const onDelete = (url, title) => {
    Modal.confirm({
      title: `Are you sure you want to delete attachment "${title}"?`,
      icon: <DeleteOutlined />,
      content: `Deleting attachments is permanent.  Are you sure?`,
      onOk() {
        const payload = {
          attachments: entity.attachments.filter(a => a.url !== url),
        }
        const meta = {
          entityId,
          entityType: entity.entityType,
          ancestry: entity.ancestry,
        }
        // TODO: this does not delete the object from the S3 bucket, so that
        // still needs to be addressed
        dispatch(actions.riskEntity.update(payload, meta))
      },
    })
  }
  const onEdit = url => dispatch(showModal(EntityAttachmentEditModal.id, {
    entity,
    url,
  }))

  const authz = useAuthz()
  const canEditAttachments = authz(actions.riskEntity.update(
    {}, // for authz check, it doesn't matter what's in the payload
    { entityId, entityType: entity.entityType, ancestry: entity.ancestry }
  ))

  const attachmentTableConfig = {
    columns: [
      {
        title: 'Attachment',
        key: 'attachment',
        render: (text, { url, title }) => <AttachmentLink url={url} title={title} />,
      },
      {
        title: 'Type',
        key: 'type',
        render: (text, { url }) => mime.getExtension(mime.getType(url)),
      },
      {
        title: 'Uploaded By',
        key: 'uploadedBy',
        render: (text, { uploadedBy }) => uploadedBy.fullName || '(Unknown)',
      },
      {
        title: 'Uploaded On',
        key: 'uploadedOn',
        render: (text, { uploadedOn }) => <ProjectDateDisplay time={uploadedOn} />,
      },
      canEditAttachments && {
        title: 'Actions',
        key: 'actions',
        // TODO: hook up buttons
        render: (text, { url, title }) => (
          <Space>
            <Button onClick={() => onEdit(url)}><EditOutlined /> Edit Title</Button>
            <Button onClick={() => onDelete(url, title)}><DeleteOutlined /> Delete</Button>
          </Space>
        ),
      },
    ].filter(Boolean),
    dataSource: entity.attachments || [],
    rowKey: 'url',
    style: styles.attachmentTable,
  }
  const onAddAttachment = () => dispatch(showModal(EntityAttachmentAddModal.id, { entity }))
  return (
    <>
      {canEditAttachments &&
        <Button onClick={onAddAttachment} type="primary"><CloudUploadOutlined /> Add Attachment</Button>
      }
      <Table {...attachmentTableConfig} />
    </>
  )
}

const styles = {
  attachmentTable: { margin: '12px 0' },
}

export default RiskEntityAttachmentManager
