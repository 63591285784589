/* eslint-disable @typescript-eslint/no-var-requires, no-undef */
const { getResourcePoliciesWithStatements } = require('../getPoliciesByResourceId')
const { hasPrincipal } = require('../../utils/policy')

/**
 * resolve the riskContext policies
 *
 * @param {String} principal - the actor/user performing the action
 * @param {String} resourceId - the resource ID
 * @param {Object} policies - the policies array
 */
const resourceResolver = (principal, resourcePolicies, globalPolicies) => {
  const userResourcePolicies = getResourcePoliciesWithStatements(resourcePolicies, globalPolicies)
    .filter(policy => hasPrincipal(policy, principal))
  return userResourcePolicies
}

module.exports = {
  resourceResolver,
}
