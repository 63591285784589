import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'

import { MainLandingPageView } from './MainLandingPageView'

import config from '../../../config.json'
import actions from '../../../actions'
import { selectAccountDecisionsQuota } from '../../../redux/account/selectors'
import { showModal } from '../../../redux/actions'

const orgName = (config.instance && config.instance.orgName) || 'Value Management Strategies'

const dashboardConfig = {
  logoAltText: 'VMSPro',
  logoAltTitle: 'Value Management Strategies | VMSPro®',
  cards: {
    byId: {
      'welcome': {
        id: 'welcome',
        title: <span>Welcome to VMSPro&reg;</span>,
        description: (
          <div>
            <p>This is the VMSPro landing page, where you'll be able to access
              any VMSPro module you have access to.</p>
            <p>Available modules:</p>
            <ul>
              <li>Risk Management</li>
              <li>Decision (coming soon)</li>
            </ul>
          </div>
        ),
        actions: [
          actions.feature.useModule(null, { moduleKey: systemConsts.Module.PORTFOLIO_RISK }),
        ],
      },
      'riskManagement': {
        id: 'riskManagement',
        title: 'Risk Management',
        description: (
          <div>
            <p>Comprehensive Risk Management:</p>
            <ul>
              <li>Calibration</li>
              <li>Identification</li>
              <li>Analysis (with Palisade @RISK support)</li>
              <li>Response Planning</li>
              <li>Monitoring & Control</li>
            </ul>
          </div>
        ),
        button: {
          linkTo: '/port',
          label: 'Go to Risk Management',
        },
        actions: [
          actions.feature.useModule(null, { moduleKey: systemConsts.Module.PORTFOLIO_RISK }),
        ],
      },
      'portfolioManagement': {
        id: 'portfolioManagement',
        title: 'Portfolio Management',
        description: (
          <div>
            <p>TODO: Marketing Copy for Portfolio Management</p>
          </div>
        ),
        button: {
          linkTo: '/portfolio',
          label: 'Go to Portfolio Management',
        },
        actions: [
          actions.feature.useModule(null, { moduleKey: systemConsts.Module.PORTFOLIO_MANAGEMENT }),
        ],
      },
      'decision': {
        id: 'decision',
        title: 'Decision Support',
        description: (
          <div>
            <p>Decisions are at the heart of every organization.  VMSPro Decision Support
              helps you make better decisions by:</p>
            <ul>
              <li>Fostering collaboration</li>
              <li>Soliciting multidisciplinary stakeholder input</li>
              <li>Mitigating cognitive bias</li>
              <li>Framing decisions</li>
            </ul>
          </div>
        ),
        button: {
          linkTo: '/decision',
          label: 'Go to Decision Support',
        },
        actions: [
          actions.feature.useModule(null, { moduleKey: systemConsts.Module.PORTFOLIO_DECISION }),
        ],
        quota: 'decision',
      },
    },
    ids: [
      'welcome',
      'riskManagement',
      'portfolioManagement',
      'decision',
    ],
  },
}

function Dashboard() {

  const dispatch = useDispatch()
  const accountDecisionsQuota = useSelector(selectAccountDecisionsQuota)

  // TODO: this has been cleaned up from its former life as a connected, class-based
  // component, but there's still plenty of cleanup/modernizing that could be done

  const navigate = useNavigate()
  const launchTo = location => window.open(location, '_blank')
  const onShowModal = id => dispatch(showModal(id))

  const cards = dashboardConfig.cards.ids.map(id => dashboardConfig.cards.byId[id])
    .filter(card => {
      if(!card.quota) return true
      switch(card.quota) {
        case 'decision': return accountDecisionsQuota.allowed > 0
        default: throw new Error('unrecognized quota: ' + card.quota)
      }
    })
  const { logoAltText, logoAltTitle, alert } = dashboardConfig

  return (
    <MainLandingPageView
      alert={alert}
      dashboardConfigCards={cards}
      handleShowModal={onShowModal}
      orgName={orgName}
      launchTo={launchTo}
      logoAltText={logoAltText}
      logoAltTitle={logoAltTitle}
      navigateTo={navigate}
    />
  )
}

export default Dashboard
