/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { splitAncestry, joinAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { EntityType, ROOT_RISK_PORTFOLIO_ID } from '@vms/vmspro3-core/dist/systemConsts'
import { CaretDownOutlined, CaretRightOutlined, LoadingOutlined, LineOutlined } from '@ant-design/icons'
import asSet from 'arraysetjs'
import _merge from 'lodash/merge'

import { Spin, Space, Button, Modal } from '../../controls'

import actions from '../../../actions'
import useAuthz from '../../../hooks/useAuthz'
import { fetchRiskEntityChildren } from '../../../redux/riskEntities/actions'
import { getEntityTypeLabel } from '../../../utils/formatUtils'

const getAllowableContainerTypes = originEntityType => {
  switch(originEntityType) {
    case EntityType.PORTFOLIO:
      return [EntityType.PORTFOLIO]
    case EntityType.PROGRAM:
      return [EntityType.PORTFOLIO]
    case EntityType.PROJECT:
      return [EntityType.PORTFOLIO, EntityType.PROGRAM]
    default:
      return []
  }
}

const RiskHierarchyTreeContext = React.createContext()

const RiskHierarchyTreeNodeIcon = ({ nodeId }) => {
  const {
    expandedNodes,
    expandNode,
    collapseNode,
  } = React.useContext(RiskHierarchyTreeContext)

  const {
    ancestry: nodeEntityAncestry,
    entityType: nodeEntityType,
  } = useSelector(state => state.riskEntities.byId[nodeId])

  const childrenAncestry = joinAncestry(nodeEntityAncestry, nodeId)
  const allChildrenLoaded = useSelector(state => !!state.riskEntities.byAncestry[childrenAncestry])

  const [loadingChildren, setLoadingChildren] = useState(false)
  const dispatch = useDispatch()
  const onShowChildren = useCallback(async () => {
    if(!allChildrenLoaded) {
      setLoadingChildren(true)
      await dispatch(fetchRiskEntityChildren(childrenAncestry))
      setLoadingChildren(false)
    }
    expandNode(nodeId)
  }, [
    allChildrenLoaded,
    childrenAncestry,
    dispatch,
    expandNode,
    nodeId,
  ])

  if(nodeEntityType === EntityType.RISK) {
    return <LineOutlined />
  } else if(loadingChildren) {
    return <Spin indicator={<LoadingOutlined css={{ fontSize: 'inherit' }} />} />
  } else if(expandedNodes.includes(nodeId) && allChildrenLoaded) {
    return <CaretDownOutlined onClick={() => collapseNode(nodeId)} />
  }
  return <CaretRightOutlined onClick={onShowChildren} />
}

const RiskHierarchyTreeNodeName = ({ nodeId }) => {
  const {
    originEntityId,
    originEntityAncestry,
    originBranchIds,
    selectedNodeId,
    setSelectedNodeId,
  } = React.useContext(RiskHierarchyTreeContext)

  const originEntityType = useSelector(state => state.riskEntities.byId[originEntityId].entityType)

  const {
    ancestry: nodeEntityAncestry,
    entityType: nodeEntityType,
    name: nodeEntityName,
  } = useSelector(state => state.riskEntities.byId[nodeId])

  const authz = useAuthz()
  const entityNameProps = useMemo(
    () => {
      const isOriginEntity = nodeId === originEntityId
      const isSelectedNode = nodeId === selectedNodeId
      const isAllowableContainerType = getAllowableContainerTypes(originEntityType).includes(nodeEntityType)
      const isOriginEntityParent = nodeId === originBranchIds[originBranchIds.indexOf(originEntityId) - 1]
      const isDescendantOfOriginEntity = nodeEntityAncestry.includes(originEntityId)
      const isAuthorized = originEntityAncestry !== '/' &&
        authz(actions.riskEntity.updateLocation(null, {
          entityId: originEntityId,
          dstParentId: nodeId,
          srcParentId: splitAncestry(originEntityAncestry).pop(),
        }))

      const _entityNameProps = {
        css: {
          padding: '2px 8px',
          display: 'inline-block',
          borderRadius: '4px',
          backgroundColor: isSelectedNode ? '#7bbdf6' : isOriginEntity ? '#F6B47B' : undefined,
        },
      }

      if(isAllowableContainerType && isAuthorized &&
        !(isOriginEntity || isOriginEntityParent || isDescendantOfOriginEntity || isSelectedNode)) {
        _merge(_entityNameProps, {
          onClick: () => setSelectedNodeId(nodeId),
          css: {
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#c0e0fc',
            },
          },
        })
      }

      return _entityNameProps
    },
    [
      authz,
      nodeId,
      nodeEntityType,
      nodeEntityAncestry,
      originEntityId,
      originBranchIds,
      originEntityType,
      originEntityAncestry,
      selectedNodeId,
      setSelectedNodeId,
    ]
  )
  return (
    <span {...entityNameProps}>
      {nodeEntityName}
    </span>
  )
}

const RiskHierarchyTreeNode = ({
  depth = 0,
  nodeId,
}) => {
  const {
    originBranchIds,
    expandedNodes,
  } = React.useContext(RiskHierarchyTreeContext)

  const childrenAncestry = useSelector(state => joinAncestry(state.riskEntities.byId[nodeId].ancestry, nodeId))
  const childNodeIds = useSelector(useMemo(
    () => createSelector(
      state => state.riskEntities.byAncestry[childrenAncestry],
      children => {
        if(children /* children are loaded */) {
          return children
            .concat() // sorting in place changes order in Redux state
            .sort((a, b) => {
              if(a.id === nodeId) return -1 // keep the origin entity up top
              if(b.id === nodeId) return 1
              return a.name.localeCompare(b.name)
            })
            .map(({ id }) => id)
        }

        const nodeIdx = originBranchIds.indexOf(nodeId)
        if(nodeIdx > -1) {
          return [originBranchIds[nodeIdx + 1]].filter(Boolean)
        }
        return []
      }
    ),
    [childrenAncestry, originBranchIds, nodeId]
  ))

  return (
    <div css={{ marginLeft: '20px' }}>
      <div css={{ marginBottom: '8px' }}>
        <RiskHierarchyTreeNodeIcon nodeId={nodeId} />
        <RiskHierarchyTreeNodeName nodeId={nodeId} />
      </div>
      {expandedNodes.includes(nodeId) && childNodeIds.map(childNodeId => (
        <RiskHierarchyTreeNode
          key={childNodeId}
          nodeId={childNodeId}
          depth={depth + 1}
        />
      ))}
    </div>
  )
}

const RiskHierarchyTreeControls = () => {
  const {
    originEntityId,
    selectedNodeId,
    setSelectedNodeId,
  } = React.useContext(RiskHierarchyTreeContext)

  const originEntity = useSelector(state => state.riskEntities.byId[originEntityId])
  const originEntityTypeLabel = getEntityTypeLabel(originEntity.entityType, originEntity.ancestry)

  const dstParentName = useSelector(state => state.riskEntities.byId[selectedNodeId]?.name)

  const dispatch = useDispatch()
  const onMoveLocation = useCallback(
    () => {
      Modal.confirm({
        title: `Move ${originEntityTypeLabel}`,
        content: `Are you sure you want to move ${originEntityTypeLabel} "${originEntity.name}" to be a child ` +
          `of "${dstParentName}"? As a result of this operation, all descendants of "${originEntity.name}" ` +
          'will also be moved to the new location which will affect statistics throughout the risk hierarchy.',
        onOk: () => {
          dispatch(actions.riskEntity.updateLocation(null, {
            entityId: originEntityId,
            dstParentId: selectedNodeId,
            srcParentId: splitAncestry(originEntity.ancestry).pop(),
          }))
          setSelectedNodeId(null)
        },
      })
    },
    [
      dispatch,
      originEntityId,
      originEntity,
      originEntityTypeLabel,
      dstParentName,
      selectedNodeId,
      setSelectedNodeId,
    ]
  )

  const onCancel = () => setSelectedNodeId(null)

  return (
    <Space css={{ marginTop: '20px' }}>
      <Button type="primary" onClick={onMoveLocation} disabled={!selectedNodeId}>
        Move {originEntityTypeLabel}
      </Button>
      {selectedNodeId && <Button onClick={onCancel}>Cancel</Button>}
    </Space>
  )
}

const RiskEntityLocation = React.memo(({ entityId: originEntityId }) => {
  const [selectedNodeId, setSelectedNodeId] = useState(null)

  const originEntityAncestry = useSelector(state => state.riskEntities.byId[originEntityId]?.ancestry)
  const [expandedNodes, setExpandedNodes] = useState(() => splitAncestry(originEntityAncestry))
  useEffect(
    () => setExpandedNodes(splitAncestry(originEntityAncestry)),
    [originEntityAncestry]
  )

  const originBranchIds = useMemo(
    () => splitAncestry(originEntityAncestry).concat(originEntityId),
    [originEntityAncestry, originEntityId]
  )

  const providerValue = useMemo(
    () => ({
      originEntityId,
      originEntityAncestry,
      originBranchIds,
      selectedNodeId,
      setSelectedNodeId,
      expandedNodes,
      expandNode: key => setExpandedNodes(state => asSet(state).add(key)),
      collapseNode: key => setExpandedNodes(state => asSet(state).remove(key)),
    }),
    [
      originEntityId,
      originEntityAncestry,
      originBranchIds,
      expandedNodes,
      selectedNodeId,
    ]
  )

  return (
    <RiskHierarchyTreeContext.Provider value={providerValue}>
      <RiskHierarchyTreeNode nodeId={ROOT_RISK_PORTFOLIO_ID} />
      <RiskHierarchyTreeControls />
    </RiskHierarchyTreeContext.Provider>
  )
})

export default RiskEntityLocation
