import { produce } from 'immer'
import _set from 'lodash/set'
import asSet from 'arraysetjs'

import actions from '../../actions'
import userActionTypes from './actionTypes'
import authActionTypes from '../auth/actionTypes'
import { LoadingStatus } from '../../utils/appConsts'

const initialState = {
  currentUser: null,
  currentUserLoadingStatus: LoadingStatus.NotLoaded,
  accountUser: null,
  accountUserLoadingStatus: LoadingStatus.NotLoaded,
  invitations: null,
  invitationsLoadingStatus: LoadingStatus.NotLoaded,
}

const userReducer = produce((state, action) => {
  // local actions always handled
  switch(action.type) {
    case authActionTypes.SignOut: {
      return initialState
    }
    case 'ResetAccountState': {
      state.accountUser = null
      state.accountUserLoadingStatus = LoadingStatus.NotLoaded
      break
    }

    case userActionTypes.FetchCurrentUserRequest: {
      state.currentUserLoadingStatus = LoadingStatus.Requested
      break
    }
    case userActionTypes.FetchCurrentUserSuccess: {
      state.currentUser = action.payload
      state.currentUserLoadingStatus = LoadingStatus.Loaded
      break
    }

    case userActionTypes.FetchAccountUserRequest: {
      state.accountUserLoadingStatus = LoadingStatus.Requested
      break
    }
    case userActionTypes.FetchAccountUserSuccess: {
      state.accountUser = action.payload
      state.accountUserLoadingStatus = LoadingStatus.Loaded
      break
    }

    case userActionTypes.FetchUserInvitationsRequest: {
      state.invitationsLoadingStatus = LoadingStatus.Requested
      break
    }
    case userActionTypes.FetchUserInvitationsSuccess: {
      state.invitations = action.payload
      state.invitationsLoadingStatus = LoadingStatus.Loaded
      break
    }
    case userActionTypes.AcceptAccountInvitation: {
      const { email, accountId } = action.payload
      const invitationId = `${accountId}/${email}`

      const idx = state.invitations.findIndex(inv => inv.id === invitationId)
      state.invitations.splice(idx, 1)
      break
    }
    default: {
      break
    }
  }

  // system user actions
  if(state.currentUser?.id === action.meta?.userId) {
    switch(action.type) {
      case actions.user.update.toString(): {
        Object.assign(state.currentUser, action.payload)
        break
      }
      default: {
        break
      }
    }
  }

  // account user actions
  if(state.accountUser?.id === action.meta?.userId) {
    switch(action.type) {
      case actions.user.agreeToEula.toString(): {
        const { eulaId, version } = action.payload
        _set(state.accountUser, ['eulas', eulaId], version)
        break
      }
      case actions.user.disable.toString(): {
        state.accountUser.disbled = true
        break
      }
      case actions.user.enable.toString(): {
        state.accountUser.disabled = false
        break
      }
      case actions.user.addPolicy.toString(): {
        asSet.mutable(state.accountUser.policyIds).add(action.meta.policyId)
        break
      }
      case actions.user.removePolicy.toString(): {
        asSet.mutable(state.accountUser.policyIds).remove(action.meta.policyId)
        break
      }
      case actions.user.setTableConfig.toString(): {
        const { configId, tableId } = action.payload
        // TODO: is this an account specific configuration? It's being set on the account user record.
        // TODO: the variable configId part of this path is sketchy, the schema shape should always be the same.
        // TODO: see https://vms.atlassian.net/browse/VP3-1946
        _set(state.accountUser, `tableConfigs.${tableId}${configId ? `.${configId}` : ''}`, action.payload)
        break
      }
      default: {
        break
      }
    }
  }
}, initialState)

export default userReducer
