/** @jsxImportSource @emotion/react */
import React from 'react'
import MenuUnfoldOutlined from '@ant-design/icons/MenuUnfoldOutlined'
import MenuFoldOutlined from '@ant-design/icons/MenuFoldOutlined'
import { Color } from '@vms/vmspro3-core/dist/systemConsts'

import { useMenuState } from '../../redux/hooks/appHooks'

export function MenuToggle() {
  const [menuOpen, toggleMenuOpen] = useMenuState()

  return (
    <span css={style} onClick={toggleMenuOpen}>
      {menuOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
    </span>
  )
}

const style = {
  cursor: 'pointer',
  fontSize: '22px',
  transition: 'color 0.3s',
  ':hover': {
    color: Color.BLUE_LINK,
  },
}
