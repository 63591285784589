/** @jsxImportSource @emotion/react */
import React from 'react'
import facepaint from 'facepaint'
import { CheckSquareOutlined, LineChartOutlined, PartitionOutlined } from '@ant-design/icons'
import { Color } from '@vms/vmspro3-core/dist/systemConsts'

import heroImage from './hero.jpg'
import PlanCard from '../../../components/Subscription/PlanCard'
import { Row, Col, Card } from '../../controls'
import Image from '../common/Image'

import config from '../../../config.json'

const breakpoints = [576, 768, 992, 1200]
const mediaQuery = facepaint(breakpoints.map(bp => `@media(min-width: ${bp}px)`))

function Hero() {
  return (
    <div css={style.heroContainer}>
      <div css={style.heroContent}>
        <Image path="logo.png" alt="VMSPro® Logo" />
        <p>
          Comprehensive Risk Management software for enterprises, risk professionals, and small businesses.
          VMSPro helps you with every phase of risk management, from risk identification to monitoring and
          control, to calibration.
        </p>
      </div>
    </div>
  )
}

function Intro() {
  return (
    <div css={style.introContainer}>
      <Row gutter={[36, 24]} justify="center">
        <Col span="24">
          <p css={style.introParagraph}>
            VMSPro Risk offers a comprehensive Risk Management solution that will enable you to better understand,
            plan for, and respond to the threats facing your organization and to take advantage of the
            opportunities in front of you.  VMSPro Risk promotes best practices in Risk Management, and supports
            all phases of Risk Management:
          </p>
          <ul css={style.introList}>
            <li>Identification: what risks are you facing?</li>
            <li>Analysis: what would be the impact of these risks if they occur?  What is their probability?
              How are they categorized?</li>
            <li>Response Planning: how will your organization respond if risks occur?  How can their impact be
              lessened?</li>
            <li>Monitoring & Control: routinely reviewing risks throughout a project or program's life to make sure
              response is timely and coordinated.</li>
            <li>Calibration: learning from past experience to better plan for risks in the future.</li>
          </ul>
        </Col>
        <Col {...style.cardSpan}>
          <Card css={style.card}>
            <CheckSquareOutlined css={style.icon} />
            <h2>Best Practices</h2>
            <ul css={style.introList}>
              <li>Efficient documentation and identification of risks</li>
              <li>Industry-standard Excel reports with Palisade @Risk integration</li>
              <li>Project snapshots and at-a-glance filtering</li>
              <li>Risk tornado charts</li>
              <li>Role-based security</li>
              <li>Customizable risk scales</li>
            </ul>
          </Card>
        </Col>
        <Col {...style.cardSpan}>
          <Card css={style.card}>
            <LineChartOutlined css={style.icon} />
            <h2>Report Generation</h2>
            <ul css={style.introList}>
              <li>Microsoft Excel risk register and aggregation reports</li>
              <li>Excel reports include Palisade @Risk integration</li>
              <li>Coming soon: risk import from Excel and CSV</li>
            </ul>
          </Card>
        </Col>
        <Col {...style.cardSpan}>
          <Card css={style.card}>
            <PartitionOutlined css={style.icon} />
            <h2>Hierarchical Risk Management</h2>
            <p>
              VMSPro Risk supports hierarchical organization for Portfolio, Program, and Project delivery.
              Risk data is aggregated at each level of the hierarchy, and custom report generation includes
              hierarchy information.  Hierarchical organization allows you to align your Risk Management
              program with funding streams.
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

function Pricing() {
  const products = config.instance?.products?.filter(p => p.type === 'Commercial') ?? []

  if(!products.length) return null

  return (
    <div css={style.pricing}>
      <h2 css={{ paddingLeft: '24px' }}>Product Pricing</h2>
      <Row gutter={30} justify="center">
        {products.map(product =>
          <Col key={product.id} span={Math.min(8, Math.floor(24 / products.length))}>
            <PlanCard product={product} />
          </Col>
        )}
      </Row>
    </div>
  )
}

function Home() {
  return (
    <>
      <Hero />
      <Intro />
      <Pricing />
    </>
  )
}

const style = {
  heroContainer: mediaQuery({
    position: 'relative',
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: ['400px', '480px', '600px', '800px'],
    padding: ['0 40px', '0 52px', '0 76px', '0 112px'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ':before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#000000',
      opacity: '0.75',
    },
  }),
  heroContent: {
    maxWidth: '800px',
    position: 'relative',
    color: '#ffffff',
    textAlign: 'center',
  },
  introContainer: {
    width: '100%',
    padding: '24px',
  },
  introParagraph: {
    margin: '0 32px',
  },
  introList: {
    margin: '12px 24px',
    listStyle: 'none',
    textIndent: '-1em',
    paddingLeft: '1em',
    'li::before': {
      content: '"• "',
      color: Color.BLUE,
    },
    li: {
      marginTop: '6px',
    },
  },
  card: mediaQuery({
    textAlign: 'center',
    background: '#ffffff',
    paddingTop: '20px 12px 6px',
    boxShadow: '0 0 12px 1px rgba(0,0,0,0.2)',
    height: '100%',
    ul: {
      textAlign: 'left',
    },
    p: {
      textAlign: 'left',
    },
  }),
  cardSpan: {
    sm: 24,
    md: 12,
    lg: 8,
  },
  icon: {
    fontSize: '40px',
  },
  pricing: {
    padding: '30px',
  },
}

export default Home
