import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import _merge from 'lodash/merge'

import { SubscriptionInfo } from '../../components/Subscription/SubscriptionInfo'
import { SubscriptionEditor } from '../../components/Subscription/SubscriptionEditor'
import { Page } from '../../components/Page'
import { Row, Col, Card } from '../../client/controls'

import Server from '../../server/VMSProServerAdapter'

const subscriptionQuery = `
  {
    subscription {
      id
      currentPeriodEnd
      currentMonthlyBill
      paymentMethod { last4, brand }
    }
  }
`

export function SubscriptionPage() {
  const [subscription, setSubscription] = useState(null)

  useEffect(
    () => Server.graphql({ query: subscriptionQuery }).then(res => {
      setSubscription(res.data.subscription)
    }),
    []
  )

  // TODO: this is a hacky way to get around the fact that this wasn't done in the Redux way;
  // this should be moved into an async "load subscription" hook etc
  const updateSubscription = useCallback(
    update => {
      setSubscription(subscription => _merge({}, subscription, update))
    },
    []
  )

  return (
    <Page loading={!subscription} title="Subscription">
      <Row gutter={24}>
        <Col span={12}>
          <Card>
            <SubscriptionInfo subscription={subscription} />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <SubscriptionEditor onSubscriptionChange={updateSubscription} subscription={subscription} />
          </Card>
        </Col>
      </Row>
      <div css={style.invoices}>
        Looking for <Link to="/invoices">invoices</Link>?
      </div>
    </Page>
  )
}

const style = {
  container: {
    margin: '24px 30px',
    padding: '12px 24px',
  },
  invoices: {
    marginTop: '32px',
  },
}
