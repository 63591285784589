import { useCallback, useMemo, ReactElement } from 'react'
import { deleteOption } from '@vms/vmspro3-core/dist/actions/decision'
import { Option } from '@vms/vmspro3-core/dist/types'
import { Column } from 'react-table'

import { Table, TableCellRenderer } from '../Table'
import { OptionModalData, OptionModalId } from '../../client/modals/OptionModal'
import { OptionDuplicateModalData, OptionDuplicateModalId } from '../../client/modals/OptionDuplicateModal'
import { Modal } from '../../client/controls'

import { useShowModal } from '../../redux/hooks/appHooks'
import { useAppDispatch } from '../../redux'
import { useAccountId } from '../../redux/account/hooks'
import { getRandomColorFromLeastFrequent } from '../../utils/getRandomColorFromLeastFrequent'
import { colorSelectOptions } from '../../utils/appConsts'
import {
  useAuthUserParticipant,
  useDecision,
  useDecisionChildAncestry,
  useOptions,
  useParticipants,
} from '../../redux/hooks/decisionHooks'
import { alphanumericSortByKey } from '../../utils/sort-utils'

type OptionsProps = {
  decisionId: string,
}
export function Options({ decisionId }: OptionsProps): ReactElement {
  const accountId = useAccountId()
  const decision = useDecision(decisionId)
  const options = useOptions(decisionId)
  const optionAncestry = useDecisionChildAncestry(decisionId)

  const showOptionModal = useShowModal<OptionModalData>(OptionModalId)
  const onCreateOption = useCallback(
    () => showOptionModal({ decisionId }),
    [showOptionModal, decisionId]
  )

  const onUpdateOption = useCallback(
    (option: Option) => showOptionModal({ decisionId, option }),
    [showOptionModal, decisionId]
  )

  const dispatch = useAppDispatch()
  const onDeleteOption = useCallback(
    (option: Option) => Modal.confirm({
      title: `Deleting option "${option.name}"`,
      content: 'Are you sure you want to delete this option?',
      onOk: () => dispatch(deleteOption(optionAncestry, option.id)),
    }),
    [dispatch, optionAncestry]
  )

  const onDownloadExcel = useCallback(
    () => import('../../utils/excelWriters').then(async ({ saveOptions }) => {
      const filename = `${decision?.name || decisionId} - Options`
      saveOptions(filename, options)
    }),
    [decisionId, decision, options]
  )

  const participants = useParticipants(decisionId)
  const authUserParticipant = useAuthUserParticipant(decisionId)
  const showOptionDuplicateModal = useShowModal<OptionDuplicateModalData>(OptionDuplicateModalId)
  /**
   * Generates duplicate option form initialValues and displays the OptionDuplicateModal.
   *
   * The default form initialValues are:
   *  - name: the source option name followed by " (copy N)" where N is the count
   *      of options with names starting with the source option name and ending with a word boundary
   *  - color: picked at random from the least used colors that are not the source option color
   *  - abbrev: source option abbrev followed by "-N" where N is the count of options starting with
   *      the source option abbrev
   */
  const onDuplicateOption = useCallback(
    (sourceOption: Option) => {
      const nameMatchCount = options.filter(opt =>
        // count matching names; a matching name is the source name (in its entirety) or names
        // that start with the source name and are followed by at least one whitespace character.
        // so if the source name was "Option 1", it would count "Option 1", "Option 1 (copy 1)", but
        // not "Option 1A" (no space after "Option 1"). Note that this is not foolproof...it will also
        // count "Option 1 A", but you can't catch 'em all.
        opt.name.startsWith(sourceOption.name) && !/^\S/.test(opt.name.substring(sourceOption.name.length))
      ).length

      const colors = colorSelectOptions.filter(o => o.value !== sourceOption.color).map(o => o.value)

      const initialValues = {
        name: `${sourceOption.name} (copy ${nameMatchCount})`,
        color: getRandomColorFromLeastFrequent(colors, options),
        abbrev: null as null | string,
        assignRatings: true,
        assignRatingsToParticipantId: authUserParticipant?.id,
      }

      if(sourceOption.abbrev) {
        const { abbrev } = sourceOption
        const abbrevMatchCount = options.filter(o => o.abbrev?.startsWith(abbrev)).length
        initialValues.abbrev = `${abbrev}-${abbrevMatchCount}`
      }

      const participantSelectOptions = participants
        .slice()
        .sort(alphanumericSortByKey('fullName'))
        .map(participant => ({
          value: participant.id,
          label: participant.fullName,
        }))

      showOptionDuplicateModal({
        accountId,
        decisionId,
        sourceOptionId: sourceOption.id,
        initialValues,
        participants,
        participantSelectOptions,
      })
    },
    [showOptionDuplicateModal, accountId, decisionId, options, participants, authUserParticipant]
  )

  const columns = useMemo<Column<Option>[]>(
    () => [
      {
        Header: 'Abbrev.',
        accessor: 'abbrev',
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: TableCellRenderer.EntityName,
      },
      {
        Header: 'Option ID',
        accessor: 'commonId',
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: TableCellRenderer.Html,
        // disable sort, because how is one supposed to meaningfully sort HTML object contents?
        disableSortBy: true,
      },
      {
        Header: 'Cost',
        accessor: 'cost',
        Cell: TableCellRenderer.Cost,
        align: 'right',
        sortType: 'cost',
        // TODO: filter function for cost objects — range?
      },
      {
        Header: 'Duration',
        accessor: 'time',
        Cell: TableCellRenderer.Duration,
        align: 'right',
        sortType: 'duration',
        // TODO: filter function for duration objects — range?
      },
    ],
    []
  )

  return (
    <Table<Option>
      columns={columns}
      data={options}
      onDuplicateRow={onDuplicateOption}
      onEditRow={onUpdateOption}
      onDeleteRow={onDeleteOption}
      disablePagination
      onAdd={onCreateOption}
      addLabel="Add Option"
      onDownload={onDownloadExcel}
      downloadLabel="Download Options Excel"
    />
  )
}
