import React from 'react'
import UserOutlined from '@ant-design/icons/UserOutlined'
import { EmailRegex } from '@vms/vmspro3-core/dist/systemConsts'

import { Form, Input } from '../client/controls'

function EmailField({ disabled, additionalRules, initialFocusRef }) {
  return (
    <Form.Item
      label="Email"
      name="email"
      type="email"
      placeholder="email@domain.com"
      normalize={email => email.trim()}
      rules={[
        {
          required: true,
          message: 'Please enter an email address',
        },
        {
          validateTrigger: 'onBlur',
          pattern: EmailRegex,
          message: 'Please enter a valid email address',
        },
        ...(additionalRules || []),
      ]}
    >
      <Input
        disabled={disabled}
        prefix={<UserOutlined css={style.inputPrefix} />}
        ref={initialFocusRef}
      />
    </Form.Item>
  )
}

/**
 * This is a special hidden field for use in a Form instance where we
 * want to maintain the form field state but not show the field in the UI.
 */
function EmailFieldHidden() {
  return (
    <Form.Item hidden name="email">
      <Input />
    </Form.Item>
  )
}

const style = {
  inputPrefix: { color: 'rgba(0,0,0,.25)' },
}

EmailField.Hidden = EmailFieldHidden
export default EmailField
