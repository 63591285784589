import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormModal from './FormModal'
import { Form, Select } from '../controls'

import actions from '../../actions'
import useAuthz from '../../hooks/useAuthz'
import { useUsers } from '../../utils/hooks'
import { getActiveUsers } from '../../selectors'
import { useModalData } from '../../redux/hooks/appHooks'
import { selectAuthUserId } from '../../redux/auth/selectors'

const UsersAddRoleModal = () => {
  const { policyId } = useModalData(UsersAddRoleModal.id)
  const authUserId = useSelector(selectAuthUserId)

  const getUserAddPolicyAction = userId => actions.user.addPolicy(null, { policyId, userId, authUserId })

  const [, loadingUsers] = useUsers()

  const authz = useAuthz()
  const activeUsers = useSelector(getActiveUsers)
  const usersSelectOptions = activeUsers
    .filter(user =>
      !user.policyIds.includes(policyId) &&
      authz(getUserAddPolicyAction(user.id))
    )
    .map(({ id, fullName }) => ({ key: id, label: fullName }))

  const dispatch = useDispatch()
  const onOk = ({ userIds }) => userIds.forEach(userId => dispatch(getUserAddPolicyAction(userId)))

  return (
    <FormModal modalId={UsersAddRoleModal.id} onOk={onOk}>
      {() =>
        <>
          <h3>Add Users to Role</h3>
          <Form.Item label="Users" name="userIds">
            <Select mode="multiple" loading={loadingUsers} disabled={loadingUsers}>
              {usersSelectOptions.map(({ key, label }) => <Select.Option key={key}>{label}</Select.Option>)}
            </Select>
          </Form.Item>
        </>
      }
    </FormModal>
  )
}
UsersAddRoleModal.id = 'UsersAddRoleModal'

export default UsersAddRoleModal
