import { ReactElement, useMemo, useState } from 'react'

import { Html, Option, Rating, RatingNotes } from '@vms/vmspro3-core/dist/types'
import { CriterionData } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils/createHtmlObject'

import { Spin } from '../../client/controls'
import { AdHocParticipantForm } from './AdHocParticipantForm'
import { AdHocParticipantPicker } from './AdHocParticipantPicker'

import { createTypedContext } from '../../utils/createTypedContext'
import { useDecisionForParticipant } from '../../services/adHocDecisionService'

type AdHocParticipationContext = {
  accountId: string,
  decisionId: string,
  decisionName: string,
  decisionObjective: Html,
  participationSessionId: string,
  participantId: string,
  criteria: CriterionData[],
  options: Option[],
  ratings: Rating[],
  ratingNotes: RatingNotes[],
  unsetParticipantId: () => void,
}
const [useAdHocParticipationContext, ContextProvider] = createTypedContext<AdHocParticipationContext>()
export { useAdHocParticipationContext }

const sessionClosedErrorMessage = 'Participation for this decision is currently closed.\n' +
  'Please contact the decision facilitator for more information.'

type AdHocParticipationProviderProps = {
  accountId: string,
  decisionId: string,
  participationSessionId: string,
  participantId: string | undefined,
  setParticipantId: (participantId?: string) => void,
  children: ReactElement,
}
export function AdHocParticipationProvider({
  accountId,
  decisionId,
  participationSessionId,
  participantId,
  setParticipantId,
  children,
}: AdHocParticipationProviderProps) {
  const [showCreateParticipant, setShowCreateParticipant] = useState(false)
  const [onShowCreateParticipant, onCancelCreateParticipant] = useMemo(
    () => [
      () => setShowCreateParticipant(true),
      () => setShowCreateParticipant(false),
    ],
    []
  )

  const { data, error, loading } = useDecisionForParticipant(
    accountId,
    decisionId,
    participantId
  )

  const unsetParticipantId = () => setParticipantId(undefined)

  if(!participantId) {
    if(showCreateParticipant) {
      return (
        <AdHocParticipantForm
          accountId={accountId}
          decisionId={decisionId}
          participationSessionId={participationSessionId}
          onCancel={onCancelCreateParticipant}
          setParticipantId={setParticipantId}
        />
      )
    }

    return (
      <AdHocParticipantPicker
        accountId={accountId}
        decisionId={decisionId}
        participationSessionId={participationSessionId}
        setParticipantId={setParticipantId}
        onShowCreateParticipant={onShowCreateParticipant}
      />
    )
  }

  if(loading) return <Spin />
  if(error) {
    if(error.graphQLErrors.some(err => err.message === 'participation session is not active')) {
      return (
        <span>{sessionClosedErrorMessage}</span>
      )
    }

    return <span>Unknown Error: {error.graphQLErrors.map(err => err.message).join(', ')}</span>
  }
  if(!data) return <h2>Data not found</h2>

  const {
    name: decisionName,
    objective,
    criteria,
    options,
    ratings,
    ratingNotes,
  } = data.decisionForParticipant

  return (
    <ContextProvider
      value={{
        accountId,
        decisionId,
        decisionName,
        decisionObjective: createHtmlObject(objective.value),
        participationSessionId,
        participantId,
        criteria,
        options,
        ratings,
        ratingNotes,
        unsetParticipantId,
      }}
    >
      {children}
    </ContextProvider>
  )
}
