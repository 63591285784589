import getAugmentAction from '../../selectors/getAugmentAction'
import type { NavigateFunction } from 'react-router-dom'

import accountActionTypes from './actionTypes'
import { LOCAL_STORAGE_ACCOUNT_ID_KEY } from '../../utils/appConsts'
import { resetAccountState } from '../actions'
import { selectAccountId } from './selectors'
import { selectAuthUserId } from '../auth/selectors'
import {
  getAccount,
  getUserAccounts,
  getPendingInvitationsForAccount,
  cancelAccountSubscription,
} from '../../services/accountService'

export function setAccountId(accountId, navigate: NavigateFunction) {
  if(!accountId) {
    throw new Error('accountId required for setAccountId action')
  }

  return (dispatch, getState) => {
    const prevAccountId = selectAccountId(getState())
    if(prevAccountId && prevAccountId !== accountId) {
      dispatch(resetAccountState(navigate))
    }

    localStorage.setItem(LOCAL_STORAGE_ACCOUNT_ID_KEY, accountId)
    dispatch({
      type: accountActionTypes.SetAccountId,
      payload: accountId,
      meta: { ephemeral: true },
    })
  }
}

export function removeAccountId(navigate: NavigateFunction) {
  return dispatch => {
    localStorage.removeItem(LOCAL_STORAGE_ACCOUNT_ID_KEY)
    dispatch(resetAccountState(navigate))
    dispatch({
      type: accountActionTypes.RemoveAccountId,
      meta: { ephemeral: true },
    })
  }
}

export function fetchAccount(accountId, userId) {
  if(!accountId) {
    throw new Error('accountId required for fetchAccount action')
  }
  if(!userId) {
    // TODO: this is a consequence of using fetchInitialState. will fix when
    // replaced by GQL query.
    throw new Error('userId is requred for fetchAccount action')
  }

  return async dispatch => {
    try {
      dispatch({
        type: accountActionTypes.FetchAccountRequest,
        meta: { ephemeral: true },
      })

      const account = await getAccount(accountId, userId)
      dispatch({
        type: accountActionTypes.FetchAccountSuccess,
        payload: account,
        meta: { ephemeral: true },
      })
    } catch(error) {
      console.error(error)
    }
  }
}

export function fetchUserAccounts(userId: string, navigate: NavigateFunction) {
  return async dispatch => {
    try {
      dispatch({
        type: accountActionTypes.FetchUserAccountsRequest,
        meta: { ephemeral: true },
      })

      const userAccounts = await getUserAccounts(userId)
      dispatch({
        type: accountActionTypes.FetchUserAccountsSuccess,
        payload: userAccounts,
        meta: { ephemeral: true },
      })

      const lsAccountId = localStorage.getItem(LOCAL_STORAGE_ACCOUNT_ID_KEY)
      if(lsAccountId) {
        if(userAccounts?.some(account => account.id === lsAccountId)) {
          dispatch(setAccountId(lsAccountId, navigate))
        } else {
          dispatch(removeAccountId(navigate))
        }
      }
    } catch(error) {
      console.error(error)
    }
  }
}

export function fetchPendingInvitationsForAccount(accountId) {
  return async dispatch => {
    try {
      dispatch({
        type: accountActionTypes.FetchPendingInvitationsForAccountRequest,
        meta: { ephemeral: true },
      })

      const pendingInvitations = await getPendingInvitationsForAccount(accountId)
      dispatch({
        type: accountActionTypes.FetchPendingInvitationsForAccountSuccess,
        payload: pendingInvitations,
        meta: { ephemeral: true },
      })
    } catch(error) {
      console.error('fetchPendingUsers error: ', error)
    }
  }
}

export function accountSubscriptionCanceled(navigate: NavigateFunction) {
  return async (dispatch, getState) => {
    // re-fetch user accounts
    const userId = selectAuthUserId(getState())
    await dispatch(fetchUserAccounts(userId, navigate))

    // remove account ID and account data
    dispatch(removeAccountId(navigate))
  }
}

export function cancelSubscription(accountId: string, reason: string, navigate: NavigateFunction) {
  return async (dispatch, getState) => {
    await cancelAccountSubscription(accountId, reason, getAugmentAction(getState()))

    await dispatch(accountSubscriptionCanceled(navigate))
  }
}
