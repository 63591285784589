import { useMemo, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import querystringify from 'querystringify'

/**
 * Hook to encode currently sellected tab (as in a Ant Design <Tab> component) in the
 * querystring for deep linking, etc.
 *
 * @param {string} defaultKey - The tab to be shown if none is explicitly specified in
 *  the querystring.
 * @param {string} [querystringKey = 'tab'] - The key to use in the querystring.
 * @returns {Object} - Object with properties "tabKey" (the currently selected tab key)
 *   and "onTabChange", a function that will change the querystring to include the new
 *   tab key.
 */
const useQuerystringTabKey = (defaultKey, querystringKey = 'tab') => {
  const navigate = useNavigate()
  const { search, pathname } = useLocation()
  const qs = useMemo(() => querystringify.parse(search), [search])
  const onTabChange = useCallback(tabKey => {
    const nextQuerystring = { ...qs, [querystringKey]: tabKey }
    if(tabKey === defaultKey) delete nextQuerystring[querystringKey]

    const path = pathname + querystringify.stringify(nextQuerystring, true)
    navigate(path)
  }, [qs, querystringKey, defaultKey, pathname, navigate])
  return { tabKey: qs[querystringKey] || defaultKey, onTabChange }
}

export default useQuerystringTabKey
