import moment from 'moment'
import numeral from 'numeral'
import * as Sentry from '@sentry/browser'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'
import { CostUnitMetadata, Duration, DurationUnitMetadata } from '@vms/vmspro3-core/dist/utils/qty'

const { EntityType } = systemConsts

/**
 * formatTimestamp formats a timestamp for display.
 *
 * @param {number} value - The timestamp to format
 * @param {string} [dateFormat = 'YYYY-MM-DD'] - Moment.js format string.
 * @param {string} [noValue = ''] - String to return if value is null or undefined.
 * @returns {string} - A formatted time string.
 */
export const formatTimestamp = (value, dateFormat = 'YYYY-MM-DD', noValue = '') => {
  if(value === null || value === undefined) return noValue
  if(typeof value === 'string') {
    value = new Date(value).valueOf()
    if(Number.isNaN(value)) throw new Error('string value must be a valid date')
    Sentry.captureMessage('found date string when expecting ' +
      'numeric timestamp in formatUtils.js:formatTimestamp')
  }
  if(typeof value !== 'number') throw new Error('timestamp must be a number')
  return moment(value).format(dateFormat)
}

export const formatDateObject = (dateObject, dateFormat = 'YYYY-MM-DD') => {
  if(!dateObject) return '-'
  const { day, month, year } = dateObject
  const timestamp = new Date(`${year}-${day}-${month} UTC`).getTime()
  return formatTimestamp(timestamp, dateFormat)
}

export const formatNumValue = (val, allowNull, decimalPlaces = 2) => {
  if(allowNull && ((typeof val === 'string' && !val.trim()) || val === null || typeof val === 'undefined')) {
    return ''
  }
  const numFormat = `0,0${decimalPlaces ? '.' + '0'.repeat(decimalPlaces) : ''}`

  // numeral does not handle very small numbers
  // https://github.com/adamwdraper/Numeral-js/issues/596
  const zeroAdjustedValue = Math.abs(val * 10 * (decimalPlaces === 0 ? 1 : decimalPlaces)) < 1 ? 0 : val
  return numeral(zeroAdjustedValue).format(numFormat)
}

/**
 * formatCostObject formats a cost object for display.
 *
 * @param {Object} costObject - The cost object to format.
 * @param {number} costObject.value - The value of the cost object.
 * @param {string} costObject.currency - The currency of the cost object.
 * @param {boolean} [allowNull = false] - If `true` formatCostObject will return an empty string for a `null`
 *    value. If `false` formatCostObject will return '0' for a `null` value.
 * @param {number} decimalPlaces - The number of decimal places to show.
 * @param {string} abbrev - The amount to abbreviate the formatted value.
 * @returns {string} - A formatted cost string.
 */
export const formatCostObject = (
  costObject,
  allowNull = false,
  decimalPlaces = 2,
  abbrev = '',
) => {
  const getAbbrDivisor = abbrev => {
    switch(abbrev) {
      case 'T': return 1000000000000
      case 'B': return 1000000000
      case 'M': return 1000000
      case 'K': return 1000
      default: return 1
    }
  }
  const { unit, value } = costObject || {}
  if(typeof value !== 'number' && !value) return ''
  const abbrNum = value && (value / getAbbrDivisor(abbrev.toUpperCase()))
  const formattedNumber = formatNumValue(abbrNum, allowNull, decimalPlaces)

  if(!unit || !formattedNumber) return ''
  const { symbol } = CostUnitMetadata[unit]
  const prefix = symbol || unit
  return prefix + formattedNumber + abbrev
}

/**
 * TODO: this needs to be moved into an instance method of Cost; see https://vms.atlassian.net/browse/VP3-2029
 *
 * formatCost formats a `Cost` instance for display
 *
 * @param {Object} costObject - The `Cost` instance
 * @param {boolean} [allowNull = false] - If `true` formatCostObject will return an empty string for a `null`
 *    value. If `false` formatCostObject will return '0' for a `null` value.
 * @param {number} decimalPlaces - The number of decimal places to show.
 * @param {string} abbrev - The amount to abbreviate the formatted value.
 * @returns {string} - A formatted cost string.
 */
export const formatCost = (
  costObject,
  allowNull = false,
  decimalPlaces = 2,
  abbrev = '',
) => {
  const getAbbrDivisor = abbrev => {
    switch(abbrev) {
      case 'T': return 1000000000000
      case 'B': return 1000000000
      case 'M': return 1000000
      case 'K': return 1000
      default: return 1
    }
  }
  const { unit, value } = costObject || {}
  if(typeof value !== 'number' && !value) return ''
  const abbrNum = value && (value / getAbbrDivisor(abbrev.toUpperCase()))
  const formattedNumber = formatNumValue(abbrNum, allowNull, decimalPlaces)

  if(!unit || !formattedNumber) return ''
  const { symbol } = CostUnitMetadata[unit]
  const prefix = symbol || unit
  return prefix + formattedNumber + abbrev
}

export const formatProjectCost = (cost, currency, allowNull = true, decimalPlaces = 2, abbr) => {
  const value = Object.values(cost).reduce((total, costItem) => {
    if(costItem && costItem.value !== null && Number.isFinite(Number(costItem.value))) total += costItem.value
    return total
  }, allowNull ? null : 0)
  return value !== null ? formatCostObject({ value, currency }, allowNull, decimalPlaces, abbr) : '-'
}

export const formatProjectNumber = project => {
  if(typeof project !== 'object') throw new Error('currently, only formatting of project objects is supported')
  const { projectNumber, integrationProjectNumber } = project
  if(integrationProjectNumber) return integrationProjectNumber
  return 'REQ-' + projectNumber
}

// TODO: make this a method of Duration: see https://vms.atlassian.net/browse/VP3-2029
export const formatDuration = (d, unit, noValue = '-', decimals = 0, appendDuration = true) => {
  if(!d || !d.value) return noValue
  const suffix = appendDuration
    ? ' ' + DurationUnitMetadata[unit].abbrev
    : ''
  return formatNumValue(Duration.convert(d, unit).value, false, decimals) + suffix
}

/**
* @param {number} rating - performance rating to be formatted for display
* @param {string} [noValue = '-'] - an optional default display value for an undefined or null performance rating
* @returns {string} - performance display value
*/
export const formatPerformanceRating = (rating, noValue = '-') => {
  if(rating === null || typeof rating === 'undefined') return noValue
  if(typeof rating !== 'number') throw new Error('rating must be a number')
  return (rating * 10).toFixed(1)
}

export const formatListForDisplay = list => {
  const nListItems = list.length
  return list
    .map((item, idx) => `${nListItems > 1 && idx + 1 === nListItems ? 'and ' : ''}${item}`)
    .join(nListItems > 2 ? ', ' : ' ') + '.'
}

export const getEntityTypeLabel = (entityType, ancestry) => {
  switch(entityType) {
    case EntityType.PORTFOLIO: return ancestry === '/' ? 'Portfolio' : 'Subportfolio'
    case EntityType.PROGRAM: return 'Program'
    case EntityType.PROJECT: return 'Project'
    case EntityType.RISK: return 'Risk'
    default: return '(Unknown)'
  }
}
