import { useCallback, useMemo, ReactElement, LegacyRef } from 'react'
import { useDispatch } from 'react-redux'
import { createId } from '@vms/vmspro3-core/dist/idUtils'
import { Criterion } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'
import { createChildCriterion, updateCriterion } from '@vms/vmspro3-core/dist/actions/decision'
import { Select } from 'antd'

import FormModal from './FormModal'
import { Form, Input } from '../controls'

import { useModalData, useCriteria } from '../../redux/hooks'
import { colorSelectOptions } from '../../utils/appConsts'
import { getRandomColorFromLeastFrequent } from '../../utils/getRandomColorFromLeastFrequent'

function useNextRandomColor(decisionId: string) {
  const criteria = useCriteria(decisionId)
  return useMemo(
    () => getRandomColorFromLeastFrequent(colorSelectOptions.map(o => o.value), criteria),
    [criteria]
  )
}

export const CriterionModalId = 'CriterionModal'
export type CriterionModalData = {
  decisionId: string
  criterion?: Criterion
  parentId?: string
}
export const CriterionModal = (): ReactElement => {
  const { decisionId, parentId, criterion } = useModalData<CriterionModalData>(CriterionModalId)
  const color = useNextRandomColor(decisionId)

  const dispatch = useDispatch()
  const onOk = useCallback(
    data => {
      data = { ...data, description: createHtmlObject(data.description?.value || null) }
      if(criterion) {
        dispatch(updateCriterion(decisionId, criterion.id, data))
      } else if(parentId) {
        const newCriterion = {
          id: createId(),
          ...data,
          parentId,
          type: 'Rated',
          color,
        }
        dispatch(createChildCriterion(decisionId, newCriterion))
      }
    },
    [criterion, dispatch, decisionId, parentId, color]
  )

  return (
    <FormModal modalId={CriterionModalId} onOk={onOk} initialValues={criterion?.data ?? { color }}>
      {(setInitialFocusRef: LegacyRef<Input>) => (
        <>
          <Form.Item label="Name" name="name" rules={[{ required: true, whitespace: true }]}>
            <Input ref={setInitialFocusRef} />
          </Form.Item>
          <Form.Item label="Abbrev." name="abbrev" rules={[{ required: true, whitespace: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Color" name="color" rules={[{ required: true }]}>
            <Select>
              {colorSelectOptions.map(o => (
                <Select.Option key={o.value} value={o.value} style={{ display: 'flex' }}>
                  <div style={{ width: '100%', height: '100%', padding: '4px 0', display: 'flex', gap: '12px' }}>
                    <div style={{ width: '50px', backgroundColor: o.value }} />
                    <span style={{ alignSelf: 'center' }}>{o.label}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Description" name={['description', 'value']}>
            <Input.TextArea />
          </Form.Item>
        </>
      )}
    </FormModal>
  )
}
CriterionModal.id = CriterionModalId
