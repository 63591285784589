import { ReactElement, useMemo } from 'react'
import { useDroppable } from '@dnd-kit/core'
import _partition from 'lodash/partition'

import { Ratable, RatingVector } from '@vms/vmspro3-core/dist/types'
import { RatingScaleConfig } from '@vms/vmspro3-core/dist/nextgen/Criterion'

import { RatingProvider } from './RatingProvider'
import { RatingNodeLayout } from './RatingNodeLayout'
import { RatingScaleOverlay } from './RatingScaleOverlay'
import { RatableItem } from './RatableItem'

import style from './Rating.module.css'
import { testId } from '../../test-automation'

interface RatingNodeProps<T> {
  nodeId: string,
  items: T[],
}
function RatingNode<T extends Ratable>({
  nodeId,
  items,
}: RatingNodeProps<T>) {
  const { setNodeRef } = useDroppable({ id: nodeId })

  return (
    <div className={style.ratingNode} ref={setNodeRef} {...testId(nodeId)}>
      {items.map(item => <RatableItem<T> key={item.id} item={item} />)}
    </div>
  )
}

export type RatingProps<T> = {
  extra?: ReactElement,
  items: T[],
  onRateItem: (itemId: string, ratingVector: RatingVector, abstain?: boolean) => void,
  ratingScaleConfig: RatingScaleConfig,
  renderItem: (item: T, ratingVector: RatingVector, abstain: boolean) => ReactElement,
}
export const Rating = <T extends Ratable>({
  onRateItem,
  renderItem,
  items,
  extra,
  ratingScaleConfig,
}: RatingProps<T>): ReactElement => {
  const ratableItems = useMemo(
    () => {
      const [unrated, withRating] = _partition(items, item => item.ratingVector === null)
      const [abstained, rated] = _partition(withRating, item => item.abstain)

      return {
        unrated,
        abstained,
        rated,
      }
    },
    [items]
  )

  return (
    <RatingProvider<T>
      maxRating={ratingScaleConfig.maxRating}
      minRating={ratingScaleConfig.minRating}
      onRateItem={onRateItem}
      renderItem={renderItem}
    >
      <div className={style.rating}>
        <RatingNodeLayout
          abstainLabel={ratingScaleConfig.abstainLabel}
          maxRatingLabel={ratingScaleConfig.maxRatingLabel}
          minRatingLabel={ratingScaleConfig.minRatingLabel}
          abstainNode={<RatingNode nodeId="abstainCanvas" items={ratableItems.abstained} />}
          ratingNode={<RatingNode nodeId="ratingCanvas" items={ratableItems.rated} />}
          ratingScaleOverlay={
            <RatingScaleOverlay
              maxRating={ratingScaleConfig.maxRating}
              minRating={ratingScaleConfig.minRating}
              ratingScale={ratingScaleConfig.ratingScale}
            />
          }
        />
        <div className={style.unratedPoolColumn}>
          <div className={style.unratedPool}>
            {ratableItems.unrated.map(item => <RatableItem<T> key={item.id} item={item} />)}
          </div>
          <div className={style.extra}>
            {extra}
          </div>
        </div>
      </div>
    </RatingProvider>
  )
}
