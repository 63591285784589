import { ReactElement } from 'react'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

import { RatingScaleConfig } from '@vms/vmspro3-core/dist/nextgen/Criterion'

import style from './RatingNodeLayout.module.css'

type RatingNodeLayoutProps = {
  abstainLabel: RatingScaleConfig['abstainLabel'],
  maxRatingLabel: RatingScaleConfig['maxRatingLabel'],
  minRatingLabel: RatingScaleConfig['minRatingLabel'],
  abstainNode?: ReactElement,
  ratingNode?: ReactElement,
  ratingScaleOverlay?: ReactElement,
}
export function RatingNodeLayout({
  abstainLabel,
  maxRatingLabel,
  minRatingLabel,
  abstainNode,
  ratingNode,
  ratingScaleOverlay,
}: RatingNodeLayoutProps) {
  return (
    <div className={style.ratingNodeLayout}>
      <div className={style.rating}>
        <div className={style.ratingLabelsColumn}>
          <UpOutlined />
          <div className={style.ratingLabels}>
            <span>{minRatingLabel}</span>
            <span>{maxRatingLabel}</span>
          </div>
          <DownOutlined />
        </div>
        <div className={style.ratingNodeBorder}>
          <div className={style.ratingNode}>
            {ratingNode}
            {ratingScaleOverlay}
          </div>
        </div>
      </div>
      <div className={style.abstain}>
        <div className={style.abstainLabelColumn}>
          <div className={style.abstainLabel}>
            {abstainLabel}
          </div>
        </div>
        <div className={style.ratingNodeBorder}>
          <div className={style.ratingNode}>
            {abstainNode}
          </div>
        </div>
      </div>
    </div>
  )
}
