/* eslint-disable @typescript-eslint/no-var-requires, no-undef */
const { resourceResolver } = require('./resolvers/resourceResolver')

/**
 * Get a list of policies for the resource
 *
 * @param {String} principal - the actor requesting authorization
 * @param {Array<Object>} resourcePolicies - the resource's policy array
 * @param {Array<Object>} globalPolicies - the identity policy array
 *
 * @return {Array} the list of policies for the resource
 */
const resolveResourcePolicies = resourceResolver

module.exports = {
  resolveResourcePolicies,
}
