import { Participant, Rating, RatingNotes, ValidRating } from "../types";

export type ParticipationSessionType = 'OptionRating' | 'CriteriaPrioritization'

/**
 * Currently, status only controls ad hoc participation; authorized participation is always
 * allowed.  We need to add some more nuance here, but the method for that is TBD.
 */
export type ParticipationSessionStatus = 'Active' | 'Inactive'

/**
 * This represents the serializable data that describes a participation session.  This
 * can be used to type database records, JSON data, or GQL interfaces.  Ideally, it would
 * be generated by an ORM.
 */
export interface ParticipationSessionData {
  id: string
  name: string
  type: ParticipationSessionType
  status: ParticipationSessionStatus
  /**
   * Direct: ratings are made directly (such as in quick prioritization or option rating)
   * AHP: paired comparisons result in indirect prioritizations (criteria prioritization only)
   */
  method: 'Direct' | 'AHP'
}

export class ParticipationSession {

  constructor(
    readonly _data: ParticipationSessionData,
    readonly participants: Participant[],
    readonly ratings: Rating[],
    readonly ratingNotes: RatingNotes[]
  ) {
    if(ratings.some(r => r.participationSessionId !== _data.id)) {
      throw new Error('invalid rating: participation session ID incorrect')
    }
    if(ratingNotes.some(r => r.participationSessionId !== _data.id)) {
      throw new Error('invalid rating notes: participation session ID incorrect')
    }
    if(_data.type === 'OptionRating' && ratings.some(r => r.subjectType === 'Criterion')) {
      throw new Error('invalid subject type for rating associated with OptionRating session')
    }
    if(_data.type === 'CriteriaPrioritization' && ratings.some(r => r.subjectType === 'Option')) {
      throw new Error('invalid subject type for rating associated with CriteriaPrioritization session')
    }
  }

  get validRatings() {
    return this.ratings.filter((r): r is ValidRating => r.ratingVector !== null && !r.abstain)
  }

  log() {
    console.group()
    console.log('ID: ', this._data.id)
    console.log('Name: ' + this._data.name)
    console.log('Status: ', this._data.status)
    console.log('Method: ' + this._data.method)
    console.log('Type: ' + this._data.type)
    console.log('Ratings:')
    console.table(this.ratings.map(r => ({
      'Participant': r.participantId,
      'Context': `${r.contextType} ${r.contextId}`,
      'Subject': `${r.subjectType} ${r.subjectId}`,
      'Rating': r.ratingVector ? r.ratingVector.map(r => r.toFixed(3)).join(', ') : '-',
      'Abstain': r.abstain,
    })))
    console.groupEnd()
  }

}

