import { ReactElement, useMemo } from 'react'
import { Criteria } from '@vms/vmspro3-core/dist/nextgen/criteria'
import { Options } from '@vms/vmspro3-core/dist/nextgen/options'
import { ValueNode } from '@vms/vmspro3-core/dist/nextgen/valueGraph/valueNode'
import {
  ParticipationSession,
  ParticipationSessionType,
} from '@vms/vmspro3-core/dist/nextgen/participationSession'

import { ValueGraph } from './ValueGraph'
import {
  useCriteria as useCriteriaData,
  useDecision,
  useOptions as useOptionsData,
  useParticipants,
  useParticipationSession as useParticipationSessionData,
  useRatings,
} from '../../redux/hooks'

function useParticipationSession(
  decisionId: string,
  participationSessionType: ParticipationSessionType,
): ParticipationSession {
  const participationSessionData = useParticipationSessionData(decisionId, participationSessionType)
  const participants = useParticipants(decisionId)
  const ratings = useRatings(decisionId, participationSessionType)

  return useMemo(
    () => new ParticipationSession(
      participationSessionData,
      participants,
      ratings,
      []
    ),
    [participationSessionData, participants, ratings]
  )
}

function useValueNode(decisionId: string, optionId: string): ValueNode | undefined {
  const decision = useDecision(decisionId)

  const optionData = useOptionsData(decisionId)
  const optionRatingSession = useParticipationSession(decisionId, 'OptionRating')

  const criteriaData = useCriteriaData(decisionId)
  const criteriaPrioritizationSession = useParticipationSession(decisionId, 'CriteriaPrioritization')

  return useMemo(
    () => {
      const criteria = new Criteria(criteriaData)
      criteria.useParticipationSession(
        criteriaPrioritizationSession,
        decision.ratingsToPrioritizationAlgorithm || 'Normalize'
      )

      const valueFormula = decision.valueFunctionJson && JSON.parse(decision.valueFunctionJson)
      const options = new Options(optionData, criteria, optionRatingSession, valueFormula)

      return options.all.find(o => o.id === optionId)?.valueGraph
    },
    [
      decision,
      criteriaData,
      criteriaPrioritizationSession,
      optionData,
      optionRatingSession,
      optionId,
    ]
  )
}

interface ValueGraphContainerProps {
  decisionId: string,
  optionId: string,
}
export function ValueGraphContainer({ decisionId, optionId }: ValueGraphContainerProps): ReactElement | null {
  const valueNode = useValueNode(decisionId, optionId)

  if(!valueNode) return null

  return <ValueGraph rootValueNode={valueNode} />
}
