import { useCallback, ReactElement, LegacyRef } from 'react'
import { useDispatch } from 'react-redux'
import { UnreachableCaseError } from 'ts-essentials'
import { createDecisionFolder, createDecision } from '@vms/vmspro3-core/dist/actions/decision'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'

import FormModal from './FormModal'
import { Form, Input } from '../controls'

import { useModalData } from '../../redux/hooks/appHooks'

export type DecisionNodeCreateModalData = {
  decisionNodeType: 'Decision' | 'DecisionFolder',
  ancestry: string,
}
function DecisionNodeCreateModal(): ReactElement {
  const { decisionNodeType, ancestry } = useModalData<DecisionNodeCreateModalData>(DecisionNodeCreateModal.id)

  const dispatch = useDispatch()
  const onOk = useCallback(
    ({ name, description }: { name: string, description: string }) => {
      switch(decisionNodeType) {
        case 'DecisionFolder': {
          return dispatch(createDecisionFolder({ name, ancestry }))
        }
        case 'Decision': {
          return dispatch(createDecision({
            name,
            ancestry,
            description: createHtmlObject(description ?? null),
            objective: createHtmlObject(''),
          }))
        }
        default: {
          throw new UnreachableCaseError(decisionNodeType)
        }
      }
    },
    [dispatch, decisionNodeType, ancestry]
  )

  return (
    <FormModal modalId={DecisionNodeCreateModal.id} onOk={onOk}>
      {(initialFocus: LegacyRef<Input>) => (
        <>
          <h2>Create {decisionNodeType}</h2>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, whitespace: true }]}
          >
            <Input ref={initialFocus} />
          </Form.Item>
          {decisionNodeType === 'Decision' && (
            <Form.Item name="description" label="Description">
              <Input />
            </Form.Item>
          )}
        </>
      )}
    </FormModal>
  )
}
DecisionNodeCreateModal.id = 'DecisionNodeCreateModal'

export { DecisionNodeCreateModal }
