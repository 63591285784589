import { useEffect, useRef } from 'react'

/**
 * Stores any value in a ref and returns the stored value from the previous
 * render cycle.
 *
 * @param {*} value
 * @returns {*}
 */
export default function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}
