import AccountCreateModal from './AccountCreateModal'
import AccountSubscriptionCanceledModal from './AccountSubscriptionCanceledModal'
import { CriterionModal } from './CriterionModal'
import { DecisionDeleteModal } from './DecisionDeleteModal'
import { DecisionEditModal } from './DecisionEditModal'
import { DecisionFolderDeleteModal } from './DecisionFolderDeleteModal'
import { DecisionFolderEditModal } from './DecisionFolderEditModal'
import { DecisionNodeCreateModal } from './DecisionNodeCreateModal'
import EndSubscriptionConfirmModal from './EndSubscriptionConfirmModal'
import EntityAttachmentAddModal from './EntityAttachmentAddModal'
import EntityAttachmentEditModal from './EntityAttachmentEditModal'
import EntityDeleteModal from './EntityDeleteModal'
import ErrorModal from './ErrorModal'
import InviteUserModal from './InviteUserModal'
import { OptionModal } from './OptionModal'
import { OptionDuplicateModal } from './OptionDuplicateModal'
import { ParticipantsMergeModal } from './ParticipantsMergeModal'
import PlanSelectionModal from './PlanSelectionModal'
import RiskCategoryModal from './RiskCategoryModal'
import RiskCopyModal from './RiskCopyModal'
import RiskCreateModal from './RiskCreateModal'
import RiskPhaseModal from './RiskPhaseModal'
import RiskEntityCreateModal from './RiskEntityCreateModal'
import RiskTableCustomizationModal from './RiskTableCustomizationModal'
import UserPreferencesModal from './UserPreferencesModal'
import UsersAddResourcePolicyModal from './UsersAddResourcePolicyModal'
import UsersAddRoleModal from './UsersAddRoleModal'
import UsersRemoveRoleConfirmModal from './UsersRemoveRoleConfirmModal'
import UnprocessedActionDispositionModal from './UnprocessedActionDispositionModal'
import { ValueFormulaConfigModal } from './ValueFormulaConfigModal'
import { ValuemetricsMissingRatingsInfoModal } from './ValuemetricsMissingRatingsInfoModal'

export default [
  AccountCreateModal,
  AccountSubscriptionCanceledModal,
  CriterionModal,
  DecisionDeleteModal,
  DecisionEditModal,
  DecisionFolderDeleteModal,
  DecisionFolderEditModal,
  DecisionNodeCreateModal,
  EndSubscriptionConfirmModal,
  EntityAttachmentAddModal,
  EntityAttachmentEditModal,
  EntityDeleteModal,
  ErrorModal,
  InviteUserModal,
  OptionModal,
  OptionDuplicateModal,
  ParticipantsMergeModal,
  PlanSelectionModal,
  RiskCategoryModal,
  RiskCopyModal,
  RiskCreateModal,
  RiskPhaseModal,
  RiskEntityCreateModal,
  RiskTableCustomizationModal,
  UserPreferencesModal,
  UsersAddResourcePolicyModal,
  UsersAddRoleModal,
  UsersRemoveRoleConfirmModal,
  UnprocessedActionDispositionModal,
  ValueFormulaConfigModal,
  ValuemetricsMissingRatingsInfoModal,
]
