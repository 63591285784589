import React from 'react'
import AntTable from 'antd/lib/table'
import 'antd/lib/table/style/css'
import * as Sentry from '@sentry/browser'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import CaretUpOutlined from '@ant-design/icons/CaretUpOutlined'
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined'

import {
  compareDurations,
  naturalAlphanumericSort,
  numcomp,
  compareIdsByLabel,
} from '../../utils/sort-utils'

class Table extends React.Component {
  state = {
    sortFields: this.props.sortFields || [],
    page: 1,
  }

  getSortFn = (type, field, asc = true) => (a, b) => (({
    string: naturalAlphanumericSort,
    number: numcomp,
    boolean: (a, b) => Boolean(a) - Boolean(b),
    bool: (a, b) => Boolean(a) - Boolean(b),
    cost: (a, b) => numcomp(a && a.value, b && b.value),
    time: (a, b) => compareDurations(a, b),
    risk: (a, b) => numcomp(a && a.index, b && b.index),
    idsByLabel: (a, b) => compareIdsByLabel(a, b, this.props.categoriesById),
    ...this.props.sortFns,
  }[type]) || (() => 0))(_get(a, field, null), _get(b, field, null)) * (asc ? 1 : -1)

  toggleSort = (field, type) => this.setState(state => {
    const sortFields = _cloneDeep(state.sortFields)
    const idx = state.sortFields.findIndex(x => x.field === field)
    if(idx < 0) {
      sortFields.unshift({ asc: true, field, type })
    } else {
      const asc = !sortFields[idx].asc
      sortFields.unshift(Object.assign(...sortFields.splice(idx, 1), { asc }))
    }
    const { onSortChange } = this.props
    if(typeof onSortChange === 'function') onSortChange(sortFields)
    return { sortFields, page: 1 }
  })

  render() {
    const {
      bordered = true,
      columns,
      dataSource,
      pagination = false,
      rowKey = 'id',
      size = 'small',
      emptyText,
      showTotal = false,
      ...otherProps
    } = this.props

    // mapTableColumns may push additional defaultSortOrder fields to this
    // array, which needs to be a shallow copy so that component state is
    // not mutated directly.
    const sortFields = [...this.state.sortFields]

    // If there are nested header rows for the column, we can recursively build their sort functions.
    // If no children; return as normal.
    const mapTableColumns = _columns => _columns.map(c => {
      const _c = _cloneDeep(c)
      const { dataIndex, sortType, title, children, defaultSortOrder = false } = _c
      const _dataIndex = Array.isArray(dataIndex) ? dataIndex.join('.') : dataIndex
      if(children) _c.children = mapTableColumns(children)
      if(defaultSortOrder && !sortFields.find(({ field }) => field === _dataIndex)) {
        sortFields.push({ asc: defaultSortOrder === 'ascend', field: _dataIndex, type: sortType })
      }
      const sortField = sortFields.find(f => f.field === _dataIndex)
      return {
        ..._c,
        ..._c.sortType && {
          title: (
            <span
              onClick={() => this.toggleSort(_dataIndex, sortType)}
              style={{ userSelect: 'none', cursor: 'pointer' }}
            >
              {title}&nbsp;
              {sortField && sortField.acs
                ? <CaretUpOutlined style={iconStyle} />
                : <CaretDownOutlined style={iconStyle} />
              }
            </span>
          ),
        },
      }
    })
    const tableColumns = columns && mapTableColumns(columns)

    // sortFns need to be generated after the mapTableColumns function, which
    // may add additional fields to the sortFields array if a defaultSortOrder
    // property is found on a column but not already existing in state.
    // This value also needs to be a shallow copy because the array is sorted
    // in place.
    const sortedRows = [...dataSource]
    const sortFns = sortFields.map(({ asc, field, type }) => this.getSortFn(type, field, asc))
    try {
      sortedRows.sort((a, b) => {
        /* eslint-disable no-restricted-syntax */
        for(const sortFn of sortFns) {
          const res = sortFn(a, b)
          if(res) return res
        }
        /* eslint-enable no-restricted-syntax */
        return 0
      })
    } catch(e) {
      // failed to sort the Table. Print an error in the console,
      // but do not block the process of the screen.
      console.error(`Error in sortFn: `, e)
      // Log this error to the server to be able to view the handle the error better in the future.
      // Sentry error call
      const sentryId = e
        ? Sentry.captureException(e)
        : Sentry.captureMessage('SortFn invoked error handler without an error object')
      console.log(`Error logged into Sentry under SentryId: ${sentryId}`)
    }

    const tableProps = {
      ...otherProps,
      bordered,
      columns: tableColumns,
      childrenColumnName: 'childColumns',
      dataSource: sortedRows,
      pagination: pagination && {
        ...pagination,
        current: this.state.page,
        onChange: page => this.setState({ page }),
        showTotal: (total, range) => showTotal ? `${range[0]}-${range[1]} of ${total} items` : '',
      },
      rowKey,
      size,
      ...emptyText && { locale: { emptyText } },
    }

    return <AntTable {...tableProps} />
  }
}

const iconStyle = { display: 'inline-block', verticalAlign: 'middle' }

export default Table
