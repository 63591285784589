/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import LockOutlined from '@ant-design/icons/LockOutlined'
import qs from 'querystringify'

import EmailField from '../../components/EmailField'
import PasswordField from '../../components/PasswordField'
import { Card, Form, Input, Button, LinkButton2, Spin } from '../../client/controls'

import Server from '../../server/VMSProServerAdapter'
import { setAuth } from '../../redux/auth/actions'

const passwordRulesCopy = 'Password must be at least 12 characters long.'
const unknownErrorCopy = 'An unknown error occurred. Please contact support.'

const setFormFieldError = (formInstance, name, errorMessage) => formInstance.setFields([{
  name,
  errors: [errorMessage],
}])

function SignInForm({
  initialValues,
  setForgotPassword,
  formInstance,
  handleSignIn,
  loading,
  message,
}) {
  const initialFocusRef = useRef(null)

  useEffect(
    () => {
      initialFocusRef.current.focus()
    },
    []
  )

  return (
    <>
      {message && (
        <div css={style.formMessage}>
          <h2>{message}</h2>
        </div>
      )}
      <Form
        form={formInstance}
        onFinish={handleSignIn}
        initialValues={initialValues}
        validateTrigger={['onChange', 'onBlur']}
        layout="vertical"
        hideRequiredMark
      >
        <EmailField initialFocusRef={initialFocusRef} />
        <PasswordField />
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
          >Sign In</Button>
          <LinkButton2 css={{ float: 'right' }} onClick={() => setForgotPassword()}>
            <i>Forgot your password?</i>
          </LinkButton2>
        </Form.Item>
        <i>New to VMSPro? <Link to="/signup">Create an account.</Link></i>
      </Form>
    </>
  )
}

function SetNewPasswordForm({
  formInstance,
  handleSetNewPassword,
  loading,
}) {
  return (
    <Form
      form={formInstance}
      onFinish={handleSetNewPassword}
      validateTrigger={['onChange', 'onBlur']}
      layout="vertical"
      hideRequiredMark
    >
      <EmailField.Hidden />
      <h3>You must enter a new password:</h3>
      <PasswordField.SetNewPassword showSuggestion />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >Done</Button>
      </Form.Item>
    </Form>
  )
}

function RequestResetPasswordForm({
  cancelForgotPassword,
  formInstance,
  handleSubmitForgotPassword,
  message,
  loading,
}) {
  return (
    <Form
      form={formInstance}
      onFinish={handleSubmitForgotPassword}
      validateTrigger={['onChange', 'onBlur']}
      layout="vertical"
      hideRequiredMark
    >
      <div css={style.formMessage}>
        <h2>{message}</h2>
      </div>
      <EmailField />
      <Form.Item>
        <Button
          css={style.cancelButton}
          onClick={cancelForgotPassword}
        >Cancel</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >Reset Password</Button>
      </Form.Item>
    </Form>
  )
}

function ResetPasswordForm({
  formInstance,
  handleResetForgotPassword,
  loading,
}) {
  return (
    <Form
      form={formInstance}
      onFinish={handleResetForgotPassword}
      layout="vertical"
      hideRequiredMark
    >
      <EmailField.Hidden />
      <h3>Check your email for a verification code:</h3>
      <Form.Item
        label="Verification Code"
        name="verificationCode"
        normalize={code => code.trim()}
        rules={[
          {
            required: true,
            message: 'Please enter the verification code',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined css={style.inputPrefix} />}
          inputMode="numeric"
          placeholder="verification code"
          autoComplete="one-time-code"
        />
      </Form.Item>
      <h3>And choose a new password:</h3>
      <PasswordField.SetNewPassword showSuggestion />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >Done</Button>
      </Form.Item>
    </Form>
  )
}

function SignIn() {
  const dispatch = useDispatch()

  const [formInstance] = Form.useForm()

  const resetPasswordFields = () => formInstance.setFieldsValue({
    password: undefined,
    newPassword: undefined,
    verifyNewPassword: undefined,
  })

  const [loading, setLoading] = useState(false)
  const [authFlowStep, setAuthFlowStep] = useState('signIn')
  const [message, setMessage] = useState('Sign in to VMSPro')
  const [pendingUser, setPendingUser] = useState(null)

  const setForgotPassword = message => {
    setMessage(message ?? 'Reset your password')
    setAuthFlowStep('forgotPasswordRequest')
  }
  const cancelForgotPassword = () => setAuthFlowStep('signIn')

  const navigate = useNavigate()
  const handleSignIn = ({ email, password }) => {
    setLoading(true)
    Server.auth.signIn(email, password)
      .then(user => {
        resetPasswordFields()
        if(user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setPendingUser(user)
          setAuthFlowStep('newPasswordRequired')
          setLoading(false)
        } else {
          const { preferred_username: userId, email } = user.attributes
          dispatch(setAuth({ userId, email }))
          navigate('/')
        }
      })
      .catch(err => {
        switch(err.code) {
          case 'NotAuthorizedException': {
            if(err.message === 'User is disabled') {
              setFormFieldError(formInstance, 'email', err.message)
            } else {
              setFormFieldError(formInstance, 'password', 'Incorrect password')
            }
            break
          }
          case 'UserNotFoundException': {
            setFormFieldError(formInstance, 'email', 'User not found')
            break
          }
          case 'PasswordResetRequiredException': {
            setForgotPassword('A password reset is required')
            break
          }
          default: {
            setFormFieldError(formInstance, 'password', unknownErrorCopy)
            break
          }
        }
        setLoading(false)
      })
  }

  const handleSetNewPassword = ({ newPassword }) => {
    setLoading(true)
    Server.auth.completeNewPassword(pendingUser, newPassword)
      .then(() => {
        resetPasswordFields()
        const { preferred_username: userId, email } = pendingUser.attributes
        dispatch(setAuth({ userId, email }))
      })
      .catch(err => {
        setFormFieldError(
          formInstance,
          'newPassword',
          err.code === 'InvalidPasswordException' ? passwordRulesCopy : unknownErrorCopy
        )
        setLoading(false)
      })
  }

  const handleSubmitForgotPassword = ({ email }) => {
    setLoading(true)
    Server.auth.forgotPassword(email)
      .then(() => {
        resetPasswordFields()
        setAuthFlowStep('forgotPasswordChooseNewPassword')
      })
      .catch(err => {
        switch(err.code) {
          case 'UserNotFoundException': {
            return setFormFieldError(formInstance, 'email', 'User not found')
          }
          case 'NotAuthorizedException': {
            // Since we are confirming users email on user creation, we won't get the standard
            // 'UserNotConfirmedException'. This should be the only case where this error code is returned
            // from Amplify.auth.forgotPassword(), so we resend the password email.
            return Server.resendPassword(email)
              .then(() => {
                setMessage('This user has not confirmed. The confirmation email has been resent to ' + email)
              })
              .catch(() => setMessage(unknownErrorCopy))
              .finally(() => setAuthFlowStep('userNotConfirmedResendEmail'))
          }
          default: {
            return setFormFieldError(formInstance, 'email', unknownErrorCopy)
          }
        }
      })
      .finally(() => setLoading(false))
  }

  const handleResetForgotPassword = ({ email, newPassword, verificationCode }) => {
    setLoading(true)
    Server.auth.forgotPasswordSubmit(email, verificationCode, newPassword)
      .then(() => {
        resetPasswordFields()
        setMessage('Please sign in with your new password')
        setAuthFlowStep('signIn')
      })
      .catch(err => {
        switch(err.code) {
          case 'InvalidPasswordException': {
            setFormFieldError(formInstance, 'password', passwordRulesCopy)
            break
          }
          case 'CodeMismatchException': {
            setFormFieldError(
              formInstance,
              'verificationCode',
              'The verification code you provided does not match'
            )
            break
          }
          default: {
            setFormFieldError(formInstance, 'password', unknownErrorCopy)
            break
          }
        }
      })
      .finally(() => setLoading(false))
  }

  const location = useLocation()
  const initialValues = React.useMemo(
    () => qs.parse(location.search),
    [location.search]
  )

  switch(authFlowStep) {
    case 'signIn': {
      return (
        <SignInForm
          initialValues={initialValues}
          setForgotPassword={setForgotPassword}
          formInstance={formInstance}
          handleSignIn={handleSignIn}
          loading={loading}
          message={message}
        />
      )
    }
    case 'newPasswordRequired': {
      return (
        <SetNewPasswordForm
          formInstance={formInstance}
          handleSetNewPassword={handleSetNewPassword}
          loading={loading}
        />
      )
    }
    case 'forgotPasswordRequest': {
      return (
        <RequestResetPasswordForm
          cancelForgotPassword={cancelForgotPassword}
          formInstance={formInstance}
          handleSubmitForgotPassword={handleSubmitForgotPassword}
          message={message}
          loading={loading}
        />
      )
    }
    case 'forgotPasswordChooseNewPassword': {
      return (
        <ResetPasswordForm
          formInstance={formInstance}
          handleResetForgotPassword={handleResetForgotPassword}
          loading={loading}
        />
      )
    }
    case 'userNotConfirmedResendEmail': {
      return loading ? <Spin /> : message
    }
    default: {
      return null
    }
  }
}

export function SignInPage() {
  return (
    <div css={style.container}>
      <Card css={style.card}>
        <SignIn />
      </Card>
    </div>
  )
}

const style = {
  container: {
    padding: '60px 24px',
  },
  card: {
    maxWidth: '400px',
    margin: '0 auto',
  },
  cancelButton: {
    marginRight: '24px',
  },
  formMessage: {
    textAlign: 'center',
  },
  inputPrefix: {
    color: 'rgba(0,0,0,.25)',
  },
}
