import React from 'react'
import { useSelector } from 'react-redux'
import _get from 'lodash/get'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'

import PageMenu from '../../client/components/common/PageMenu'
import RoleDetails from '../../components/Roles/RoleDetails'
import { Page } from '../../components'
import { Col, Row } from '../../client/controls'

import actions from '../../actions'
import useAuthz from '../../hooks/useAuthz'
import { assumeAuthz } from '../../utils/authzUtils'
import { getRolePolicyMenuItems } from '../../selectors'
import { selectAuthUserId } from '../../redux/auth/selectors'
import { useFetchUsers } from '../../redux/users/hooks'
import useQuerystringTabKey from '../../hooks/useQuerystringTabKey'

export function RolesPage() {
  useFetchUsers()

  const authUserId = useSelector(selectAuthUserId)
  const roleMenus = useSelector(getRolePolicyMenuItems)

  const { tabKey, onTabChange } = useQuerystringTabKey(_get(roleMenus, '[0].items[0].key'), 'role')

  const getAssumeRoleAction = policyId => actions.user.assumeAuthz({ policyId }, {
    type: systemConsts.AssumeAuthzType.ASSUME_ROLE,
    authUserId,
  })

  const authz = useAuthz()
  const assumeRole = () => assumeAuthz(authz, getAssumeRoleAction(tabKey))

  return (
    <Page title="Roles">
      <Row gutter={30} type='flex'>
        <Col flex="1">
          {roleMenus.map(({ title, items }) =>
            <PageMenu
              key={title}
              menuItems={items}
              menuTitle={title}
              onSelectKey={onTabChange}
              selectedKey={tabKey}
            />
          )}
        </Col>
        <Col flex="3">
          {tabKey &&
            <RoleDetails
              assumeRole={assumeRole}
              canAssumeRole={authz(getAssumeRoleAction(tabKey))}
              policyId={tabKey}
            />
          }
        </Col>
      </Row>
    </Page>
  )
}
