import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateAccountSubscription } from '@vms/vmspro3-core/dist/actions/account/updateSubscription'
import { Account, Product } from '@vms/vmspro3-core/dist/types'

import actions from '../../actions'
import config from '../../config.json'
import useAuthz from '../../hooks/useAuthz'
import { LoadingStatus } from '../../utils/appConsts'
import { fetchPendingInvitationsForAccount } from './actions'
import {
  selectAccount,
  selectAccountId,
  selectAccountLoadingStatus,
  selectPendingInvitationsForAccount,
  selectPendingInvitationsLoadingStatusForAccount,
  selectUserAccounts,
  selectUserAccountsLoadingStatus,
} from './selectors'

const products = config.instance.products as Product[]

export function useAccountId() {
  return useSelector(selectAccountId)
}

export function useAccount(): [account: Account | undefined, loading: boolean] {
  const account = useSelector(selectAccount)
  const loadingStatus = useSelector(selectAccountLoadingStatus)
  const loading = loadingStatus !== LoadingStatus.Loaded

  return [account, loading]
}

export function useUserAccounts() {
  const userAccounts = useSelector(selectUserAccounts)
  const loadingStatus = useSelector(selectUserAccountsLoadingStatus)
  const loading = loadingStatus !== LoadingStatus.Loaded

  return [userAccounts, loading]
}

export function usePendingInvitationsForAccount() {
  const authz = useAuthz()
  const authorized = useMemo(
    () => authz({ module: 'System', type: actions.invitation.read.toString() }),
    [authz]
  )

  const pendingInvitations = useSelector(selectPendingInvitationsForAccount)
  const loadingStatus = useSelector(selectPendingInvitationsLoadingStatusForAccount)
  const loading = loadingStatus !== LoadingStatus.Loaded

  return [pendingInvitations, loading, authorized]
}

export function useFetchPendingInvitationsForAccount() {
  const authz = useAuthz()
  const accountId = useSelector(selectAccountId)
  const authorized = useMemo(
    () => authz({ module: 'System', type: actions.invitation.read.toString() }),
    [authz]
  )

  const dispatch = useDispatch()
  const loadingStatus = useSelector(selectPendingInvitationsLoadingStatusForAccount)
  useEffect(
    () => {
      if(authorized && loadingStatus === LoadingStatus.NotLoaded) {
        dispatch(fetchPendingInvitationsForAccount(accountId))
      }
    },
    [dispatch, authorized, loadingStatus, accountId]
  )
}

/**
 * Determines if the current user can update the subscription for the current account.  It does this by seeing if
 * there are any products that the user could update the account subscription with (including the current product).
 * It may seem odd that the current product is included, but there is the case where they are already at the
 * biggest account that will accomodate their current usage, and they have "no where to go", so to speak.  They
 * should still be able to access the subscription controls, and it's up to the UI to explain to them why they
 * don't have anything to choose from.
 */
export function useCanUpdateSubscription() {
  const authz = useAuthz()
  const account = useSelector(selectAccount)
  return useMemo(
    () => account && products.some(p => authz(updateAccountSubscription(account, p, null))),
    [authz, account]
  )
}
