import React from 'react'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'

import { Divider } from '../../controls'

import { buttonBar } from '../../styles/style-consts'

const { Color } = systemConsts

/**
 * DEPRECATED
 *
 * This component was created back in the PfM days when were trying to adhere to Blaine's
 * comps a little too slavishly.  The built-in antd component of this same name should
 * be preferred.  This component is used extensively in PfM code, and will require some
 * effort to comprehensively replace.  See https://vms.atlassian.net/browse/VP3-1720.
 */
const PageHeader = props => {
  const {
    breadcrumb,
    leftControls = [],
    leftDetails,
    leftLowerDetails,
    rightControls = [],
    rightDetails,
    title,
  } = props

  return (
    <div style={style.wrapper}>
      <div style={style.content}>
        <div style={style.detailPanelLeft}>
          {breadcrumb &&
            <div style={style.breadcrumb}>
              <span key={breadcrumb} style={style.smallDetail}>{breadcrumb}</span>
            </div>
          }
          <span style={style.largeDetailLeft}>{title}</span>
          {leftDetails &&
            <div style={style.leftDetails}>
              {leftDetails.map((detail, idx) => idx
                ? [
                  <Divider key={'divider' + detail} type='vertical' style={style.smallDetailDivider} />,
                  <span key={detail} style={style.smallDetail}>{detail}</span>,
                ]
                : <span key={detail} style={style.smallDetail}>{detail}</span>
              )}
            </div>
          }
          {leftLowerDetails &&
            <div style={style.leftLowerDetails}>
              {leftLowerDetails.map((detail, idx) => idx
                ? [
                  <Divider key={'divider' + detail} type='vertical' style={style.smallDetailDivider} />,
                  <span key={detail} style={style.smallDetail}>{detail}</span>,
                ]
                : <span key={detail} style={style.smallDetail}>{detail}</span>
              )}
            </div>
          }
        </div>
        <div style={style.detailPanelRight}>
          {rightDetails && rightDetails.map(({ title, content }, idx) => {
            const detail = (
              <div key={title} style={style.detailItem}>
                <span style={style.smallDetail}>{title}</span>
                <span style={style.largeDetailRight}>{content}</span>
              </div>
            )
            return idx
              ? [<Divider key={'divider' + title} type='vertical' style={style.largeDetailDivider} />, detail]
              : detail
          })}
        </div>
      </div>
      {(leftControls.length > 0 || rightControls.length > 0) &&
        <div className="button-bar" {...style.buttonBar}>
          <div>
            {leftControls}
          </div>
          <div>
            {rightControls}
          </div>
        </div>
      }
    </div>
  )
}

const style = {
  buttonBar,
  breadcrumb: {
    alignItems: 'left',
    display: 'flex',
    marginBottom: '12px',
  },
  content: {
    display: 'flex',
    height: 'auto',
    justifyContent: 'space-between',
    padding: '18px 36px',
    width: '100%',
  },
  detailItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  detailPanelLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailPanelRight: {
    alignItems: 'center',
    display: 'flex',
    height: 'auto',
  },
  largeDetailLeft: {
    color: Color.BLACK,
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '0px',
  },
  largeDetailRight: {
    color: Color.BLACK,
    fontSize: '20px',
    fontWeight: '500',
    marginTop: '10px',
  },
  largeDetailDivider: {
    height: '60%',
    margin: '0 20px',
  },
  leftDetails: {
    alignItems: 'center',
    display: 'flex',
  },
  leftLowerDetails: {
    marginTop: '6px',
    marginBottom: '-16px',
  },
  smallDetail: {
    color: Color.GRAY_TEXT,
  },
  smallDetailDivider: {
    margin: '0 12px',
  },
  wrapper: {
    backgroundColor: 'white',
    height: 'auto',
    width: '100%',
  },
}

export default PageHeader
