import { produce } from 'immer'

import {
  CreateChildCriterionAction,
  DeleteCriterionAction,
  UpdateCriterionAction,
  CreateDecisionAction,
} from '@vms/vmspro3-core/dist/actions/decision'
import { CriterionData } from '@vms/vmspro3-core/dist/nextgen/Criterion'

import { FetchDecisionEntitySuccessAction, FetchDecisionFolderChildrenSuccessAction } from '../actionTypes'

type CriteriaState = {
  byId: Record<string, CriterionData>
}
export const initialState: CriteriaState = {
  byId: {},
}

type Actions =
  | FetchDecisionEntitySuccessAction
  | FetchDecisionFolderChildrenSuccessAction
  | CreateChildCriterionAction
  | DeleteCriterionAction
  | UpdateCriterionAction
  | CreateDecisionAction

export const criteriaReducer = produce<CriteriaState, [Actions?]>((state, action) => {
  if(!action?.type) return

  switch(action.type) {
    case 'FetchDecisionFolderChildrenSuccess': // fall through to FetchDecisionEntitySuccess
    case 'FetchDecisionEntitySuccess': {
      if(action.payload.criteria) {
        Object.assign(state.byId, action.payload.criteria)
      }
      break
    }
    case 'Create Criterion': {
      state.byId[action.payload.id] = action.payload
      break
    }
    case 'Delete Criterion': {
      delete state.byId[action.meta.criterionId]
      action.meta.descendantCriteriaIds?.forEach((descCriterionId: string) => {
        delete state.byId[descCriterionId]
      })
      break
    }
    case 'Update Criterion': {
      Object.assign(state.byId[action.meta.criterionId], action.payload)
      break
    }
    case 'Create Decision': {
      action.payload.criteria.forEach(criterion => state.byId[criterion.id] = criterion)
      break
    }
    default: {
      break
    }
  }
}, initialState)
