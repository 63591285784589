import { useCallback, useMemo, useState } from 'react'
import { ExperimentOutlined } from '@ant-design/icons'
import { Select } from 'antd'

import { updateDecision as updateDecisionAC, UpdateDecisionAction } from '@vms/vmspro3-core/dist/actions/decision'
import { valueExpressionToString } from '@vms/vmspro3-core/dist/valuemetrics/valuemetrics'
import { Expression as ValueFormulaExpression } from '@vms/vmspro3-core/dist/valuemetrics/valueFormula'
import { RatingToPrioritizationAlgorithm } from '@vms/vmspro3-core/dist/nextgen/prioritization/quickPrioritization'
import { Criteria } from '@vms/vmspro3-core/dist/nextgen/criteria'
import { Options } from '@vms/vmspro3-core/dist/nextgen/options'
import { Option } from '@vms/vmspro3-core/dist/nextgen/Option'
import { ParticipationSession } from '@vms/vmspro3-core/dist/nextgen/participationSession'

import { PerformanceChart } from './PerformanceChart'
import { ValuemetricsChart } from './ValuemetricsChart'
import { ValuemetricsTable } from './ValuemetricsTable'
import { ValueGraphContainer } from '../../ValueGraph/ValueGraphContainer'
import { ValueFormulaConfigModalId } from '../../../client/modals/ValueFormulaConfigModal'
import { Button, Drawer, Form, Switch } from '../../../client/controls'

import { useAppDispatch } from '../../../redux'
import { SelectOption } from '../../../types'
import {
  useShowModal,
  useCriteria,
  useDecision,
  useOptions,
  useParticipants,
  useParticipationSession,
  useRatings,
} from '../../../redux/hooks'

const ratingsToPrioritizationAlgorithmSelectOptions: SelectOption[] = [
  { label: 'Recenter And Normalize (default)', value: 'RecenterAndNormalize' },
  { label: 'Normalize', value: 'Normalize' },
]

function useCriteriaNextgen(decisionId: string): Criteria {
  const criteriaData = useCriteria(decisionId)
  const criteriaPrioritizationSessionData = useParticipationSession(decisionId, 'CriteriaPrioritization')
  const criteriaPrioritizationRatings = useRatings(decisionId, 'CriteriaPrioritization')
  const participants = useParticipants(decisionId)
  const { ratingsToPrioritizationAlgorithm } = useDecision(decisionId)

  return useMemo(
    () => {
      const criteria = new Criteria(criteriaData)
      const criteriaPrioritizationSession = new ParticipationSession(
        criteriaPrioritizationSessionData,
        participants,
        criteriaPrioritizationRatings,
        []
      )
      criteria.useParticipationSession(criteriaPrioritizationSession, ratingsToPrioritizationAlgorithm)
      return criteria
    },
    [
      criteriaData,
      criteriaPrioritizationSessionData,
      criteriaPrioritizationRatings,
      participants,
      ratingsToPrioritizationAlgorithm,
    ]
  )
}

function useOptionsNextgen(decisionId: string): Options {
  const criteria = useCriteriaNextgen(decisionId)
  const optionsData = useOptions(decisionId)
  const optionRatingParticipationSessionData = useParticipationSession(decisionId, 'OptionRating')
  const optionRatingRatings = useRatings(decisionId, 'OptionRating')
  const participants = useParticipants(decisionId)
  const decision = useDecision(decisionId)

  return useMemo(
    () => {
      const optionRatingSession = new ParticipationSession(
        optionRatingParticipationSessionData,
        participants,
        optionRatingRatings,
        []
      )

      const valueFunctionExpr = decision.valueFunctionJson ? JSON.parse(decision.valueFunctionJson) : undefined
      const options = new Options(optionsData, criteria, optionRatingSession, valueFunctionExpr)

      if(decision.baselineOptionId) {
        options.baseline = options.all.find(option => option.id === decision.baselineOptionId)
      }

      return options
    },
    [
      criteria,
      optionsData,
      optionRatingParticipationSessionData,
      optionRatingRatings,
      participants,
      decision.valueFunctionJson,
      decision.baselineOptionId,
    ]
  )
}

export function Valuemetrics({ decisionId }: { decisionId: string }) {
  const dispatch = useAppDispatch()

  const decision = useDecision(decisionId)
  const valueFunctionExpr = useMemo(
    () => decision.valueFunctionJson ? JSON.parse(decision.valueFunctionJson) : null,
    [decision]
  )

  const updateDecision = useCallback(
    (payload: UpdateDecisionAction['payload']) => dispatch(updateDecisionAC(decisionId, payload)),
    [decisionId, dispatch]
  )
  const updateRatingsToPrioritizationAlgorithm = useCallback(
    (ratingsToPrioritizationAlgorithm: RatingToPrioritizationAlgorithm) => (
      updateDecision({ ratingsToPrioritizationAlgorithm })
    ),
    [updateDecision]
  )
  const updateBaselineOptionId = useCallback(
    (optionId: string | undefined) => updateDecision({ baselineOptionId: optionId ?? null }),
    [updateDecision]
  )

  const [hasBaseline, setHasBaseline] = useState<boolean>(!!decision.baselineOptionId)
  const toggleHasBaseline = useCallback(
    () => {
      // when toggling hasBaseline to false we set the baselineOptionId to
      // undefined. if the value of hasBaseline is being set to false, this is
      // clearing the baselineOptionId. if the value of hasBaseline is being
      // set to true, there is no initial or default baseline so this is harmless.
      updateBaselineOptionId(undefined)
      setHasBaseline(state => !state)
    },
    [updateBaselineOptionId]
  )

  const [valueGraphOptionId, setValueGraphOptionId] = useState<string>()

  const showValueFormulaConfigModal = useShowModal(ValueFormulaConfigModalId)
  const onConfigureValueFormula = useCallback(
    () => showValueFormulaConfigModal({
      initialValueExpr: valueFunctionExpr,
      onOk: (expr: ValueFormulaExpression) => updateDecision({ valueFunctionJson: JSON.stringify(expr) }),
    }),
    [valueFunctionExpr, showValueFormulaConfigModal, updateDecision]
  )

  const criteria = useCriteriaNextgen(decisionId)
  const options = useOptionsNextgen(decisionId)
  const [optionsSortOrder, setOptionsSortOrder] = useState(() => options.all.map(option => option.id))

  const sortedOptions = useMemo(
    () => optionsSortOrder
      .map(optionId => options.byId(optionId))
      .filter((option): option is Option => Boolean(option)),
    [optionsSortOrder, options]
  )

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <label>Has Baseline:&nbsp;
          <Switch checked={hasBaseline} onChange={toggleHasBaseline} />
        </label>
        <div style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
          <span>
            <b>Current value formula:</b>
          </span>
          <span>
            {valueExpressionToString(valueFunctionExpr)}
          </span>
          <Button
            icon={<ExperimentOutlined />}
            onClick={onConfigureValueFormula}
            type="primary"
          >
            Configure Value Formula
          </Button>
        </div>
      </div>
      <ValuemetricsTable
        decisionId={decisionId}
        hasBaseline={hasBaseline}
        options={options}
        setOptionsSortOrder={setOptionsSortOrder}
        setValueGraphOptionId={setValueGraphOptionId}
        updateBaselineOptionId={updateBaselineOptionId}
      />
      <div style={{ paddingTop: '20px', display: 'flex', gap: '48px' }}>
        <div style={{ flex: 1, minWidth: '0' }}>
          <ValuemetricsChart
            options={sortedOptions}
            valueFunctionExpr={valueFunctionExpr}
          />
          <Form.Item label="Ratings-to-prioritization algorithm (advanced)">
            <Select
              onChange={updateRatingsToPrioritizationAlgorithm}
              options={ratingsToPrioritizationAlgorithmSelectOptions}
              value={decision.ratingsToPrioritizationAlgorithm ?? 'Normalize'}
            />
          </Form.Item>
        </div>
        <div style={{ flex: 1, minWidth: '0' }}>
          <PerformanceChart
            criteria={criteria.all}
            options={sortedOptions}
          />
        </div>
      </div>
      <Drawer
        onClose={() => setValueGraphOptionId(undefined)}
        visible={!!valueGraphOptionId}
        width="100%"
      >
        {valueGraphOptionId &&
          <ValueGraphContainer decisionId={decisionId} optionId={valueGraphOptionId} />
        }
      </Drawer>
    </>
  )
}
