import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'querystringify'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'

import { Button, Col, Form, Row } from '../controls'

const { Color } = systemConsts

/**
 * @prop {Function} children
 * @prop {Function} createFilterFunctions
 * @prop {Object} defaultValue
 * @prop {Array} formInputs
 * @prop {Array} rows
 */
const TableFilter = ({
  children,
  createFilterFunctions,
  defaultValue,
  formInputs,
  rows,
}) => {
  // Filter function state
  const [filters, setFilters] = useState([])

  // Input state
  const [inputState, setInputState] = useState({})
  const updateInputState = updates => setInputState(prevInputState => ({ ...prevInputState, ...updates }))

  const didMountRef = useRef(!defaultValue)
  const { pathname, search } = useLocation()
  const { filter: filterString } = qs.parse(search)

  // Set filters and input state when query string 'filterString' changes
  useEffect(() => {
    const filterDfns = filterString
      ? JSON.parse(filterString)
      : !didMountRef.current
        ? Object.entries(defaultValue).map(([field, value]) => ({ field, value }))
        : []
    setFilters(filterDfns)
    const updatedInputState = filterDfns.reduce((_inputs, { field, value }) => (
      Object.assign(_inputs, { [field]: value })
    ), {})

    setInputState(updatedInputState)
    if(!didMountRef.current) didMountRef.current = true
  }, [filterString, defaultValue])

  const navigate = useNavigate()

   // Get filter definitions from input state and push to query string
  const applyFilterDefinitions = () => {
    const filterDefinitions = Object.entries(inputState)
      .reduce((_defs, [field, value]) => {
        if(Array.isArray(value) ? value.length : value) {
          _defs.push({ field, value })
        }
        return _defs
      }, [])

    const filterDefsURIComponent = encodeURIComponent(JSON.stringify(filterDefinitions))
    navigate(`${pathname}?filter=${filterDefsURIComponent}`)
  }

  // Clear filter definitions from query string
  const clearFilterDefinitions = () => {
    navigate(pathname)
    setFilters([])
    setInputState({})
  }

  // Create filter functions and filter rows by filter functions
  const filterFns = createFilterFunctions(filters)
  const filteredRows = rows.filter(row => filterFns.every(fn => fn(row)))

  const formControlsOffset = (3 - (formInputs.length % 4)) * 6

  return (
    <>
      <Form style={style.formContainer} layout="vertical">
        <Row align='bottom' gutter={30} type='flex'>
          {formInputs.map(({ field, key, label }) => (
            <Col key={key} span={6}>
              <Form.Item style={style.formItem} label={label || ''}>
                {field(inputState, updateInputState, applyFilterDefinitions)}
              </Form.Item>
            </Col>
          ))}
          <Col offset={formControlsOffset} span={6}>
            <Form.Item style={style.formItem}>
              <div style={style.formControls}>
                <Button type="primary" onClick={applyFilterDefinitions}>Apply</Button>
                <Button onClick={clearFilterDefinitions}>Clear</Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {children(filteredRows)}
    </>
  )
}

const style = {
  formContainer: {
    background: Color.WHITE,
    marginBottom: '24px',
  },
  formControls: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  formItem: {
    marginBottom: '12px',
  },
}

export default TableFilter
