/**
 * Joins ancestry fragments to create a new ancestry string. Enforces
 * accurate ancestry string composition by replacing double slashes with
 * a single slash, adding a missing leading slash, and removing a trailing
 * slashes.
 *
 * @example
 * joinAncestry('abc', 'def', 'ghi')
 *   // returns '/abc/def/ghi'
 * joinAncestry('/abc/def//ghi/', 'jkl/')
 *   // returns '/abc/def/ghi/jkl'
 *
 * @param {...string} pathFragments
 * @returns {string}
 */
export function joinAncestry(...pathFragments: string[]) {
  return ('/' + pathFragments.join('/'))
    .replace(/\/+/g, '/')
    .replace(/(.)\/$/, '$1')  // negative lookbehind would be better, but not supported on Safari
}

/**
 * Given an ancestry string, returns an array of ancestor entity IDs.
 *
 * @example
 * splitAncestry('/')
 *   // returns []
 * splitAncestry('/abc/def/ghi/jkl')
 *   // returns ['abc', 'def', 'ghi', 'jkl']
 *
 * @param {string} ancestry
 * @returns {string[]}
 */
export function splitAncestry(ancestry: string) {
  return ancestry.split('/').filter(Boolean)
}
