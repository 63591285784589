import unflattenDeep from '@vms/vmspro3-core/dist/utils/unflattenDeep'

import Server from '../server/VMSProServerAdapter'

export async function getRiskEntity(entityId) {
  return Server.getItem(entityId)
    .then(({ entity, ancestors, policiesByResourceId }) => ({
      entity: unflattenDeep(entity),
      ancestors: unflattenDeep(ancestors),
      policiesByResourceId,
    }))
}

export async function getRiskEntityChildren(childAncestry) {
  return Server.getItemsByAncestry(childAncestry)
    .then(({ entities, policiesByResourceId }) => ({
      entities: unflattenDeep(entities),
      policiesByResourceId,
    }))
}
