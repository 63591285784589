import { ReactElement, useMemo } from 'react'
import _groupBy from 'lodash/groupBy'
import _keyBy from 'lodash/keyBy'
import _matches from 'lodash/matches'

import { Html, Ratable, Rating, RatingNotes } from '@vms/vmspro3-core/dist/types'
import { identifyRatingNotes } from '@vms/vmspro3-core/dist/utils/ratingNotes'
import { CriterionData } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { defaultCriteriaPrioritizationScaleConfig } from '@vms/vmspro3-core/dist/nextgen/criteria'
import {
  createTree,
  getDescendantNodeData,
  getInternalNodeData,
  getNodeLabels,
  TreeNode,
} from '@vms/vmspro3-core/dist/utils/createTree'

import { RatingInterface, RatingInterfaceProps } from '../RatingInterface/RatingInterface'

import { useAdHocParticipationContext } from './adHocParticipationContext'
import { useQuerystringValue } from '../../hooks/useQuerystringValue'
import { useCycleSelectOptions } from '../../hooks/useCycleSelectOptions'
import { useUpdateRating, useUpdateRatingNotes } from '../../services/adHocDecisionService'
import { getInternalNodeSelectOptions } from '../../utils/treeSelectOptions'

type CriteriaRatingInterfaceProps = RatingInterfaceProps<CriterionData>

type useRatingContextProgressPropsData = {
  participationSessionId: string,
  participantId: string,
  ratings: Rating[],
  rootPerformanceCriteriaTreeNode: TreeNode<CriterionData>
}
function useRatingContextProgressProps({
  participationSessionId,
  participantId,
  ratings,
  rootPerformanceCriteriaTreeNode,
}: useRatingContextProgressPropsData): CriteriaRatingInterfaceProps['ratingContextProgressProps'] {
  const subjectRatingsByContextId = useMemo(
    () => _groupBy(
      ratings.filter(_matches<
        Pick<Rating, 'participationSessionId' | 'participantId' | 'contextType' | 'subjectType'>
      >({
        participationSessionId,
        participantId,
        contextType: 'Criterion',
        subjectType: 'Criterion',
      })),
      'contextId'
    ),
    [ratings, participationSessionId, participantId]
  )

  const subjectCriteriaByParentId = useMemo(
    () => _groupBy(
      getDescendantNodeData(rootPerformanceCriteriaTreeNode),
      'parentId'
    ),
    [rootPerformanceCriteriaTreeNode]
  )
  const contextCriteria = useMemo(
    () => getInternalNodeData(rootPerformanceCriteriaTreeNode),
    [rootPerformanceCriteriaTreeNode]
  )

  return useMemo(
    () => {
      const totalCount = contextCriteria.length
      let inProgressCount = 0
      let completedCount = 0

      // it is possible that a criterion may have been used as a rating context
      // before its children were deleted, leaving stray ratings that should
      // not contribute to rating progress metrics. only use current context
      // criteria ratings.
      contextCriteria.forEach(({ id: contextCriterionId }) => {
        const contextRatings = subjectRatingsByContextId[contextCriterionId]
        const contextChildCriteria = subjectCriteriaByParentId[contextCriterionId]
        const subjectHasRating = (criterion: CriterionData) => contextRatings?.some(rating =>
          rating.subjectId === criterion.id &&
          rating.ratingVector !== null
        )

        if(contextChildCriteria.every(subjectHasRating)) {
          completedCount++
        } else if(contextChildCriteria.some(subjectHasRating)) {
          inProgressCount++
        }
      })

      return {
        totalCount,
        inProgressCount,
        completedCount,
      }
    },
    [subjectRatingsByContextId, subjectCriteriaByParentId, contextCriteria]
  )
}

type RatingContextDetailsType = CriteriaRatingInterfaceProps['ratingContextDetailsProps']['detailsType']
type useRatingContextDetailsPropsData = {
  decisionObjective: Html,
  contextCriterion: CriterionData,
  subjectCriteria: CriterionData[],
  ratingContextLabel: string,
}
function useRatingContextDetailsProps({
  decisionObjective,
  contextCriterion,
  subjectCriteria,
  ratingContextLabel,
}: useRatingContextDetailsPropsData): CriteriaRatingInterfaceProps['ratingContextDetailsProps'] {
  const [detailsType, setDetailsType] = useQuerystringValue<RatingContextDetailsType>('detail-type', 'context')

  return {
    decisionObjective,
    ratingContextDescription: contextCriterion.description,
    ratingContextLabel,
    subjects: subjectCriteria,
    subjectTypeLabel: 'Child Criteria',
    detailsType,
    setDetailsType,
  }
}

type useRatingNotesPropsData = {
  accountId: string,
  decisionId: string,
  participationSessionId: string,
  participantId: string,
  ratingNotes: RatingNotes[],
  ratingContextLabel: string,
  contextId: string,
}
function useRatingNotesProps({
  accountId,
  decisionId,
  participationSessionId,
  participantId,
  ratingNotes,
  ratingContextLabel,
  contextId,
}: useRatingNotesPropsData): CriteriaRatingInterfaceProps['ratingNotesProps'] {
  const [onChangeRatingNotesValue] = useUpdateRatingNotes(accountId, decisionId, {
    participationSessionId,
    participantId,
    contextType: 'Criterion',
    contextId,
    subjectType: 'Criterion',
  })

  const ratingNotesId = useMemo(
    () => identifyRatingNotes({
      participationSessionId,
      participantId,
      contextId,
      subjectType: 'Criterion',
    }),
    [participationSessionId, participantId, contextId]
  )

  const selectedRatingNotes = ratingNotes.find(_matches<
    Pick<RatingNotes, 'participationSessionId' | 'participantId' | 'contextId' | 'subjectType'>
  >({
    participationSessionId,
    participantId,
    contextId,
    subjectType: 'Criterion',
  }))

  return {
    label: `Notes for "${ratingContextLabel}"`,
    value: selectedRatingNotes?.notes.value ?? '',
    onChange: onChangeRatingNotesValue,
    key: ratingNotesId,
  }
}

type useRatingPropsData = {
  accountId: string,
  decisionId: string,
  contextId: string,
  participantId: string,
  participationSessionId: string,
  subjectCriteria: CriterionData[],
  ratings: Rating[],
}
function useRatingProps({
  accountId,
  decisionId,
  participantId,
  participationSessionId,
  ratings,
  subjectCriteria,
  contextId,
}: useRatingPropsData): CriteriaRatingInterfaceProps['ratingProps'] {
  const ratableChildCriteria = useMemo<Ratable<CriterionData>[]>(
    () => {
      if(!subjectCriteria || !ratings || !contextId) {
        return []
      }

      const ratingsBySubjectId = _keyBy(
        ratings.filter(_matches<
          Pick<Rating, 'participationSessionId' | 'participantId' | 'contextType' | 'contextId' | 'subjectType'>
        >({
          participationSessionId,
          participantId,
          contextType: 'Criterion',
          contextId,
          subjectType: 'Criterion',
        })),
        'subjectId'
      )

      return subjectCriteria.map(criterion => {
        const rating = ratingsBySubjectId[criterion.id]

        return {
          ...criterion,
          ratingVector: rating?.ratingVector ?? null,
          abstain: rating?.abstain,
        }
      })
    },
    [contextId, participantId, participationSessionId, subjectCriteria, ratings]
  )

  const [onRateCriterion] = useUpdateRating(accountId, decisionId, {
    participationSessionId,
    participantId,
    contextType: 'Criterion',
    contextId,
    subjectType: 'Criterion',
  })

  return {
    items: ratableChildCriteria,
    onRateItem: onRateCriterion,
    ratingScaleConfig: defaultCriteriaPrioritizationScaleConfig,
  }
}

function useRatingContextControls(rootPerformanceCriterionTreeNode: TreeNode<CriterionData>) {
  const criteriaSelectOptions = useMemo(
    () => getInternalNodeSelectOptions(rootPerformanceCriterionTreeNode),
    [rootPerformanceCriterionTreeNode]
  )

  const [ratingContextId, setRatingContextId] = useQuerystringValue('criterionId', criteriaSelectOptions[0].value)

  const { prevOption, nextOption } = useCycleSelectOptions({
    selectOptions: criteriaSelectOptions,
    value: ratingContextId,
    onChange: setRatingContextId,
  })

  return {
    prevRatingContext: prevOption,
    nextRatingContext: nextOption,
    ratingContextId,
  }
}

export function AdHocCriteriaPrioritization(): ReactElement {
  const {
    accountId,
    decisionId,
    decisionName,
    decisionObjective,
    participationSessionId,
    participantId,
    criteria,
    ratings,
    ratingNotes,
  } = useAdHocParticipationContext()

  const criteriaTree = useMemo(() => createTree(criteria ?? []), [criteria])
  const rootPerformanceCriteriaTreeNode = useMemo(
    () => {
      const rootPerformanceCriteriaTreeNode = criteriaTree.all.find(node => node.data.type === 'Performance')
      if(!rootPerformanceCriteriaTreeNode) {
        throw new Error('Root performance criterion not found in decision')
      }
      return rootPerformanceCriteriaTreeNode
    },
    [criteriaTree]
  )

  const {
    prevRatingContext,
    nextRatingContext,
    ratingContextId,
  } = useRatingContextControls(rootPerformanceCriteriaTreeNode)

  const ratingContextLabel = useMemo(
    () => {
      const treeNodeLabels = getNodeLabels(rootPerformanceCriteriaTreeNode)
      return treeNodeLabels?.[ratingContextId] ?? ''
    },
    [rootPerformanceCriteriaTreeNode, ratingContextId]
  )
  const contextCriterion = criteriaTree.byId[ratingContextId]?.data
  const subjectCriteria = useMemo(
    () => criteriaTree.byId[ratingContextId].children.map(c => c.data),
    [ratingContextId, criteriaTree]
  )

  const ratingContextProgressProps = useRatingContextProgressProps({
    participantId,
    participationSessionId,
    ratings,
    rootPerformanceCriteriaTreeNode,
  })
  const ratingContextDetailsProps = useRatingContextDetailsProps({
    decisionObjective,
    contextCriterion,
    subjectCriteria,
    ratingContextLabel,
  })
  const ratingNotesProps = useRatingNotesProps({
    accountId,
    decisionId,
    participationSessionId,
    participantId,
    ratingNotes,
    ratingContextLabel,
    contextId: ratingContextId,
  })
  const ratingProps = useRatingProps({
    accountId,
    decisionId,
    participantId,
    participationSessionId,
    subjectCriteria,
    ratings,
    contextId: ratingContextId,
  })

  return (
    <RatingInterface<CriterionData>
      hideRating
      ratingContextProgressProps={ratingContextProgressProps}
      ratingContextDetailsProps={ratingContextDetailsProps}
      ratingNotesProps={ratingNotesProps}
      ratingProps={ratingProps}
      prevRatingContext={prevRatingContext}
      nextRatingContext={nextRatingContext}
      ratingContextLabel={ratingContextLabel}
      decisionName={decisionName}
      participationActivityLabel="Prioritization"
    />
  )
}
