import React, { useMemo } from 'react'

export function SelectFilter(props) {
  const { filterValue, setFilter, preFilteredRows, id, getColumnFilterOptionLabel } = props.column

  const options = useMemo(
    () => {
      const values = new Set(preFilteredRows.map(row => row.values[id]))
      return Array.from(values).map(value => ({
        value,
        label: typeof getColumnFilterOptionLabel === 'function' ? getColumnFilterOptionLabel(value) : value,
      }))
    },
    [id, preFilteredRows, getColumnFilterOptionLabel]
  )

  return (
    <select value={filterValue} onChange={e => setFilter(e.target.value)}>
      <option value="">All</option>
      {options.map(({ value, label }) => (
        <option key={value} value={value}>{label}</option>
      ))}
    </select>
  )
}
