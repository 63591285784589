import Server from '../server/VMSProServerAdapter'
import {
  AssumedAuthzType,
  getAssumedAuthz,
} from '../utils/authzUtils'

export async function getAuth() {
  return Server.auth.getUser()
    .then(user => {
      const { preferred_username: userId, email } = user?.attributes ?? {}

      const res = {
        userId,
        email,
      }

      const assumedAuthz = getAssumedAuthz()
      if(assumedAuthz) {
        const { type } = assumedAuthz
        switch(type) {
          case AssumedAuthzType.ASSUME_ROLE: {
            res.assumedAuthz = assumedAuthz
            break
          }
          case AssumedAuthzType.IMPERSONATE_USER: {
            res.userId = assumedAuthz.assumedUserId
            res.assumedAuthz = {
              type,
              actualUserId: userId,
            }
            break
          }
          default: {
            throw new Error(`Assumed authz set with unknown type ${type}`)
          }
        }
      }

      return res
    })
}

export async function signOut() {
  return Server.auth.signOut()
}
