import { ReactElement, useCallback } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { saveAs } from 'file-saver'
import { filterInvalidRatings } from '@vms/vmspro3-core/dist/valuemetrics/prioritization'

import { Button } from '../../client/controls'

import {
  useDecision,
  useParticipationSessionId,
  useParticipants,
  useCriteria,
  useRatings,
  useRatingNotes,
  useOptions,
} from '../../redux/hooks/decisionHooks'

interface DecisionDownloadButtonProps {
  decisionId: string
  label?: string
}

export function DecisionDownloadButton(props: DecisionDownloadButtonProps): ReactElement {
  const {
    decisionId,
    label = 'Download Decision Excel',
  } = props

  const decision = useDecision(decisionId)
  const optionsData = useOptions(decisionId)
  const participationSessionIds = {
    criteria: useParticipationSessionId(decisionId, 'CriteriaPrioritization'),
    options: useParticipationSessionId(decisionId, 'OptionRating'),
  }
  const participants = useParticipants(decisionId)
  const criteriaData = useCriteria(decisionId)
  const criteriaRatings = useRatings(decisionId, 'CriteriaPrioritization')
  const criteriaRatingNotes = useRatingNotes(decisionId, {
    participationSessionId: participationSessionIds.criteria,
    subjectType: 'Criterion',
  })
  const optionRatings = useRatings(decisionId, 'OptionRating')
  const optionRatingNotes = useRatingNotes(decisionId, {
    participationSessionId: participationSessionIds.options,
    subjectType: 'Option',
  })

  const onClick = useCallback(
    () => {
      const d = {
        decision,
        participants,
        criteriaData,
        criteriaRatings: filterInvalidRatings(criteriaRatings),
        criteriaRatingNotes,
        optionRatings: filterInvalidRatings(optionRatings),
        optionRatingNotes,
        optionsData,
      }
      if(!decision || !('name' in decision)) return
      import('@vms/vmspro3-core/dist/reports/decisionExcelWriter').then(async ({ createDecisionWorkbook }) => {
        const filename = `${decision.name || decisionId}`
        const wb = await createDecisionWorkbook(d)
        // write out the spreadsheet
        const buf = await wb.xlsx.writeBuffer()
        saveAs(new Blob([buf]), filename + '.xlsx')
      })
    },
    [
      decision,
      decisionId,
      participants,
      criteriaData,
      criteriaRatings,
      criteriaRatingNotes,
      optionRatings,
      optionRatingNotes,
      optionsData,
    ]
  )

  return (
    <Button onClick={onClick} icon={<DownloadOutlined />}>{label}</Button>
  )
}
