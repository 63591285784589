import { produce } from 'immer'
import asSet from 'arraysetjs'
import { PolicyType } from '@vms/vmspro3-core/dist/systemConsts'

import actions from '../../actions'
import authActionTypes from '../auth/actionTypes'
import policiesActionTypes from './actionTypes'
import { LoadingStatus } from '../../utils/appConsts'

const initialState = {
  byId: {},
  ids: [],
  loadingStatus: LoadingStatus.NotLoaded,
  byResourceId: {},
}

const policiesReducer = produce((state, action) => {
  switch(action.type) {
    case authActionTypes.SignOut: {
      return initialState
    }
    case 'ResetAccountState': {
      // the only account specific policies are resource policies
      state.byResourceId = initialState.byResourceId
      break
    }
    case policiesActionTypes.FetchPoliciesRequest: {
      state.loadingStatus = LoadingStatus.Requested
      break
    }
    case policiesActionTypes.FetchPoliciesSuccess: {
      action.payload.policies?.forEach(policy => {
        policy.id = policy.policyId
        delete policy.policyId
        state.byId[policy.id] = policy
        state.ids.push(policy.id)
      })
      state.loadingStatus = LoadingStatus.Loaded

      break
    }

    case actions.policy.create.toString(): {
      const { id, policyType, resourceId } = action.payload

      if(policyType === PolicyType.RESOURCE) {
        if(!state.byResourceId[resourceId]) state.byResourceId[resourceId] = []
        state.byResourceId[resourceId].push(action.payload)
      } else {
        state.byId[id] = action.payload
        asSet(state.ids).add(id)
      }
      break
    }
    case actions.policy.update.toString(): {
      const { policyId, policyType, resourceId } = action.meta

      if(policyType === PolicyType.RESOURCE) {
        const polIdx = state.byResourceId[resourceId].findIndex(p => p.policyId === policyId)
        state.byResourceId[resourceId][polIdx] = {
          ...state.byResourceId[resourceId][polIdx],
          ...action.payload,
        }
      } else {
        state.byId[policyId] = {
          ...state.byId[policyId],
          ...action.payload,
        }
      }
      break
    }
    case policiesActionTypes.LoadResourcePolicies: {
      Object.assign(state.byResourceId, action.payload)
      break
    }
    default: {
      break
    }
  }
}, initialState)

export default policiesReducer
