import {
  TreeNode,
  TreeNodeData,
  GetNodeLabelsOptions,
  getLeafNodes,
  getNodeLabels,
  getInternalNodes,
} from '@vms/vmspro3-core/dist/utils/createTree'
import { SelectOption } from '../types'

export function getLeafNodeSelectOptions<T extends TreeNodeData>(
  rootNode: TreeNode<T>,
  options?: GetNodeLabelsOptions
): SelectOption[] {
  const leafNodes = getLeafNodes(rootNode)
  const nodeLabels = getNodeLabels(rootNode, options)
  return leafNodes
    .map(node => ({ value: node.data.id, label: nodeLabels[node.data.id] }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

export function getInternalNodeSelectOptions<T extends TreeNodeData>(
  rootNode: TreeNode<T>,
  options?: GetNodeLabelsOptions
): SelectOption[] {
  const internalNodes = getInternalNodes(rootNode)
  const nodeLabels = getNodeLabels(rootNode, options)
  return internalNodes.map(node => ({ value: node.data.id, label: nodeLabels[node.data.id] }))
}
