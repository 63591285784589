import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import EndSubscriptionConfirmModal from '../../client/modals/EndSubscriptionConfirmModal'
import PlanSelectionModal from '../../client/modals/PlanSelectionModal'
import PlanCard from './PlanCard'
import { Form, Button, Space } from '../../client/controls'

import actions from '../../actions'
import { selectAccount } from '../../redux/account/selectors'
import { showModal } from '../../redux/actions'
import getAugmentAction from '../../selectors/getAugmentAction'
import Server from '../../server/VMSProServerAdapter'

import config from '../../config.json'

const cardBrandLabel = {
  amex: 'American Express',
  diners: 'Diners Club',    // note no apostrophe in Diners Club brand guidelines
  discover: 'Discover',
  jcb: 'JCB',
  mastercard: 'Mastercard', // note Mastercard brand guidelines do not capitalize the "C"
  unionpay: 'UnionPay',
  visa: 'Visa',
}

const SubscriptionEditing = ({ onUpdate, onCancel }) => {
  const [errorMsg, setErrorMsg] = useState()
  const [loading, setLoading] = useState()

  const elements = useElements()
  const stripe = useStripe()
  const augmentAction = useSelector(getAugmentAction)
  const dispatch = useDispatch()
  const onFinish = async () => {
    const card = elements.getElement(CardElement)
    setLoading(true)
    const { paymentMethod, error: stripeError }  = await stripe.createPaymentMethod({ type: 'card', card })
    if(paymentMethod) {
      const action = augmentAction(actions.account.updatePaymentMethod(paymentMethod.id))

      await Server.tryAction(action)
        .catch(err => {
          const msg = err?.response?.data?.message || 'Uknown error; please contact support.'
          setErrorMsg(msg)
          setLoading(false)
        })

      action.meta.ephemeral = true  // it's already been handled!  we don't want isomorphic redux
                                    // sending it to the server again
      dispatch(action)
      const { last4, brand } = paymentMethod?.card ?? {}
      onUpdate(last4, brand)
    } else {
      setErrorMsg(stripeError.message)
      setLoading(false)
    }
  }

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item
        label="Card Information"
        validateStatus={errorMsg ? 'error' : undefined}
        help={errorMsg}
      >
        <CardElement />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button loading={loading} type="primary" htmlType="submit">Update</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

// TODO: we should probably align with Stripe terminology.  paymentMethod != card; rather,
// a card is a type of payment method
const CardDescription = ({ paymentMethod }) => {
  const brandLabel = cardBrandLabel[paymentMethod?.brand]
  const last4 = paymentMethod?.last4
  return (brandLabel
    ? <span>{brandLabel} ending in {last4}.</span>
    : <span>No card configured.</span>
  )
}

const SubscriptionEditorComponent = ({ subscription, onSubscriptionChange }) => {
  const dispatch = useDispatch()
  const [editing, setEditing] = useState(false)
  const account = useSelector(selectAccount)

  const onUpdatePayment = useCallback((last4, brand) => {
    onSubscriptionChange({ paymentMethod: { last4, brand } })
    setEditing(false)
  }, [onSubscriptionChange, setEditing])

  if(editing) {
    return <SubscriptionEditing onUpdate={onUpdatePayment} onCancel={() => setEditing(false)} />
  }

  const onEndSubscription = () => dispatch(showModal(EndSubscriptionConfirmModal.id))
  const onChangePlan = () => dispatch(showModal(PlanSelectionModal.id, { onSubscriptionChange }))

  const currentProductId = account.productId

  const currentProduct = config.instance.products.find(p => p.id === currentProductId)
  const productIsInternal = currentProduct.type === 'Internal'

  return (
    <>
      <h3>Current Plan</h3>
      {account.status === 'Canceled'
        ? <>
            <p><i>Your account has been canceled, and you are not currently subscribed to any plan.</i></p>
            <Button onClick={onChangePlan} type="primary">Choose Plan</Button>
        </>
        : <PlanCard
            product={currentProduct}
            actionText="Change Plan"
            onAction={onChangePlan}
        />
      }
      <h3 style={{ marginTop: '36px' }}>Payment Information</h3>
      {productIsInternal &&
        <p>
          <i>This account is subscribed to a free internal product. No payment method is required.  If you
          wish to switch to a paid acount, you must provide a payment method first.</i>
        </p>
      }
      <CardDescription paymentMethod={subscription.paymentMethod} />
      <div style={{ marginTop: '12px' }}>
        <Space>
          <Button type="primary" onClick={() => setEditing(true)}>Update</Button>
          {!productIsInternal && account.status !== 'Canceled' &&
            <Button danger onClick={onEndSubscription}>End Subscription</Button>
          }
        </Space>
      </div>
    </>
  )
}

const stripe = loadStripe(config.instance.stripe.publishableKeys.test)

export function SubscriptionEditor(props) {
  return (
    <Elements stripe={stripe}>
      <SubscriptionEditorComponent {...props} />
    </Elements>
  )
}
