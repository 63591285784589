import { useCallback, useMemo } from 'react'
import { CellValue, Column } from 'react-table'
import { QuestionCircleFilled } from '@ant-design/icons'

import { Color } from '@vms/vmspro3-core/dist/systemConsts'
import { Options } from '@vms/vmspro3-core/dist/nextgen/options'
import { extractCoefficients } from '@vms/vmspro3-core/dist/valuemetrics/valuemetrics'

import { Table, TableCellRenderer } from '../../Table'
import {
  ValuemetricsMissingRatingsInfoModalData,
  ValuemetricsMissingRatingsInfoModalId,
} from '../../../client/modals/ValuemetricsMissingRatingsInfoModal'

import { useShowModal } from '../../../redux/hooks'
import { MissingPerformanceRatingInfo } from '@vms/vmspro3-core/dist/nextgen/valueGraph/performanceValueNode'

const initialSortState = {
  sortBy: [{ id: 'value', desc: true }],
}

const format: Record<string, (cellProps: CellValue) => string | null> = {
  cost: ({ value }) => {
    if(!Number.isFinite(value)) {
      return null
    }

    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    }).format(value)
  },
  time: ({ value }) => {
    if(!Number.isFinite(value)) {
      return null
    }

    return Intl.NumberFormat('en-US', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(value)
  },
  score: ({ value }) => {
    if(!Number.isFinite(value)) {
      return null
    }

    return Intl.NumberFormat('en-US', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(value)
  },
  percent: ({ value }) => {
    if(!Number.isFinite(value)) {
      return null
    }

    return Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(value)
  },
}

type ValuemetricsTableData = {
  optionId: string,
  name: string,
  abbrev: string,
  color: string,
  isBaseline: boolean,
  quant: {
    cost: number | null,
    time: number | null,
  },
  scores: Record<
    'unweighted' | 'weighted',
    Record<'cost' | 'time' | 'perf', number | null>
    >,
  value: number | null,
  change?: Record<string, number | null>,
  missingRatings: MissingPerformanceRatingInfo[],
}

interface ValuemetricsTableProps {
  decisionId: string,
  hasBaseline: boolean,
  options: Options,
  setOptionsSortOrder: (optionIds: string[]) => void,
  setValueGraphOptionId: (optionId: string | undefined) => void,
  updateBaselineOptionId: (baselineOptionId: string | undefined) => void,
}
export function ValuemetricsTable({
  decisionId,
  hasBaseline,
  options,
  setOptionsSortOrder,
  setValueGraphOptionId,
  updateBaselineOptionId,
}: ValuemetricsTableProps) {
  const showMissingRatingModal = useShowModal<ValuemetricsMissingRatingsInfoModalData>(
    ValuemetricsMissingRatingsInfoModalId
  )

  const data = useMemo(
    () => {
      const coefficients = extractCoefficients(options.valueFormula)

      return options.all.map(option => {
        const datum: ValuemetricsTableData = {
          optionId: option.id,
          name: option.name,
          abbrev: option.abbrev,
          color: option.color,
          isBaseline: !!options.baseline && options.baseline === option,
          quant: {
            cost: option.quantAttrs.C?.value ?? null,
            time: option.quantAttrs.T?.value ?? null,
          },
          scores: {
            unweighted: {
              cost: option.quantScores.C,
              time: option.quantScores.T,
              perf: option.performanceGraph?.value ?? null,
            },
            weighted: {
              cost: typeof option.quantScores.C === 'number'
                ? (option.quantScores.C * coefficients.C)
                : null,
              time: typeof option.quantScores.T === 'number'
                ? (option.quantScores.T * coefficients.T)
                : null,
              perf: typeof option.performanceGraph?.value === 'number'
                ? (option.performanceGraph.value * coefficients.P)
                : null,
            },
          },
          change: {
            cost: option.changeInQuantScores.C,
            time: option.changeInQuantScores.T,
            perf: option.changeInPerf,
            value: option.changeInValue,
          },
          value: option.valueGraph?.value ?? null,
          missingRatings: option.performanceGraph?.missingRatings || [],
        }
        return datum
      })
    },
    [options]
  )

  const unratedCriteria = useMemo(() =>
    options.criteria.all.filter(c => c.pri.global === null),
    [options.criteria]
  )

  const columns = useMemo<Column<ValuemetricsTableData>[]>(
    () => [
      {
        Header: 'Option Information',
        columns: [
          {
            id: 'baseline-checkbox',
            Header: 'Baseline',
            accessor: 'optionId',
            Cell: cellProps => (
              <input
                type="checkbox"
                checked={cellProps.row.original.isBaseline}
                onChange={() => updateBaselineOptionId(cellProps.value)}
              />
            ),
            disableSortBy: true,
          },
          {
            Header: 'Abbrev.',
            accessor: 'abbrev',
          },
          {
            Header: 'Option',
            accessor: 'name',
            Cell: TableCellRenderer.EntityName,
          },
          {
            Header: 'Cost (USD)',
            accessor: row => row.quant.cost,
            Cell: format.cost,
            sortType: 'numeric',
            align: 'right',
          },
          {
            Header: 'Time (months)',
            accessor: row => row.quant.time,
            Cell: format.time,
            sortType: 'numeric',
            align: 'right',
          },
        ],
      },
      {
        Header: 'Scores',
        columns: [
          {
            id: 'unweighted-perf',
            Header: 'Perf.',
            accessor: row => row.scores.unweighted.perf,
            Cell: (cellProps: CellValue) => {
              const { value, row: { original: { name, missingRatings } } } = cellProps
              if(!Number.isFinite(value)) {
                return (
                  <QuestionCircleFilled
                    style={{ color: Color.BLUE_LINK }}
                    onClick={() => showMissingRatingModal({
                      optionName: name,
                      decisionId,
                      missingRatings,
                      unratedCriteria,
                    })}
                  />
                )
              }
              return format.score(cellProps)
            },
            sortType: 'numeric',
            sortDescFirst: true,
            align: 'right',
          },
          {
            id: 'unweighted-cost',
            Header: 'Cost',
            accessor: row => row.scores.unweighted.cost,
            Cell: format.score,
            sortType: 'numeric',
            sortDescFirst: true,
            align: 'right',
          },
          {
            id: 'unweighted-time',
            Header: 'Time',
            accessor: row => row.scores.unweighted.time,
            Cell: format.score,
            sortType: 'numeric',
            sortDescFirst: true,
            align: 'right',
          },
        ],
      },
      {
        Header: '% Change',
        id: 'baseline-changes',
        columns: [
          {
            id: 'baseline-change-perf',
            Header: 'Perf.',
            accessor: row => row.change?.perf,
            Cell: format.percent,
            sortType: 'numeric',
            sortDescFirst: true,
            align: 'right',
          },
          {
            id: 'baseline-change-cost',
            Header: 'Cost',
            accessor: row => row.change?.cost,
            Cell: format.percent,
            sortType: 'numeric',
            sortDescFirst: true,
            align: 'right',
          },
          {
            id: 'baseline-change-time',
            Header: 'Time',
            accessor: row => row.change?.time,
            Cell: format.percent,
            sortType: 'numeric',
            sortDescFirst: true,
            align: 'right',
          },
        ],
      },
      {
        Header: 'Value Index',
        accessor: 'value',
        Cell: cellProps => {
          if(cellProps.value === null) {
            return null
          }

          return (
            <span
              style={{ color: Color.BLUE_LINK, cursor: 'pointer' }}
              onClick={() => setValueGraphOptionId(cellProps.row.original.optionId)}
            >
              {Intl.NumberFormat('en-US', {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(cellProps.value)}
            </span>
          )
        },
        sortType: 'numeric',
        sortDescFirst: true,
        align: 'right',
      },
      {
        id: 'baseline-change-value',
        Header: '% Change',
        accessor: row => row.change?.value,
        Cell: format.percent,
        sortType: 'numeric',
        sortDescFirst: true,
        align: 'right',
      },
    ],
    [decisionId, updateBaselineOptionId, setValueGraphOptionId, showMissingRatingModal, unratedCriteria]
  )

  const hiddenColumns = useMemo<string[]>(
    () => {
      function getBaselineColumnIds(columns: Column<ValuemetricsTableData>[]) {
        const baselineColumnIds: string[] = []
        columns.forEach(column => {
          if(column.id?.startsWith('baseline')) {
            baselineColumnIds.push(column.id)
          }
          if('columns' in column && column.columns) {
            baselineColumnIds.push(...getBaselineColumnIds(column.columns))
          }
        })
        return baselineColumnIds
      }

      return hasBaseline ? [] : getBaselineColumnIds(columns)
    },
    [columns, hasBaseline]
  )

  const onSortByChanged = useCallback(
    (data: ValuemetricsTableData[]) => setOptionsSortOrder(data.map(row => row.optionId)),
    [setOptionsSortOrder]
  )

  return (
    <Table<ValuemetricsTableData>
      columns={columns}
      data={data}
      disablePagination
      hiddenColumns={hiddenColumns}
      onSortByChanged={onSortByChanged}
      initialState={initialSortState}
    />
  )
}
