import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { Descriptions } from '../../client/controls'

import { selectDateTimeFormat } from '../../redux/user/selectors'
import { selectAccount } from '../../redux/account/selectors'

const QuotaUsage = ({ quota }) => quota.allowed < 10_000_000
  ? <>{quota.used} of {quota.allowed}</>
  : <>{quota.used} (unlimited)</>

export function SubscriptionInfo({ subscription }) {

  const dateTimeFormat = useSelector(selectDateTimeFormat)
  const account = useSelector(selectAccount)

  const info = [
    ['Account name', account.name],
    ['Account created', moment(account.createdOn).format(dateTimeFormat)],
    ['Account status', account.status],
  ]
  if(typeof subscription.currentMonthlyBill === 'number') {
    info.push(['Current monthly bill', '$' + subscription.currentMonthlyBill.toFixed(2)])
  }
  if(typeof subscription.currentPeriodEnd === 'number') {
    info.push(['Next payment due', moment(subscription.currentPeriodEnd).format(dateTimeFormat)])
  }

  return (
    <>
      <Descriptions title="Account Info" column={1}>
        {info.map(item => <Descriptions.Item key={item[0]} label={item[0]}>{item[1]}</Descriptions.Item>)}
      </Descriptions>
      <Descriptions title="Plan Usage" column={1}>
        <Descriptions.Item label="Users">
          <QuotaUsage quota={account.quotas.users} />
        </Descriptions.Item>
        <Descriptions.Item label="Projects">
          <QuotaUsage quota={account.quotas.projects} />
        </Descriptions.Item>
        {account.quotas.decisions.allowed > 0 &&
        <Descriptions.Item label="Decisions">
          <QuotaUsage quota={account.quotas.decisions} />
        </Descriptions.Item>
        }
      </Descriptions>
    </>
  )
}
