import React from 'react'
import { css } from 'glamor'
import { useSelector } from 'react-redux'

import AssumingRole from './AssumingRole'
import ImpersonatingUser from './ImpersonatingUser'
import { Button } from '../../../client/controls'

import { selectAssumedAuthz } from '../../../redux/auth/selectors'
import {
  restoreDefaultAuthz,
  AssumedAuthzType,
} from '../../../utils/authzUtils'

const AssumedAuthorizationHeader = () => {
  const assumedAuthz = useSelector(selectAssumedAuthz)

  if(!assumedAuthz) return null

  return (
    <div {...style.container}>
      {(() => {
        switch(assumedAuthz.type) {
          case AssumedAuthzType.ASSUME_ROLE: return <AssumingRole />
          case AssumedAuthzType.IMPERSONATE_USER: return <ImpersonatingUser />
          default: return (
            <h3 style={style.message}>AuthZ modification type &quot;{assumedAuthz.type}&quot; not recognized!</h3>
          )
        }
      })()}
      <Button onClick={restoreDefaultAuthz}>Restore Default AuthZ</Button>
    </div>
  )
}

const blink = css.keyframes('blink', {
  '0%': { backgroundColor: 'red' },
  '100%': { backgroundColor: 'yellow' },
})

const style = {
  container: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 30px',
    animation: `${blink} 1s alternate infinite`,
  }),
  message: {
    margin: '0',
  },
}
export default AssumedAuthorizationHeader
