import { createId } from '../idUtils'

const CreateUserActionType = 'Create User'
function create(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  if(!payload.id) payload.id = createId()
  if(meta.accountId && meta.accountId !== '*') throw new Error('"Create User" must have meta.accountId === "*"')
  return {
    module: 'System',
    type: CreateUserActionType,
    payload,
    meta: {
      ...meta,
      accountId: '*',
    },
  }
}
create.toString = () => CreateUserActionType

const AddToAccountActionType = 'Add User to Account'
function addToAccount(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: AddToAccountActionType,
    payload,
    meta,
  }
}
addToAccount.toString = () => AddToAccountActionType

const UpdateActionType = 'Update User'
function update(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: UpdateActionType,
    payload,
    meta,
  }
}
update.toString = () => UpdateActionType

const DisableUserActionType = 'Disable User'
function disable(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: DisableUserActionType,
    payload,
    meta,
  }
}
disable.toString = () => DisableUserActionType

const EnableUserActionType = 'Enable User'
function enable(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: EnableUserActionType,
    payload,
    meta,
  }
}
enable.toString = () => EnableUserActionType

const ChangePasswordActionType = 'Change Password'
function changePassword(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: ChangePasswordActionType,
    payload,
    meta,
  }
}
changePassword.toString = () => ChangePasswordActionType

const AddPolictyToUserActionType = 'Add Policy to User'
function addPolicy(payload: Record<string, unknown> | null, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: AddPolictyToUserActionType,
    payload: payload || {},
    meta,
  }
}
addPolicy.toString = () => AddPolictyToUserActionType

const RemovePolicyActionType = 'Remove Policy from User'
function removePolicy(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: RemovePolicyActionType,
    payload,
    meta,
  }
}
removePolicy.toString = () => RemovePolicyActionType

const SetTableConfigActionType = 'Set Table Config'
function setTableConfig(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: SetTableConfigActionType,
    payload,
    meta,
  }
}
setTableConfig.toString = () => SetTableConfigActionType

const AgreeToEulaActionType = 'Agree to EULA'
function agreeToEula(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: AgreeToEulaActionType,
    payload,
    meta,
  }
}
agreeToEula.toString = () => AgreeToEulaActionType

const AssumeAuthzActionType = 'Assume AuthZ'
function assumeAuthz(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: AssumeAuthzActionType,
    payload,
    meta,
  }
}
assumeAuthz.toString = () => AssumeAuthzActionType

export default {
  create,
  addToAccount,
  update,
  disable,
  enable,
  changePassword,
  addPolicy,
  removePolicy,
  setTableConfig,
  agreeToEula,
  assumeAuthz,
}
