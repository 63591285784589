import { createSelector } from 'reselect'

import { selectAccountUser } from '../redux/user/selectors'

/**
 *
 * @param {Object} state - redux state object
 * @returns {Array} - array containing user IDs
 */
const getUserIds = state => state.users.ids

/**
 *
 * @param {Object} state - redux state object
 * @returns {Object} - object containing users by ID
 */
const getUsersById = state => state.users.byId

/**
 *
 * @param {Object} state - redux state object
 * @param {string} userId - user ID
 * @returns {Object} - user object
 */
const getUser = (state, userId) => state.users.byId[userId]

/**
 *
 * @param {Object} state - redux state object
 * @returns {Array} - array containing user objects
 */
const getAllUsers = createSelector(
  getUserIds,
  getUsersById,
  (ids, byId) => ids.map(id => byId[id])
)

/**
 * Returns an array of all active users.
 * @param {Object} state - redux state object
 * @returns {Array} - active users
 */
const getActiveUsers = createSelector(
  getAllUsers,
  users => users.filter(u => !u.disabled)
)

/**
 * Gets active user's config settings for the given table
 *
 * @param {Object} state - redux state object
 * @param {String} tableId - table ID
 * @returns {Object} - object containing configuration for the given table's columns
 */
const getTableConfigs = (state, tableId) => {
  const user = selectAccountUser(state)
  return user?.tableConfigs?.[tableId]
}

export {
  getActiveUsers,
  getUser,
  getAllUsers,
  getTableConfigs,
}
