import _get from 'lodash/get'

export default function magnitudeFromArticle(article, statSelections, statOverlay) {
  let magnitude
  if(!article[statOverlay]) {
    // there is/was some pretty dangerous parsing here, there is no guarantee a givin risk/article will have a path
    // not sure if defaulting to zero is correct
    switch(statSelections.length) {
      case 1: return { magnitude: article[statSelections[0].path] || 0 }
      case 2: return { magnitude: _get(article, `${[statSelections[0].path]}.${[statSelections[1].path]}`, 0) }
      case 3: return {
        magnitude: _get(
          article,
          `${[statSelections[0].path]}.${[statSelections[1].path]}.${[statSelections[2].path]}`,
          0,
        ),
      }
      default:
        magnitude = article[statSelections[0].path]
        for(let index = 0; index < statSelections.length; index++) {
          magnitude = magnitude[statSelections[index + 1].path]
        }
        return { magnitude }
    }
  }
  let isOverlaid = false
  const articleOverlay = article[statOverlay]
  switch(statSelections.length) {
    case 1:
      if(typeof articleOverlay[statSelections[0].path] !== 'undefined') {
        magnitude = articleOverlay[statSelections[0].path]
        isOverlaid = true
      } else {
        magnitude = article[statSelections[0].path]
      }
      break
    case 2:
      if(typeof articleOverlay[statSelections[0].path][statSelections[1].path] !== 'undefined') {
        magnitude = articleOverlay[statSelections[0].path][statSelections[1].path]
        isOverlaid = true
      } else {
        magnitude = article[statSelections[0].path][statSelections[1].path]
      }
      break
    default:
      magnitude = article[statSelections[0].path]
      for(let index = 0; index < statSelections.length; index++) {
        magnitude = magnitude[statSelections[index + 1].path]
      }
      break
  }
  if(typeof magnitude === 'undefined') magnitude = null
  return { magnitude, isOverlaid }
}
function magnitudesFromArticles(articles, statSelections, statOverlay) {
  return articles.map(article => magnitudeFromArticle(article, statSelections, statOverlay))
}

export { magnitudesFromArticles }
