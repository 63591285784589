import React from 'react'
import { useDispatch } from 'react-redux'

import ConfirmModal from './ConfirmModal'

import actions from '../../actions'
import { useModalData } from '../../redux/hooks/appHooks'

const UsersRemoveRoleConfirmModal = () => {
  const {
    roleName,
    policyId,
    userId,
    userName,
  } = useModalData(UsersRemoveRoleConfirmModal.id)

  const dispatch = useDispatch()
  const onConfirm = () => dispatch(actions.user.removePolicy(null, { policyId, userId }))

  return (
    <ConfirmModal id={UsersRemoveRoleConfirmModal.id} onConfirm={onConfirm}>
      <p>Are you sure you want to remove {userName} from the {roleName} role?</p>
    </ConfirmModal>
  )
}
UsersRemoveRoleConfirmModal.id = 'UsersRemoveRoleConfirmModal'

export default UsersRemoveRoleConfirmModal
