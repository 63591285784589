import { GlobalPolicy } from '@vms/vmspro3-core/dist/types'
import { useSelector } from 'react-redux'

import { LoadingStatus } from '../../utils/appConsts'
import {
  selectCurrentUserLoadingStatus,
  selectAccountUserLoadingStatus,
} from '../user/selectors'
import {
  selectUserPolicies,
  selectPoliciesLoadingStatus,
} from './selectors'

export function useUserPolicies(): [userPolicies: GlobalPolicy[], loading: boolean] {
  const currentUserLoadingStatus = useSelector(selectCurrentUserLoadingStatus)
  const accountUserLoadingStatus = useSelector(selectAccountUserLoadingStatus)
  const policiesLoadingStatus = useSelector(selectPoliciesLoadingStatus)

  // account user is a special case, if it hasn't been requested we are
  // just authorizing with system policies.
  const loading = currentUserLoadingStatus !== LoadingStatus.Loaded
    || policiesLoadingStatus !== LoadingStatus.Loaded
    || accountUserLoadingStatus === LoadingStatus.Requested

  const userPolicies = useSelector(selectUserPolicies)

  return [userPolicies, loading]
}
