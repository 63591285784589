import getAugmentAction from '../../selectors/getAugmentAction'

import userActionTypes from './actionTypes'
import { fetchUserAccounts, setAccountId } from '../account/actions'
import { acceptAccountInvitation, getPendingInvitationsForUser } from '../../services/accountService'
import { getCurrentUser, getAccountUser } from '../../services/userService'

export function fetchCurrentUser(userId) {
  if(!userId) throw new Error('userId is required for fetchCurrentUser action')

  return async dispatch => {
    try {
      dispatch({
        type: userActionTypes.FetchCurrentUserRequest,
        meta: { ephemeral: true },
      })

      const user = await getCurrentUser(userId)
      dispatch({
        type: userActionTypes.FetchCurrentUserSuccess,
        payload: user,
        meta: { ephemeral: true },
      })
    } catch(error) {
      console.error('fetchCurrentUser error: ', error)
    }
  }
}

export function fetchAccountUser(accountId, userId) {
  if(!accountId) throw new Error('accountId is required for fetchAccountUser action')
  if(!userId) throw new Error('userId is required for fetchAccountUser action')

  return async dispatch => {
    try {
      dispatch({
        type: userActionTypes.FetchAccountUserRequest,
        meta: { ephemeral: true },
      })

      const accountUser = await getAccountUser(accountId, userId)
      dispatch({
        type: userActionTypes.FetchAccountUserSuccess,
        payload: accountUser,
        meta: { ephemeral: true },
      })
    } catch(error) {
      console.error('fetchAccountUser error: ', error)
    }
  }
}

export function fetchUserInvitations(email) {
  if(!email) throw new Error('email is required for fetchUserInvitations action')

  return async dispatch => {
    try {
      dispatch({
        type: userActionTypes.FetchUserInvitationsRequest,
        meta: { ephemeral: true },
      })

      const userInvitations = await getPendingInvitationsForUser(email)
      dispatch({
        type: userActionTypes.FetchUserInvitationsSuccess,
        payload: userInvitations,
        meta: { ephemeral: true },
      })
    } catch(error) {
      console.error('fetchUserInvitations error: ', error)
    }
  }
}

export function acceptInvitation(userId: string, email: string, accountId: string, navigate: NavigateFunction) {
  if(!userId) throw new Error('userId required for acceptInvitation action')
  if(!email) throw new Error('email required for acceptInvitation action')
  if(!accountId) throw new Error('accountId required for acceptInvitation action')

  return async (dispatch, getState) => {
    try {
      const augmentAction = getAugmentAction(getState())
      await acceptAccountInvitation(userId, email, accountId, augmentAction)

      // handle invitation state
      dispatch({
        type: userActionTypes.AcceptAccountInvitation,
        payload: { accountId, email },
        meta: { ephemeral: true },
      })

      // refresh user accounts and set accountId
      await dispatch(fetchUserAccounts(userId, navigate))
      dispatch(setAccountId(accountId))
    } catch(error) {
      console.error('acceptInvitation action error: ', error)
    }
  }
}
