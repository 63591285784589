import { ReactElement, useCallback, useMemo } from 'react'

import { Criterion } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { Criteria as CriteriaClass } from '@vms/vmspro3-core/dist/nextgen/criteria'
import { deleteCriterion } from '@vms/vmspro3-core/dist/actions/decision'

import { EntityLabel } from '../EntityLabel'
import { CriterionDescription } from './CriterionDescription'
import { CriterionModalId, CriterionModalData } from '../../../client/modals/CriterionModal'
import { RatingScaleEditor } from './RatingScaleEditor'
import { Button, Modal, Space } from '../../../client/controls'
import { Tree } from '../../Tree'

import style from './Criteria.module.css'
import { useAppDispatch } from '../../../redux'
import { useShowModal } from '../../../redux/hooks/appHooks'
import { useQuerystringValue } from '../../../hooks/useQuerystringValue'
import { useCriteria as useCriteriaData } from '../../../redux/hooks'

function useCriteria(decisionId: string): CriteriaClass {
  const criteriaData = useCriteriaData(decisionId)

  return useMemo(
    () => new CriteriaClass(criteriaData),
    [criteriaData]
  )
}

function renderCriterionLabel(criterion: Criterion) {
  return (
    <EntityLabel
      abbrev={criterion.abbrev}
      color={criterion.color}
      name={criterion.name}
    />
  )
}

export function Criteria({ decisionId }: { decisionId: string }): ReactElement {
  const criteria = useCriteria(decisionId)

  const [selectedCriterionId, setSelectedCriterionId] = useQuerystringValue('criterionId', criteria.perfRoot.id)
  const selectedCriterion = criteria.byId[selectedCriterionId]

  const showCriterionModal = useShowModal<CriterionModalData>(CriterionModalId)
  const onAddChild = useCallback(
    () => {
      if(selectedCriterionId) {
        showCriterionModal({ decisionId, parentId: selectedCriterionId })
      }
    },
    [showCriterionModal, decisionId, selectedCriterionId]
  )
  const onEditCriterion = useCallback(
    () => {
      if(selectedCriterion) {
        showCriterionModal({ decisionId, criterion: selectedCriterion })
      }
    },
    [showCriterionModal, decisionId, selectedCriterion]
  )

  const dispatch = useAppDispatch()
  const onDeleteCriterion = useCallback(
    () => {
      if(!selectedCriterion) {
        return
      }

      const hasDescendants = selectedCriterion.descendants.length > 0

      Modal.confirm({
        title: `Deleting criterion "${selectedCriterion.name}"`,
        content: (
          <>
            <p>
              This action will delete criterion "{selectedCriterion.name}"
              {hasDescendants ? ' and all of its descendants:' : '.'}
            </p>
            {hasDescendants &&
              <>
                <Tree<Criterion>
                  rootNode={selectedCriterion}
                  renderLabel={renderCriterionLabel}
                />
                <br />
              </>
            }
            <p>Are you sure you want to proceed?</p>
          </>
        ),
        onOk: () => {
          const descendantIds = hasDescendants
            ? selectedCriterion.descendants.map(d => d.id)
            : undefined
          const deleteCriterionAction = deleteCriterion(decisionId, selectedCriterion.id, descendantIds)
          dispatch(deleteCriterionAction)
          setSelectedCriterionId()
        },
      })
    },
    [dispatch, decisionId, setSelectedCriterionId, selectedCriterion]
  )

  return (
    <div className={style.criteria}>
      <div>
        <Tree<Criterion>
          rootNode={criteria.perfRoot}
          renderLabel={renderCriterionLabel}
          onSelectItem={setSelectedCriterionId}
          selectedItemId={selectedCriterionId}
        />
        <Space className={style.criteriaControls}>
          <Button
            danger
            disabled={selectedCriterion === criteria.perfRoot}
            onClick={onDeleteCriterion}
          >
            Delete
          </Button>
          <Button
            type="primary"
            disabled={!selectedCriterion}
            onClick={onAddChild}
          >
            Add Child
          </Button>
        </Space>
      </div>
      <div className={style.selectedCriterion}>
        {selectedCriterion && (
          <>
            <div className={style.selectedCriterionDescription}>
              <CriterionDescription
                name={selectedCriterion.name}
                description={selectedCriterion.description}
              />
              <div className={style.criteriaControls}>
                <Button onClick={onEditCriterion}>Edit</Button>
              </div>
            </div>
            <RatingScaleEditor
              key={selectedCriterionId}
              decisionId={decisionId}
              criterion={selectedCriterion}
            />
          </>
        )}
      </div>
    </div>
  )
}
