import { getNumberSort } from '@vms/vmspro3-core/dist/utils/sortUtils'

/**
 * Count risks by type, qualitative proability, phase, and category.
 *
 * @param {Array} risks
 * @param {Object} riskContext - lens thru which we are interpreting the batch of risks
 * @returns {Object} - totals by risk key: "type", "probQual" (qualitative proability),
 *   "phase", and "category".  Note that type values that don't have any associated risks
 *   *will* be present in the object, with a count of 0.  All valid (non-deleted) values
 *   as described by the risk context will be present.
 */
export const getRiskAggregateData = (risks, riskContext) => {
  const { types, riskScales } = riskContext

  const initialValues = {
    type: types.riskType.values.reduce((obj, v) => Object.assign(obj, { [v.value]: 0 }), {}),
    probQual: riskScales.prob.reduce((obj, v) => Object.assign(obj, { [v.key]: 0 }), {}),
    phase: types.phase.values
      .filter(v => !v.isDeleted)
      .reduce((obj, v) => Object.assign(obj, { [v.value]: 0 }), {}),
    category: types.category.values
      .filter(v => !v.isDeleted)
      .reduce((obj, v) => Object.assign(obj, { [v.value]: 0 }), {}),
  }

  return risks.reduce((totals, risk) => {
    // the typeof checks below prevent risks that reference deleted categories / phases won't wount
    risk.category && typeof totals.category[risk.category] === 'number' && totals.category[risk.category]++
    risk.phase && typeof totals.phase[risk.phase] === 'number' && totals.phase[risk.phase]++
    risk.type && totals.type[risk.type]++
    totals.probQual[risk.prob.qual]++

    return totals
  }, initialValues)
}

/**
 * funtion for sorting and returning top N risks.
 *
 * @param {Array} risks
 * @param {number} nRisks - total number of top risks desired to be returned in sorted arrays
 * @returns {Object} - risks by different top N keys. Currently, this returns arrays for cost expected value
 * and total severity.
 */
export const getTopRisks = (risks, nRisks = 5) => {
  const _risks = risks.map(r => {
    const parentId = r.ancestry.split('/').pop()
    const riskUrl = `/proj/${parentId}/risk/${r.id}`
    const ev = r.managed?.impact?.cost?.quant?.ev?.value || r.impact?.cost?.quant?.ev?.value
    const totalSeverity = r.managed?.severity?.total || r.severity?.total
    return {
      name: r.name,
      id: r.id,
      totalSeverity,
      ev,
      riskUrl,
    }
  })
  const sortedRisksByEv = _risks.slice().sort(getNumberSort('ev', true))
  const topRisksByCostEv = sortedRisksByEv.splice(0, nRisks)

  const sortedRisksBySeverity = _risks.slice().sort(getNumberSort('totalSeverity', true))
  const topRisksBySeverity = sortedRisksBySeverity.splice(0, nRisks)

  return { topRisksByCostEv, topRisksBySeverity }
}
