/** @jsxImportSource @emotion/react */
import React from 'react'
import moment from 'moment'
import { keyframes } from '@emotion/react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Color } from '@vms/vmspro3-core/dist/systemConsts'

import Image from '../../client/components/common/Image'
import { Button } from '../../client/controls'
import { MenuToggle } from '../Menu'
import { UserMenu } from './UserMenu'

import { useIsAuthenticated } from '../../redux/auth/hooks'
import { selectAccountName } from '../../redux/account/selectors'
import { screenSizeMd, screenLessThanMd } from '../../client/styles/style-consts'

function ActionQueueSaveStatus() {
  const [saveStatus, setSaveStatus] = React.useState('')
  const { actionQueue } = window
  React.useEffect(() => {
    if(!actionQueue) return
    const updateSaveStatus = () => {
      const { queue, batchInfo: { current, previous } } = actionQueue
      setSaveStatus(queue.length || current
        ? 'Saving...'
        : previous
          ? <span css={style.fadeOut}>Saved {moment(previous.finish).fromNow()}</span>
          : ''
      )
    }
    actionQueue.addListener(updateSaveStatus)
    return () => actionQueue.removeListener(updateSaveStatus)
  }, [actionQueue])

  return (
    <span>
      {saveStatus}
    </span>
  )
}

export function Header() {
  const { pathname } = useLocation()

  const [isAuthenticated] = useIsAuthenticated()
  const accountName = useSelector(selectAccountName)

  const isSmallScreen = useMediaQuery({ maxWidth: screenSizeMd })
  const buttonSize = isSmallScreen ? 'small' : undefined

  return (
    <header css={style.container}>
      <div>
        {isAuthenticated && <MenuToggle />}
        <Link to='/'>
          <Image path="logo-nav.png" css={style.logo} alt="Logo" />
        </Link>
        <span css={style.accountName}>{accountName}</span>
      </div>
      <div>
        {isAuthenticated
          ? (
            <>
              {!isSmallScreen && <ActionQueueSaveStatus css={style.actionQueueSaveStatus} />}
              <UserMenu />
            </>
          )
          : pathname === '/' && (
            <>
              <Link to="/signup">
                <Button type="primary" size={buttonSize}>Get Started</Button>
              </Link>
              <Link to="/signin">
                <Button size={buttonSize}>Sign In</Button>
              </Link>
            </>
          )
        }
      </div>
    </header>
  )
}

const style = {
  container: {
    '--header-child-margin': '20px',
    '--header-padding': '0 24px',
    [screenLessThanMd]: {
      '--header-child-margin': '12px',
      '--header-padding': '0 12px',
    },
    height: 'var(--header-height)',
    padding: 'var(--header-padding)',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Color.BLUE_DARK,
    color: Color.WHITE,
    '> *': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      '> :not(:last-child)': {
        marginRight: 'var(--header-child-margin)',
      },
    },
  },
  accountName: {
    [screenLessThanMd]: {
      display: 'none',
    },
  },
  actionQueueSaveStatus: {
    [screenLessThanMd]: {
      display: 'none',
    },
  },
  logo: {
    display: 'inline-block',
    height: '28px',
    [screenLessThanMd]: {
      height: '20px',
    },
  },
  fadeOut: {
    animation: `${keyframes({ from: { opacity: 1 }, to: { opacity: 0 } })} 4s ease forwards`,
  },
}
