import React from 'react'
/** @jsxImportSource @emotion/react */
import { COLOR } from '../_riskConfig/color'

export default function Chunk({
  article,
  changeCurrentTooltip,
  chunkMargin,
  columnWidth,
  greatestMagnitude,
  hasRelativeScale,
  pinnedTooltip,
  pinTooltip,
}) {

  let elementWidth = columnWidth
  let elementHeight = columnWidth
  if(hasRelativeScale) {
    elementHeight = columnWidth * Math.abs(article.magnitude) / greatestMagnitude
    if(Math.abs(article.magnitude / greatestMagnitude) <= 0.5) {
      elementWidth = (columnWidth - chunkMargin) / 2
      elementHeight *= (columnWidth / elementWidth)
    }
    // helpful for checking whether your chunks are being sized properly
    // console.log(
    //   "\\\\\\\\", article.name, "is",
    //   elementHeight,
    //   "tall\n//// this article is",
    //   100 * Math.abs(article.magnitude / greatestMagnitude)
    //   + "% the size of the leader")
  }

  const focusStyle = {
    zIndex: 1000,
    boxShadow: '0 0 0 2px #000000cc',
    '*': {
      opacity: 1,
    },
  }

  return (
    <div
      id={article.id}
      css={{
        width: `${elementWidth}px`,
        height: `${elementHeight}px`,
        backgroundColor: COLOR.GREY_2L,
        cursor: 'pointer',
        position: 'relative',
        paddingBottom: `${chunkMargin}px`,
        paddingLeft: `${chunkMargin}px`,
        marginTop: `${chunkMargin}px`,
        marginLeft: `${chunkMargin}px`,
        transitionProperty: 'height, width, flex, border, box-shadow',
        transitionDuration: '0.1s',
        fontSize: `${elementHeight * 0.33}px`,
        lineHeight: '1em',
        overflow: 'hidden',
        fontWeight: '530',
        ...pinnedTooltip === article ? focusStyle : {},
        '*': {
          opacity: 1,
        },
        '&:hover,&:focus': focusStyle,
        '&:active': {
          border: `6px solid ${COLOR.GREY_1DR}`,
          boxShadow: '0 0 0 0px #00000000',
          zIndex: '1000',
        },
      }}
      value={article}
      onMouseEnter={() => changeCurrentTooltip(article)}
      onMouseLeave={() => changeCurrentTooltip(undefined)}
      onClick={() => pinTooltip(article)}
    >
      <div
        css={{
          width: `${elementWidth}px`,
          height: `${elementHeight}px`,
          backgroundColor: article.color,
          position: 'relative',
          top: '0px',
          right: `${chunkMargin}px`,
        }}
      />
    </div>
  )
}
