const SimulateAuthzErrorActionType = 'Simulate AuthZ Error'
function simulateAuthzError(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: 'Simulate AuthZ Error',
    payload,
    meta,
  }
}
simulateAuthzError.toString = () => SimulateAuthzErrorActionType

const SimulateServerErrorActionType = 'Simulate Server Error'
function simulateServerError(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: SimulateServerErrorActionType,
    payload,
    meta,
  }
}
simulateServerError.toString = () => SimulateServerErrorActionType

const PingActionType = 'Ping'
function ping(payload: Record<string, unknown>, meta: Record<string, unknown>) {
  return {
    module: 'System',
    type: PingActionType,
    payload,
    meta,
  }
}
ping.toString = () => PingActionType

export default {
  simulateAuthzError,
  simulateServerError,
  ping,
}
