// this opens up the door to stripping test automation IDs for production builds if we decide that's valuable
const testIdsEnabled = true

/**
 * Utility function to generate a test automation ID for a React component.
 * Usage in JSX context:
 *
 *   <span {...testId('foo')}>{foo}</span>
 *   <button {...testId('fooButton')}>Foo</span>
 *
 * If test IDs are enabled, the resulting HTML will be:
 *
 *   <span data-testid="foo">Value of Foo</span>
 *   <button data-testid="fooButton">Foo</button>
 *
 * Allowing targeting by test automation tools.  This can be applied to
 * any component or built-in HTML tag in JSX context.
 *
 * While test IDs do not necessarily need to be unique, having duplicate
 * test IDs on the same page could interefere with your test automation
 * functioning as expected.
 */
const testId = id => (testIdsEnabled ? { 'data-testid': id } : {})

export { testId }
