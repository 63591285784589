import { ButtonProps } from 'antd'
import { useCallback } from 'react'
import { useNavigate, To, NavigateOptions } from 'react-router-dom'
import Button from './Button'

interface ButtonLinkProps extends ButtonProps {
  to: To,
  options?: NavigateOptions,
}
/**
 * Button that serves as a React Router link (which is distinct from
 * LinkButton, which is a button that appears like a link).
 */
const ButtonLink = ({
  to,
  options,
  ...props
}: ButtonLinkProps) => {
  const navigate = useNavigate()
  const onClick = useCallback(
    () => navigate(to, options),
    [navigate, to, options]
  )

  return (
    <Button onClick={onClick} {...props} />
  )
}

export default ButtonLink
