import { ReactElement } from 'react'
import { MissingPerformanceRatingInfo } from '@vms/vmspro3-core/dist/nextgen/valueGraph/performanceValueNode'

import FormModal from './FormModal'

import { useModalData } from '../../redux/hooks/appHooks'
import { Criterion } from '@vms/vmspro3-core/dist/nextgen/Criterion'

function UnratedCriteriaContents(
  {
    unratedCriteria,
  }: {
    unratedCriteria: Criterion[],
  }
) {
  return (
    <>
      <h3>Not all criteria have been prioritized.</h3>
      <p>Performance can only be calculated when all criteria have been
        prioritized.  The following criteria are missing priorities:</p>
      <ul>
        {unratedCriteria.map(c =>
          <li key={c.id}>
            {c.label({ skipAncestors: 2 })}
          </li>
        )}
      </ul>
    </>
  )
}

function MissiongOptionRatingContents(
  {
    optionName,
    missingRatings,
  }: {
    optionName: string,
    missingRatings: MissingPerformanceRatingInfo[],
  }
) {
  return (
    <>
      <h3>Option "{optionName}" is missing ratings.</h3>
      <p>Performance can only be calculated when options have been rated
        against all criteria.  The following criteria are missing ratings
        for this option:</p>
      <ul>
        {missingRatings.map(mr =>
          <li key={mr.criterion.id}>
            {mr.criterion.label({ skipAncestors: 2 })}
          </li>
        )}
      </ul>
    </>
  )
}

export const ValuemetricsMissingRatingsInfoModalId = 'ValuemetricsMissingRatingsInfoModal'
/**
 * Displays missing ratings for a single option.  Important note: this assumes that
 * the missingRatings prop contains only missing ratings for the same option, and there
 * is at least one missing rating.
 */
export function ValuemetricsMissingRatingsInfoModal(): ReactElement {
  const { optionName, missingRatings, unratedCriteria } =
    useModalData<ValuemetricsMissingRatingsInfoModalData>(ValuemetricsMissingRatingsInfoModalId)

  return (
    <FormModal
      modalId={ValuemetricsMissingRatingsInfoModalId}
      title="Missing Ratings"
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      {unratedCriteria.length > 0
        ? <UnratedCriteriaContents unratedCriteria={unratedCriteria} />
        : <MissiongOptionRatingContents optionName={optionName} missingRatings={missingRatings} />
      }
    </FormModal>
  )
}
ValuemetricsMissingRatingsInfoModal.id = ValuemetricsMissingRatingsInfoModalId
export type ValuemetricsMissingRatingsInfoModalData = {
  decisionId: string,
  optionName: string,
  missingRatings: MissingPerformanceRatingInfo[],
  unratedCriteria: Criterion[],
}
