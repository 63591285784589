/* @jsxImportSource @emotion/react */
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AssumeAuthzType } from '@vms/vmspro3-core/dist/systemConsts'
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined'
import WarningOutlined from '@ant-design/icons/WarningOutlined'
import { blue } from '@ant-design/colors'

import UserRolesForTable from './UserRolesForTable'
import UserTableFilter from '../filters/UserTableFilter'
import { Button, Menu, Dropdown, Table, Modal } from '../controls'

import actions from '../../actions'
import useAuthz from '../../hooks/useAuthz'
import useEnforceUsersQuota from '../../hooks/useEnforceUsersQuota'
import { useUsers } from '../../utils/hooks'
import { assumeAuthz } from '../../utils/authzUtils'
import { selectAuthUserId } from '../../redux/auth/selectors'

const UsersTable = () => {
  const authz = useAuthz()
  const dispatch = useDispatch()
  const authUserId = useSelector(selectAuthUserId)

  const [users, loadingUsers] = useUsers()

  const sortedUsers = useMemo(
    () => Object.values(users)
      .sort((a, b) => {
        if(a.disabled && !b.disabled) return 1
        if(b.disabled && !a.disabled) return -1
        return 0
      }),
    [users]
  )

  const [enforceUsersQuota, loadingEnforceUsersQuota] = useEnforceUsersQuota()

  const navigate = useNavigate()
  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'fullName',
        render: (fullName, { id: userId }) => (
          <Button
            size="small"
            type="link"
            onClick={() => navigate(`/user/${userId}`)}
          >
            {fullName}
          </Button>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'Role(s)',
        dataIndex: 'policyIds',
        render: policyIds => <UserRolesForTable policyIds={policyIds} />,
      },
      {
        title: 'Status',
        dataIndex: 'disabled',
        render: disabled => disabled ? 'Deactivated' : 'Active',
      },
      {
        align: 'center',
        render: (_, { id: userId, fullName, disabled }) => {
          const menuItems = []

          if(disabled) {
            const activateUserAction = actions.user.enable(undefined, { userId })
            if(authz(activateUserAction)) {
              menuItems.push(
                <Menu.Item
                  key="activate-user"
                  disabled={loadingEnforceUsersQuota}
                  onClick={enforceUsersQuota(
                    () => Modal.confirm({
                      title: 'Activate ' + fullName,
                      content: 'This user account will be reactivated. Are you sure you want to continue?',
                      onOk: () => dispatch(activateUserAction),
                    })
                  )}
                >
                  Activate
                </Menu.Item>
              )
            }
          } else {
            const impersonateUserAction = actions.user.assumeAuthz({ userId }, {
              type: AssumeAuthzType.IMPERSONATE_USER,
              authUserId,
            })
            if(authz(impersonateUserAction)) {
              menuItems.push(
                <Menu.Item
                  key="impersonate-user"
                  onClick={() => assumeAuthz(authz, impersonateUserAction)}
                >
                  Impersonate User
                </Menu.Item>
              )
            }

            const deactivateUserAction = actions.user.disable(undefined, { userId, authUserId })
            if(authz(deactivateUserAction)) {
              menuItems.push(
                <Menu.Item
                  key="deactivate-user"
                  onClick={() => Modal.confirm({
                    icon: <WarningOutlined />,
                    title: 'Deactivate ' + fullName,
                    content: 'This user will be deactivated. They will no longer be able to sign into ' +
                      'this account. Are you sure you want to continue?',
                    okText: 'Deactivate ' + fullName,
                    onOk: () => dispatch(deactivateUserAction),
                  })}
                >
                  Deactivate
                </Menu.Item>
              )
            }
          }

          if(!menuItems.length) return null

          return (
            <Dropdown
              placement="bottomRight"
              overlay={<Menu>{menuItems}</Menu>}
            >
              <EllipsisOutlined style={{ fontSize: '20px', color: blue.primary, cursor: 'pointer' }} />
            </Dropdown>
          )
        },
      },
    ],
    [authUserId, authz, dispatch, navigate, enforceUsersQuota, loadingEnforceUsersQuota]
  )

  return (
    <>
      <UserTableFilter users={sortedUsers}>
        {filteredUsers => (
          <Table
            loading={loadingUsers}
            onRow={row => {
              if(row.disabled) {
                return {
                  style: {
                    backgroundColor: '#eeeeee',
                  },
                }
              }
            }}
            columns={columns}
            dataSource={filteredUsers}
          />
        )}
      </UserTableFilter>
    </>
  )
}

export default UsersTable
