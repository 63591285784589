import { CellValue, Renderer } from 'react-table'
import { match } from 'ts-pattern'
import { NumberWithStats } from '@vms/vmspro3-core/dist/stats/quartiles'

function formatNumber(
  n: number | undefined,
  options: getNumberCellRendererOptions = {},
) {
  const {
    NaNValue = null,
    InfinityValue = '∞',
    NegativeInfinityValue = '-∞',
    formatter = Intl.NumberFormat(),
  } = options
  if(typeof n !== "number" || Number.isNaN(n)) return NaNValue
  if(n === Infinity) return InfinityValue
  if(n === -Infinity) return NegativeInfinityValue
  return formatter.format(n)
}

type getNumberCellRendererOptions = {
  NaNValue?: string | null
  InfinityValue?: string | null
  NegativeInfinityValue?: string | null
  formatter?: Intl.NumberFormat
}
/**
 * Returns a renderer component for rendering numbers  Allows you to pass
 * in a Intl.NumberFormat instance for number formatting, and can specify
 * behavior for NaN and infinite values.
 */
export function getNumberCellRenderer(options: getNumberCellRendererOptions = {}): Renderer<CellValue> {
  return ({ value }) => formatNumber(value, options)
}

/**
 * Given a NumberWithStats type, this will return some JSX decorated with:
 *   - An up arrow if the number is a maximum
 *   - A down arrow if the number is a minimum
 *   - No arrows if the number is a minimum AND a maximum (in the case where there
 *       is only one number, or all numbers are equal)
 *   - Bold + orange if the number is an outlier
 */
export function renderNumberWithStats(n: NumberWithStats, options: getNumberCellRendererOptions) {
  const { value, isMax, isMin, isOutlier } = n
  const numStr = formatNumber(value, options)
  const style = isOutlier
    ? { fontWeight: 'bold', color: '#be5504' } as const
    : {}
  const prefix = match({ isMax, isMin })
    .with({ isMin: true, isMax: false }, () => <>⇣&nbsp;</>)
    .with({ isMin: false, isMax: true }, () => <>⇡&nbsp;</>)
    .otherwise(() => '')
  return <span style={style}>{prefix}{numStr}</span>
}

/**
 * Returns a renderer component for rendering numbers  Allows you to pass
 * in a Intl.NumberFormat instance for number formatting, and can specify
 * behavior for NaN and infinite values.
 */
export function getNumberCellRendererWithStats(
  options: getNumberCellRendererOptions = {}
): Renderer<CellValue<{ value?: NumberWithStats }>> {
  return cell => {
    if(!cell.value) return options.NaNValue || null
    return renderNumberWithStats(cell.value, options)
  }
}
